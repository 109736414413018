import React, { useEffect, useState } from 'react';
import BuildingsTable from './BuildingsTable';
import { Typography } from '@mui/material';
import { ROUTE_REGION_AND_BUILDING_DATA } from '../routes/Routes';
import { useAuth } from '../hooks/useAuth';
import './css/Buildings.scss';

const Buildings = () => {
  const pageTitle = "Region & Building Data";
    const [forceReload, setForceReload] = React.useState(false);
    const { user } = useAuth();
    const [allowEditing, setAllowEditing] = useState(false);
    const [allowCreate, setAllowCreate] = useState(false);

    useEffect(() => {
        const permissions = user.role.ModulePermissions?.filter(module => { return (module.Page == ROUTE_REGION_AND_BUILDING_DATA.module) })
        setAllowEditing(permissions?.filter(module => { return (module.Update) }).length > 0)
        setAllowCreate(permissions?.filter(module => { return (module.Create) }).length > 0)
    },[])
  
  return (
    <div>
      <Typography component="div">
        <h1 style={{ color: "#06152B" }}>{pageTitle}</h1>
      </Typography>
          <BuildingsTable forceReload={forceReload} stopForceReload={() => { setForceReload(false) }} allowEditing={allowEditing} allowCreate={allowCreate} />
      <br />
    </div>
  );
}

export default Buildings;
