import { baseUrl } from "../util/Endpoint";
import dayjs from "dayjs";

const GetFetchDownload = async (
  billDate) => {
  if (billDate) {

    const billingDate = dayjs(billDate).utc().format('MM/YYYY')
    const endpoint = baseUrl() + '/v1/CRMADownload/GetCRMASpreadsheets?billingDate=' + encodeURIComponent(billingDate);
    const user = JSON.parse(localStorage.getItem("user"));
    const email = user.email.toString().toLowerCase();

    var myHeaders = new Headers();
    myHeaders.append("accept", "application/zip");
    myHeaders.append("From", email);
    myHeaders.append("Authorization", "Bearer " + + localStorage.getItem("JWT_TOKEN"));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    const billMonth = parseInt(dayjs(billDate).utc().format('MM'));
    let fyMonthNum = billMonth < 10 ? billMonth + 3 : billMonth - 9;
    let fyMonthStr = fyMonthNum.toString().length == 1 ? '0' + fyMonthNum : fyMonthNum;
    let fileName = 'FY' + dayjs(billDate).utc().format('YYYY') + "-" + fyMonthStr + "-" +
      dayjs(billDate).utc().format('MMMM') + ".zip";

    await fetch(endpoint, requestOptions)
      .then((response) => {
        response.blob().then((blob) => {

          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);

          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = fileName;
          alink.click();
        });
      })
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }
}
export default GetFetchDownload;