import React, { useState, useEffect } from "react";
import { useAuth } from '../hooks/useAuth';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ROUTE_USERS, ROUTE_EDIT_USER } from "../routes/Routes";
import GetFetch from "../hooks/GetFetch";
import PutPostPatchFetch from "../hooks/PutPostPatchFetch";
import ClosingAlert from "../components/ClosingAlert";
import { BaseButton } from "../components/BaseButton/BaseButton";
import { BaseTextField } from "../components/BaseTextField/BaseTextField";
import { BaseCheckBox } from "../components/BaseCheckBox/BaseCheckBox";
import { BaseSelectField } from "../components/BaseSelectField/BaseSelectField";
import { BaseDateField } from "../components/BaseDateField/BaseDateField";
import { BaseMultiSelect } from "../components/BaseMultiSelect/BaseMultiSelect";
import IsNullOrEmpty from "../components/IsNullOrEmpty";
import { Content } from "../Enums/contents";
import { DateBox, SelectBox } from "devextreme-react";
import { Dimensions } from "../Enums/dimensions"
import "./UpdateUser.scss";


const { Heights: { inputHeight } } = Dimensions
const {
    Alerts: {
        UserSavedSuccessful
    },
    Buttons: {
        cancel,
        submit,
        MUI: {
            Severity: {
                success,
                warning
            },
            Variant: {
                outlined
            }
        }
    },
    Inputs: {
        labelModes: {
            _floating,
        },
        Checkbox: {
            TieService: {
                checkedMessage,
                tie_text,
                uncheckedMessage
            }
        },
        Dates: {
            invalidEndDateMessage,
            invalidStartDateMessage,
            invalidSeperationDateMessage,
            max,
            min
        },
        EmployeeType,
        EmployeeTypeRefs: {
            EmployeeTypeId,
            EmployeeTypeLabel
        },
        Input_TextField_Labels: {
            contractorCompanyLabel,
            contractStartDateLabel,
            contractEndDateLabel,
            employeeTypeLabel,
            jobTitleLabel,
            regionsLabel,
            separationDateLabel,
            userAccountLabel
        },
        Input_SelectField_Labels: {
            rolesListOptionsLabel,
            statusLabel,
            statusListOptionsLabel,
            userRoleLabel
        },
        Input_SelectField_Ids: {
            rolesListId,
            statusListId
        },
        ValidationMessages: {
            noNull
        }
    },
    Titles: {
        _UserUpdate
    }
} = Content;

const UpdateUser = () => {
    const { title } = ROUTE_EDIT_USER;
    const { state: { selectedRowData } } = useLocation();
    const {
        EDIPI,
        createdDate,
        createdBy,
        contractorCompany,
        contractStartDate, 
        contractEndDate,
        emailAddress,
        employeeType,
        jobTitle,
        regions,
        separationDate,
        tieIsOverride,
        userAccountName,
        userRoleId,
        userStatusId
    } = selectedRowData;
    const { 
        user, 
        user: { Regions } 
    } = useAuth();
    const [_contractorCompany, set_ContractorCompany] = useState(contractorCompany ?? "");
    const [_contractStartDate, set_ContractStartDate] = useState(contractStartDate ?? null);
    const [_contractEndDate, set_ContractEndDate] = useState(contractEndDate?? null);
    const [_employeeType, set_EmployeeType] = useState(employeeType ?? "");
    const [_jobTitle, set_JobTitle] = useState(jobTitle ?? "");
    const [_regions, set_Regions] = useState(regions ?? "");
    const [_separationDate, set_SeparationDate] = useState(separationDate ?? "");
    const [_tieOverride, set_TieOverride] = useState(tieIsOverride);
    const [_userRoleId, set_userRoleId] = useState(userRoleId);
    const [_userStatusId, set_UserStatusId] = useState(userStatusId);

    const [rolesList, setRolesList] = useState();
    const [statusList, setStatusList] = useState();

    const [canSubmitForm, setCanSubmitForm] = useState(false);
    
    const [invalidStartDate, setInvalidStartDate] = useState(true);
    const [invalideEndDate, setInvalidEndDate] = useState(true);
    const [invalidSeperationDate, setInvalidSeperationDate] = useState(true);

    const [errorMessage, setErrorMessage] = useState("");
    const [userDataSaved, setUserDataSaved] = useState(false);
    const [userDataFailed, setUserDataFailed] = useState(false);

    const navigate = useNavigate();
    const navigateToUsersRoute = () => navigate(ROUTE_USERS.withSlash);
    const textFieldStylesTwo = {
        m: 1,
        width: '11.5rem',
        margin: '1.5rem 1rem'
    };
    const selectStylesThree = {
        m: 1,
        width: '10rem',
        margin: '1rem'
    };
    useEffect(() => {
        document.title = title;
        handleSettingRoles();
        handleSettingStatuses();
    }, [])

    useEffect(() => {
        enableFormSubmisson();
    }, [
        _contractorCompany,
        _contractEndDate,
        _contractStartDate,
        _employeeType,
        _jobTitle,
        _regions,
        _separationDate,
        _userStatusId,
        _userRoleId
    ]);
   
    const enableFormSubmisson = () => {
        const result = (
            maximumContractEndDateValidation(max, _contractEndDate)
            && maximumContractStartDateValidation(max, _contractStartDate)
            && maximumSeperationDateValidation(max, _separationDate)
            && minimumContractEndDateValidation(min, _contractEndDate)
            && minimumContractStartDateValidation(min, _contractStartDate)
            && minimumSeperationDateValidation(min, _separationDate)
            && validateEmployeeType(_employeeType)
            && validateEndDate(_contractEndDate)
            && validateStartDate(_contractStartDate)
            && validateFields(_separationDate)
            && validateFields(_userRoleId)
            && validateUserStatus(_userStatusId)
            && validateRegions(_regions)
            && validateSychronizatedDates(_contractStartDate, _contractEndDate)
        );

        setCanSubmitForm(result);
    };
    const validateStartDate = (value)=>{
        return _employeeType == "Federal" || value ;
    
    }
    const validateEndDate = (value)=>{
        return _employeeType == "Federal" || value ;
       
    }
    const validateFields = (value) => {
        if (IsNullOrEmpty(value)) { 
            return false;
        };
        
        return true;
    };
    
    const validateUserStatus = (value)=> {
        return value > 0 ;
    };

    const handleSettingRoles = async () => {
        const { Message, Success, Errors } = await GetFetch('/v1/SystemRoles/GetRoles/');
        if (Success) {
            setRolesList(Message);
        };

        if (Errors) {
            return;
        };
    };

    const handleSettingStatuses = async () => {
        const { Message, Success, Errors } = await GetFetch('/v1/SystemUser/GetUserStatuses/');;
        if (Success) {
            setStatusList(Message);
        };

        if (Errors) {
            return;
        };
    };

    /**
     * @description Below are methods for handling validiation on individaul fields, as well as rules with dates
     * @todo Simplify this logic for validation by refactoring  
     */
    
    const maximumContractStartDateValidation = (maximumDate, contractstartdate) => {
        if (_employeeType == "Contractor" && new Date(contractstartdate) > maximumDate) {
            setInvalidEndDate(false);
            return false;
        };

        return true;
    };
    
    const maximumContractEndDateValidation = (maximumDate, contractenddate) => {
       
        if ( _employeeType == "Contractor" && new Date(contractenddate) > maximumDate) {
            setInvalidEndDate(false);
            return false;
        };

        return true;
    };

    const maximumSeperationDateValidation = (maximumDate, seperationdate) => {
        if (new Date(seperationdate) > maximumDate) {
            setInvalidSeperationDate(false);
            return false;
        };
        
        return true;
    };
    
    const minimumContractStartDateValidation = (minimumDate, contractstartdate) => {
        if (_employeeType == "Contractor" && new Date(contractstartdate) < minimumDate) {
            setInvalidStartDate(false);
            return false;
        };
        
        return true;
    };
    
    const minimumContractEndDateValidation = (minimumDate, contractenddate) => {
        if (_employeeType == "Contractor" && new Date(contractenddate) < minimumDate) {
            setInvalidStartDate(false);
            return false;
        };
        
        return true;
    };
    
    const minimumSeperationDateValidation = (minimumDate, seperationdate) => {
        if (new Date(seperationdate) < minimumDate) {
            setInvalidSeperationDate(false);
            return false;
        };

        return true;
    };
    
    const validateEmployeeType = (value) => {
        if (value && value === "Contractor") {
            return true;
        };

        if (value && value === "Federal") {
            return true;
        };
    
        return false;
    };
    
    const validateRegions = (value) => {
        if (value.length === 0) {
            return false;
        } 
        
        return true;
    };

    const validateSychronizatedDates = (startDate, endDate) => {
        if (new Date(startDate) > new Date(endDate)) {
            return false;
        };
        
        return true;
    };

    const handleSubmit = async () => {
        try {
            let today = new Date();
            let month = today.getMonth() + 1;
            let numberedDay = today.getDate();
            let year = today.getFullYear();
            const today_utc = `${month}/${numberedDay}/${year}`;

            const userObject = {
                "EDIPI": EDIPI,
                "CreatedBy": createdBy,
                "CreatedDate": createdDate,
                "ContractStartDate": _contractStartDate,
                "ContractEndDate": _contractEndDate,
                "ContractorCompany": _contractorCompany,
                "EmailAddress": emailAddress,
                "EmployeeType": _employeeType,
                "JobTitle": _jobTitle,
                "Regions": _regions,
                "SeparationDate": _separationDate,
                "TieIsOverride": _tieOverride,
                "UpdatedBy": user.email,
                "UpdatedDate": today_utc,
                "UserAccountName": userAccountName,
                "UserRoleId": _userRoleId,
                "UserStatusId": _userStatusId,
            };

            const route = "/v1/SystemUser/ModifyUser/";
            const { Success, Errors }  = await PutPostPatchFetch(route, "POST", userObject);

            if (Success) {
                setUserDataSaved(true);
                setUserDataFailed(false);
                props.close(true);
            } else {
                setUserDataSaved(false);
                setUserDataFailed(true);
                setErrorMessage(Errors[0].Message)
            }
        }
        catch (ex) {
            console.log(`The request for modifying a user did not submit: ${ex}`)
        };
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit();
        };
    };

    const setUserStatusIdd = (event) => {
        if (event && event.selectedItem) set_UserStatusId(event.selectedItem.StatusId);
    };

    const setEmployeeType = (event) =>{
        if (event && event.selectedItem) set_EmployeeType(event.selectedItem.id);
    };
    
    const setUserRole = (event) =>{
        if (event && event.selectedItem) set_userRoleId(event.selectedItem.RoleId);
    };
  const onEmployeeTypeChanged = (newValue)=> {
    set_EmployeeType(newValue?.value || ''); // Set the value to the selected option's value
    if (newValue?.value === "Federal") {
        set_ContractStartDate(null);
        set_ContractEndDate(null);
    }
  }
    return (
        <div>
            <div>
                <h1>{ title }</h1>
            </div>
            <form className="inline__div--wrapper" method="post">
                <BaseTextField 
                    disabled={false}
                    hoverStateEnabled={false}
                    inputAttribute={{"aria-label": "User Account"}}
                    label={userAccountLabel}
                    labelmode={_floating}
                    onEnterKeyDown={handleKeyDown}
                    readOnly={true}
                    value={userAccountName}
                    validationMessage={noNull}
                />
                <BaseTextField 
                    inputAttribute={{"aria-label": "Contractor Company"}}
                    isValueValid={true}
                    label={contractorCompanyLabel}
                    labelMode={_floating}
                    onEnterKeyDown={handleKeyDown}
                    value={_contractorCompany}
                    valueUpdatedByUser={
                        ({value}) => set_ContractorCompany(value) 
                    }
                    isRequired = {false}
                />
                <span className="inline__span--textbox-wrapper">
                <DateBox               
                dateSerializationFormat="MM/dd/yyyy"
                useMaskBehavior = {true}
                displayFormat = "MM/dd/yyyy"
                height={inputHeight} 
                inputAttr={{"aria-label": "Contract Start Date"}}
                isValid={!(!_contractStartDate && _employeeType === "Contractor")}
                label="Contract Start Date"
                max={max}
                min={min}
                dateOutOfRangeMessage="date is not in acceptable date range"
                onValueChanged={(e)=> set_ContractStartDate(e.value) }
                showDropDownButton={false}
                type="date"              
                value={_contractStartDate ?? null}                
                   />  </span>
                <span className="inline__span--textbox-wrapper">   
                <DateBox             
                dateSerializationFormat="MM/dd/yyyy"
                useMaskBehavior = {true}
                displayFormat = "MM/dd/yyyy"
                height={inputHeight} 
                inputAttr={{"aria-label": "Contract End Date"}}
                isValid={!(!_contractEndDate && _employeeType === "Contractor")}
                label="Contract End Date"
                max={max}
                min={min}
                dateOutOfRangeMessage="date is not in acceptable date range"
                onValueChanged={(e) => set_ContractEndDate(e.value) }
                showDropDownButton={false}
                type="date"
                value={_contractEndDate ?? null}
                   />              
                </span>
                <BaseTextField 
                    inputAttribute={{"aria-label": "Job Title"}}
                    isValueValid={true}
                    label={jobTitleLabel}
                    labelMode={_floating}
                    onEnterKeyDown={handleKeyDown}
                    value={_jobTitle}
                    valueUpdatedByUser={
                        ({value}) => set_JobTitle(value)
                    }
                    isRequired = {false}
                />
            <span className="inline__span--textbox-wrapper">                      
            <SelectBox
                    dataSource={EmployeeType}
                    displayExpr={EmployeeTypeLabel}
                    height={inputHeight}
                    isValid={_employeeType !== '' ? true : false}
                    inputAttr={{"aria-label": "Employee Type"}}
                    label={employeeTypeLabel}
                    labelMode={_floating}
                    onEnterKey={handleKeyDown}             
                    onSelectionChanged={setEmployeeType}
                    searchEnabled={true}
                    value={_employeeType}
                    valueExpr={EmployeeTypeId}
                    visible={true}
                />              
           </span>

                <BaseDateField 
                    dateOutOfRangeMessage={invalidSeperationDateMessage}
                    earliestDate={min}
                    inputAttr={{"aria-label": "Separation Date"}}
                    isValid={invalidSeperationDate}
                    label={separationDateLabel}
                    latestDate={max}
                    showDropDownButton={false}
                    value={ _separationDate }
                    valueUpdatedByUser={
                        ({value}) => set_SeparationDate(value)
                    }
                />
                <BaseSelectField 
                    data={statusList}
                    inputAttribute={{"aria-label": "User Status ID"}}
                    label={statusLabel}
                    labelMode={_floating}
                    onEnterKeyDown={handleKeyDown}
                    optionsLabel={statusListOptionsLabel}
                    optionUpdatedByUser={setUserStatusIdd }
                    validationRuleCallback={() => !IsNullOrEmpty(_userStatusId)}
                    value={_userStatusId}
                    valueExpr={statusListId}
                />
                <BaseSelectField 
                    data={rolesList}
                    inputAttribute={{"aria-label": "User Role ID"}}
                    label={userRoleLabel}
                    labelMode={_floating}
                    onEnterKeyDown={handleKeyDown}
                    optionsLabel={rolesListOptionsLabel}
                    optionUpdatedByUser={setUserRole}
                    validationRuleCallback={() => !IsNullOrEmpty(_userRoleId)}
                    value={_userRoleId}
                    valueExpr={rolesListId}
                />
                <BaseCheckBox 
                    elementAttr={
                        { "aria-label": tie_text }
                    }
                    onCheckedValueChanged={
                        ({value}) =>  set_TieOverride(value) 
                    }
                    text={tie_text}
                    value={_tieOverride}
                />
                <BaseMultiSelect 
                    data={Regions}
                    inputAttribute={{"aria-label": "Assigned Regions"}}
                    label={regionsLabel}
                    labelMode={_floating}
                    onEnterKeyDown={handleKeyDown}
                    value={_regions}
                    onSelectionChanged={
                        ({value}) => set_Regions(value)
                    }
                />
                <span 
                    className="sr-only" 
                    aria-live="assertive"
                    >
                        { _tieOverride ? checkedMessage : uncheckedMessage }
                </span>
            </form>
            <div className="inline__div--button-wrapper">
                <BaseButton 
                    label={cancel}
                    onClick={navigateToUsersRoute}
                    variant={outlined}
                />
                <BaseButton 
                    ariaDisabled={!canSubmitForm ? true : false}
                    disabled={!canSubmitForm}
                    label={submit}
                    onClick={handleSubmit}
                    variant={outlined}
                />
            </div>
            <div>
                <ClosingAlert
                    clickableIcon={navigateToUsersRoute}
                    enableAlertIcon={true}
                    message={UserSavedSuccessful}
                    severity={success}
                    visible={userDataSaved}
                />
                <ClosingAlert
                    severity={warning}
                    message={errorMessage}
                    visible={userDataFailed}
                    hideAlert={() => { setUserDataFailed(false) }}
                />
            </div>
        </div>
    );

};

export default UpdateUser;