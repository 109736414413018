import {
    useEffect,
    useState,
    useMemo,
    useCallback
} from "react";
import DataGrid, {
    Column,
    Export,
    KeyboardNavigation,
    Paging,
    Pager,
    SearchPanel,
    Summary,
    GroupItem,
    TotalItem,
    GroupPanel
} from 'devextreme-react/data-grid';
import { logExport, useAuth, } from "../hooks/useAuth";
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import CustomStore from 'devextreme/data/custom_store';
import GetFetch from '../hooks/GetFetch';
import ExportGrid from '../components/ExportGrid';

export default function DataGridSpecificCostEstimate({ posts, fiscalYear }) {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const handleExport = (e) => {
        //logExport(user.email)
        ExportGrid(e, `Building_Specific_Cost_Estimate_${fiscalYear}`);
    }

    const costEstimateData = useMemo(() => new CustomStore({
        key: 'ID',
        load: async () => {
            if (posts?.length) {
                const data = posts.map(post => {

                    return {
                        ID: post.PostId,
                        FiscalYear: fiscalYear,
                        Region: post.Building.RegionNumber,
                        BuildingNumber: post.Building.SequenceNumber,
                        TaskOrderId: post.TaskOrderId,
                        BuildingName: post.Building.Name,
                        PostLocationName: post.PostLocation.Name,
                        PostLocationId: post.PostLocation.PostLocationId,
                        StartDate: post.StartDate,
                        EndDate: post.EndDate,
                        CLIN: post.CLIN,
                        PsoCost: post.TotalServiceCosts,
                        CustomerName: post.PostAllocations.OccupancyAgreement?.Customer.AgencyName,
                        CustomerNumber: post.PostAllocations.OccupancyAgreement?.Customer.CustomerId,
                    };
                })
                return data;
            }
            return [];
        }
    }), [posts, fiscalYear]);

    return (
        <>
            <DataGrid
                columnAutoWidth={true}
                dataSource={costEstimateData}
                onCellPrepared={null}
                showBorders={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                onExporting={(e) => handleExport(e)}
                style={{ color: "#06152B" }}                
            >
                <KeyboardNavigation enabled={true} />
                <Paging defaultPageSize={10} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                />
                <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                />
                <GroupPanel visible={true} />                
                <Column dataField="FiscalYear" caption="Fiscal Year" allowColumnDragging={false} />
                <Column dataField="Region" caption="Region" allowColumnDragging={false} />
                <Column dataField="BuildingNumber" caption="Building Number" />
                <Column dataField="BuildingName" caption="Building Name" />
                <Column dataField="CustomerName" caption="Customer Name" />
                <Column dataField="CustomerNumber" caption="Customer Number" allowColumnDragging={false} />
                <Column dataField="TaskOrderId" caption="Task Order Number" allowColumnDragging={false} />
                <Column dataField="CLIN" caption="CLIN" allowColumnDragging={false} />
                <Column dataField="PostLocationName" caption="Post Name" allowColumnDragging={false} />
                <Column dataField="StartDate" caption="Post Start Date" allowColumnDragging={false}/>
                <Column dataField="EndDate" caption="Post End Date" allowColumnDragging={false}/>
                <Column dataField="PsoCost" caption="PSO Fiscal Year Cost" format="currency" allowColumnDragging={false}/>

                <Summary>                   
                    <GroupItem
                        column="PsoCost"
                        summaryType="sum"
                        valueFormat="currency"
                        alignByColumn={true}
                        showInGroupFooter={true} 
                        displayFormat="Total Cost: {0}" />
                    <TotalItem
                        column="PsoCost"
                        summaryType="sum"
                        valueFormat="currency"
                        displayFormat="Total Fiscal Year Cost: {0}"
                        alignByColumn={true} />
                </Summary>
                <Export
                    enabled={true}
                    formats={pageFormats}
                />
            </DataGrid>
        </>
    );
}