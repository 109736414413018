import * as React from "react";
import { useRef } from "react";
import {
    DropDownBox
    , List
} from "devextreme-react";

const RegionMultiSelectUserBased = ({
    _label,
    _regions,
    _setRegions,
    _userRegions,
}) => {
    const listRef = useRef(null);
    const dropDownBoxRef = useRef(null);
    return (
            <DropDownBox
                label={_label}
                labelMode="floating"
                dataSource={_regions}
                height={56}
                width={150}
                ref={dropDownBoxRef}
                value={_regions}
            >
                <List
                    ref={listRef}
                    showSelectionControls={true}
                    dataSource={_userRegions}
                    selectionMode="multiple"
                    onSelectionChanged={(e) => {
                        // console.log(e)
                        let copy = [..._regions];
                        if (e.addedItems.length > 0) {
                            copy.push(e.addedItems[0])
                        }
                        else if (e.removedItems.length > 0) {
                            const index = copy.indexOf(e.removedItems[0])
                            copy.splice(index, 1)
                        }
                        _setRegions(copy);
                    }}
                />
            </DropDownBox>
    );
}

export default RegionMultiSelectUserBased;