import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Export,
    Column,
    MasterDetail
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { pageFormats, allowedPageSizes } from '../../components/GlobalDataGridConfigurations';
import CustomPreparedHeaderCell from '../../components/CustomPreparedHeaderCell';
import InfoIconComponent from '../../components/CustomInfoIcon';
import { FOUOheader, FOUOfooter } from '../../components/FOUO';
import { useAuth } from '../../hooks/useAuth';
import ExportGrid from '../../components/ExportGrid';
import dayjs from 'dayjs';
import { RowExpander } from '../../components/RowExpander/RowExpander';

const UserChanges = ({ data }) => {
    const { user } = useAuth();
    // TODO: clean up audit log database, so we don't need this switch
    const mapUserRoleId = (roleId) => {
        switch (roleId) {
            case 63:
                return "Admin"
            case 64:
                return "HQRevenueUser"
            case 65:
                return "MissionSupportApprover"
            case 66:
                return "NationalDataManager"
            case 67:
                return "RegionalFYCostUser"
            default:
                return `Role ID: ${roleId} no longer exists.`
        }
    }
    // TODO: clean up audit log database, so we don't need this switch
    const mapUserStatuses = (statusId) => {
        switch (statusId) {
            case 1:
                return "Active"
            case 2:
                return "Deactivated"
            case 3:
                return "Terminated"
            case 4:
                return "Suspended"
            default:
                return `Status ID: ${roleId} no longer exists.`
        }
    }
    const dataSource = new CustomStore({
        key: 'AuditTrailNumber',
        load: async () => {
            const mappedData = data.map((element) => {
                let regions = "N/A";
                if(element.ChangedValue.UserByRegions.length > 0)
                {
                   let reg = element.ChangedValue.UserByRegions.filter(i=> i.IsInactive === false).map(x=> x.RegionNumber);
                   if(reg.length > 0)
                   {
                    regions = reg.toString();
                   }
                }
                
                //console.log(element);
                return {
                    AuditTrailNumber: element.AuditTrailNumber ?? "N/A",
                    ChangeType: element.ChangeType ?? "N/A",
                    DateChanged: (element.DateChanged === null || element.DateChanged === "")
                     ? "N/A" : dayjs(element.DateChanged).utc().format("MM/DD/YYYY h:mma"),
                    UpdatedDate: element.ChangedValue.UpdatedDate ?? "N/A",
                    UpdatedBy: element.user ?? "N/A",
                    UserAccountName: element.ChangedValue.UserAccountName ?? "N/A",
                    EmailAddress: element.ChangedValue.EmailAddress ?? "N/A",
                    ContractorCompany: element.ChangedValue.ContractorCompany ?? "N/A",
                    JobTitle: element.ChangedValue.JobTitle ?? "N/A",
                    EmployeeType: element.ChangedValue.EmployeeType ?? "N/A",
                    ContractStartDate: element.ChangedValue.ContractStartDate ?? "N/A",
                    ContractEndDate: element.ChangedValue.ContractEndDate ?? "N/A",
                    SeparationDate: element.ChangedValue.SeparationDate ?? "N/A",
                    RegionNumber: regions,
                    RoleName: (element.ChangedValue.UserRoleI === null)
                        ? mapUserRoleId(element.ChangedValue.UserRoleIid)
                        : element.ChangedValue.UserRoleI.RoleName,
                    Name: (element.ChangedValue.UserStatus === null)
                        ? mapUserStatuses(element.ChangedValue.UserStatusId)
                        : element.ChangedValue.UserStatus.Name,
                    PreviousEntry: element.InitialValue,
                    PreviousRole: (element.InitialValue === null)
                        ? null
                        : (element.InitialValue.UserRoleI === null)
                            ? mapUserRoleId(element.InitialValue.UserRoleIid)
                            : element.InitialValue.UserRoleI.RoleName,
                    PreviousUserStatus: (element.InitialValue === null)
                        ? null
                        : (element.InitialValue.UserStatus === null)
                            ? mapUserStatuses(element.InitialValue.UserStatusId)
                            : element.InitialValue.UserStatus.Name,
                    CurrentEntry: element.ChangedValue
                }

            });
            return mappedData;
        }
    });
    const pdfFont = ()=>
    {    return 8;   }
    const wrapPdfCell = ()=>{
     return true;   }
    return (
        <div>
            <FOUOheader
                dateString={dayjs.utc().format('MM/DD/YYYY h:mma') + " " + "UTC"}
                heading={"Audit - User Changes"}
                userEmail={user.email}
            />
            <DataGrid
                columnAutoWidth={true}
                dataSource={dataSource}
                onCellPrepared={CustomPreparedHeaderCell}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                onExporting={e => ExportGrid(e, "Audit_Report", wrapPdfCell(), pdfFont())}
            >
                <Column type='detailExpand' cellRender={(data) => RowExpander(data,"AuditTrailNumber")} />
                <Column dataField="AuditTrailNumber" caption="Audit Trail Number" visible={false} />
                <Column dataField="DateChanged" caption="Date Changed (UTC)" />
                <Column dataField="ChangeType" caption="Change Type" />
                <Column dataField="UpdatedBy" caption="Changed By" />
                <Column dataField="UserAccountName" caption="User Changed" />
                <Column dataField="EmailAddress" caption="Email" />
                <Column dataField="ContractorCompany" caption="Contractor Company" />
                <Column dataField="JobTitle" caption="Job Title" />
                <Column dataField="EmployeeType" caption="Employee Type" />
                <Column dataField="ContractStartDate" caption="Contract Start Date" />
                <Column dataField="ContractEndDate" caption="Contract End Date" />
                <Column dataField="SeparationDate" caption="Separation Date" />
                <Column dataField="RegionNumber" caption="User Region" />
                <Column dataField="RoleName" caption="Role" />
                <Column dataField="Name" caption="User Status" />

                {/* <FilterRow visible={true} /> */}
                <KeyboardNavigation enabled={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Paging defaultPageSize={10} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                />
                <Export
                    enabled={true}
                    formats={pageFormats}
                />
                <MasterDetail
                    enabled={true}
                    render={({ data }) => RenderTemplate(data)}
                />
            </DataGrid>
            <FOUOfooter />
        </div>
    )
}

const renderGridCell = (cellInfo) => {
    if (cellInfo.column.dataField === 'UserAccountName') {
        if (JSON.stringify(cellInfo.data.PreviousEntry.UserAccountName) !== JSON.stringify(cellInfo.data.CurrentEntry.UserAccountName)) {
            let value = cellInfo.data.PreviousEntry.UserAccountName;
            return (
                <>
                    {value}
                    <InfoIconComponent item={value} key={value} />
                </>
            );
        }
    }
    /* Email */
    if (cellInfo.column.dataField === 'Email') {
        if (JSON.stringify(cellInfo.data.PreviousEntry.Email) !== JSON.stringify(cellInfo.data.CurrentEntry.Email)) {
            let value = cellInfo.data.PreviousEntry.Email;
            return (
                <>
                    {value}
                    <InfoIconComponent item={value} key={value} />
                </>
            );
        }
    }
    /* ContractorCompany */
    if (cellInfo.column.dataField === 'ContractorCompany') {
        if (JSON.stringify(cellInfo.data.PreviousEntry.ContractorCompany) !== JSON.stringify(cellInfo.data.CurrentEntry.ContractorCompany)) {
            let value = cellInfo.data.PreviousEntry.ContractorCompany;

            return (
                <>
                    {value}
                    <InfoIconComponent item={value} key={value} />
                </>
            );
        }
    }
    /* Job Title */
    if (cellInfo.column.dataField === 'JobTitle') {
        if (JSON.stringify(cellInfo.data.PreviousEntry.JobTitle) !== JSON.stringify(cellInfo.data.CurrentEntry.JobTitle)) {
            let value = cellInfo.data.PreviousEntry.JobTitle;

            return (
                <>
                    {value}
                    <InfoIconComponent item={value} key={value} />
                </>
            );
        }
    }
    /* Employee Type */
    if (cellInfo.column.dataField === 'EmployeeType') {
        if (JSON.stringify(cellInfo.data.PreviousEntry.EmployeeType) !== JSON.stringify(cellInfo.data.CurrentEntry.EmployeeType)) {
            let value = cellInfo.data.PreviousEntry.EmployeeType;
            return (
                <>
                    {value}
                    <InfoIconComponent item={value} key={value} />
                </>
            );
        }
    }
    /* Contract Start Date */
    if (cellInfo.column.dataField === 'ContractStartDate') {
        if (JSON.stringify(cellInfo.data.PreviousEntry.ContractStartDate) !== JSON.stringify(cellInfo.data.CurrentEntry.ContractStartDate)) {
            let value = cellInfo.data.PreviousEntry.ContractStartDate;

            return (
                <>
                    {value}
                    <InfoIconComponent item={value} key={value} />
                </>
            );
        }
    }
    /* Contract End Date */
    if (cellInfo.column.dataField === 'ContractEndDate') {
        if (JSON.stringify(cellInfo.data.PreviousEntry.ContractEndDate) !== JSON.stringify(cellInfo.data.CurrentEntry.ContractEndDate)) {
            let value = cellInfo.data.PreviousEntry.ContractEndDate;
            return (
                <>
                    {value}
                    <InfoIconComponent item={value} key={value} />
                </>
            );
        }
    }
    /* Separation Date */
    if (cellInfo.column.dataField === 'SeparationDate') {
        if (JSON.stringify(cellInfo.data.PreviousEntry.SeparationDate) !== JSON.stringify(cellInfo.data.CurrentEntry.SeparationDate)) {
            let value = cellInfo.data.PreviousEntry.SeparationDate;

            return (
                <>
                    {value}
                    <InfoIconComponent item={value} key={value} />
                </>
            );
        }
    }
    /* User Region */
    if (cellInfo.column.dataField === 'RegionNumber') {
        
            if (cellInfo.data.PreviousEntry.UserByRegions.length > 0 &&
                cellInfo.data.PreviousEntry.UserByRegions.map(a=> a.RegionNumber).toString() !== 
                cellInfo.data.CurrentEntry.UserByRegions.map(a=>a.RegionNumber).toString()) {
                let value = cellInfo.data.PreviousEntry.UserByRegions.map(a=> a.RegionNumber).toString();
            return (
                <>
                    {value}
                    <InfoIconComponent item={value} key={value} />
                </>
            );
        
    }
}
    /* Role */
    if (cellInfo.column.dataField === 'PreviousRole') {

        if (cellInfo.data.PreviousEntry.UserRoleIid !== cellInfo.data.CurrentEntry.UserRoleIid) {

            return (
                <>
                    {cellInfo.value}
                    <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                </>
            );

        }
        if (cellInfo.data.PreviousEntry.UserRoleI !== null || cellInfo.data.CurrentEntry.UserRoleI !== null) {
            if (cellInfo.data.PreviousEntry.UserRoleI?.RoleName.trim() !== cellInfo.data.CurrentEntry.UserRoleI?.RoleName.trim()) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
    }
    /* User Status */
    if (cellInfo.column.dataField === 'PreviousUserStatus') {
        if (cellInfo.data.PreviousEntry.UserStatusId !== cellInfo.data.CurrentEntry.UserStatusId) {

            return (
                <>
                    {cellInfo.value}
                    <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                </>
            );
        }
    }
    return cellInfo.value;
}

const RenderTemplate = (values) => {
    if (values.PreviousEntry === null || values.ChangeType === 'Insert') {
        <>No Previous History, record was inserted.</>
    }

    return (
        <>
            {(values.PreviousEntry === null || values.ChangeType === 'Insert') ?
                <>No Previous History, record was inserted.</>
                :
                <DataGrid
                    dataSource={[values]}
                    showBorders={true}
                    columnAutoWidth={true}
                >
                    <Column dataField="UserAccountName" caption="User" cellRender={renderGridCell} />
                    <Column dataField="EmailAddress" caption="Email" cellRender={renderGridCell} />
                    <Column dataField="ContractorCompany" caption="Contractor Company" cellRender={renderGridCell} />
                    <Column dataField="JobTitle" caption="Job Title" cellRender={renderGridCell} />
                    <Column dataField="EmployeeType" caption="Employee Type" cellRender={renderGridCell} />
                    <Column dataField="ContractStartDate" caption="Contract Start Date" cellRender={renderGridCell} />
                    <Column dataField="ContractEndDate" caption="Contract End Date" cellRender={renderGridCell} />
                    <Column dataField="SeparationDate" caption="Separation Date" cellRender={renderGridCell} />
                    <Column dataField="RegionNumber" caption="User Region" cellRender={renderGridCell} />
                    <Column dataField="PreviousRole" caption="Role" cellRender={renderGridCell} />
                    <Column dataField="PreviousUserStatus" caption="User Status" cellRender={renderGridCell} />
                </DataGrid >
            }
        </>
    );
}
export default UserChanges;
