import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_CUSTOMERS } from '../routes/Routes.js';
import { BaseButton } from "../components/BaseButton/BaseButton";
import { Content } from '../Enums/contents';
import { BaseCheckBox } from '../components/BaseCheckBox/BaseCheckBox.js';
import CustomerBuildingSelect from './CustomerBuildingSelect.js';
import { GetDateToday_dashes_reverse } from '../util/GetDateToday.js';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, {
    Item as DataGridItem,
    Editing,
    Column,
    Toolbar,
    Lookup,
    RequiredRule,
    CustomRule,
    KeyboardNavigation,
    SearchPanel,
    Item,
} from 'devextreme-react/data-grid';
import { useAuth } from '../hooks/useAuth.js';
import { BaseTextField } from '../components/BaseTextField/BaseTextField.js';
import IsNullOrEmpty from '../components/IsNullOrEmpty.js';
import US_StatesSelectField from './components/US_StatesSelectField.js';
import ClosingAlert from '../components/ClosingAlert.js';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch.js';
import CustomPreparedHeaderCell from '../components/CustomPreparedHeaderCell.js';
import RegionMultiSelectDevEx from '../Reports/TotalBuildingCostReportComponents/RegionMultiSelectDevEx.js';
import AddBuildingsBasedOnUsersRegion from '../components/AddBuildingsBasedOnUsersRegion/AddBuildingsBasedOnUsersRegion.js';
import { Box } from 'devextreme-react';
import OccupancyAgreementAddAndUpdate from './components/AddOccupancyAgreement.js';
import AddOccupancyAgreement from './components/AddOccupancyAgreement.js';
import UpdateOccupancyAgreement from './components/UpdateOccupancyAgreement.js';

const {
    Alerts: {
        CustomerSavedSuccessful
    },
    Buttons: {
        cancel,
        submit,
        selectBuildingsLabel,
        MUI: {
            Severity: {
                success,
                warning,
                info
            },
            Variant: {
                outlined
            }
        }
    },
    Inputs: {
        labelModes: {
            _floating
        },
        Input_TextField_Labels: {
            addressOneLabel,
            addressTwoLabel,
            cityNameLabel,
            zipCodeLabel,
            customer_labels: {
                agencyNameLabel,
                abNameLabel,
                abShortNameLabel,
                abCodeLabel,
                billedABCodeLabel,
                actualABCodeLabel,
                agencyLocationCodeLabel,
            }
        },
        Input_Validation_Messages: {
            abCodeValidationMessage
        }
    },
    Masks: {
        zipCodeInvalidMessage,
        longZipCodeInvalidMessage,
        zipCodeMask
    }
} = Content;

const CustomersUpdate = () => {
    const {
        state: {
            selectedRowData: {
                Address1,
                Address2,
                CityName,
                StateLocation,
                ZipCode,
                CustomerId,
                AgencyName,
                AgencyBureauName,
                AgencyBureauShortName,
                AgencyBureauCode,
                ActualAgencyBureauCode,
                AgencyLocationCode,
                BusinessEventTypeCode,
                BilledAgencyBureauCode,
                BureauAddressId,
                TreasuryAccSymbol,
                Buildings,
                BuildingIds,
                buildingNumbers,
                address,
                POCFullName,
                SendIPAC,
                SendIpacActual,
                POCsToRecieveSummary,
                PocsToReceiveSummaryActual,
                CustomerPOCFirstName,
                CustomerPOCLastName,
                FundingDocumentNumber,
                OccupancyAgreements,
                CreatedBy,
                CreatedDate,
                BuildingByCustomer
            }
        }
    } = useLocation();
    // console.log(Buildings)
    const { user } = useAuth();
    const [_agencyName, set_AgencyName] = useState(AgencyName ?? "");
    const [_abName, set_ABName] = useState(AgencyBureauName ?? "");
    const [_abShortName, set_ABShortName] = useState(AgencyBureauShortName ?? "");
    const [_abCode, set_ABCode] = useState(AgencyBureauCode ?? "");
    const [_billedABCode, set_BilledABCode] = useState(BilledAgencyBureauCode ?? "");
    const [_actualABCode, set_ActualABCode] = useState(ActualAgencyBureauCode ?? "");
    const [_agencyLocationCode, set_AgencyLocationCode] = useState(AgencyLocationCode ?? "");
    const [_tas, set_tas] = useState(TreasuryAccSymbol);
    const [_betc, set_betc] = useState(BusinessEventTypeCode);
    const [_pocFirstName, set_pocFirstName] = useState(CustomerPOCFirstName);
    const [_pocLastName, set_pocLastName] = useState(CustomerPOCLastName);
    const [_fundingDocNumber, set_fundingDocNumber] = useState(FundingDocumentNumber);
    const [_sendIpac, set_sendIpac] = useState(SendIpacActual);
    const [_receiveInvoiceSummary, set_receiveInvoiceSummary] = useState(PocsToReceiveSummaryActual);
    const [_address1, set_Address1] = useState(Address1);
    const [_address2, set_Address2] = useState(Address2);
    const [_zipCode, set_ZipCode] = useState(ZipCode);
    const [_stateLocation, set_StateLocation] = useState(StateLocation);
    const [_cityName, set_CityName] = useState(CityName);
    const [_selectedBuildings, set_SelectedBuildings] = useState(Buildings ?? [])
    const [_selectedOas, set_SelectedOas] = useState(OccupancyAgreements ?? []);
    const [canSubmitForm, set_CanSubmitForm] = useState(false);
    const [customerSaved, setCustomerSaved] = useState(false);
    const [customerSaveFail, setCustomerSaveFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [addBuildingToSelection, set_AddBuildingToSelection] = useState(false);
    const [codeMismatchAlert, setCodeMismatchAlert] = useState(false);
    const [allowedBuildings, setAllowedBuildings] = useState([]);

    const [editingRow, setEditingRow] = useState(null);
    const handleRowClick = (e) => {
        const rowData = e.data;
        const buildingData = _selectedBuildings.find(obj => obj.BuildingId === rowData.BuildingId);
        if (buildingData === undefined) {
            return;
        }
        if (isInRegion(buildingData.RegionNumber)) {
            setEditingRow(rowData);
            // console.log('Row data:', rowData); // Do something with the row data, e.g., set state for editing
        }

    };
    /**
     * @todo: Export this function for global sharing
    */
    const navigate = useNavigate();
    const navigateToRoute = () => navigate(ROUTE_CUSTOMERS.withSlash);
    const title = "Edit Customer";

    useEffect(() => {
        document.title = title;
    }, []);

    useEffect(() => {
        let saverList = [];
        if (_selectedBuildings !== undefined) {
            if (_selectedBuildings !== null) {
                if (_selectedBuildings.length > 0) {
                    _selectedBuildings.forEach(b => {
                        if (isInRegion(b.RegionNumber)) {
                            saverList.push(b);
                        }
                    })
                }
            }
        }
        setAllowedBuildings([...saverList]);
    }, [_selectedBuildings]);

    useEffect(() => {
        if (isValidCode(_billedABCode) && isValidCode(_actualABCode)) {
            const billedStart = _billedABCode.slice(0, 2)
            const actualStart = _actualABCode.slice(0, 2)

            setCodeMismatchAlert(billedStart != actualStart)
        }
    }, [_billedABCode, _actualABCode]);

    useEffect(() => {
        enableFormSubmission();
    }, [
        _agencyName,
        _abName,
        _abShortName,
        _abCode,
        _agencyLocationCode,
        _billedABCode,
        _actualABCode,
        _agencyLocationCode,
        _address1,
        _cityName,
        _stateLocation,
        _zipCode,
        _tas,
        _betc,
        _pocFirstName,
        _pocLastName,
        _fundingDocNumber,
    ]);

    const regions = JSON.parse(window.localStorage.getItem("user")).Regions;
    const isInRegion = (evaluateThisRegion) => {
        if (regions.includes(evaluateThisRegion)) {
            return true;
        }
        return false;
    }
    const isValidOa = (input) => {
        var data = input.data?.OANumber
        const reg = /^[a-z0-9]+$/i
        return (!IsNullOrEmpty(data) && data.length <= 8 && reg.test(data))
    };
    const validEffectiveExpirationDate = (e) => {
        const effective = e.data?.EffectiveDate
        const expiration = e.data?.ExpirationDate
        return (!effective || !expiration || effective < expiration)
    }
    const isValidNumber = (input) => {
        if (input.value) {
            var stringValue = input.value?.toString();
            var s = stringValue?.split('.')
            if ((s[1]?.length + s[0]?.length) > 18 || s[1]?.length > 2) return false
        }
        return true;
    };

    const enableFormSubmission = () => {
        const result = (
            isValidName(_agencyName)
            && isValidName(_abName)
            && isValidABShortName(_abShortName)
            && isValidCode(_abCode)
            && isValidCode(_billedABCode)
            && isValidCode(_actualABCode)
            && isValidAgencyLocationCode(_agencyLocationCode)
            && validateFields(_address1)
            && validateFields(_cityName)
            && validateFields(_stateLocation)
            && validZipCode(_zipCode)
            && validateTas(_tas)
            && validateBetc(_betc)
            && validatePocName(_pocFirstName)
            && validatePocName(_pocLastName)
            && validateFundingDocNumber(_fundingDocNumber)
        );

        set_CanSubmitForm(result);
    };
    const validateFields = (value) => {
        if (IsNullOrEmpty(value)) {
            return false;
        };

        return true;
    }
    const validateTas = (value) => {
        const reg = /^[a-zA-Z0-9]+$/i
        if (IsNullOrEmpty(value)) {
            return false;
        }
        if (value.length > 22) {
            return false;
        }
        if (!reg.test(value)) {
            return false;
        }
        return true;
    }
    const validZipCode = (value) => {
        if (IsNullOrEmpty(value)) {
            // console.log("zip is empty")
            return false;
        }
        if (typeof value !== 'string') {
            // console.log("zip is not a string")
            return false;
        }
        const input = value.replace("-", "").trim();
        // this is to account for the masking
        // if (value.length === 6) {
        //     console.log("trimming it")
        //     value = value.substring(0, 5)
        // }
        // alert(input.length);
        if (input.length === 5 || input.length === 9) {
            // console.log("zip is good, length is:", input.length)
            return true;
        }
        // console.log("zip is bad, length is:", input.length);
        return false;
    };

    const isValidName = (input) => {
        return (!IsNullOrEmpty(input) && input.length <= 55)
    };
    const isValidABShortName = (input) => {
        return (IsNullOrEmpty(input) || input.length <= 55)
    }
    const isValidCode = (input) => {
        if (IsNullOrEmpty(input)) {
            return false;
        }

        if (input.length !== 4) {
            return false;
        }
        if (/^[0-9]*$/.test(input) !== true) {
            return false;
        }
        return true;
    };
    const isValidAgencyLocationCode = (input) => {
        return (!IsNullOrEmpty(input) && input.length <= 11)
    };

    const validateBetc = () => {
        const reg = /^[a-z]+$/i
        if (IsNullOrEmpty(_betc)) {
            return false;
        }
        return _betc.length <= 8 && reg.test(_betc);
    };
    const validatePocName = (name) => {
        const reg = /^[a-z]+$/i
        if (IsNullOrEmpty(name)) {
            return false;
        }
        return name.length <= 50 && reg.test(name);
    };

    const validateFundingDocNumber = (value) => {
        const reg = /^[a-z0-9-]+$/i
        if (IsNullOrEmpty(value)) {
            // field is not required, empty is fine.
            return true;
        }
        if (value.length > 0)
            return reg.test(value) && value.length <= 20;
        return true;
    }
    function convertDateFormat(dateString) {
        // Validate the input date format
        const regex = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!regex.test(dateString)) {
          return dateString;
        }
      
        // Split the input date string into components
        const [month, day, year] = dateString.split('/');
      
        // Format the date into YYYY-MM-DD
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }
    const handleSubmit = async () => {
        // console.log("Clicked Submit Button")
        if (!canSubmitForm) {
            console.error('Can\'t be submitted', buildingObject)
            return;
        }

        let occupancyAgreementsToSubmit = [];
        if (_selectedOas.length > 0) {
            for (var i = 0; i < _selectedOas.length; i++) {

                const throwAwayList = _selectedBuildings.filter(b => b.SequenceNumber === _selectedOas[i].BuildingNumber);
                const buildingInformation = throwAwayList[0];
                let effectiveDate = convertDateFormat(_selectedOas[i].EffectiveDate);
                let expirationDate = convertDateFormat(_selectedOas[i].ExpirationDate);
                // console.log("typeof effectiveDate", typeof effectiveDate)
                // console.log("typeof expirationDate", typeof expirationDate)
                // if (typeof _selectedOas[i].EffectiveDate !== 'string') {
                //     effectiveDate = _selectedOas[i].EffectiveDate.toISOString().substring(0, 10);
                // }
                // if (typeof _selectedOas[i].ExpirationDate !== 'string') {
                //     expirationDate = _selectedOas[i].ExpirationDate.toISOString().substring(0, 10);
                // }
                let createdBy = user.email;
                if (_selectedOas[i].CreatedBy !== undefined) {
                    if (_selectedOas[i].CreatedBy !== null) {
                        createdBy = _selectedOas[i].CreatedBy;
                    }
                }
                let createdDate = GetDateToday_dashes_reverse();
                if (_selectedOas[i].CreatedDate !== undefined) {
                    if (_selectedOas[i].CreatedDate !== null) {
                        createdDate = _selectedOas[i].CreatedDate;
                    }
                }
                var occupancyAgreementsJson =
                {
                    OccupancyAgreementId: _selectedOas[i].OccupancyAgreementId,
                    CustomerAccountId: CustomerId,
                    EffectiveDate: effectiveDate,
                    ExpirationDate: expirationDate,
                    CreatedDate: createdDate,
                    CreatedBy: createdBy,
                    BuildingId: buildingInformation.BuildingId,
                    BuildingNumber: buildingInformation.SequenceNumber,
                    OANumber: _selectedOas[i].OANumber,
                    TotalRentSqFt: _selectedOas[i].TotalRentSqFt,
                    Inactive: false
                };
                occupancyAgreementsToSubmit.push(occupancyAgreementsJson)
            }
        }
        const customerObject = {
            "CustomerId": CustomerId,
            "AgencyName": _agencyName,
            "AgencyBureauName": _abName,
            "AgencyBureauShortName": _abShortName,
            "AgencyBureauCode": _abCode,
            "BilledAgencyBureauCode": _billedABCode,
            "ActualAgencyBureauCode": _actualABCode,
            "AgencyLocationCode": _agencyLocationCode,
            "TreasuryAccSymbol": _tas,
            "BusinessEventTypeCode": _betc,
            "CustomerPOCFirstName": _pocFirstName,
            "CustomerPOCLastName": _pocLastName,
            "POCsToRecieveSummary": _receiveInvoiceSummary,
            "SendIPAC": _sendIpac,
            "FundingDocumentNumber": _fundingDocNumber,
            "BureauAddress": {
                "Id": BureauAddressId ?? 0,
                "Address1": _address1,
                "Address2": _address2,
                "CityName": _cityName,
                "State": _stateLocation,
                "ZipCode": _zipCode
            },

            "CreatedBy": CreatedBy,
            "CreatedDate": CreatedDate,
            "UpdatedBy": user.email,

            "Buildings": _selectedBuildings,
            "BuildingByCustomer": BuildingByCustomer,
            "OccupancyAgreements": occupancyAgreementsToSubmit
        };
        console.log("customerObject", customerObject)
        const request = await PutPostPatchFetch('/v1/Customer', 'PUT', customerObject);
        const { Success, Errors } = request;

        if (request === undefined) {
            setCustomerSaved(false);
            setCustomerSaveFail(true);
            setErrorMessage("Something went wrong... Please correct your inputs and try again");
            return;
        }

        if (Success) {
            setCustomerSaved(true);
            setCustomerSaveFail(false);
        }
        else {
            setCustomerSaved(false);
            setCustomerSaveFail(true);
            setErrorMessage(Errors[0].Message);
            console.log("What went wrong:", Errors);
        }

    }
    return (
        <div>
            <h1>{title}</h1>
            <form className="inline__div--wrapper" method="post">
                <>
                    <BaseTextField
                        inputAttribute={{ "aria-label": "Agency Name" }}
                        isValueValid={isValidName(_agencyName)}
                        hoverStateEnabled={false}
                        label={agencyNameLabel}
                        labelMode={_floating}
                        maxLength={55}
                        value={_agencyName}
                        valueUpdatedByUser={
                            ({ value }) => set_AgencyName(value)
                        }
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "Agency Bureau Name" }}
                        isValueValid={isValidName(_abName)}
                        hoverStateEnabled={false}
                        label={abNameLabel}
                        labelMode={_floating}
                        maxLength={55}
                        value={_abName}
                        valueUpdatedByUser={
                            ({ value }) => set_ABName(value)
                        }
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "Agency Bureau Short Name" }}
                        isValueValid={isValidABShortName(_abShortName)}
                        isRequired={false}
                        hoverStateEnabled={false}
                        label={abShortNameLabel}
                        labelMode={_floating}
                        maxLength={55}
                        value={_abShortName}
                        valueUpdatedByUser={
                            ({ value }) => set_ABShortName(value)
                        }
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "Agency Bureau Code" }}
                        isValueValid={isValidCode(_abCode)}
                        customValidationMessage="Should be 4 digit Numbers only."
                        hoverStateEnabled={false}
                        label={abCodeLabel}
                        labelMode={_floating}
                        maxLength={4}
                        mask={"0000"}
                        maskCharacter={"*"}
                        value={_abCode}
                        valueUpdatedByUser={
                            ({ value }) => set_ABCode(value)
                        }
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "Billed Agency Bureau Code" }}
                        isValueValid={isValidCode(_billedABCode)}
                        customValidationMessage="Should be 4 digit Numbers only."
                        hoverStateEnabled={false}
                        label={billedABCodeLabel}
                        labelMode={_floating}
                        maxLength={4}
                        mask={"0000"}
                        maskCharacter={"*"}
                        value={_billedABCode}
                        valueUpdatedByUser={
                            ({ value }) => set_BilledABCode(value)
                        }
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "Actual Agency Bureau Code" }}
                        isValueValid={isValidCode(_actualABCode)}
                        customValidationMessage="Should be 4 digit Numbers only."
                        hoverStateEnabled={false}
                        label={actualABCodeLabel}
                        labelMode={_floating}
                        maxLength={4}
                        mask={"0000"}
                        maskCharacter={"*"}
                        value={_actualABCode}
                        valueUpdatedByUser={
                            ({ value }) => set_ActualABCode(value)
                        }
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "Agency Location Code" }}
                        isValueValid={isValidAgencyLocationCode(_agencyLocationCode)}
                        hoverStateEnabled={false}
                        label={agencyLocationCodeLabel}
                        labelMode={_floating}
                        maxLength={8}
                        value={_agencyLocationCode}
                        valueUpdatedByUser={
                            ({ value }) => set_AgencyLocationCode(value)
                        }
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "Address One" }}
                        isValueValid={validateFields(_address1)}
                        label={addressOneLabel}
                        labelMode={_floating}
                        value={_address1}
                        valueUpdatedByUser={
                            ({ value }) => set_Address1(value)
                        }
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "Address Two" }}
                        isValueValid={true}
                        label={addressTwoLabel}
                        labelMode={_floating}
                        value={_address2}
                        valueUpdatedByUser={
                            ({ value }) => set_Address2(value)
                        }
                    />

                    <BaseTextField
                        inputAttribute={{ "aria-label": "City Name" }}
                        isValueValid={validateFields(_cityName)}
                        label={cityNameLabel}
                        labelMode={_floating}
                        value={_cityName}
                        valueUpdatedByUser={
                            ({ value }) => set_CityName(value)
                        }
                    />
                    <US_StatesSelectField
                        inputAttribute={{ "aria-label": "State Abbreviation" }}
                        enableSearch={true}
                        isVisible={true}
                        optionUpdatedByUser={({ selectedItem }) => set_StateLocation(selectedItem)}
                        value={_stateLocation}
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "Zip Code" }}
                        isValueValid={validZipCode(_zipCode)}
                        label={zipCodeLabel}
                        labelMode={_floating}
                        mask={"00000-0000"}
                        maskCharacter={"*"}
                        maskInvalidMessage={longZipCodeInvalidMessage}
                        maxLength={10}
                        value={_zipCode}
                        valueUpdatedByUser={
                            (e) => {
                                set_ZipCode(e.value)
                            }
                        }
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "TAS-Treasury Account Symbol" }}
                        isValueValid={validateTas(_tas)}
                        customValidationMessage="Max 22 characters is allowed. Cannot include special characters. Example: 123X4567891X123456"
                        label="TAS-Treasury Account Symbol *"
                        labelMode={_floating}
                        value={_tas}
                        maxLength={22}
                        valueUpdatedByUser={
                            (e) => {
                                set_tas(e.value)
                            }
                        }
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "BETC-Business Event Type Code" }}
                        isValueValid={validateBetc()}
                        customValidationMessage="Max 8 characters. Cannot include special characters or numbers. Example: COLL"
                        label={"BETC-Business Event Type Code *"}
                        maxLength={8}
                        labelMode={_floating}
                        value={_betc}
                        isRequired={true}
                        valueUpdatedByUser={
                            ({ value }) => set_betc(value)
                        }
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "Customer POC First Name" }}
                        isValueValid={validatePocName(_pocFirstName)}
                        maxLength={50}
                        customValidationMessage="Max 50 characters. Cannot include special characters or numbers. Example: Jonathan"
                        label={"Customer POC First Name *"}
                        labelMode={_floating}
                        value={_pocFirstName}
                        valueUpdatedByUser={
                            ({ value }) => set_pocFirstName(value)
                        }
                    />
                    <BaseTextField
                        inputAttribute={{ "aria-label": "Customer POC Last Name" }}
                        isValueValid={validatePocName(_pocLastName)}
                        customValidationMessage="Max 50 characters. Cannot include special characters or numbers. Example: Smith"
                        maxLength={50}
                        label={"Customer POC Last Name *"}
                        labelMode={_floating}
                        value={_pocLastName}
                        valueUpdatedByUser={
                            ({ value }) => set_pocLastName(value)
                        }
                    />
                    <div className="funding-document-section"
                        style={{
                            background: '#F5F5F5'
                            , minWidth: 500
                            , padding: '0 1em 0 1em'
                            , margin: "0 0 1em 0"
                            , borderRadius: '1em'
                            , display: 'flex'
                            , alignItems: 'center'
                            , justifyContent: 'center'

                        }}
                    >
                        <div className="funding-document-field-container"
                            style={{
                                maxWidth: 225
                                , flex: 2
                                , marginRight: 5
                                , alignContent: 'left'
                            }}
                        >
                            <BaseTextField
                                inputAttribute={{ "aria-label": "Funding Document Number" }}
                                isValueValid={validateFundingDocNumber(_fundingDocNumber)}
                                isRequired={false}
                                label={"Funding Document Number"}
                                labelMode={_floating}
                                customValidationMessage="Max 20. Cannot include special characters (except for hyphen)."
                                value={_fundingDocNumber}
                                valueUpdatedByUser={
                                    ({ value }) => set_fundingDocNumber(value)
                                }
                            />
                        </div>
                        <div style={{
                            fontWeight: 'bold'
                            , fontSize: 10
                            , flex: 1
                            , alignContent: 'right'
                        }}>
                            <div><span>Document # Formats: </span> </div>
                            <div><span>MIPR (12345678)</span></div>
                            <div><span>GINV (X1234-567-890-111222)</span></div>
                            <div><span>SWA (SWA-R2023FRBY124)</span></div>
                        </div>
                    </div>


                    <BaseCheckBox
                        elementAttr={{ "aria-label": "Send IPAC" }}
                        onCheckedValueChanged={({ value }) => set_sendIpac(value)}
                        text="Send IPAC"
                        value={_sendIpac}
                    />
                    <BaseCheckBox
                        elementAttr={{ "aria-label": "POCs To Receive Invoice Summary" }}
                        onCheckedValueChanged={({ value }) => set_receiveInvoiceSummary(value)}
                        text="POCs To Receive Invoice Summary"
                        value={_receiveInvoiceSummary}
                    />
                </>
                <div>
                    {addBuildingToSelection ?
                        <div style={{
                            background: '#F5F5F5'
                            , padding: "1em 2em 3em 2em"
                            , margin: "0 0 1em 0"
                            , borderRadius: '1em'

                        }}>
                            <h4>Add Building(s) to Customer Account</h4>

                            <AddBuildingsBasedOnUsersRegion
                                _selectedBuildings={_selectedBuildings}
                                _setSelectedBuildings={set_SelectedBuildings}
                                _userRegions={regions}
                                _userAccountName={user.email}
                            />
                            <BaseButton
                                label={"Close"}
                                onClick={() => { set_AddBuildingToSelection(false) }}
                                variant={"outlined"}
                            />
                        </div>
                        :
                        <div>
                            <BaseButton
                                label={"Add Building(s)"}
                                onClick={() => { set_AddBuildingToSelection(true) }}
                                variant={"outlined"}
                            />
                        </div>
                    }


                    <div style={{
                        background: 'white',
                        padding: '2em 1em 3em 1em',
                        borderRadius: '1em'
                    }}>
                        <>
                            <div
                                className="datagrid-title-selected-buildings"
                                style={{
                                    // margin: "2em 0 1em 0"
                                }}
                            >
                                <strong>Selected Buildings:</strong>
                            </div>
                            <DataGrid
                                columnAutoWidth={true}
                                dataSource={_selectedBuildings}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                                onCellPrepared={CustomPreparedHeaderCell}
                                onRowRemoved={(e) => {
                                    console.log(`removed ${e.data.SequenceNumber}`)
                                    const updatedList = _selectedBuildings.filter(obj => obj.BuildingId !== e.data.BuildingId)
                                    set_SelectedBuildings(updatedList)
                                }}
                                noDataText="Please Select Buildings"
                            >
                                <KeyboardNavigation enabled={true} />
                                <Column dataField="BuildingId" visible={false} />
                                <Column dataField="AddressId" visible={false} />
                                <Column dataField="RegionNumber" caption="Region" />
                                <Column dataField="SequenceNumber" caption="Building Number" />
                                <Column dataField="Name" caption="Name" />
                                <Column dataField="Location.Address1" caption="Address 1" />
                                <Column dataField="Location.Address2" caption="Address 2" />
                                <Column dataField="Location.CityName" caption="City" />
                                <Column dataField="Location.State" caption="State" />
                                <Column dataField="Location.ZipCode" caption="Zip Code" />
                                <Column dataField="Status" caption="Status" />
                                <Editing
                                    mode="popup"
                                    allowDeleting={(e) => {
                                        return isInRegion(e.row.data.RegionNumber);
                                    }}
                                />

                            </DataGrid>
                        </>
                        <div style={{
                            background: '#F5F5F5'
                            , padding: "1em 2em 3em 2em"
                            , margin: "2em 0 1em 0"
                            , borderRadius: '1em'

                        }}>
                            <AddOccupancyAgreement
                                availableBuildings={allowedBuildings}
                                customerId={CustomerId}
                                userId={user.email}
                                selected_Oas={_selectedOas}
                                set_SelectedOas={set_SelectedOas}
                            />
                            <UpdateOccupancyAgreement
                                editingRow={editingRow}
                                availableBuildings={allowedBuildings}
                                customerId={CustomerId}
                                userId={user.email}
                                selected_Oas={_selectedOas}
                                set_SelectedOas={set_SelectedOas}
                            />
                        </div>
                        <DataGrid
                            dataSource={_selectedOas}
                            keyExpr="OccupancyAgreementId"
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            onCellPrepared={CustomPreparedHeaderCell}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            rowAlternationEnabled={true}
                            onRowClick={handleRowClick}
                            noDataText={_selectedBuildings.length < 1 ? "Please Select Buildings" : "Please Add Occupancy Agreements"}
                            onInitNewRow={(e) => {
                                let index = 0;
                                if (_selectedOas.length > 0) {
                                    let oaIds = [];
                                    // console.log(_selectedOas);
                                    _selectedOas.forEach(oa => oaIds.push(oa.OccupancyAgreementId))
                                    const min = Math.min(...oaIds);
                                    if (min < 1) {
                                        index = min - 1;
                                    }
                                }
                                e.data.OccupancyAgreementId = index;
                            }}
                        >
                            <Column
                                dataType="string"
                                dataField="OANumber"
                                caption="OA Number"
                                setCellValue={(newData, value, currentRowData) => {
                                    newData.OANumber = value.toUpperCase();
                                }}
                            >
                                <RequiredRule />
                                <CustomRule
                                    type="custom"
                                    message="Required. Must be a max of 8 letters and/or numbers. Example: AB123412"
                                    validationCallback={isValidOa}
                                />
                            </Column>
                            <Column
                                dataType="date"
                                dataField="EffectiveDate"
                                format="shortDate"
                                editorOptions={{ useMaskBehavior: true, placeholder: "MM/DD/YYYY" }}
                            >
                                <CustomRule
                                    type="custom"
                                    message="Date must be before the Expiration Date"
                                    validationCallback={validEffectiveExpirationDate}
                                />
                            </Column>
                            <Column
                                dataType="date"
                                dataField="ExpirationDate"
                                format="shortDate"
                                editorOptions={{ useMaskBehavior: true, placeholder: "MM/DD/YYYY" }}
                            >
                                <CustomRule
                                    type="custom"
                                    message="Date must be after the Occupancy Agreement Effective Date"
                                    validationCallback={validEffectiveExpirationDate}
                                />
                            </Column>
                            <Column
                                dataType="number"
                                dataField="TotalRentSqFt"
                            >
                                <CustomRule
                                    type="custom"
                                    message="Max 18 numbers. Limit to the hundredths. Example 1234.56"
                                    validationCallback={isValidNumber}
                                />
                            </Column>
                            <Column
                                dataType="object"
                                caption="Building"
                                dataField="BuildingNumber"
                            >
                                <RequiredRule
                                    message='Building is required.'
                                />
                                <Lookup
                                    dataSource={_selectedBuildings}
                                    displayExpr={(e) => {
                                        if (!isInRegion(e.RegionNumber)) {
                                            return e.SequenceNumber + " (Not in your region.)";
                                        }
                                        return e.SequenceNumber
                                    }}

                                    valueExpr="SequenceNumber"

                                />
                            </Column>
                            <Column
                                caption="Id (Auto Assigned)"
                                dataField="OccupancyAgreementId"
                                visible={false}
                                allowEditing={false}
                            />
                            <Editing
                                mode="popup"
                                allowDeleting={
                                    (e) => {
                                        // console.log(e)
                                        const res = _selectedBuildings.find(b => b.SequenceNumber === e.row.data.BuildingNumber)
                                        // console.log(res)
                                        return isInRegion(res.RegionNumber);
                                    }}
                            />
                        </DataGrid>
                    </div>
                </div>
            </form>

            {!customerSaved &&
                <div className="block--flex-items"
                    style={{
                        margin: '1em 0 2em 0'
                    }}>

                    <BaseButton
                        label={cancel}
                        variant={outlined}
                        onClick={navigateToRoute}
                    />
                    <BaseButton
                        ariaDisabled={!canSubmitForm}
                        disabled={!canSubmitForm}
                        label={submit}
                        onClick={handleSubmit}
                        variant={outlined}
                    />
                    {/* <BaseButton
                        label={
                            "check OAs in console"
                        }
                        onClick={() => { console.log("selected OAs:", _selectedOas) }}
                        variant={outlined}
                    />
                    <BaseButton
                        label={
                            "check buildings in console"
                        }
                        onClick={() => { console.log("selected buildings:", _selectedBuildings) }}
                        variant={outlined}
                    /> */}
                </div>
            }
            <div
                className='alerts-section'
                style={{
                    margin: '3em 0 0 0'
                }}
            >
                <ClosingAlert
                    clickableIcon={navigateToRoute}
                    enableAlertIcon={true}
                    message={CustomerSavedSuccessful}
                    severity={success}
                    visible={customerSaved}
                />
                <ClosingAlert
                    hideAlert={() => { setErrorMessage("") }}
                    message={errorMessage}
                    severity={warning}
                    visible={customerSaveFail}
                />
                <ClosingAlert
                    hideAlert={() => { setCodeMismatchAlert(false) }}
                    message={"There appears to be a mismatch between the billed and actual agency bureau codes. If this is correct, please ignore this message and continue."}
                    severity={info}
                    visible={codeMismatchAlert}
                />
            </div>

        </div>
    );
}

export default CustomersUpdate;

