import { 
    Suspense,
    useState, 
    useEffect,
} from "react";
import { useLoaderData, useOutlet, Await } from "react-router-dom";
import { AuthProvider } from "../hooks/useAuth";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import SessionContext from "../App/SessionContext";
import ErrorPage from "./ErrorPage";
import SecurityBannerModal from "../SecurityBanner/SecurityBannerModal";
export const AuthLayout = () => {
    const outlet = useOutlet();
    // const { userPromise } = useLoaderData();
    // const [openBanner, setOpenBanner] = useState(false);
    // useEffect(() => {
    //     if (SessionContext.get("UserId") && !SessionContext.get("SecurityAccepted")) {
    //         bannerModal();
    //     }
    // }, [openBanner]);

    // const bannerModal = () => {
    //     console.log("Opening banner");
    //     setOpenBanner(true);
    // }
    return (
        // <Suspense fallback={<LinearProgress />}>
        //     <Await
        //         resolve={userPromise}
        //         errorElement={<ErrorPage/>}
        //         children={(
        //             // user
        //             ) => (
                    <AuthProvider
                        // userData={user}
                    >
                        {/* <SecurityBannerModal open={openBanner} setOpen={setOpenBanner} /> */}
                        {outlet}
                    </AuthProvider>
        //         )}
        //     />
        // </Suspense>
    );
};