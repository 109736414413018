import { useState, useEffect } from 'react';
import {
    Box,
    Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Content } from '../Enums/contents';
import './css/Adjustments.scss';
import { ROUTE_EDIT_ADJUSTMENTS } from '../routes/Routes';
import ApprovalSingleSelectDevEx from './components/ApprovalSingleSelectDevEx';
import ClosingAlert from '../components/ClosingAlert';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch';
import { LoadingIndicatorCircle } from "../components/Loading";

const {
    Buttons: {
        MUI: {
            Variant: {
                outlined
            }
        }
    }
} = Content;

const BillingAdjustmentsTableModifiers = ({ selectedRowKey, roleAllowApprove, roleAllowEdit, roleAllowDeactivate, rowSelected, onApprovalChange, onActivationChange }) => {
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState()
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [loading, setLoading] = useState()
    const [allowEditing, setAllowEdit] = useState(false);
    const [allowApprove, setAllowApprove] = useState(false);
    const [allowDeactivate, setAllowDeactivate] = useState(false);
    const [selectedOANumber, setSelectedOANumber] = useState(undefined);
    const [approvalOptions, setApprovalOptions] = useState(['Submitted', 'Approved', 'Denied'])

    useEffect(() => {
        handlePermissionsChanges(selectedRowKey)
        setSelectedOANumber(selectedRowKey?.OANumber);
    }, [selectedRowKey])

    const handleEditAdjustment = () => {
        var selectedRowData = selectedRowKey
        navigate(ROUTE_EDIT_ADJUSTMENTS.withSlash, {
            state: { selectedRowData }
        });
    }

    const handlePermissionsChanges = (selected) => {
        let deactivated = selected?.IsInactive;
        if (!deactivated) {
            switch (selected?.ApprovalStatus) {
                case 'Approved': // Logic for approved Adjustments: NO edit, NO approval change, NO Deactivate
                    setAllowApprove(false);
                    setAllowDeactivate(false);
                    setAllowEdit(false);
                    setApprovalOptions(['Approved'])
                    break;
                case 'Denied': // Logic for denied Adjustments: YES Edit, Approvers and Deactivators can change back to submitted, YES Deactivate/Reactivate
                    setAllowApprove(roleAllowApprove || roleAllowDeactivate)
                    setAllowEdit(roleAllowEdit)
                    setAllowDeactivate(roleAllowDeactivate)
                    setApprovalOptions(['Submitted', 'Denied'])
                    break;
                case 'Submitted': // Logic for submitted. YES Edit, YES Approval Change Allowed, YES Deactivate/Reactivate
                    setAllowApprove(roleAllowApprove || roleAllowDeactivate)
                    setAllowEdit(roleAllowEdit)
                    setAllowDeactivate(roleAllowDeactivate)
                    if (roleAllowApprove) setApprovalOptions(['Submitted', 'Approved', 'Denied'])
                    else setApprovalOptions(['Submitted', 'Denied'])
                    break;
                case 'Pending': // Logic for submitted. YES Edit, YES Approval Change Allowed, YES Deactivate/Reactivate
                    setAllowApprove(roleAllowApprove || roleAllowDeactivate)
                    setAllowEdit(roleAllowEdit)
                    setAllowDeactivate(roleAllowDeactivate)
                    if (roleAllowApprove) setApprovalOptions(['Submitted', 'Approved', 'Denied'])
                    else setApprovalOptions(['Submitted', 'Denied'])
                    break;
                default:
                    setAllowApprove(false)
                    setAllowDeactivate(false)
                    setAllowEdit(false)
                    setApprovalOptions([])
                    break;
            }
        }
        else {
            // Logic for Deactivated. NO Edit, NO Approval Change allowed (reactivate will set status to "Submitted"), YES reactivate for Approvers or Deactivators
            setAllowApprove(false);
            setAllowEdit(false);
            setAllowDeactivate(roleAllowApprove || roleAllowDeactivate);
            setApprovalOptions([])
        }
    }

    const handleActivationChangeClick = async () => {
        setLoading(true)
        var editedAdjustment = {
            Id: selectedRowKey.AdjustmentId,
            IsInactive: (deactivateButtonChange === "Deactivate")
        }
        const { Success, Errors } = await PutPostPatchFetch('/v1/Adjustments/DeactivateAdjustment', 'PUT', editedAdjustment);

        if (Success) {
            setSuccess(true);
            setSuccessMessage(`Adjustment has been successfully ${deactivateButtonChange}d.`)
            setError(false);
            setLoading(false);
            onActivationChange(deactivateButtonChange)
            var tempNewKey = selectedRowKey;
            tempNewKey.IsInactive = editedAdjustment.IsInactive
            tempNewKey.ApprovalStatus = "Submitted"
            handlePermissionsChanges(tempNewKey)
        }
        else {
            setSuccess(false);
            setError(true);
            setErrorMessage(Errors[0].Message ?? "Something went wrong... Please correct your inputs and try again.");
            console.error(Errors);
            setLoading(false);
        };
    }

    const handleApprovalChangeClick = async (value) => {
        setLoading(true)
        var editedAdjustment = {
            Id: selectedRowKey.AdjustmentId,
            ApprovalStatus: value
        }
        const { Success, Errors } = await PutPostPatchFetch('/v1/Adjustments/ApproveAdjustment', 'PUT', editedAdjustment);

        if (Success) {
            setSuccess(true);
            setSuccessMessage(`Adjustment has been successfully ${value}.`)
            setError(false);
            setLoading(false);
            onApprovalChange(value)
            var tempNewKey = selectedRowKey;
            tempNewKey.ApprovalStatus = editedAdjustment.ApprovalStatus
            handlePermissionsChanges(tempNewKey)
        }
        else {
            setSuccess(false);
            setError(true);
            setErrorMessage(Errors[0].Message ?? "Something went wrong... Please correct your inputs and try again.");
            console.error(Errors);
            setLoading(false);
        };
    }


    const editButtonLabel = selectedOANumber ? `Edit Adjustment for OA: ${selectedOANumber}` : "Edit Adjustment";
    const deactivateButtonChange = selectedRowKey?.IsInactive ? "Reactivate" : "Deactivate";
    const deactivateButtonLabel = selectedOANumber ? `${deactivateButtonChange} Adjustment for OA: ${selectedOANumber}` : "Deactivate Adjustment";

    return (
        <div>
            <Box sx={{ height: 100, width: '97%', margin: 'auto auto 0 auto' }}>
                {selectedRowKey && !loading &&
                    <>
                        {allowEditing &&
                            <Button
                                sx={{ mt: 1, ml: 1, pl: 1 }}
                                aria-disabled={!rowSelected}
                                disabled={!rowSelected}
                                onClick={handleEditAdjustment}
                                variant={outlined}
                            >
                                {editButtonLabel}
                            </Button>
                        }
                        {allowDeactivate &&
                            <Button
                                sx={{ mt: 1, ml: 1, pl: 1 }}
                                aria-disabled={!rowSelected}
                                disabled={!rowSelected}
                                onClick={handleActivationChangeClick}
                                variant={outlined}
                            >
                                {deactivateButtonLabel}
                            </Button>
                        }
                        {allowApprove &&
                            <ApprovalSingleSelectDevEx
                                approvalOptions={approvalOptions}
                                approvalAllowed={allowApprove}
                                customLabel={selectedOANumber}
                                selectedAdjustment={selectedRowKey}
                                handleSubmit={handleApprovalChangeClick}
                            />
                        }
                    </>
                }
                {loading &&
                    <LoadingIndicatorCircle message="Submitting changes, please wait ..." />
                    }
            </Box>
            <ClosingAlert
                clickableIcon={() => { setSuccess(false) }}
                hideAlert={() => { setSuccess(false) }}
                message={`Adjustment has been successfully ${deactivateButtonChange}d.`}
                severity='success'
                visible={success}
            />
            <ClosingAlert
                hideAlert={() => { setError(false) }}
                message={errorMessage}
                severity='warning'
                visible={error}
            />
        </div>
    )
}


export default BillingAdjustmentsTableModifiers;