import {
  Suspense,
  useEffect,
} from "react";
import {
  Typography
} from "@mui/material";
import {
  ErrorElement,
  LoadedPage,
} from "./UsersPageComponents";
import {
  Await,
  useLoaderData,
} from "react-router-dom";
import {LoadingIndicatorPageLength} from "../components/Loading";
import { ROUTE_USERS } from "../routes/Routes";

const UsersPage = () => {
  const { userPromise } = useLoaderData();
  const title = ROUTE_USERS.title;

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Typography component="div" sx={{ mb: 1 }}>
        <h1 style={{ color: "#06152B" }}>{title}</h1>
      </Typography>

      <Suspense fallback={<LoadingIndicatorPageLength message={"Loading Users, Please wait..."} />}>
        <Await
          resolve={userPromise}
          errorElement={<ErrorElement />}
          children={(result) => (
            <LoadedPage dataIn={result.Message} />
          )}
        />
      </Suspense>
    </>
  );
};

export default UsersPage;
