import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import { BaseButton } from "../components/BaseButton/BaseButton";
import { BaseNumberBox } from "../components/BaseNumberBox/BaseNumberBox";
import { Content } from "../Enums/contents"
import { ROUTE_ADD_ADJUSTMENTS, ROUTE_EDIT_ADJUSTMENTS } from "../routes/Routes";
import PutPostPatchFetch from "../hooks/PutPostPatchFetch";
import ClosingAlert from "../components/ClosingAlert";
import currencyFormatter from '../components/CurrencyFormatter';
import "./css/Adjustments.scss"
import BuildingSingleSelectDevEx from "../Reports/CostEstimateReport/BldgNumberDropdownDevEx";
import { ROUTE_BILLING_ADJUSTMENTS } from "../routes/Routes";
import OASingleSelectDevEx from "./components/OASingleSelectDevEx";
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ReasonSingleSelectDevEx from "./components/ReasonSingleSelectDevEx";
import { TextBox } from "devextreme-react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import FeeTypeSingleSelectDevEx from "./components/FeeTypeSingleSelectDevEx";
import { LoadingIndicatorCircle } from "../components/Loading";

const dateFieldSx = {
    mt: 2.2,
    mr: 1,
    width: 200
};

const {
    Alerts: {
        AdjustmentSavedSuccssful
    },
    Buttons: {
        cancel,
        submit,
        MUI: {
            Severity: {
                success,
                warning
            },
            Variant: {
                outlined
            }
        }
    },
    Inputs: {
        labelModes: {
            _static
        },
        Input_TextField_Labels: {
        }
    },
    NummberFormatting: {
        currencyFormat
    }
} = Content;

const currencyStringToFloat = (e) => {
    return parseFloat(e.replace(/[$,]/g, ""))
}

const EditAdjustment = () => {
    const {
        state
    } = useLocation();
    const title = ROUTE_EDIT_ADJUSTMENTS.title;
    const navigate = useNavigate();

    const [adjustment, setAdjustment] = useState({
        adjustmentId: state.selectedRowData.AdjustmentId,
        approvalStatus: state.selectedRowData.ApprovalStatus,
        buildingSeqNo: state.selectedRowData.BuildingNumber,
        oaNo: { OANumber: state.selectedRowData.OANumber },
        feeType: { FeeTypeName: state.selectedRowData.FeeTypeName, FeeTypeId: state.selectedRowData.FeeTypeId },
        reason: state.selectedRowData.Reason,
        psoAdjustment: currencyStringToFloat(state.selectedRowData.PSOAmount),
        tcmAdjustment: currencyStringToFloat(state.selectedRowData.TCMAmount),
        adjustedAmount: currencyStringToFloat(state.selectedRowData.AdjustedAmount),
        adjustedAmountDisplay: currencyFormatter.format(state.selectedRowData.AdjustedAmount),
        startDate: dayjs(state.selectedRowData.unformattedStartDate),
        endDate: dayjs(state.selectedRowData.unformattedEndDate)
    })

    const [canSubmitForm, setCanSubmitForm] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [adjustmentSaved, setAdjustmentSaved] = useState(false);
    const [error, setError] = useState(false);
    const [dateErrorMessage, setDateErrorMessage] = useState('');
    const [adjustedError, setAdjustedError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [existing, setExisting] = useState(false);
    const [existingOAs, setExistingOAs] = useState([])

    useEffect(() => {
        document.title = title;
        console.log(state.selectedRowData)
    }, []);

    useEffect(() => {
        if (validateAdjustments() && adjustment.adjustedAmount != 0 &&
            adjustment.oaNo && adjustment.oaNo.BuildingAnsiRent != 0
            && adjustment.feeType
            && adjustment.reason
            && adjustment.startDate >= minDate && adjustment.startDate < adjustment.endDate
            && adjustment.endDate > adjustment.startDate && adjustment.endDate <= maxDate) setCanSubmitForm(true);
        else setCanSubmitForm(false)
    }, [adjustment]);

    useEffect(() => {
        if (adjustment.oaNo?.BuildingAnsiRent > 0) {
            var calculated = (adjustment.psoAdjustment + adjustment.tcmAdjustment) * adjustment.oaNo.TotalRentSqFt / adjustment.oaNo.BuildingAnsiRent
            handleChange({ name: "adjustedAmount", value: calculated })
            handleChange({ name: "adjustedAmountDisplay", value: currencyFormatter.format(adjustment.adjustedAmount) })
            setAdjustedError(false)
        }
        else if (!adjustment.oaNo?.BuildingAnsiRent || adjustment.oaNo?.BuildingAnsiRent <= 0) {
            handleChange({ name: "adjustedAmount", value: null })
            handleChange({ name: "adjustedAmountDisplay", value: "Unable to calculate. Please check the Building's ANSI rent value is greater than 0" });
            setAdjustedError(true);
        }
        else {
            handleChange({ name: "adjustedAmount", value: null })
            handleChange({ name: "adjustedAmountDisplay", value: "No Adusted Amount available with current entries. Please ensure either PSO or TCM adjustments total a value other than 0" });
            setAdjustedError(false);
        }

    },
        [adjustment.psoAdjustment,
        adjustment.tcmAdjustment,
        adjustment.oaNo]);

    const returnToAdjustmentsPage = () => {
        navigate(ROUTE_BILLING_ADJUSTMENTS.withSlash);
    };

    const validateAdjustments = () => {
        if (adjustment.psoAdjustment == 0 && adjustment.tcmAdjustment == 0)
            return false;
        return true;
    }

    //useEffect(() => {
    //    console.log(adjustment)
    //}, [adjustment])

    const handleChange = (e) => {
        //console.log(e)
        const { name, value } = e;
        setAdjustment(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const minDate = new Date((new Date().getFullYear() - 5), (new Date().getMonth()), 1);
    const minDateFormatted = `${minDate.getMonth() + 1}-${minDate.getFullYear()}`;
    const maxDate = new Date((new Date().getFullYear()), (new Date().getMonth()), 0);
    const maxDateFormatted = `${maxDate.getMonth() + 1}-${maxDate.getFullYear()}`;
    const dateMessage = `Date must be between ${minDateFormatted} and ${maxDateFormatted}`;

    const handleSubmit = async () => {
        if (!canSubmitForm) {
            console.error('Can\'t be submitted')
            return;
        };
        setSubmitting(true);
        var searchCriteria = {
            "BuildingNumbers": [adjustment.buildingSeqNo]
        };
        const existing = await PutPostPatchFetch('/v1/Adjustments/ByBuildingNumberList', 'POST', searchCriteria)

        if (existing?.Success) {
            if (existing.Message?.length > 0) {
                let oldest = new Date(new Date().getFullYear() - 6, new Date().getMonth(), new Date().getDay())
                var existingAdjs = existing.Message.filter(i => (new Date(i.BillPeriodEnd) > oldest && i.OccupancyAgreementId == adjustment.oaNo.OccupancyAgreementId && i.Id != adjustment.adjustmentId))
                if (existingAdjs.length > 0) {
                    setExistingOAs(existingAdjs);
                    setExisting(true);
                    return;
                }
            }
        }
        setExisting(false);
        handleCreate();

    }

    const generateExisting = () => {
        return (
            existingOAs.map((existingValue) => { return (<p key={"Adjustment"+existingValue.Id}>An adjustment has already been applied to the Occupancy Agreement number {existingValue.OccupancyAgreement.OANumber} of ${existingValue.AdjustedAmount} for period {existingValue.BillPeriodStart} - {existingValue.BillPeriodEnd}</p>) })
        )
    }

    const handleCreate = async () => {
        if (!canSubmitForm) {
            //console.error('Can\'t be submitted')
            return;
        };
        var adjustedStart = adjustment.startDate.date(1);
        var adjustedEnd = adjustment.endDate.date(1);

        //console.log(adjustment.feeType)
        var editedAdjustment = {
            Id: adjustment.adjustmentId,
            AdjustedAmount: adjustment.adjustedAmount,
            PSOAmount: adjustment.psoAdjustment,
            TCMAmount: adjustment.tcmAdjustment,
            OccupancyAgreementId: adjustment.oaNo.OccupancyAgreementId,
            FeeTypeId: adjustment.feeType.FeeTypeId,
            Reason: adjustment.reason,
            BillPeriodStart: adjustedStart.format('YYYY-MM-DD'),
            BillPeriodEnd: adjustedEnd.format('YYYY-MM-DD')
        }
        console.log(editedAdjustment)
        const { Success, Errors } = await PutPostPatchFetch('/v1/Adjustments/EditAdjustment', 'PUT', editedAdjustment);

        if (Success) {
            setAdjustmentSaved(true);
            setError(false);
            setSubmitting(false);
        }
        else {
            setAdjustmentSaved(false);
            setError(true);
            setErrorMessage(Errors[0].Message ?? "Something went wrong... Please correct your inputs and try again.");
            console.error(Errors);
            setSubmitting(false);
        };
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleCreate();
        };
    };

    const handleContinue = () => {
        console.log("creating")
        setExisting(false);
        handleCreate()
    }
    const handleCancel = () => {
        setExisting(false);
        setSubmitting(false)
    }

    return (
        <div>
            <h1 style={{ color: "#06152B" }}>{title}</h1>
            <form className="inline__div--wrapper" method="post">

                <BuildingSingleSelectDevEx
                    value={adjustment.buildingSeqNo}
                    setter={(e) => { handleChange({ name: "buildingSeqNo", value: e }) }}
                    isRequired={true}

                />
                {adjustment.buildingSeqNo &&
                    <OASingleSelectDevEx
                        value={adjustment.oaNo}
                        setter={(e) => { handleChange({ name: "oaNo", value: e }) }}
                        selectedBuilding={adjustment.buildingSeqNo}
                        isRequired={true}
                    />
                }
                <FeeTypeSingleSelectDevEx
                    value={adjustment.feeType}
                    setter={(e) => { handleChange({ name: "feeType", value: e }) }}
                    isRequired={true}
                />
            </form>
            <form className="inline__div--wrapper" method="post">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                        sx={dateFieldSx}
                        id="start-date"
                        label="Bill Period Start Date"
                        format="MM-YYYY"
                        value={adjustment.startDate}
                        onChange={(newValue) => { console.log(newValue); handleChange({ name: "startDate", value: newValue }); setDateErrorMessage('') }}
                        inputProps={{
                            onKeyDown: handleKeyDown
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                        required
                        onError={(error) => { setDateErrorMessage(dateMessage) }}
                        slotProps={{
                            textField: {
                                helperText: dateErrorMessage
                            }
                        }}
                    />
                    <DateField
                        id="end-date"
                        sx={dateFieldSx}
                        label="Bill Period End Date"
                        format="MM-YYYY"
                        value={adjustment.endDate}
                        onChange={(newValue) => { handleChange({ name: "endDate", value: newValue }); setDateErrorMessage('') }}
                        inputProps={{
                            onKeyDown: handleKeyDown
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                        onError={(error) => { setDateErrorMessage(dateMessage) }}
                        slotProps={{
                            textField: {
                                helperText: dateErrorMessage
                            }
                        }}
                        required
                    />
                </LocalizationProvider>
            </form>
            <form className="inline__div--wrapper" method="post">
                <BaseNumberBox
                    inputAttribute={{ "aria-label": "PSO Amount" }}
                    label="PSO Amount *"
                    labelMode={_static}
                    numberFormat={currencyFormat}
                    onEnterKeyDown={handleKeyDown}
                    value={adjustment.psoAdjustment}
                    valueUpdatedByUser={
                        ({ value }) => handleChange({ name: "psoAdjustment", value: value })
                    }
                    validationRuleCallback={() => validateAdjustments()}
                    isValueValid={validateAdjustments}
                    validationRequired={true}
                    errMsg="At least one value must be non-zero."
                />
                <BaseNumberBox
                    inputAttribute={{ "aria-label": "TCM Amount" }}
                    label="TCM Amount *"
                    labelMode={_static}
                    numberFormat={currencyFormat}
                    onEnterKeyDown={handleKeyDown}
                    value={adjustment.tcmAdjustment}
                    valueUpdatedByUser={
                        ({ value }) => handleChange({ name: "tcmAdjustment", value: value })
                    }
                    validationRuleCallback={() => validateAdjustments()}
                    isValueValid={validateAdjustments}
                    validationRequired={true}
                    errMsg="At least one value must be non-zero."
                />
                <ReasonSingleSelectDevEx
                    value={adjustment.reason}
                    setter={(value) => { handleChange({ name: "reason", value: value }) }}
                />
            </form>
            {//adjustedAmount &&
                <form className="inline__div--wrapper" >
                    <TextBox
                        label="Calculated Adjusted Amount"
                        labelMode='floating'
                        value={adjustment.adjustedAmountDisplay}
                        height={56}
                        maxLength={150}
                        width={700}
                        ignoreEmptyValue={true}
                        id="adjusted_amount_textBox_002"
                        isValid={!adjustedError}
                        readOnly
                    >
                    </TextBox>
                </form>
            }
            {(submitting) ? (
                <LoadingIndicatorCircle message="submitting, please wait ..." />
            ) :
                <form className="inline__div--wrapper" >
                    <BaseButton
                        ariaDisabled={!canSubmitForm}
                        disabled={!canSubmitForm}
                        label={submit}
                        onClick={handleSubmit}
                        variant={outlined}
                    />
                    <BaseButton
                        label={cancel}
                        variant={outlined}
                        onClick={returnToAdjustmentsPage}
                    />
                </form>
            }
            <Popup
                visible={existing}
                onHiding={() => { setExisting(false) }}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButon={false}
                showTitle={true}
                title="WARNING: Existing Adjustment(s)"
                contianer=".dx-viewport"
                width={500}
                height={500}
            >
                <Position at="center" my="center" />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={{
                        stylingMode: 'outlined',
                        text: 'Proceed',
                        onClick: handleContinue
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        stylingMode: 'outlined',
                        text: 'Cancel',
                        onClick: handleCancel
                    }}
                />
                {generateExisting()}
            </Popup>
            <ClosingAlert
                clickableIcon={returnToAdjustmentsPage}
                enableAlertIcon={true}
                message={AdjustmentSavedSuccssful}
                severity={success}
                visible={adjustmentSaved}
            />
            <ClosingAlert
                hideAlert={() => { setError(false) }}
                message={errorMessage}
                severity={warning}
                visible={error}
            />
        </div >
    );
};

export default EditAdjustment;