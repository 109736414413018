// Global constants
export const RMSAUTHSAML_URL = '/rmsSAML';
export const RMSAUTHMETHOD_COGNITO = "COGNITO";
export const RMSAUTHMETHOD_OKTA = "OKTA";

// Constants from env-variable file
export const rmsVerbose = window._env_.RMS_VERBOSE;
export const rmsVersion = window._env_.RMS_VERSION;
export const rmsDeployment = window._env_.RMS_DEPLOYMENT;
export const rmsApiUri = window._env_.RMS_API_URI;
export const rmsDhsAuthSvcUri = window._env_.RMS_DHSAUTH_SVC_URI;
export const rmsSessionConcurrency = window._env_.RMS_SESSION_CONCURRENCY;
export const rmsSessionTimeoutArg = window._env_.RMS_SESSION_TIMEOUT;
export const rmsSessionCheckInterval = window._env_.RMS_SESSION_CHECK_INTERVAL;
export const rmsAuthMethod = window._env_.RMS_AUTH_METHOD;
export const tokenRefreshInterval = 10800000
// export const tokenRefreshInterval = window._env_.RMS_TOKEN_REFRESH_INTERVAL;

export const rmsShowReactEnvVariables = () => {
    if (rmsVerbose === 'true') {
        console.log("------------------RMS: ENV Variables----------------------------------------");
        console.log(`rmsVerbose(RMS_VERBOSE) [${rmsVerbose}].`);
        console.log(`rmsVersion(RMS_VERSION) [${rmsVersion}].`);
        console.log(`rmsDeployment(RMS_DEPLOYMENT) [${rmsDeployment}].`);
        console.log(`rmsApiUri(RMS_API_URI) [${rmsApiUri}].`);
        console.log(`rmsDhsAuthSvcUri(RMS_DHSAUTH_SVC_URI) [${rmsDhsAuthSvcUri}].`);
        console.log(`rmsSessionConcurrency(RMS_SESSION_CONCURRENCY) [${rmsSessionConcurrency}].`);
        console.log(`rmsSessionTimeout(RMS_SESSION_TIMEOUT) [${rmsSessionTimeoutArg} minutes].`);
        console.log(`rmsSessionCheckInterval(RMS_SESSION_CHECK_INTERVAL) [${rmsSessionCheckInterval} seconds].`);
        console.log(`rmsAuthMethod(RMS_AUTH_METHOD) [${rmsAuthMethod}].`);
        console.log(`rmsTokenRefreshInternal(RMS_TOKEN_REFRESH_INTERVAL) [${tokenRefreshInterval}].`);
        console.log("----------------------------------------------------------------------------");
    }
}