import { DateBox, Validator } from "devextreme-react";
import { RequiredRule, CustomRule } from "devextreme-react/data-grid";
import IsNullOrEmpty from "../IsNullOrEmpty";
import { Dimensions } from "../../Enums/dimensions";
import "./BaseDateField.scss";

const { Heights: { inputHeight } } = Dimensions

/**
 * 
 * @module BaseDateField 
 * This is the base of a DateField for RMS.
 * It uses a textfield with type of "date" as the root UI Component.
 * This field can be used to input calendar dates in MM-dd-yyy format,
 * and can be expanded in the future to accommidate other date and 
 * time values as needed.
 * 
 */

export const BaseDateField = (
/**
 * @param {String} dateSerializationFormat: Specifies a date value serialization format (eg: yyyy-MM-dd). Use it only if the vlaues is not specified at design time. Optional. Defaults to Undefined.
 * @param {String | Number | Function} height: Specifies the UI components height. Defaults to Undefined. 
 * @param {Object} inputAttr: Sets any global HTML attributes to the component. Optional. Defualts to {}.
 * @param {String} label: The visible label (text) for the button. Defaults to "".
 * @param {Date} min: Sets the earliest possible date that a user can input, like so: new Date(yyyy, m, d). Optional. Defaults to {}.
 * @param {Date} max: Sets the latest possible date that a user can input, like so: new Date(yyyy, m, d). Optional. Defaults to {}.
 * @param {Boolean} isValid: Specifies or indicates if a field's value is valid (for required fields). Optional. Defaults to true.
 * @param {Function} onValueChanged: Accepts a function that updates the state of the date field with a new value. 
 * @param {Boolean} showDropDownButton: Shows the dropdown button on the field and render a calendar that a user can select a calendar date from when set to true. Defaults to false.
 * @param {String} type: Format used to display date/time information. Accepts only "date" (date only), "time" (time only), or "datetime" (date and time) as assigned values (literal union). Defaults to "date".
 * @param {Boolean} useMaskBehavior: Specifies whether to control user input a mask created on `displayFormat` when set to true. Defaults to false.
 * @param {String | Date | Number} value: Sets the initial state for the the date field. Defaults to Null.
 */
    {
        dateOutOfRangeMessage,
        earliestDate,
        inputAttr,
        isValid,
        latestDate,
        label,
        showDropDownButton,
        valdiationMessage,
        value,
        valueUpdatedByUser
    }
) => {
    return (
        <span className="inline__span--datefield-wrapper">
            <DateBox
                dateOutOfRangeMessage={dateOutOfRangeMessage}
                dateSerializationFormat="MM/dd/yyyy"
                height={inputHeight} 
                inputAttr={inputAttr}
                isValid={isValid}
                label={label}
                max={latestDate}
                min={earliestDate}
                onValueChanged={valueUpdatedByUser}
                showDropDownButton={showDropDownButton}
                type="date"
                useMaskBehavior={true}
                value={value}
            >
                <Validator>
                    <RequiredRule type="required" />
                    <CustomRule
                        message={valdiationMessage}
                        reevaluate={true}
                        validationCallback={() => !IsNullOrEmpty(value)}
                    />
                </Validator>
            </DateBox>
        </span>
    );
};