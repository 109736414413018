import {
    Alert,
    FormControl,
    InputLabel,
    OutlinedInput,
    MenuItem,
    Typography,
    FormHelperText,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import GetFetch from "../../hooks/GetFetch";
import { useAuth } from "../../hooks/useAuth";
import { LoadingIndicatorCircle } from "../../components/Loading";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const selectStylesOne = {
    m: 1,
    width: '12rem',
    margin: '1rem'
};
const inputLabelStyles = { margin: "1rem 0 0 1rem" };

const  inputProps={
    "aria-required": "true"
}

const RegionDropdown = ({ value, setter }) => {
    const [regions, setRegions] = useState([]);
    const [loading, setLoading] = useState();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const { user } = useAuth();

    const dropdownTitle = "Region(s) *";
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        // here we will reset all values to default so that we only display one thing at a time
        // i.e. loading, data, or an error
        setError(false);
        setRegions(null);
        setLoading(true);
        const result = await GetFetch('/v1/Region');
        const { Success, Message, Errors } = result;
        if (Success) {
            setError(false);
            setRegions(Message);
        }
        else {
            setError(true);
            if (Errors[0].Message === "No records found at the moment") {
                setErrorMessage("Something went wrong. Please contact your administrator.");
            }
            else {
                setErrorMessage(Errors[0].Message);
            }
        }
        setLoading(false);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const setSelectedRegionNumber = (event, newValue) => {
        if (newValue) {
            setter(newValue.map((region) => region.RegionNumber));
        } else {
            setter([]);
        }
    };

    return (
        <React.Fragment>
            {loading && <LoadingIndicatorCircle message="Loading available regions..." />}
            {regions && (
                <FormControl sx={{ mt: 1, mb: 1 }} error={value.length === 0}>
                    <InputLabel
                        style={inputLabelStyles}
                        id="regionId"
                        htmlFor="region-id"
                    >
                    </InputLabel>
                    <Autocomplete
                        multiple
                        id="region-autocomplete"
                        options={regions}
                        getOptionLabel={(option) => option.RegionNumber + " (" + option.OfficeLocation + ")"}
                        value={regions.filter((region) => value.includes(region.RegionNumber))}
                        onChange={setSelectedRegionNumber}
                        input={<OutlinedInput label={dropdownTitle} />}
                        onKeyDown={handleKeyDown}
                        aria-required="true"
                        required={true}
                        style={selectStylesOne}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={dropdownTitle}
                                variant="outlined"
                                onKeyDown={handleKeyDown}
                                error={value.length === 0}
                                InputProps={{
                                    ...params.InputProps,
                                    inputProps: {
                                    ...params.inputProps,
                                    'aria-required': 'true',
                                    },
                                }}
                            />
                        )}
                    />
                    {value.length === 0 && <FormHelperText>Required *</FormHelperText>}
                </FormControl>
            )}
            {error && (
                <Alert severity="error" sx={{ height: '50px', mt: 4 }}>
                    {errorMessage}
                </Alert>
            )}
        </React.Fragment>
    )
}
export default RegionDropdown;
