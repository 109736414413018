import React, { useState, useEffect } from 'react';
import {
    TableContainer,
    Typography
} from '@mui/material';
import DataGrid, {
    Column,
    Export,
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel
} from 'devextreme-react/data-grid';
import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';
import InfoIconComponent from '../../components/CustomInfoIcon';
import currencyFormatter from '../../components/CurrencyFormatter';
import SearchButtonGroupCostEstimates from './SearchButtonGroupCostEstimates';
import { ROUTE_COST_ESTIMATE_REPORT } from '../../routes/Routes';
import { logExport, useAuth } from '../../hooks/useAuth';
import { MasterDetail } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import CustomPreparedHeaderCell from '../../components/CustomPreparedHeaderCell';
import ExportGrid from '../../components/ExportGrid';
import { allowedPageSizes, pageFormats } from '../../components/GlobalDataGridConfigurations';
import { FOUOfooter, FOUOheader } from '../../components/FOUO';
import { sortRegions } from '../../util/_helpers';


const roundedFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
        maximumFractionDigits: 0,
});

const CostEstimatesReport = () => {
    const [data, setData] = useState();
    const { 
        user, 
        user: { 
            Regions 
        } 
    } = useAuth();

    useEffect(() => {
        document.title = ROUTE_COST_ESTIMATE_REPORT.title;
    }, []);

    const roundedFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    });

    const dataIsValid = () => {
        if (data === undefined) {
            return false;
        }
        if (data === null) {
            return false;
        }
        
        return true;
    }
   
  const getAddress = (address)=>{
    
    let buildingAddress = `${address.AddressOne}, ${address.AddressTwo === ' ' ? '' : address.AddressTwo + ','} ${address.CityName}, ${address.State} ${address.ZipCode}`;
    return buildingAddress;
  }
  const getCostType = (arr, costId)=>{
    let resArray = []
      arr.forEach(subArray => subArray.filter(x=> x.SecurityCostId === costId).length > 0 ? 
                              resArray.push(subArray.filter(x=> x.SecurityCostId === costId)[0]): '');
    let type = resArray.length > 0 ? resArray[0].CostType : 'N/A';
    return type;
  }
   const getAuditLog = (auditLog, bldgSqNumber, data)=>{
    
   const log = auditLog.map((ele)=>{
   var re = data.map((x)=> {return x.CostCharges}) ;
   
   var res = ele.ChangeType === "Insert" ? getCostType(re, ele.OldValues.SecurityCostId)
            : ele.ChangeValues?.SecurityType?.SecurityType1 ;

    let bldg  =   data ? data.filter(i=> i.BuildingSequenceNumber == bldgSqNumber) : [] ;
    
    let bldgRegionData = bldg ? bldg.map(
                    (x)=>{                   
                    
                        return {                        
                            Region: x.RegionNumber,
                            BuildingNumber: x.BuildingSequenceNumber,
                            BuildingName: x.BuildingName,                      
                            Address : getAddress(x.Address)
                        }}): null ;
   return {
  
    BuildingData : bldgRegionData ,

     Charges: ele.ChangeType === 'Insert' ? currencyFormatter.format(ele.OldValues.Charges):       
            ele.ChangeValues ? roundedFormatter.format(ele.ChangeValues.Charges) : roundedFormatter.format(0),
     CostType: res,
     ChangeType: ele.ChangeType,
     CreatedBy: ele.ChangeType === 'Insert' ? ele.OldValues.CreatedBy : ele.ChangeValues.CreatedBy ?? 'N/A',
     CreatedDate: ele.ChangeType === 'Insert' ? ele.OldValues.CreatedDate : ele.OldValues.CreatedDate ?? 'N/A',
     UpdatedBy:  ele.ChangeValues?.UpdatedBy ?? 'N/A',
     UpdatedDate: ele.ChangeType === "Insert" ? 'N/A' : ele.ChangeValues?.UpdatedDate ?? 'N/A',
     TimeChange: ele.ChangeType === "Insert" ? 'N/A' : ele.TimeChange ?? 'N/A',

     initCharges: ele.OldValues ? currencyFormatter.format(ele.OldValues.Charges) : 'N/A',
    //  initCostType: ele.ChangeType === "Insert" ? res : ele.OldValues?.SecurityType?.SecurityType1 ?? 'N/A',
     initCreatedBy: ele.OldValues?.CreatedBy ?? 'N/A',
     initCreatedDate: ele.OldValues?.CreatedDate ?? 'N/A',
     OldValues: ele.OldValues,
     ChangeValues: ele.ChangeValues
        }
    });
    return log ;
  }
    const dataSource = new CustomStore({
     
        load: async () => {    
            const auditlogData 
             = data ? data.map(
                (ele)=>{                   
                
                    return {                        
                        
                        AuditLog : getAuditLog(ele.AuditLog, ele.BuildingSequenceNumber, data)                       
                    }
                }
             ) : null;             
          let resultArr = [];
          let res = auditlogData ? auditlogData.map((x)=> {return x.AuditLog}) : [] ;
          res.forEach(x=> x.forEach(y => resultArr.push(y)));
          return resultArr ;
        }
    });
    const pdfFont = ()=>
    {    return 6;   }
    const wrapPdfCell = ()=>{
     return true;   }
    const handleExport = (e) => {
        logExport(user.email)
        ExportGrid(e, "Changes to Cost Estimate Report", wrapPdfCell(), pdfFont())
    }

    return (
        <div>
            <Typography
                sx={{ width: '98%', margin: 'auto' }}
                className="title-cost-estimates"
                component="div"
            >
                <h1 style={{ color: "#06152B" }}>Review Changes of Fiscal Year Cost Estimates for Region: { sortRegions(Regions) }</h1>
            </Typography>
            <br />
            <SearchButtonGroupCostEstimates setter={setData} />

            {dataIsValid() && dataSource &&
                <>
                    <Box sx={{
                        height: 600
                        , width: '97%'
                        , margin: 'auto'
                    }}
                    >
                        <Paper
                            elevation={5}
                            sx={{ padding: '30px', marginTop: '7px' }}
                        >
                            <FOUOheader 
                                dateString={new Date().toString()}
                                heading={"Changes of Fiscal Year Cost Estimates"}
                                userEmail={user.email}
                            />
                            <TableContainer
                                id="audit-table-container"
                                component={Paper}
                                elevation={0}
                            >
                                <DataGrid 
                                    columnAutoWidth={true}
                                    dataSource={dataSource} 
                                    onCellPrepared={CustomPreparedHeaderCell}
                                    showBorders={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                    onExporting={e => handleExport(e)}
                                 
                            >
                                  
                                    <Column dataField="BuildingData[0].Region" caption="Region" />
                                    <Column dataField="BuildingData[0].BuildingNumber" caption="Building Number" />
                                    <Column dataField="BuildingData[0].BuildingName" caption="Building Name" />
                                    <Column dataField="BuildingData[0].Address" caption="Address" />  
                                
                                    <Column dataField="ChangeType" caption="Change Type"/>                 
                                   
                                    <Column dataField="Charges" caption="Charge"/>
                                    <Column dataField="CostType" caption="Cost Type"/>                                 
                                
                                    <Column dataField="initCharges" caption="Initial Charge" cellRender={(cellInfo) => renderGridCell(cellInfo, roundedFormatter)} />
                                    
                                    <Column dataField="TimeChange" caption="Date Changed" /> 
                                    <Column dataField="UpdatedBy" caption="Updated By"/>
                                    <Column dataField="initCreatedBy" caption="Created By" cellRender={renderGridCell} />
                                    <Column dataField="initCreatedDate" caption="Created Date" cellRender={renderGridCell} />                                 
                                    <KeyboardNavigation enabled={true} />
                                    <SearchPanel 
                                        highlightCaseSensitive={true} 
                                        visible={true} 
                                    />
                                    <Paging defaultPageSize={10} />
                                    <Pager 
                                        showPageSizeSelector={true}
                                        allowedPageSizes={allowedPageSizes}
                                    />
                                   
                                    <Export 
                                        enabled={true} 
                                        formats={pageFormats} 
                                    />
                                </DataGrid>
                            </TableContainer>
                            <FOUOfooter />
                        </Paper>
                    </Box>
                </>
            }

        </div>
    )
}

const renderGridCell = (cellInfo, roundedFormatter) => {
    if (cellInfo.rowType === 'data') {
      if (cellInfo.column.dataField === 'initCharges') {
          const initCharges = cellInfo.data.OldValues?.Charges;
          const changeCharges = cellInfo.data.ChangeValues?.Charges;
          const value = initCharges !== undefined ? initCharges : changeCharges;

          if (cellInfo.data.ChangeType.toLowerCase() != 'insert' && initCharges != changeCharges) {
          return (
            <>
              {roundedFormatter.format(initCharges)}
              <InfoIconComponent item={initCharges} key={initCharges} />
            </>
          );
        }
        else
       return(<>{roundedFormatter.format(initCharges)}</> )
      }
     
        return cellInfo.value ;
    }
}


export default CostEstimatesReport;