const IsNullOrEmpty = (value) => {
    if (value === undefined) {
        return true;
    }
    if (value === null) {
        return true;
    }
    if (value === '') {
        return true;
    }
    if (!value) {
        return true;
    }
    return false;
}
export default IsNullOrEmpty;