import React from 'react';
import { 
    CircularProgress,
    LinearProgress, 
    Typography,
} from "@mui/material";
/**
 * Takes a message argument to display along with linear progress loader component
 * @param {string} Message 
 * @returns a linear progress loading component with configurable message
 */
export const LoadingIndicatorPageLength = ({message}) => {
    return (
        <>
            <Typography variant="caption" component="em">
                {message}
            </Typography>
            <LinearProgress />
        </>
    )
}

export const LoadingIndicatorCircle = ({message}) => {
    return (
        <>
            <Typography variant="caption" component="em">
                {message}
            </Typography>
            <CircularProgress size={15} sx={{mr: 2}}/>
        </>
    )
}
