import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { hoverColor } from './NavStyle';

/**
 * Takes a Title, an Icon, and a List of NavItems
 * @param {} param0 
 * @returns JSX Object (a Dropdown menu containing other navigation)
 */
const NavDropDownItem = ({ title, icon, elements, itemButtonId }) => {
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <React.Fragment>
            <ListItemButton
                onClick={handleClick}
                onMouseEnter={() => { setShow(true); }}
                onMouseLeave={() => { setShow(false); }}
                style={{
                    color: show ? 'white' : '',
                    background: show ? hoverColor : '',
                    borderRadius: show ? '5px' : '',
                }}
                id={(itemButtonId)}
            >
                <ListItemIcon
                    style={{
                        color: show ? 'white' : ''
                    }}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText primary={title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout='auto' unmountOnExit>
                {elements}
            </Collapse>
        </React.Fragment>
    );
};
export default NavDropDownItem;