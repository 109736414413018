export const usStates = [
    { id: 'ALABAMA', value: 'AL'},
    { id: 'ALASKA', value: 'AK'},
    { id: 'AMERICAN SAMOA', value: 'AS'},
    { id: 'ARIZONA', value: 'AZ'},
    { id: 'ARKANSAS', value: 'AR'},
    { id: 'CALIFORNIA', value: 'CA'},
    { id: 'COLORADO', value: 'CO'},
    { id: 'CONNECTICUT', value: 'CT'},
    { id: 'DELAWARE', value: 'DE'},
    { id: 'DISTRICT OF COLUMBIA', value: 'DC'},
    { id: 'FEDERATED STATES OF MICRONESIA', value: 'FM'},
    { id: 'FLORIDA', value: 'FL'},
    { id: 'GEORGIA', value: 'GA'},
    { id: 'GUAM', value: 'GU'},
    { id: 'HAWAII', value: 'HI'},
    { id: 'IDAHO', value: 'ID'},
    { id: 'ILLINOIS', value: 'IL'},
    { id: 'INDIANA', value: 'IN'},
    { id: 'IOWA', value: 'IA'},
    { id: 'KANSAS', value: 'KS'},
    { id: 'KENTUCKY', value: 'KY'},
    { id: 'LOUISIANA', value: 'LA'},
    { id: 'MAINE', value: 'ME'},
    { id: 'MARSHALL ISLANDS', value: 'MH'},
    { id: 'MARYLAND', value: 'MD'},
    { id: 'MASSACHUSETTS', value: 'MA'},
    { id: 'MICHIGAN', value: 'MI'},
    { id: 'MINNESOTA', value: 'MN'},
    { id: 'MISSISSIPPI', value: 'MS'},
    { id: 'MISSOURI', value: 'MO'},
    { id: 'MONTANA', value: 'MT'},
    { id: 'NEBRASKA', value: 'NE'},
    { id: 'NEVADA', value: 'NV'},
    { id: 'NEW HAMPSHIRE', value: 'NH'},
    { id: 'NEW JERSEY', value: 'NJ'},
    { id: 'NEW MEXICO', value: 'NM'},
    { id: 'NEW YORK', value: 'NY'},
    { id: 'NORTH CAROLINA', value: 'NC'},
    { id: 'NORTH DAKOTA', value: 'ND'},
    { id: 'NORTHERN MARIANA ISLANDS', value: 'MP'},
    { id: 'OHIO', value: 'OH'},
    { id: 'OKLAHOMA', value: 'OK'},
    { id: 'OREGON', value: 'OR'},
    { id: 'PALAU', value: 'PW'},
    { id: 'PENNSYLVANIA', value: 'PA'},
    { id: 'PUERTO RICO', value: 'PR'},
    { id: 'RHODE ISLAND', value: 'RI'},
    { id: 'SOUTH CAROLINA', value: 'SC'},
    { id: 'SOUTH DAKOTA', value: 'SD'},
    { id: 'TENNESSEE', value: 'TN'},
    { id: 'TEXAS', value: 'TX'},
    { id: 'UTAH', value: 'UT'},
    { id: 'VERMONT', value: 'VT'},
    { id: 'VIRGIN ISLANDS', value: 'VI'},
    { id: 'VIRGINIA', value: 'VA'},
    { id: 'WASHINGTON', value: 'WA'},
    { id: 'WEST VIRGINIA', value: 'WV'},
    { id: 'WISCONSIN', value: 'WI'},
    { id: 'WYOMING', value: 'WY' }
];