import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const CustomPaperTheme = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(12),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    justifyContent: 'center',
    width: '48%',
    maxWidth: '750px',
    margin: 'auto',
    // marginTop: '400px'
}));

const CustomCard = (props) => {
    return (
        <CustomPaperTheme elevated={12}>
            <Box sx={{
                width: '98%'
                , margin: 'auto'
            }}
            >
                <Stack spacing={3}>
                    {props.children}
                </Stack>
            </Box>
        </CustomPaperTheme>
    );
}
export default CustomCard;