import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ROUTE_CUSTOMERS } from '../routes/Routes.js';
import IsNullOrEmpty from '../components/IsNullOrEmpty';
import CustomPreparedHeaderCell from "../components/CustomPreparedHeaderCell";
import "./../styles/utilities.scss";
import "./CustomersUpdate.scss"
import DataGrid, {
    Item as DataGridItem,
    Editing,
    Column,
    Toolbar,
    Lookup,
    RequiredRule,
    CustomRule,
} from 'devextreme-react/data-grid';

const OccupancyAgreementAdd = ({  buildingOptions, setOas }) => {
   
    const [_oas, set_Oas] = useState([])

    useEffect(() => {
        let filtered = _oas?.filter(oa => buildingOptions?.map(building => building.BuildingId).includes(oa.BuildingId))
        setOas(filtered)
    }, [buildingOptions, _oas])
    
    const regions = JSON.parse(window.localStorage.getItem("user")).Regions;
    let buildingDataLookUp = buildingOptions.filter(e => regions.find(r => r === e.RegionNumber))
    const isValidOa = (input) => {
        var data = input.data?.OccupancyAgreementNumber
        const reg = /^[a-z0-9]+$/i
        return (!IsNullOrEmpty(data) && data.length <= 8 && reg.test(data))
    };
    const validEffectiveExpirationDate = (e) => {
        const effective = e.data?.EffectiveDate
        const expiration = e.data?.ExpirationDate
        return (!effective || !expiration || effective < expiration)
    }
    const isValidNumber = (input) => {
        if (input.value) {
            var stringValue = input.value?.toString();
            var s = stringValue?.split('.')
            if ((s[1]?.length + s[0]?.length) > 18 || s[1]?.length > 2) return false
        }
        return true;
    };
    const onSaved = (e) => {
        var rowData = e.component.getVisibleRows()?.map(r => r.data);
        setOas(rowData)
    }
    

    

    return (
        <>

            {
                !buildingOptions || buildingOptions.length <= 0 ?
                    <em>Please select one or more buildings to start adding Occupancy Agreements.</em>
                    :
                    <>
                        <div
                            className="datagrid-title-OA-to-add"
                            style={{
                                margin: "2em 0 1em 0"
                            }}
                        >
                            <strong>Occupancy Agreements to Add:</strong>
                        </div>
                        <DataGrid
                            dataSource={_oas}
                            keyExpr="OccupancyAgreementId"
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            allowColumnReordering={true}
                            onCellPrepared={CustomPreparedHeaderCell}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            rowAlternationEnabled={true}
                            noDataText="Please Add Occupancy Agreements"
                            onSaved={onSaved}
                        >
                            <Column dataType="string" dataField="OccupancyAgreementNumber" >
                                <CustomRule
                                    type="custom"
                                    message="Required. Must be a max of 8 letters and/or numbers. Example: AB123412"
                                    validationCallback={isValidOa}
                                />
                            </Column>
                            <Column dataType="date" dataField="EffectiveDate" format="shortDate" editorOptions={{ useMaskBehavior: true, placeholder: "MM/DD/YYYY" }}>
                                <CustomRule
                                    type="custom"
                                    message="Date must be before the Expiration Date"
                                    validationCallback={validEffectiveExpirationDate}
                                />
                            </Column>
                            <Column dataType="date" dataField="ExpirationDate" format="shortDate" editorOptions={{useMaskBehavior:true, placeholder:"MM/DD/YYYY"}}>
                                <CustomRule
                                    type="custom"
                                    message="Date must be after the Occupancy Agreement Effective Date"
                                    validationCallback={validEffectiveExpirationDate}
                                />
                            </Column>
                            <Column dataType="number" dataField="TotalRentSqFt">
                                <CustomRule
                                    type="custom"
                                    message="Max 18 numbers. Limit to the hundredths. Example 1234.56"
                                    validationCallback={isValidNumber}
                                />
                            </Column>
                            <Column dataType="string" dataField="BuildingNumber">
                                <RequiredRule
                                    message="Building number is required"
                                />
                                <Lookup
                                    dataSource={buildingDataLookUp}
                                    displayExpr="SequenceNumber"
                                    valueExpr="BuildingId"
                                />
                            </Column>
                            <Editing
                                mode="popup"
                                allowUpdating={true}
                                allowDeleting={true}
                                allowAdding={true}
                            >

                            </Editing>
                            <Toolbar>
                                <DataGridItem name="groupPanel" />
                                <DataGridItem name="addRowButton" showText="always" />
                                <DataGridItem name="exportButton" />
                                <DataGridItem name="columnChooserButton" />
                                <DataGridItem name="searchPanel" />
                            </Toolbar>
                        </DataGrid>
                    </>
            }
        </>
    );
}

export default OccupancyAgreementAdd;
