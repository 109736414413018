import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom"
import { BaseButton } from "../../components/BaseButton/BaseButton";
import { BaseNumberBox } from "../../components/BaseNumberBox/BaseNumberBox";
import { Content } from "../../Enums/contents"
import {
    ROUTE_ADD_SECURITY_COST,
    ROUTE_FY_COST_ESTIMATE
} from "../../routes/Routes";
import FiscalYearDropdown from "./FiscalYearDropdown"
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import { useAuth } from "../../hooks/useAuth";
import ClosingAlert from "../../components/ClosingAlert";
import "../../BuildingsPage/BuildingsUpdate.scss"
import BuildingSingleSelectDevEx from "../../Reports/CostEstimateReport/BldgNumberDropdownDevEx";
const {
    Alerts: {
        SecurityCostSavedSuccssful
    },
    Buttons: {
        cancel,
        submit,
        MUI: {
            Severity: {
                success,
                warning
            },
            Variant: {
                outlined
            }
        }
    },
    Inputs: {
        labelModes: {
            _static
        },
        Input_NumberBox_Label: {
            pmoChargesLabel,
            tcmChargesLabel
        },
        Input_TextField_Labels: {
        },
        Input_SelectField_Labels: {
            buildingSequenceNumberLabel,
            fiscalYearLabel
        }
    },
    NummberFormatting: {
        currencyFormat
    }
} = Content;

const AddSecurityCost = () => {
    const title = ROUTE_ADD_SECURITY_COST.title;
    const navigate = useNavigate();
    const { user } = useAuth();
    const [buildingSeqNo, setBuildingSeqNo] = useState('');
    const [fiscalYear, setFiscalYear] = useState('');
    const [psoCharge, setPSOcharge] = useState(0);
    const [tcmCharge, setTCMcharge] = useState(0);
    const [amortCCharge, setAMORTCcharge] = useState('');

    const [canSubmitForm, setCanSubmitForm] = useState(false);
    const [securityCostSaved, setSecurityCostSaved] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        document.title = title;
    }, []);

    useEffect(() => {
        validValues();
    }, [
        fiscalYear,
        buildingSeqNo,
        psoCharge,
        tcmCharge
    ]);
  useEffect(() => {
    validatePSOCharge();
    validatePSOCharge();
  }, [
    psoCharge,
    tcmCharge
  ])
    const returnToCostEstimatePage = () => {
        navigate(ROUTE_FY_COST_ESTIMATE.withSlash);
    };

    const validValues = () => {
        const result = (
            validateSqNo(buildingSeqNo)
            && validatePSOCharge(psoCharge)
            && validateTcmCharge(tcmCharge)
            && validateFiscalYear(fiscalYear)           
            );

        setCanSubmitForm(result);
    };
    const validateSqNo = (buildingSeqNo)=>{
        if (!buildingSeqNo || buildingSeqNo == undefined || buildingSeqNo == '' || buildingSeqNo === 'None') {
            return false;
        };
        return true;
    }
    
    const validatePSOCharge = ()=>{
        if(psoCharge <= 0 && tcmCharge <= 0)    
        return false ;
        if(psoCharge < 0)
        return false ;
       return true ; 
    }

    const validateTcmCharge = ()=>{
        if(psoCharge <= 0 && tcmCharge <= 0)    
        return false ;
        if(tcmCharge < 0)
        return false ;
      return true ; 
}

  const validateFiscalYear = (fiscalYear)=>{
    if (!fiscalYear ||fiscalYear == undefined || fiscalYear == '' || isNaN(fiscalYear) ){
        return false;
    }
    return true;  }

    const handleCreate = async () => {
        if (!canSubmitForm) {
            console.error('Can\'t be submitted', securityCost)
            return;
        };

        let convertedTcmCharge;
        let convertedPsoCharge;
        // let convertedAmortcCharge;
        try {
            convertedTcmCharge = parseInt(tcmCharge);
            convertedPsoCharge = parseInt(psoCharge);
            // convertedAmortcCharge = parseInt(amortCCharge);
            if (isNaN(convertedTcmCharge) || isNaN(convertedPsoCharge)) {
                throw "Not a number";
            }
            setError(false);
            setErrorMessage(null);
        } catch (ex) {
            setError(true);
            setErrorMessage("The charge you entered is not a number. Please review your input and ensure that charges entered are numeric.");
            console.log(ex);
            return;
        }
        const securityCost = {
            "CreatedBy": user.email,
            "BuildingSequenceNumber": buildingSeqNo,
            "FiscalYear": fiscalYear,
            "TcmCharge": convertedTcmCharge,
            "PsoCharge": convertedPsoCharge,
            "AmortCapCharge": 0,
            "ChargesYtd": 0,
            "AdjustmentCharges": 0,
            "AdjustmentChargesYtd": 0,
            "ApprovalStatus":"Submitted"
        };
        
        const { Success, Errors } = await PutPostPatchFetch('/v1/SecurityCost/Multi', 'POST', securityCost);

        if (Success) {
            setSecurityCostSaved(true);
            setError(false);
        }
        else {
            setSecurityCostSaved(false);
            setError(true);
            setErrorMessage(Errors[0].Message ?? "Something went wrong... Please correct your inputs and try again.");
            console.error(Errors[0].Message);
        };
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleCreate();
        };
    };

    return (
        <div>
            <h1 style={{ color: "#06152B" }}>{title}</h1>
            <form className="inline__div--wrapper" method="post">
             
                 <BuildingSingleSelectDevEx
                        value={buildingSeqNo}
                        setter={setBuildingSeqNo}
                        isRequired ={true}
                      
                    />
                <FiscalYearDropdown
                    inputAttribute={{ "aria-label": "Fiscal Year *" }}
                    enableSearch={true}
                    label={fiscalYearLabel}
                    onEnterKeyDown={handleKeyDown}
                    value={fiscalYear}
                    optionUpdatedByUser={
                        ({ selectedItem }) => setFiscalYear(selectedItem)
                    }
                    isVisible={true}
                />
                <BaseNumberBox
                        inputAttribute={{ "aria-label": "PSO Charges" }}
                        label={pmoChargesLabel}
                        labelMode={_static}
                        numberFormat={currencyFormat}
                        isValueValid = {validatePSOCharge()}
                        onEnterKeyDown={handleKeyDown}
                        value={psoCharge}
                        validationRuleCallback={() => validatePSOCharge()}
                        valueUpdatedByUser={
                            ({ value }) => setPSOcharge(value)
                        }
                        validationRequired = {true}
                        errMsg = "Please provide a valid value."
                />
                <BaseNumberBox
                    inputAttribute={{ "aria-label": "TCM Charges" }}
                    label={tcmChargesLabel}
                    labelMode={_static}
                    numberFormat={currencyFormat}
                    isValueValid = {validateTcmCharge()}
                    onEnterKeyDown={handleKeyDown}
                    value={tcmCharge}
                    validationRuleCallback={() => validatePSOCharge()}
                    valueUpdatedByUser={
                        ({ value }) => setTCMcharge(value)
                    }
                    validationRequired = {true}
                    errMsg = "Please provide a valid value."
                />
            </form>
            <div className="break"></div>
            <div className="block--flex-items">
            <BaseButton
                    ariaDisabled={canSubmitForm === false}
                    disabled={canSubmitForm === false}
                    label={submit}
                    onClick={handleCreate}
                    variant={outlined}
                />                
                <BaseButton
                    label={cancel}
                    variant={outlined}
                    onClick={returnToCostEstimatePage}
                />
            </div>
            <ClosingAlert
                clickableIcon={returnToCostEstimatePage}
                enableAlertIcon={true}
                message={SecurityCostSavedSuccssful}
                severity={success}
                visible={securityCostSaved}
            />
            <ClosingAlert
                hideAlert={() => { setError(false) }}
                message={errorMessage}
                severity={warning}
                visible={error}
            />     
        </div >       
    );
};

export default AddSecurityCost;