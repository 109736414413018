import GetFetch from './GetFetch';

export const refreshApiToken = async () => {
  // const currentToken = localStorage.getItem("JWT_TOKEN");
  const token =  await getNewToken();
  // if (currentToken === null) {
    // let jwt_token = await getNewToken();
    // window.localStorage.setItem("JWT_TOKEN", jwt_token.access_token);
  // }
  return token;
}

const getNewToken = async () => {
  const res = await GetFetch('/v1/TokenService/Get');
  const { Message, Success, Errors } = res;
  if (Success) {
      const token = Message;
      window.localStorage.setItem("JWT_TOKEN", token);
      return token;
  };
  if (Errors.length > 0) {
      console.log("What went wrong:", Errors);
  };
}