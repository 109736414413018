import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Export,
    Column
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { pageFormats, allowedPageSizes } from '../components/GlobalDataGridConfigurations';
import CustomPreparedHeaderCell from '../components/CustomPreparedHeaderCell';
import Paper from '@mui/material/Paper';
import { FOUOheader, FOUOfooter } from '../components/FOUO';
import { useAuth } from '../hooks/useAuth';
import ExportGrid from '../components/ExportGrid';
import dayjs from 'dayjs';

const Tasks = ({ data }) => {
    const { user } = useAuth();

    const dataSource = new CustomStore({
        key: 'Id',
        load: async () => {
            const mappedData = data.map((element) => {
                //console.log(element);
                return {
                    Id: element.WorkflowStepRecordHistoryId,
                    EntityName: element.WorkflowStepDomain?.WorkFlowDomain?.EntityName ?? "N/A",
                    Region: element.RegionId ?? "N/A",
                    StepName: element.WorkflowStepDomain?.WorkflowStep ?? "N/A",
                    LastChanged: (element.UpdatedDate === null || element.UpdatedDate === "")?((element.CreatedDate === null || element.CreatedDate === "")
                        ? "N/A" : dayjs(element.CreatedDate).utc().format("MM/DD/YYYY") ) : dayjs(element.UpdatedDate).utc().format("MM/DD/YYYY"),
                    Status: element.WorkflowStepStatus?.StatusName ?? "N/A",
                    Details: element.ObjectDetails ?? "N/A"
                }

            });
            return mappedData;
        }
    });

    return (
        <div>
            <Paper
                elevation={5}
                sx={{ padding: '30px', marginTop: '7px' }}
            >
            <DataGrid
                columnAutoWidth={true}
                dataSource={dataSource}
                onCellPrepared={CustomPreparedHeaderCell}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
            >
                <Column dataField="Id" caption="Step Tracker Id" visible={false} />
                    <Column dataField="EntityName" caption="Entity Type" />
                    <Column dataField="Region" caption="Region" />
                    <Column dataField="Details" caption="Details" />
                    <Column dataField="StepName" caption="Current Step" />
                    <Column dataField="LastChanged" caption="Date of Last Activity" />
                    <Column dataField="Status" caption="Task Status" />

                {/* <FilterRow visible={true} /> */}
                <KeyboardNavigation enabled={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Paging defaultPageSize={10} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                />
            </DataGrid>
                <FOUOfooter />
            </Paper>
        </div>
    )
}
export default Tasks;
