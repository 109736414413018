import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTE_ADD_ADJUSTMENTS, ROUTE_BILLING_ADJUSTMENTS } from '../routes/Routes';
import { useAuth } from '../hooks/useAuth';
import { BaseButton } from '../components/BaseButton/BaseButton';
import { Content } from '../Enums/contents';
import SearchButtonGroupAdjustments from './components/SearchButtonGroupAdjustments';
import BillingAdjustmentsTable from './BillingAdjustmentsTable';

const {
    Buttons: {
        MUI: {
            Variant: {
                outlined
            }
        }
    }
} = Content;
const BillingAdjustments = () => {
    const navigate = useNavigate();
    const pageTitle = ROUTE_BILLING_ADJUSTMENTS.title;
    const { user } = useAuth();
    const [allowEditing, setAllowEditing] = useState(false);
    const [allowCreate, setAllowCreate] = useState(false);
    const [allowApprove, setAllowApprove] = useState(false);
    const [allowDeactivate, setAllowDeactivate] = useState(false);
    const [data, setData] = useState();


    useEffect(() => {
        const permissions = user.role.ModulePermissions?.filter(module => { return (module.Page == ROUTE_BILLING_ADJUSTMENTS.module) })
        setAllowEditing(permissions?.filter(module => { return (module.Update) }).length > 0)
        setAllowCreate(permissions?.filter(module => { return (module.Create) }).length > 0)
        setAllowDeactivate(permissions?.filter(module => { return (module.Deactivate) }).length > 0)
        setAllowApprove(permissions?.filter(module => { return (module.Approve) }).length > 0)
    }, [])

    const handleAddAdjustment = () => {
        navigate(ROUTE_ADD_ADJUSTMENTS.withSlash);
    }

    return (
        <div>
            <Typography component="div">
                <h1>{pageTitle}</h1>
            </Typography>
            <Box sx={{ height: 45, width: '97%', margin: 'auto auto 0 auto' }}>

                {allowCreate && <BaseButton
                    label="Add An Adjustment"
                    onClick={handleAddAdjustment}
                    variant={outlined}
                />}
            </Box>
            <SearchButtonGroupAdjustments setter={setData} />
            <BillingAdjustmentsTable
                allowEditing={allowEditing}
                allowCreate={allowCreate}
                allowApprove={allowApprove}
                allowDeactivate={allowDeactivate}
                data={data}
                setter={setData} />

        </div>
    );
}

export default BillingAdjustments;
