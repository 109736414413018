import { ROUTE_WELCOME } from "./Routes";
import { Navigate } from "react-router-dom";
export const ValidateRoute = ({ children, route }) => {
    var _user = JSON.parse(localStorage.getItem("user"));
    // console.log(_user.AdminUser === false)
    if (_user.role && _user.role.ModulePermissions && route) {
        const res = _user.role.ModulePermissions.filter(module => { return (module.Page === route?.module && module.Read) })
        const create = (!route.create || (route.create && res.filter(module => { return (module.Create) }).length > 0))
        const edit = (!route.edit || (route.edit && res.filter(module => { return (module.Update) }).length > 0))
        if (res.length > 0 && create && edit) {
            return children;
        }
    }
    return <Navigate to={ROUTE_WELCOME.withSlash} replace />;
}