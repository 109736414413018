import { DropDownBox, List, TextBox } from "devextreme-react";
import { useEffect, useState, useRef } from "react";
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import { useAuth } from '../../hooks/useAuth';
import { LoadingIndicatorCircle } from "../../components/Loading";
import GetFetch from "../../hooks/GetFetch";
import ClosingAlert from "../../components/ClosingAlert";

const FeeTypeSingleSelectDevEx = ({ value, setter, isRequired }) => {
    const [feeTypeList, setFeeTypeList] = useState([]);
    const [feeTypeNames, setFeeTypeNames] = useState([]);
    const [selectedFee, setSelectedFee] = useState()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const dropDownBoxRef = useRef(null);
    const { user } = useAuth();
    const [selectedKey, setSelectedItemKey] = useState();

    useEffect(() => {
        fetchFeeTypes()
    }, [])

    useEffect(() => {
        if (selectedFee) {
            setter(feeTypeList.filter(oa => oa.FeeTypeName === selectedFee)[0])
        }
        else { setter(null) }
    }, [selectedFee])


    const title = isRequired == true ? "Fee Type *" : "Fee Type";

    const fetchFeeTypes = async () => {
        setLoading(true);

        const feeList = await GetFetch('/v1/FeeType/GetFeeTypes')
        if (feeList.Success === true) {
            let allFeeTypes = feeList.Message
                .map(oa => oa.FeeTypeName);
            if (allFeeTypes.includes(value?.FeeTypeName)) {
                setSelectedFee(value.FeeTypeName);
                setSelectedItemKey(value.FeeTypeName);
            }
            setFeeTypeNames(allFeeTypes);
            setFeeTypeList(feeList.Message)
            setLoading(false)
        }
        else {
            setError(true)
            setErrorMessage(feeList.Errors[0].Message)
            setLoading(false)
        }
    }

    return (
        <>
            {(loading) && (
                <LoadingIndicatorCircle message="Loading Fee Types, please wait ..." />
            )}
            {!loading && feeTypeNames &&
                <div className="inline__div--wrapper">
                    <DropDownBox
                        label={title}
                        labelMode="floating"
                        height={45}
                        value={selectedFee}
                        dataSource={feeTypeNames}
                        ref={dropDownBoxRef}
                        onValueChanged={(e) => {
                            setSelectedFee(e.value);
                        }}
                        isValid={isRequired && !value ? false : true}
                    >
                        <List
                            selectedItemKeys={[selectedKey]}
                            selectionMode="single"
                            showSelectionControls={true}
                            ref={dropDownBoxRef}
                            onSelectionChanged={(e) => {
                                console.log(e.addedItems[0])
                                setSelectedFee(e.addedItems[0]);
                                setSelectedItemKey(e.addedItems[0]);
                                dropDownBoxRef.current.instance.close();
                            }}
                            dataSource={feeTypeNames}
                            pageLoadMode="scrollBottom"
                        />

                    </DropDownBox>
                    <ClosingAlert
                        severity="error"
                        message={errorMessage}
                        visible={error}
                        hideAlert={() => { setError(false) }}
                    />
                </div>
            }
        </>
    )
}
export default FeeTypeSingleSelectDevEx;