import { useState } from 'react';
import Button from '@mui/material/Button';
import {
    ButtonGroup,
    FormGroup,
    Typography
} from '@mui/material';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';

import PutPostPatchFetch from '../../hooks/PutPostPatchFetch.js';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { LoadingIndicatorPageLength } from '../../components/Loading';

import ClosingAlert from '../../components/ClosingAlert';
import RegionMultiSelectDevEx from '../../Reports/TotalBuildingCostReportComponents/RegionMultiSelectDevEx.js';
import BuildingMultiSelectDevEx from '../../Reports/TotalBuildingCostReportComponents/BuildingMultiselectDevEx.js';


const dateFieldSx = {
    mt: 1,
    mr: 1,
    width: 200
};

const SearchButtonGroupAdjustments = ({ setter }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedBldgNumber, setSelectedBldgNumber] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const [alertConfig, setAlertConfig] = useState({
        isVisible: false,
        type: 'info',
        message: '',
    })

    const [selectedRegions, setSelectedRegions] = useState([]);
    const showAlert = (message, type) => {
        setAlertConfig({
            isVisible: true,
            type: type,
            message: message,
        });
    };

    const hideAlert = () => {
        setAlertConfig({
            isVisible: false,
            type: alertConfig.type,
            message: "",
        });
    };

    const invalidDate = "Please ensure that the date range you entered follows the correct syntax. The date range should be in the format of 'MM/YYYY - MM/YYYY.' For example, '01/2023 - 12/2023' represents a valid date range for the fiscal year. Double-check your input and try again. If you continue to experience issues, please contact our support team for assistance.";

    const handle_setSelectValue_BldgSequenceNo = (value) => {
        setSelectedBldgNumber(value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };
    const handleSearch = async () => {
        hideAlert()
        setter(null);
        setLoadingData(true);
        const startDateFormat = startDate?.format('YYYY-MM-DD');
        const endDateFormat = endDate?.format('YYYY-MM-DD');

        try {

            let searchParameters = { StartDate: startDateFormat, EndDate: endDateFormat, BuildingNumbers: selectedBldgNumber };
            const result = await PutPostPatchFetch('/v1/Adjustments/ByBuildingNumberList', 'POST', searchParameters);
            if (result.Success) {
                setter(result.Message);
                hideAlert();

            } else if (result.Errors) {
                let errorMessage = result.Errors[0].Message
                if (result.Errors.startDate || result.Errors.endDate)
                    errorMessage = "invalid date(s)";
                showAlert(errorMessage, "error");
                setter(null);
                console.error(result.Errors);
            }

        } catch (error) {
            showAlert("something went wrong. Please try again", "error");
            setter(null);
            console.error(error);
        }
        setLoadingData(false);
        //}
    };

    return (
        <FormGroup sx={{ width: '97%', margin: 'auto' }}>
            <Typography
                variant="body1"
                component="em"
                style={{ color: "#06152B" }}
            >
                Search by Date Range, Building Number, and Occupancy Agreement
            </Typography>
            <ButtonGroup
                disableElevation
                variant="contained"
                sx={{ my: 1 }}
            >
                <RegionMultiSelectDevEx
                    value={selectedRegions}
                    valueSetter={setSelectedRegions}
                />

                <span>&nbsp;</span>
                <BuildingMultiSelectDevEx
                    value={selectedBldgNumber}
                    valueSetter={handle_setSelectValue_BldgSequenceNo}
                    selectedRgns={selectedRegions}
                    dependentOnRegion={true}
                    req={true}

                />
                <span>&nbsp;</span>
            </ButtonGroup>
            <ButtonGroup>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                        sx={dateFieldSx}
                        id="start-date"
                        label="Bill Period Start Date"
                        format="MM-YYYY"
                        value={startDate}
                        onChange={(newValue) => { setStartDate(newValue) }}
                        inputProps={{
                            onKeyDown: handleKeyDown
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                    />
                    <DateField
                        id="end-date"
                        sx={dateFieldSx}
                        label="Bill Period End Date"
                        format="MM-YYYY"
                        value={endDate}
                        onChange={(newValue) => { setEndDate(newValue) }}
                        inputProps={{
                            onKeyDown: handleKeyDown
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                    />
                </LocalizationProvider>
                <span>&nbsp;</span>
                <Button
                    id="submit-button"
                    variant="contained"
                    disabled={!selectedBldgNumber || selectedBldgNumber.length < 1}

                    onClick={handleSearch}
                    startIcon={<QueryStatsRoundedIcon />}
                >
                    Find Adjustments
                </Button>
            </ButtonGroup>
            {loadingData &&
                <LoadingIndicatorPageLength message={"searching for records..."} />
            }
            <ClosingAlert
                severity={alertConfig.type}
                message={alertConfig.message}
                visible={alertConfig.isVisible}
                hideAlert={hideAlert}
            />
            {/* <CircularProgress color="secondary" /> */}
        </FormGroup>
    );
}

export default SearchButtonGroupAdjustments;