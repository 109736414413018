import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as FpsLogo } from '../assets/logo.svg';
import CustomAppBar from './CustomAppBar';
import Main from './Main';
import NavList from './NavList';
import DrawerHeader from './DrawerHeader';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
    useNavigate,
} from 'react-router';
import { Grid, SvgIcon } from '@mui/material';
import {
    useState,
    useEffect,
    useCallback,
    useRef,
    Fragment
} from 'react';
import { Outlet } from 'react-router';
import { useAuth } from '../hooks/useAuth';
import { RequireValidation } from '../routes/RequireValidation';
import ConcurrentSessionsModal from '../Sessions/ConcurrentSession';
import SecurityBannerModal from '../SecurityBanner/SecurityBannerModal';
import SessionContext from '../App/SessionContext';
import { rmsVersion, rmsDeployment, rmsSessionTimeoutArg, rmsSessionConcurrency, rmsSessionCheckInterval } from "../util/reactAppEnvVariables";
import { useInterval } from '../util/Poller';
import useAutoTimeout from "../hooks/useAutoTimeout";
import { sortRegions } from '../util/_helpers';
import SkipToMainContent from './SkipToMainContent.js';
const drawerWidth = 240;

export default function NavigationMenu(props) {
    const [open, setOpen] = useState();
    const [show, setShow] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openConcurrentSessions, setOpenConcurrentSessions] = useState(false);
    const [openBanner, setOpenBanner] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [outoftime, setOutOfTime] = useState(false);
    const [timerDisplay, setTimeDisplay] = useState({});
    const timer = useAutoTimeout(rmsSessionTimeoutArg * 60)
    const { user, logout, getActiveSession } = useAuth();
    // Display Current Sessions Dialog if there is an existing Session Token
    useEffect(() => {
        if (SessionContext.get("ExistingSessionToken")) {
            concurrentSessionsModal();
            setOpenBanner(true);
        }
    }, [openConcurrentSessions]);

    // Display Security Banner after successful Login
    useEffect(() => {
        if (SessionContext.get("UserId") && !SessionContext.get("SecurityAccepted")) {
            bannerModal();
        }
    }, [openBanner]);

    // Display Security Banner after successful Login
    useEffect(() => {
        secondsToTime(timer); // set the timer display
        if (timer < 120) {
            setOutOfTime(true);
        } else {
            setOutOfTime(false);
        }
    }, [timer]);

    // If timer goes to 0, logout the user since Session has Timed out
    if (timer === 0) {
        logout('warning', `You have been inactive for ${rmsSessionTimeoutArg} minutes. Your session has timed out. Please log back in to continue.`, false);
    }

    // We must have an active session in place to stay logged in.
    // Poll for an activeSession and if found, continue but if not found, logoff.
    useInterval(async () => {
        if (rmsSessionConcurrency === 'true' && !openConcurrentSessions && !openBanner) {
            const sessionToken = SessionContext.get("SessionToken");
            let asResult = await getActiveSession(sessionToken, user.email)
            if (!asResult.Success) {
                console.log("NavigationMenu.js - Error finding ActiveSession.");
                logout('info', `You have been logged out because your user has logged in from another location. User successfully logged out.`, true);
                return;
            }
            else if (!asResult.Message) {
                console.log("NavigationMenu.js - No ActiveSession found.");
                logout('info', `You have been logged out because your user has logged in from another location. User successfully logged out.`, true);
                return;
            }
        }
    }, 1000 * rmsSessionCheckInterval);

    const toggleExpanded = () => {
        setIsExpanded(prev => !prev);
    };
    const concurrentSessionsModal = () => {
        setOpenConcurrentSessions(true);
    }
    const bannerModal = () => {
        setOpenBanner(true);
    }
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));
        let hoursString = hours.toString();
        if (hours < 10) {
            hoursString = "0" + hoursString;
        }

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let minutesString = minutes.toString();
        if (minutes < 10) {
            minutesString = "0" + minutesString;
        }

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        let secondsString = seconds.toString();
        if (seconds < 10) {
            secondsString = "0" + secondsString;
        }
        setTimeDisplay({
            "h": hoursString,
            "m": minutesString,
            "s": secondsString
        });
    };

    const menuItemDisabledStyles = { color: "black", cursor: "default", opacity: 1, pointerEvents: "none" }

    return (
        <>
            <div>
                <SecurityBannerModal open={openBanner} setOpen={setOpenBanner} />
                <ConcurrentSessionsModal open={openConcurrentSessions} setOpen={setOpenConcurrentSessions} />

                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <CustomAppBar position="fixed" open={open} elevation={0}>
                        <Toolbar
                            sx={{ backgroundColor: 'white', }}
                        >
                            <SkipToMainContent />
                            <IconButton
                                role="button"
                                color="black"
                                aria-label="open drawer"
                                aria-expanded={open ? 'true' : 'false'}
                                onClick={handleDrawerOpen}
                                edge="start"
                                id="logo_button_040"
                                onMouseEnter={() => { setShow(true); }}
                                onMouseLeave={() => { setShow(false); }}
                                style={{
                                    color: show ? 'white' : '',
                                    background: show ? 'linear-gradient(72.47deg, #005288.16%, rgba(0, 82, 136, 0.7) 76.47%)' : '',
                                }}
                                sx={{ mr: 2, ...(open && { display: 'none' }) }}
                                tabIndex={0}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleDrawerOpen();
                                    }
                                }}
                                onFocus={() => {
                                    //const announcement = open ? 'Open Drawer button expanded' : 'Open Drawer button collapsed';
                                    //// Use a screen reader library or API to announce the message
                                    //announceScreenReaderMessage(announcement);
                                }}
                            >

                                <SvgIcon sx={{ fontSize: 45 }}>
                                    <FpsLogo />
                                </SvgIcon>
                            </IconButton>

                            <Box sx={{ width: '100%' }} />

                            <Typography
                                variant="h7"
                                id="timeout_session_046"
                                sx={{
                                    color: outoftime ? 'white' : '#06152B'
                                    , background: outoftime ? 'red' : 'white'
                                    , px: 2
                                }}
                            >
                                Time out:[{timerDisplay.h}:{timerDisplay.m}:{timerDisplay.s}]
                            </Typography>
                            {user &&
                                <div>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        sx={{ ml: 2 }}
                                        onClick={handleMenu}
                                        color="primary"
                                        id="account_of_current_user_041"
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem sx={{ ...menuItemDisabledStyles, color: '#06152B' }}>Logged in as: {user.email}</MenuItem>
                                        <MenuItem sx={{ ...menuItemDisabledStyles, color: '#06152B' }}>Access to region(s): {sortRegions(user.Regions)}</MenuItem>
                                        <MenuItem onClick={logout} sx={{ color: '#06152B' }}>Log out</MenuItem>
                                    </Menu>
                                </div>
                            }
                        </Toolbar>
                    </CustomAppBar>
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',

                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={open}
                    >
                        <DrawerHeader>
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                            >
                                <Grid>
                                    <SvgIcon sx={{ fontSize: 45 }}>
                                        <FpsLogo />
                                    </SvgIcon>
                                </Grid>
                                <Grid>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        sx={{ marginBottom: '2px' }}
                                    >
                                        <strong>FPS RMS</strong>
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <IconButton
                                        role="button"
                                        aria-label="Close drawer"
                                        aria-expanded={toggleExpanded}
                                        onClick={handleDrawerClose}
                                        onMouseEnter={() => { setShow(true); }}
                                        onMouseLeave={() => { setShow(false); }}
                                        id="icon_button_close_drawer_042"
                                        style={{
                                            color: show ? 'white' : '',
                                            background: show ? 'linear-gradient(72.47deg, #005288.16%, rgba(0, 82, 136, 0.7) 76.47%)' : '',
                                        }}
                                    >
                                        <MenuOpenIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DrawerHeader>
                        <NavList />
                        <Typography
                            component='div'
                        >
                            <h6
                                style={{
                                    paddingLeft: '20px',
                                    color: "#06152B"
                                }}
                            >   RMS version: {rmsVersion}-{rmsDeployment}</h6>
                        </Typography>
                    </Drawer>
                    <Main id="main-div" open={open}>
                        <DrawerHeader />
                        <RequireValidation>
                            <Outlet />
                        </RequireValidation>

                    </Main>
                </Box>
            </div>
        </>
    );
}