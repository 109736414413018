const  FiscalYearList = ()=>{

    var currentYear = new Date().getFullYear();
    var currentMonth = new Date().getMonth() + 1;
    var fiscalYears = [];
    if(currentMonth < 10) fiscalYears.push(currentYear)
    if(currentMonth > 6) fiscalYears.push(currentYear + 1)
    
      return  fiscalYears ;
    
}
export default FiscalYearList;