import { Alert } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import GetFetch from "../../../hooks/GetFetch";
import { useAuth } from "../../../hooks/useAuth";
import { LoadingIndicatorCircle } from "../../../components/Loading";
import { SelectBox } from "devextreme-react";
import Validator, { CustomRule, RequiredRule } from 'devextreme-react/validator';
import IsNullOrEmpty from "../../../components/IsNullOrEmpty";
import { BaseSelectField } from "../../../components/BaseSelectField/BaseSelectField";
import "./SingleRegionSelectField.scss";


const SingleRegionSelectField = (
    {
        enableSearch,
        isVisible,
        label, 
        optionUpdatedByUser,
        validationMethod,
        inputAttribute,
        value
    }
) => {
    const [regions, setRegions] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const { user } = useAuth();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setError(false);
        setRegions(null);
        setLoading(true);
        const result = await GetFetch('/v1/Region');
        const { Success, Message, Errors } = result;
        if (Success) {
            const regionNumbers = Message.map(({RegionNumber}) => RegionNumber);
            regionNumbers.sort((a, b) => a - b);
            
            setError(false);
            setRegions(regionNumbers);
        }
        else {
            setError(true);
            if (Errors[0].Message === "No records found at the moment") {
                setErrorMessage("Something went wrong. Please contact your administrator.");
            }
            else {
                setErrorMessage(Errors[0].Message);
            }
        }
        setLoading(false);
    };


    return (
        <>
            {
                loading && 
                <span className="inline__span--wrapper">
                    <LoadingIndicatorCircle message="Loading available regions..." />
                </span>
            }
            {
                regions && 
                (
                    <BaseSelectField
                        inputAttribute={inputAttribute}
                        data={regions}
                        enableSearch={enableSearch}
                        isVisible={isVisible}
                        label={label}
                        onInitialized={(e) => e.component.validate()}
                        onOptionChanged={(e) => e.component.validate()}
                        optionUpdatedByUser={optionUpdatedByUser}
                        validationRuleCallback={() => !IsNullOrEmpty(value)}
                        value={value}
                    />
                )
            }
            {/* {regions && (
                <SelectBox
                    label={dropdownTitle}
                    dataSource={regions}
                    searchEnabled={true}
                    height={56}
                    width={188}
                    value={value}
                    id="building_region_number_013"
                    onValueChange={(value) => {
                        setter(value)
                    }}
                >
                    <Validator
                        onInitialized={(e) => e.component.validate()}
                        onOptionChanged={(e) => e.component.validate()}
                    >
                        <RequiredRule type="required" />
                        <CustomRule 
                            message="A Region Number is required"
                            validationCallback={ () => validationMethod(value) }
                            reevaluate={true}
                        />
                    </Validator>
                </SelectBox>         
            )} */}
            {error && (
                <Alert severity="error" sx={{ height: '50px', mt: 4 }}>
                    {errorMessage}
                </Alert>
            )}
        </>
    );
};

export default SingleRegionSelectField;