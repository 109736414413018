import { useEffect, useRef, useState } from "react";
import RegionMultiSelectUserBased from "../RegionMultiSelectUserBased/RegionMultiSelectUserBased";
import "./AddBuildingsBasedOnUsersRegion.scss";
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import { LoadingIndicatorCircle } from "../Loading";
import { Box, DropDownBox, List, TextBox } from "devextreme-react";
import { Item as BoxItem } from "devextreme-react/box";
import { BaseButton } from "../BaseButton/BaseButton";

const AddBuildingsBasedOnUsersRegion = ({
    _selectedBuildings,
    _setSelectedBuildings,
    _userRegions,
    _userAccountName
}) => {
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [regions, setRegions] = useState([]);
    const [allSequenceNumbers, setAllSequenceNumbers] = useState([]);
    const [displayedSequenceNumbers, setDisplayedSequenceNumbers] = useState([]);
    const [stagedSequenceNumbers, setStagedSequenceNumbers] = useState([]);
    const [search, setSearch] = useState("");

    const reference = useRef(null);

    useEffect(() => {
        fetchBuildingNumbers();
    }, []);

    useEffect(() => {
        setDisplayedSequenceNumbers(regionizeSequenceNumbers());
        // every time regions is changed, lets clear the search
        setSearch("");
    }, [regions]);

    useEffect(() => {
        // change display list based on search
        handleSearch();
    }, [search]);

    useEffect(() => {
        setStagedSequenceNumbers([]);
    }, [_selectedBuildings])

    const fetchBuildingNumbers = async () => {
        setLoading(true);
        const regionAndUser = { RegionNumbers: _userRegions, UserId: _userAccountName };
        const { Success, Message, Errors } = await PutPostPatchFetch('/v1/BuildSpecReport/BuildingNumbersByRegions', 'POST', regionAndUser);

        if (Success) {
            setAllSequenceNumbers(Message);
            setDisplayedSequenceNumbers(Message);
        }
        else {
            // throw error
        }
        setLoading(false);
    };
    const regionizeSequenceNumbers = () => {
        let result = [];
        if (regions.length < 1 || regions.length === _userRegions.length) {
            // no regions or all regions selected; show all
            result = allSequenceNumbers;
        }
        else {
            const regions_copy = [...regions];
            result = allSequenceNumbers.filter(e => regions_copy.find(r => r === e.RegionNumber));
        }
        return result;
    }
    const handleAddingStagedBuildingsToCustomerAccount = async () => {
        setSubmitting(true)
        if (stagedSequenceNumbers.length > 0) {
            let commitSeqList = stagedSequenceNumbers.map(b => b.SequenceNumber);
            let seqOnly = stagedSequenceNumbers.map(b => b.SequenceNumber);
            let selectedSeqOnly = _selectedBuildings.map(b => b.SequenceNumber);

            for (var i = 0; i < seqOnly.length; i++) {
                if (selectedSeqOnly.includes(seqOnly[i])) {
                    commitSeqList.splice(i, 1);
                }
            }
            if (commitSeqList.length > 0) {
                const request = await PutPostPatchFetch(
                    '/v1/Building/ListOfBuildingsByNumber'
                    , 'PUT'
                    , commitSeqList
                );
                const { Success, Errors, Message } = request;
                if (Success) {
                    let submissionList = _selectedBuildings.concat(Message);
                    _setSelectedBuildings(submissionList);
                }
                else {
                    console.log(Errors)
                }
            }
            setStagedSequenceNumbers([]);
        }
        setSubmitting(false)
    }

    const handleSearch = () => {
        let result = regionizeSequenceNumbers();
        if (search.length > 0) {
            // perform search
            let searchedValueUpperCase = search.toLocaleUpperCase();
            let filtered = result.filter(e => e.SequenceNumber.startsWith(searchedValueUpperCase))
            result = filtered;
        }
        setDisplayedSequenceNumbers(result);
    }
    return (
        <>
            {loading && displayedSequenceNumbers.length < 1
                ? <LoadingIndicatorCircle message={"Gathering Buildings..."} />
                :
                <>
                    <Box
                        direction="row"
                    >
                        <BoxItem ratio={1}>
                            <>
                                <p>Select Building(s) for this customer account:</p>
                                <DropDownBox
                                    label="Select Building(s)"
                                    labelMode="floating"
                                    height={56}
                                    width={230}
                                    value={stagedSequenceNumbers}
                                    valueExpr="SequenceNumber"
                                    displayExpr="SequenceNumber"
                                    dataSource={displayedSequenceNumbers}
                                    ref={reference}
                                    tabIndex={0}
                                >
                                    <>
                                        <TextBox
                                            value={search}
                                            onInput={e => setSearch(e.event.target.value)}
                                            placeholder="Search..."
                                            showClearButton={true}
                                        />
                                        <List
                                            ref={reference}
                                            // selectedItemKeys={value.map(b => { b.SequenceNumber, b.RegionNumber })}
                                            // showSelectionControls={true}
                                            dataSource={displayedSequenceNumbers}
                                            selectionMode="multiple"
                                            valueExpr="SequenceNumber"
                                            displayExpr="SequenceNumber"
                                            pageLoadMode="scrollBottom"
                                            showScrollbar="always"
                                            height="400px"
                                            useNativeScrolling={false}
                                            onSelectionChanged={(e) => {
                                                let copy = [...stagedSequenceNumbers];
                                                // Item is added
                                                if (e.addedItems.length > 0) {
                                                    copy.push(e.addedItems[0])
                                                }
                                                // Item is being removed
                                                else if (e.removedItems.length > 0) {
                                                    const index = copy.indexOf(e.removedItems[0])
                                                    copy.splice(index, 1)
                                                }
                                                setStagedSequenceNumbers(copy);
                                            }}
                                        />

                                    </>
                                </DropDownBox>
                            </>

                        </BoxItem>
                        <BoxItem ratio={2}
                            style={{
                                background: 'white'
                            }}>

                            <>
                                <p>Optionally filter by Region(s):</p>
                                <RegionMultiSelectUserBased
                                    _label={"Filter By Region(s)"}
                                    _regions={regions}
                                    _setRegions={setRegions}
                                    _userRegions={_userRegions}
                                />
                            </>
                        </BoxItem>
                    </Box>

                    <div style={{
                        background: 'white',
                        borderRadius: '10px',
                        margin: '10px 0 10px 0',
                        maxWidth: '530px',
                        padding: 15
                    }}>
                        <List
                            dataSource={stagedSequenceNumbers}
                            displayExpr="SequenceNumber"
                            noDataText="Staged Building(s) appear here, please select Building(s)."
                        />
                        <BaseButton
                            label={"Commit Building(s) to Customer Account"}
                            onClick={handleAddingStagedBuildingsToCustomerAccount}
                            variant={"outlined"}
                            disabled={stagedSequenceNumbers.length < 1 || submitting}
                        />

                    </div>
                </>
            }
        </>
    )
}
export default AddBuildingsBasedOnUsersRegion;