import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_CREATE_CUSTOMER, ROUTE_CUSTOMERS, ROUTE_EDIT_CUSTOMER } from '../routes/Routes';
import {
    Box,
    LinearProgress,
} from '@mui/material';
import GetFetch from '../hooks/GetFetch';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Selection,
    Column,
    Export
} from 'devextreme-react/data-grid';
import CustomPreparedHeaderCell from '../components/CustomPreparedHeaderCell';
import CustomersAdd from './CustomersAdd';
import CustomersUpdate from './CustomersUpdate';
import { logExport, useAuth } from '../hooks/useAuth';
import ExportGrid from '../components/ExportGrid';
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import ClosingAlert from '../components/ClosingAlert';
import { BaseButton } from '../components/BaseButton/BaseButton';
import { Content } from '../Enums/contents';
import './css/Customers.scss';


const {
    Buttons: {
        editCustomerButtonLabel,
        newCustomerButtonLabel,
        MUI: {
            Variant: {
                outlined
            }
        }
    }
} = Content;

const CustomersTable = (props) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [addBuilding, setAddBuilding] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState();
    const [updateBld, setUpdateBld] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [editRowButton, setEditRowButton] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        fetchData();
    }, [props]);

    const customerObject = {
        "AgencyName": "",
        "AgencyBureauName": "",
        "AgencyBureauShortName": "",
        "AgencyBureauCode": "",
        "BilledAgencyBureauCode": "",
        "ActualAgencyBureauCode": "",
        "AgencyLocationCode": "",
        "BureauAddress": {
            "Id": 0,
            "Address1": "",
            "Address2": "",
            "CityName": "",
            "State": "",
            "ZipCode": ""
        },
        "CreatedBy": "",
        "CreatedDate": "",
        "UpdatedBy": "",
        "UpdatedDate": ""
    };

    const fetchCustomers = () => {
        return GetFetch('/v1/Customer/GetCustomers')
    };

    const fetchData = async () => {
        setLoading(true);
        /**
         * @todo
         * REPLACE SESSION OBJECTS WITH USER DATA FROM CALLS
         */
        const { Errors, Message, Success } = await fetchCustomers();
        setLoading(false);

        if (Success === true) {
            setData(Message);
            setError(false);
            setErrorMessage(null);
        }
        else {
            setError(true);
            setErrorMessage(Errors[0].Message);
            console.error(Errors);
        }
    };


    const dataSource = new CustomStore({
        key: 'CustomerId',
        load: async () => {
            const data = await fetchCustomers();
            const { Message } = data;
            console.log(data);
            let checkedData = [];
            if (Message)
                checkedData = Message.map(
                (
                    {
                        CustomerId,
                        AgencyName,
                        AgencyBureauName,
                        AgencyBureauShortName,
                        AgencyBureauCode,
                        BilledAgencyBureauCode,
                        ActualAgencyBureauCode,
                        AgencyLocationCode,
                        TreasuryAccSymbol,
                        BusinessEventTypeCode,
                        CustomerPOCFirstName,
                        CustomerPOCLastName,
                        POCsToRecieveSummary,
                        SendIPAC,
                        FundingDocumentNumber,
                        BureauAddress: {
                            Id,
                            Address1,
                            Address2,
                            CityName,
                            State,
                            ZipCode
                        },
                        Buildings,
                        OccupancyAgreements,
                        CreatedBy,
                        CreatedDate,
                        BuildingByCustomer
                    }
                ) => {
                    let buildingIds = [];
                    let buildingNumbers = ""
                    let specialBuildingList = [];
                    if (Buildings) {
                        buildingIds = Buildings.map(({ BuildingId }) => { return BuildingId });
                        buildingNumbers = Buildings.map(({ SequenceNumber }) => { return SequenceNumber }).toString();
                    }
                    let oas = "";
                    if (OccupancyAgreements) {
                        oas = OccupancyAgreements.map(({ OANumber }) => { return OANumber })
                    }
                    return {
                        CustomerId: CustomerId,
                        Buildings: Buildings,
                        AgencyName: AgencyName,
                        AgencyBureauName: AgencyBureauName,
                        AgencyBureauShortName: AgencyBureauShortName,
                        AgencyBureauCode: AgencyBureauCode,
                        BilledAgencyBureauCode: BilledAgencyBureauCode,
                        ActualAgencyBureauCode: ActualAgencyBureauCode,
                        AgencyLocationCode: AgencyLocationCode,
                        TreasuryAccSymbol: TreasuryAccSymbol,
                        BusinessEventTypeCode: BusinessEventTypeCode,
                        POCFullName: CustomerPOCFirstName + " " + CustomerPOCLastName,
                        CustomerPOCFirstName: CustomerPOCFirstName,
                        CustomerPOCLastName: CustomerPOCLastName,
                        POCsToRecieveSummary: POCsToRecieveSummary === true ? "Yes" : "No",
                        PocsToReceiveSummaryActual: POCsToRecieveSummary,
                        SendIPAC: SendIPAC === true ? "Yes" : "No",
                        SendIpacActual: SendIPAC,
                        FundingDocumentNumber: FundingDocumentNumber,
                        BureauAddressId: Id,
                        address: `${Address1}, ${Address2 !== null && Address2 !== '' ? Address2 + ',' : ''} ${CityName}, ${State} ${ZipCode}`,
                        Address1: Address1,
                        Address2: Address2,
                        CityName: CityName,
                        StateLocation: State,
                        ZipCode: ZipCode,
                        buildingIds: buildingIds,
                        buildingNumbers: buildingNumbers,
                        OccupancyAgreements: OccupancyAgreements,
                        OccupancyAgreementNumbers: oas,
                        CreatedBy: CreatedBy,
                        CreatedDate: CreatedDate,
                        BuildingByCustomer: BuildingByCustomer
                    };
                }
            );

            return checkedData;
        }
    });

    const handleAddCustomer = () => {
        navigate(ROUTE_CREATE_CUSTOMER.withSlash, {
            state: { selectedRowData }
        });
    };

    const handleEditButtonClick = () => {
        navigate(ROUTE_EDIT_CUSTOMER.withSlash, {
            state: { selectedRowData }
        });
    };
    const pdfFont = () => { return 7; }
    const wrapPdfCell = () => {
        return true;
    }

    const handleExport = (e) => {
        logExport(user.email);
        ExportGrid(e, "Customers_Data", wrapPdfCell(), pdfFont())
    }

    /**
     * @todo Share this global function with UpdateUser.js
     */
    const handleRowClick = (e) => {
        setSelectedRowData(e.data);
        setEditRowButton(true);
    };

    /**
     * @todo Share this variable with UsersPageComponents.js
     */
    const isRecordSelected = !editRowButton ? true : false;


    return (
        <div>
            <ClosingAlert
                severity="error"
                message={errorMessage}
                visible={error}
                hideAlert={() => { setError(false) }}
            />
            {
                loading &&
                <LinearProgress />
            }
            <div>
                {
                    !loading && !error &&
                    <>
                        <Box sx={{ height: 45, width: '97%', margin: 'auto auto 0 auto' }}>
                            {props.allowCreate && <BaseButton
                                label={newCustomerButtonLabel}
                                onClick={handleAddCustomer}
                                variant={outlined}
                            />}
                            {props.allowEditing && <BaseButton
                                ariaDisabled={isRecordSelected}
                                disabled={isRecordSelected}
                                label={editCustomerButtonLabel}
                                onClick={handleEditButtonClick}
                                variant={outlined}
                            />}
                        </Box>


                        <Box sx={{ height: 700, width: '97%', margin: 'auto' }}>
                            <span
                                aria-live="polite"
                                aria-atomic="true"
                            >
                                <DataGrid
                                    columnAutoWidth={true}
                                    dataSource={dataSource}
                                    onCellPrepared={CustomPreparedHeaderCell}
                                    onRowClick={handleRowClick}
                                    showBorders={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                    onExporting={e => handleExport(e)}
                                >
                                    <KeyboardNavigation enabled={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                                    <Selection mode="single" />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={allowedPageSizes}
                                    />
                                    <Column dataField="CustomerId" visible={false} />
                                    <Column dataField="BureauAddressId" visible={false} />
                                    <Column dataField="BuildingIds" visible={false} />
                                    <Column dataField="Buildings" visible={false}/>
                                    <Column dataField="AgencyName" caption="Agency Name" />
                                    <Column dataField="AgencyBureauName" caption="Agency Bureau Name" />
                                    <Column dataField="AgencyBureauShortName" caption="AB Short Name" />
                                    <Column dataField="AgencyBureauCode" caption="AB Code" />
                                    <Column dataField="BilledAgencyBureauCode" caption="Billed AB Code" />
                                    <Column dataField="ActualAgencyBureauCode" caption="Actual AB Code" />
                                    <Column dataField="AgencyLocationCode" caption="Agency Location Code" />
                                    <Column dataField="address" caption="Address" />
                                    <Column dataField="Address1" caption="Address1" visible={false}/>
                                    <Column dataField="Address2" caption="Address2" visible={false}/>
                                    <Column dataField="CityName" caption="CityName" visible={false}/>
                                    <Column dataField="StateLocation" caption="State" visible={false}/>
                                    <Column dataField="ZipCode" caption="ZipCode" visible={false}/>
                                    <Column dataField="buildingNumbers" caption="Associated Buildings" />
                                    <Column dataField="OccupancyAgreementNumbers" caption="OA Numbers" />
                                    <Column dataField="OccupancyAgreements" caption="Occupancy Agreement Objects" visible={false}/>
                                    <Column dataField="TreasuryAccSymbol" caption="Treasury Acc. Symbol" />
                                    <Column dataField="BusinessEventTypeCode" caption="Business Event Type Code" />
                                    <Column dataField="POCFullName" caption="Customer POC" />
                                    <Column dataField="CustomerPOCFirstName" caption="CustomerPOCFirstName" visible={false}/>
                                    <Column dataField="CustomerPOCLastName" caption="CustomerPOCLastName" visible={false}/>
                                    <Column dataField="POCsToRecieveSummary" caption="POC to Recieve Summary" />
                                    <Column dataField="PocsToReceiveSummaryActual" caption="actual PocSum Value" visible={false}/>
                                    <Column dataField="SendIPAC" caption="Send IPAC" />
                                    <Column dataField="SendIpacActual" caption="actual SendIpacActual Value" visible={false}/>
                                    <Column dataField="FundingDocumentNumber" caption="Funding Doc #" />
                                    <Column dataField="CreatedBy" caption="CreatedBy" visible={false}/>
                                    <Column dataField="CreatedDate" caption="CreatedDate" visible={false}/>
                                    <Column dataField="BuildingByCustomer" caption="Building By Customer" visible={false}/>
                                    <Export
                                        enabled={true}
                                        formats={pageFormats}
                                    />
                                </DataGrid>

                                </span>
                            </Box>
                    </>
                }
            </div>
        </div >
    );
};


export default CustomersTable;