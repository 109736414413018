import React from 'react';
import { Item } from 'devextreme-react/list';
import Button from 'devextreme-react/button';
import { AlignHorizontalCenter } from '@mui/icons-material';

const ModuleItem = ({ prop }) => {
    return (
    <div className={`task-item task-item-priority--low`}>
        <Button
            id={`button-${prop.id}`}
            width='100%'
            onClick={() => { window.location.href = prop.path }}>
            {prop.name}
        </Button>
    </div>
  );
}
export default ModuleItem;