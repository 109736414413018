import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from "../Login/RmsPool";
import GetFetch from "./GetFetch";
import PutPostPatchFetch from './PutPostPatchFetch.js';
import SessionContext from "../App/SessionContext";
import { ROUTE_WELCOME, ROUTE_RMS } from "../routes/Routes";
import { RMSAUTHMETHOD_COGNITO, RMSAUTHMETHOD_OKTA, rmsAuthMethod, rmsSessionConcurrency } from "../util/reactAppEnvVariables";
import { baseUrl } from "../util/Endpoint";
import { refreshApiToken } from "./ApiToken.js";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage("user", null);
    const [unverifiedUser, setUnverifiedUser] = useLocalStorage("unverifiedUser", null);
    const navigate = useNavigate();

    // handle login for both Cognito and Okta
    const login = async (data) => {
        if (data === null) {
            logout('error', 'Login called without a userid. User successfully logged out.', false);
            return;
        }

        console.log("Login - email = ", data.email);
        SessionContext.set("UserId", data.email);
        const userUnverifiedData = {
            "IsAuthorized": false,
            "email": data.email,
        }
        setUnverifiedUser(userUnverifiedData);
        const result = await authenticate(data.email, data.password);
        // If the above fails, an exception will be thrown and toast will catch
        const token = await refreshApiToken();
        console.log("retrieved token:", token !== null);
        const fpsResult = await validateUser();
        if (!fpsResult.Success) {
            console.log("validateUser fpsResult.Success FALSE = ", fpsResult);
            logout('error', 'Failed to validate user. User successfully logged out.', false);
            setUnverifiedUser(null);
            return;
        }
        if (!fpsResult.Message.IsValid) {
            console.log("validateUser fpsResult.Message !IsValid", fpsResult.Message.Message);
            logout('error', `Failed to validate user. Reason: User State = ${fpsResult.Message.Message}. User successfully logged out.`, false);
            setUnverifiedUser(null);
            return;
        }
        // If rmsSessionConcurrency=true and there is already another Active Session
        let activeSessionResult = null;
        if (rmsSessionConcurrency === 'true') {
            const asResult = await getActiveSessionByUserAccount(data.email);
            if (asResult.Success) {
                const esToken = asResult.Message.SessionToken;
                if (esToken !== null) {
                    console.log('An Active Session Exists.');
                    SessionContext.set("ExistingSessionToken", esToken);
                }
            } else {
                activeSessionResult = await activeSessionBegin(data.email);
                if (!activeSessionResult.Success) {
                    logout('error', `Failed to begin an active session. Error Message: "${activeSessionResult.Errors[0].Code}" User successfully logged out.`, false);
                    return;
                }
                // Setup the Session Token in the SessionContext
                SessionContext.set("SessionToken", activeSessionResult !== null ? activeSessionResult.Message : null);
            }
        }

        const userData = {
            "email":
                rmsAuthMethod === RMSAUTHMETHOD_OKTA ?
                    data.email :
                    result.idToken.payload.email,
            "Regions": fpsResult.Message.FpsUserDomain.Regions,
            "SessionToken": activeSessionResult !== null ? activeSessionResult.Message : null,
            "role": fpsResult.Message.FpsUserDomain.Role,
            "AdminUser": fpsResult.Message.FpsUserDomain.Role.Name === 'Admin',
        }

        setUser(userData);
        navigate(ROUTE_WELCOME.withSlash);
    }

    // call this function to sign out logged in user
    const logout = async (asev, amsg, sessionEnded) => {
        if (rmsSessionConcurrency === 'true') {
            // End the Active Session
            const sToken = SessionContext.get("SessionToken");
            const username = SessionContext.get("UserId")
            if (sToken && !sessionEnded) {
                const activeSessionResult = await activeSessionEnd(sToken, username);
                if (!activeSessionResult.Success) {
                    asev = 'error';
                    amsg = 'Failed to end session. User logged out with errors.'
                }
                SessionContext.remove("SessionToken");
            }
        }

        // Remove from SessionContext
        SessionContext.remove("UserId");
        SessionContext.remove("SecurityAccepted");
        SessionContext.remove("logoutTime");
        // clear local storage
        window.localStorage.clear();
        // Perform Cognito specific logout functions
        if (rmsAuthMethod === RMSAUTHMETHOD_COGNITO) {
            const user = Pool.getCurrentUser();
            if (user) {
                user.signOut();
            }
        }

        setUser(null);

        // Setup the Alert Message for the Login Screen from logging off
        asev = asev === 'info' || asev === 'warning' || asev === 'success' || asev === 'error' ? asev : 'success';
        amsg = amsg ? amsg : 'User successfully logged out.'
        let searchStr = `?alertseverity=${asev}&alertmessage=${amsg}`;

        console.log('useAuth: logging off - searchStr = ', searchStr);

        navigate(ROUTE_RMS.withSlash + searchStr, { replace: true });
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            logExport,
            getActiveSession,
            getActiveSessionByUserAccount,
            activeSessionBegin,
            activeSessionEnd,
            validateUser,
            useAuth,
            getSession
        }),
        [user]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
/**
 * @description
 * Get the logged in user, along with the login method, or logout method. Use destruction, see examples below
 * @example const { user } = useAuth();
 * const { login } = useAuth();
 * const { logout } = useAuth();
 * const { user, login, logout } = useAuth();
 * @returns A user, a login function, a logout function
 */
export const useAuth = () => {
    return useContext(AuthContext);
};

export const logExport = async () => {
    await fetch(baseUrl() + '/v1/Audit/LogDataExtract');
}

export const validateUser = async () => {
    return await GetFetch('/v1/SystemUser/ValidateUserName');
}

export const getActiveSession = async (sessionToken) => {
    return await GetFetch('/v1/SystemUser/GetActiveSession?sessionToken=' + sessionToken);
}

export const getActiveSessionByUserAccount = async (userAccount) => {
    return await GetFetch('/v1/SystemUser/GetActiveSessionByUserAccount');
}

export const activeSessionBegin = async () => {
    return await PutPostPatchFetch('/v1/SystemUser/ActiveSessionBegin', 'PUT', {});
}

export const activeSessionEnd = async (sessionToken,) => {
    return await PutPostPatchFetch('/v1/SystemUser/ActiveSessionEnd/' + sessionToken, 'PUT', {});
}

export const getSession = async () => {
    return await new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(session);
                }
            });
        }
        else {
            reject("No user found. Did you authenticate yet? We can't get a session if we didn't create one yet. Use authenticate method first.");
        }
    });
};

const authenticate = async (Username, Password) => {
    if (rmsAuthMethod === RMSAUTHMETHOD_COGNITO) {
        return authenticateCognito(Username, Password);
    } else {
        return authenticateOkta(Username, Password);
    }
}

const authenticateCognito = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
        const user = new CognitoUser({ Username, Pool });
        const authDetails = new AuthenticationDetails({ Username, Password });
        // use the CognitoUser *authenticateUser* method to attempt to initialize a session. It takes an AuthenticationDetails object that holds the credentials
        user.authenticateUser(authDetails, {
            onSuccess: async (data) => {
                console.log("onSucces: ", data);
                resolve(data);
            },
            onFailure: (err) => {
                console.log("onFailure: ", err);
                reject(err);
            },
            newPasswordRequired: (data) => {
                console.log("newPasswordRequired: ", data);
            },
        });
    });
};

const authenticateOkta = async (Username) => {
    // Essentially do nothing.
    return Username;
};
