import { useAuth } from '../hooks/useAuth';
import React, {
    useState,
    useEffect
} from 'react';
//import Button from '@mui/material/Button';
import Button from 'devextreme-react/button';
import LoginCard from '../util/CustomCard';
import {
    useNavigate,
} from 'react-router';
import { ROUTE_WELCOME } from '../routes/Routes';
import { rmsVersion, rmsDeployment } from "../util/reactAppEnvVariables";
import LoadIndicator from 'devextreme-react/load-indicator';
import Form, { Item, GroupItem } from 'devextreme-react/form';
import { TextBox } from 'devextreme-react';
import ClosingAlert from '../components/ClosingAlert';
import { refreshApiToken } from '../hooks/ApiToken';

const LoginCognito = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const { user, login } = useAuth();
    const [alertConfig, setAlertConfig] = useState({
        isVisible: false,
        type: 'info'
    });
    const navigate = useNavigate();
    useEffect(() => {
        // If user is already logged in, route them to home page
        if (user !== null) {
            navigate(ROUTE_WELCOME.withSlash);
        };
    }, [user])


    const showAlert = (message, type) => {
        setAlertConfig({
            isVisible: true,
            type: type,
            message: message,
        });
    };
    const hideAlert = () => {
        setAlertConfig({
            isVisible: false,
            type: alertConfig.type
        });
    };

    const onSubmit = async () => {
        setLoading(true);
        try {
            await login({
                "email": email,
                "password": password
            });
        } catch (ex) {
            showAlert("Something went wrong. Please check your credentials and your connection and try again.", "error");
        }
        setLoading(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSubmit();
        };
    };

    const handleEmailChange = (event) => {
        setEmail(event.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.value);
    };

    return (
        <div
            style={{
                marginTop: '40px'
            }}
        >
            <ClosingAlert
                visible={alertConfig.isVisible}
                message={alertConfig.message}
                severity={alertConfig.type}
                hideAlert={() => { hideAlert() }}
            />
            <LoginCard>
                <Form>
                    <GroupItem caption="Please Log In">
                        <Item dataField="email" label="Email">
                            <TextBox
                                value={email}
                                onValueChanged={handleEmailChange}
                                onKeyDown={handleKeyDown}
                                id="email_field_002"
                            />
                        </Item>
                        <Item dataField="password" label="Password">
                            <TextBox
                                mode="password"
                                value={password}
                                onValueChanged={handlePasswordChange}
                                onKeyDown={handleKeyDown}
                                id="password_field_003"
                            />
                        </Item>
                    </GroupItem>
                </Form>
                {/* {
                    loading ? 
                    <LinearProgress />
                    : 
                    <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        size="small"
                        type="submit"
                        onClick={onSubmit}
                        disabled={!email || !password}
                        style={{
                            backgroundColor: '#005288',
                            color: '#FFF',
                        }}
                    >
                        Login
                    </Button>} */}
                {
                    loading ?
                        <LoadIndicator
                            visible={true}
                        />
                        :
                        <Button
                            type="default"
                            text="Login"
                            onClick={onSubmit}
                            disabled={!email || !password}
                            stylingMode="outlined"
                            id="login_button_004"
                        />
                }
                <div style={
                    {
                        color: 'black',
                        marginTop: '20px',
                        textAlign: 'center'
                    }
                }>
                    <span style={{ fontSize: '14px', color: "#06152B"}}>
                        RMS version: {rmsVersion}-{rmsDeployment}
                    </span>
                </div>
            </LoginCard>
        </div>
    );
};

export default LoginCognito;
