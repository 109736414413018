import {
    useState,
    useCallback,
    useEffect
} from 'react';
import { ROUTE_BUILDING_SPECIFIC_COST_ESTIMATE } from '../routes/Routes';
import SpecificCostEstimate from './SpecificCostEstimate';

export default function BuildingSpecificCostEstimate() {
    const buildingFeeTypeFilter = 2;
    const pageTitle = ROUTE_BUILDING_SPECIFIC_COST_ESTIMATE.title;
 
    return <SpecificCostEstimate feeTypeFilter={buildingFeeTypeFilter} pageTitle={pageTitle} />
}