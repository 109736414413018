
import { Box } from "devextreme-react";
import { Item } from "devextreme-react/cjs/data-grid";
import { BaseTextField } from "../../components/BaseTextField/BaseTextField";
import { Content } from "../../Enums/contents";
import { useCallback, useEffect, useState } from "react";
import { BaseNumberBox } from "../../components/BaseNumberBox/BaseNumberBox";
import { BaseSelectField } from "../../components/BaseSelectField/BaseSelectField";
import { BaseDateField } from "../../components/BaseDateField/BaseDateField";
import { BaseButton } from "../../components/BaseButton/BaseButton";
import IsNullOrEmpty from "../../components/IsNullOrEmpty";
import { GetDateToday_dashes_reverse } from "../../util/GetDateToday";
const {
    Inputs: {
        labelModes: {
            _floating
        },
    },
} = Content;
const UpdateOccupancyAgreement = ({
    editingRow,
    availableBuildings,
    customerId,
    userId,
    selected_Oas,
    set_SelectedOas
}) => {
    const [occupancyAgreement, setOccupancyAgreement] = useState(null);
    const [readyToSubmit, setReadyToSubmit] = useState(false);
    const [show, setShow] = useState(false);

    const minDate = new Date(1900, 0, 1);
    useEffect(() => {
        if (editingRow !== null) {
            setOccupancyAgreement(
                {
                    OccupancyAgreementId: editingRow.OccupancyAgreementId,
                    CustomerAccountId: customerId,
                    EffectiveDate: editingRow.EffectiveDate,
                    ExpirationDate: editingRow.ExpirationDate,
                    CreatedDate: editingRow.CreatedDate,
                    CreatedBy: editingRow.CreatedBy,
                    BuildingId: editingRow.BuildingId,
                    BuildingNumber: editingRow.BuildingNumber,
                    OANumber: editingRow.OANumber,
                    TotalRentSqFt: editingRow.TotalRentSqFt,
                    Inactive: false
                }
            )
        }
        
    }, [editingRow])
    useEffect(() => {
        if (occupancyAgreement !== null && evaluateIfReadyToSubmit(occupancyAgreement)) {
            setReadyToSubmit(true)
        }
        else {
            setReadyToSubmit(false);
        }
    }, [occupancyAgreement]);
    const handleCancel = () => {
        setOccupancyAgreement({
            OccupancyAgreementId: 0,
            CustomerAccountId: customerId,
            EffectiveDate: "",
            ExpirationDate: "",
            CreatedDate: GetDateToday_dashes_reverse(),
            CreatedBy: userId,
            BuildingId: 0,
            BuildingNumber: "",
            OANumber: "",
            TotalRentSqFt: 0,
            Inactive: false
        });
        setShow(false);
        setReadyToSubmit(false);
    }
    const handleSubmit = () => {
        if (evaluateIfReadyToSubmit(occupancyAgreement)) {
            let copyOas = [...selected_Oas];
            const index = copyOas.findIndex(obj => obj.OccupancyAgreementId === occupancyAgreement.OccupancyAgreementId);
            let copy = { ...occupancyAgreement };
            copyOas[index] = copy;
            set_SelectedOas(copyOas);
            handleCancel();
        }
    }
    function isAlphanumeric(str) {
        // Regular expression to check if the string contains only letters and numbers
        const alphanumericRegex = /^[a-zA-Z0-9]+$/;

        // Test the string against the regular expression
        return alphanumericRegex.test(str);
    }
    const isValidOa = (value) => {
        if (IsNullOrEmpty(value)) {
            return false;
        }
        if (value.length > 8) {
            return false;
        }
        if (!isAlphanumeric(value)) {
            return false;
        }
        return true;
    };
    const validEffectiveExpirationDate = (value) => {
        console.log(value);
        // function parseDate(dateStr) {
        //     const [month, day, year] = dateStr.split('/').map(Number);
        //     return new Date(year, month - 1, day); // Note: month is zero-based
        // }
        // const effectiveDate = parseDate(value.EffectiveDate);
        // const expirationDate = parseDate(value.ExpirationDate);
        return (!value.EffectiveDate
            || !value.ExpirationDate
            || value.EffectiveDate < value.ExpirationDate
        )
    }
    const evaluateIfReadyToSubmit = (value) => {
        if (!isValidOa(value.OANumber)) {
            return false;
        }
        if (!validEffectiveExpirationDate(value)) {
            return false;
        }
        if (value.BuildingId < 1) {
            return false;
        }
        return true;
    }
    const handleChange = (name) => (e) => {
        const value = name === 'OANumber' ? e.value.toUpperCase() : e.value; // Get the new value from the event object
        setOccupancyAgreement((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleSelectionChanged_Building = useCallback((e) => {
        console.log(`Selection changed`, e.selectedItem);
        console.log("handleSelectionChanged_Building before", occupancyAgreement)
        const selectedItem = e.selectedItem; // Get the selected item from the event object
        let copy = JSON.parse(JSON.stringify(occupancyAgreement))
        copy.BuildingId = selectedItem.BuildingId;
        copy.BuildingNumber = selectedItem.SequenceNumber;
        console.log("handleSelectionChanged_Building copy ", copy)
        setOccupancyAgreement(copy);
    }, [occupancyAgreement]);

    return (
        <>
            {!show && <BaseButton
                label="Edit Occupancy Agreement"
                variant="outlined"
                disabled={(editingRow === null && availableBuildings.length < 1) || (editingRow !== null && availableBuildings.length < 1) || (editingRow === null && availableBuildings.length >= 1)}
                onClick={() => {
                    setShow(true);
                }}
            />}
            {(show && availableBuildings && availableBuildings.length > 0)
                &&
                <>
                    <Box
                        direction="row"
                    >
                        <Item ratio={1}>
                            <BaseTextField
                                inputAttribute={{ "aria-label": "OA Number" }}
                                value={occupancyAgreement.OANumber}
                                name="OANumber"
                                maxLength={8}
                                label={"OA Number"}
                                valueUpdatedByUser={handleChange("OANumber")}
                                isRequired={true}
                                labelMode={_floating}
                                customValidationMessage="Required. Must be a max of 8 letters and/or numbers. Example: AB123412"
                            />
                        </Item>
                        <Item ratio={1}>
                            <BaseDateField
                                inputAttr={{ "aria-label": "Effective Date" }}
                                label="Effective Date"
                                min={minDate}
                                isValid={true}
                                value={occupancyAgreement.EffectiveDate}
                                showDropDownButton={true}
                                valueUpdatedByUser={handleChange("EffectiveDate")}
                            />
                        </Item>
                        <Item ratio={1}>
                            <BaseDateField
                                inputAttr={{ "aria-label": "Expiration Date" }}
                                label="Expiration Date"
                                min={minDate}
                                isValid={true}
                                value={occupancyAgreement.ExpirationDate}
                                showDropDownButton={true}
                                valueUpdatedByUser={handleChange("ExpirationDate")}
                            />
                        </Item>
                        <Item ratio={1}>
                            <BaseNumberBox
                                inputAttribute={{ "aria-label": "Total Rent Sq Ft" }}
                                value={occupancyAgreement.TotalRentSqFt}
                                name="TotalRentSqFt"
                                label={"Total Rent Sq Ft"}
                                valueUpdatedByUser={handleChange("TotalRentSqFt")}
                                isRequired={true}
                                labelMode={_floating}
                                customValidationMessage="Required. Must be a max of 8 letters and/or numbers. Example: AB123412"
                            />
                        </Item>
                        <Item ratio={1}>
                            <BaseSelectField
                                data={availableBuildings}
                                optionsLabel={"SequenceNumber"}
                                valueExpr={"SequenceNumber"}
                                inputAttribute={{ "aria-label": "Building Select" }}
                                value={occupancyAgreement.BuildingNumber}
                                label="Building"
                                optionUpdatedByUser={handleSelectionChanged_Building}
                                isRequired={true}
                                labelMode={_floating}
                                validationRuleCallback={() => { return false }}
                                // validationRuleCallback={() => occupancyAgreement.BuildingNumber !== null}
                                isVisible={true}
                            />
                        </Item>
                    </Box>

                    <BaseButton
                        label="Update Occupancy Agreement"
                        variant="outlined"
                        disabled={!readyToSubmit}
                        onClick={handleSubmit}
                    />
                    <BaseButton
                        label="Cancel"
                        variant="outlined"
                        onClick={handleCancel}
                    />

                </>
            }

        </>
    );

}
export default UpdateOccupancyAgreement;