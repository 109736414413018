import React, {
    useState,
    useEffect,
} from 'react';
import {
    RouterProvider,
} from 'react-router-dom';
import {
    ThemeProvider,
} from '@mui/material/styles';
import './css/App.scss';
import { theme } from './Themes';
import { router } from '../routes/Routes';
import SessionContext from './SessionContext';
import { tokenRefreshInterval } from "../util/reactAppEnvVariables";
//TODO
//I recommend to be moved from app.js. Currently, when the app loads it retrieves the token to be consumed.
//At this point the user is not logged in for this reason the order of the operation should be move after the user log in
import { refreshApiToken } from "../hooks/ApiToken";
import 'devextreme/dist/css/dx.light.css';

SessionContext.setStoreType();
const App = () => {
    const [startTokenInterval, setStartTokenInterval] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setStartTokenInterval(true);
        }, tokenRefreshInterval); //Interval is set to run every three hours...
        return () => clearInterval(interval);
    }, []);

    if (startTokenInterval === true) {
        refreshApiToken();
        setStartTokenInterval(false);
    }

    return (
        <div className='background-color'>

        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
        </div>
    );
}
export default App;