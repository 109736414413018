import dayjs from "dayjs";

/**
 * 
 * @returns today's DATE OBJECT Date in format 'Wed Jul 19 2023 12:25:54 GMT-1000 (Hawaii-Aleutian Standard Time)'
 */
export const GetDateObjectToday = () => {
    let today = new Date();
    return today;
}
/**
 * 
 * @returns today's Date in format 7/20/2023 (M/dd/yyyy)
 */
export const GetDateToday = () => {
    let today = new Date();
    let today_utc = (today.getMonth() + 1) + "/" + (today.getDate() + 1) + "/" + today.getFullYear();
    return today_utc;
}
/**
 * 
 * @returns today's Date in format 7-20-2023 (M-dd-yyyy)
 */
export const GetDateToday_dashes = () => {
    let today = new Date();
    let today_utc = (today.getMonth() + 1) + "-" + (today.getDate() ) + "-" + today.getFullYear();
    return today_utc;
}
/**
 * 
 * @returns today's Date in format 2023-07-20 (yyyy-MM-dd)
 */
export const GetDateToday_dashes_reverse = () => {
    let today = new Date();
    let day = `${today.getDay()}`;
    let month = `${today.getMonth() + 1}`;
    let year = today.getFullYear();
    
    if (month.length === 1) {
        month = "0" + month;
    }
    if (day.length === 1) {
        day = "0" + day;
    }
    let value = `${year}-${month}-${day}`;
    return value;
}
export const GetDateToday_MuiFormat = () => {
    let today = new Date();
    let day = `${today.getDay()}`;
    let month = `${today.getMonth() + 1}`;
    let year = today.getFullYear();
    
    if (month.length === 1) {
        month = "0" + month;
    }
    if (day.length === 1) {
        day = "0" + day;
    }
    let value = `${month}-${day}-${year}`;
    return dayjs(value);
}