/**
 * @namespace [[<object>] API Endpoints] 
 * @property Region
 *      http method: GET
 *      returns: An Array containig all region numbers
 *      params: none
 * 
 * @property Customers
 *      https method: GET
 *      returns: And Array of all customer agency data
 * 
 * @property Customer
 * 
 * @property ContractSummaries
 *      http method: GET
 *      returns
 */

export const Endpoints = Object.freeze({
    REGIONS: 'v1/Region', // GET regions
    GET_CUSTOMERS: '/v1/Customer/GetCustomers/',
    GET_CUSTOMER: '/v1/Customer/GetCustomerById/',
    GET_CONTRACT_SUMMARIES: '/v1/Contract/Summaries/'
});