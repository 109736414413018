import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_HELP, ROUTE_WELCOME } from "../routes/Routes";
import Button from "devextreme-react/button";

const HelpPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = ROUTE_HELP.title;
    }, []);

    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "30rem",
        border: "4px solid #808080",
        borderRadius: "40px",
        margin: "15rem auto auto",
        height: "9.125rem"
      };

    return (
        <div style={containerStyle}>
            <div>
                <h3 style={{ color: "#06152B" }}>This page is not available at this time.</h3>
            </div>
            <Button
                type="default"
                icon="back" 
                text="Return Home"
                stylingMode="outlined"
                onClick={() => {
                    navigate(ROUTE_WELCOME.withSlash);
                }}
            />
        </div>
    );
};

export default HelpPage;
