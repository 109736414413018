import { DropDownBox, List, TextBox } from "devextreme-react";
import { useEffect, useState, useRef } from "react";
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import { useAuth } from '../../hooks/useAuth';
import { LoadingIndicatorCircle } from "../../components/Loading";

const BldgNumberDropDownDevEx = ({ value, setter, isRequired }) => {
    const [buildingList, setBuildingList] = useState([]);
    const [allBuildingList, setAllBuildingList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [loading, setLoading] = useState(false);
    const dropDownBoxRef = useRef(null);
    const { user } = useAuth();
    const [selectedKey, setSelectedItemKey] = useState();
 
    useEffect(() => {
        fetchBuildingNumbers();
    }, [])
    const title = isRequired == true ? "Building Number *" : "Building Number" ;
    const fetchBuildingNumbers = async () => {
        setLoading(true);
        const regionAndUser = { RegionNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], UserId: user.email };
  
        const bldList = await PutPostPatchFetch('/v1/BuildSpecReport/BuildingNumbersByRegions', 'POST', regionAndUser);
        if (bldList.Success === true) {
            let allSeqNumbers = bldList.Message.map((bld) => bld.SequenceNumber);
            setBuildingList(allSeqNumbers);
            setAllBuildingList(allSeqNumbers);
        }
        else {

            setBuildingList(null);
        }
        }

    const onSearchValueChanged = (e) => {
        let searchedVal = e.value;
        setSearchValue(searchedVal);
        if (typeof searchedVal === "string" && searchedVal ) {
            let filteredList = allBuildingList.filter((bldg) => { return bldg.toUpperCase()
                                      .includes(searchedVal.toUpperCase()) });
                if(filteredList && filteredList.length > 0)
                  setBuildingList(filteredList)
                else if (filteredList && filteredList.length === 0)
                    {
                        setter('');
                        setSelectedItemKey();
                        setBuildingList(filteredList);  
                    }
        }
        else if (typeof searchedVal === "string" && searchedVal === '') {         
            setter('');
            setSelectedItemKey();
            setBuildingList(allBuildingList);            
        }

    };
    return (
        <>
          {(loading && !buildingList) && (
                <LoadingIndicatorCircle message="Loading Building Numbers, please wait ..." />
            )}
            <span className="inline__div--wrapper">
                <DropDownBox             
                    label = {title}                
                    labelMode="floating"
                    height={45}                       
                    value={value}
                    dataSource={buildingList}
                    ref={dropDownBoxRef}
                    onValueChanged={(e) => setter(e.value)}
                    isValid = {isRequired == true ? value : true}
                >
                    <TextBox
                        value={searchValue ?? ""}
                        onValueChanged={onSearchValueChanged}
                        placeholder="Search..."
                        showClearButton = {true}
                    />
                    <List
                    selectedItemKeys={selectedKey}
                        selectionMode="single"
                        showSelectionControls={true}
                        ref={dropDownBoxRef}
                        onSelectionChanged={(e) => {
                            setter(e.addedItems[0]);
                            setSelectedItemKey(e.addedItems[0]);
                            dropDownBoxRef.current.instance.close();
                        }}
                        dataSource={buildingList}
                        pageLoadMode="scrollBottom"
                    />
                
                </DropDownBox>             
            </span>
        </>
    )
}
export default BldgNumberDropDownDevEx;