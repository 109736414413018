import { baseUrl } from "../util/Endpoint";
/**
 * This function must be used with an 'await' operator, or it will fail. For Get api calls to backend.
 * @param {string} url - ex. '/v1/SummaryCostEstimate/', baseUrl is already provided in this hook.
 * @returns result of the api call
 */
const GetFetch = async (url, responseType = 'json') => {
    let authHeader = {}
    // Get username
    let user = null;
    let unverifiedUser = null;
    try {
        const userHold = JSON.parse(localStorage.getItem("user"));
        const invalidUser = JSON.parse(localStorage.getItem("unverifiedUser"));
        user = userHold;
        unverifiedUser = invalidUser;
    }
    catch (ex) {
        console.log("Couldn't JSON parse the user local storage object", ex);
    }
    if (user !== null) {
        authHeader = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("JWT_TOKEN"),
                'From': user.email,
            }
        }
        // console.log(headersObject);
    }
    else {
        authHeader = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("JWT_TOKEN"),
                'From': unverifiedUser ? unverifiedUser.email : "",
            }
        }
    }
    const result = await fetch(baseUrl() + url, authHeader)
        .then(async (response) => {
            // console.log("response: ", response)
            if (responseType === 'json') {
                // console.log("json response: ", response)
                try {
                    const resp = await response.json();
                    return resp;
                }
                catch (ex) {
                    return console.log(ex);
                }
            }

            if (responseType === 'blob') {
                console.log("blob response: ", response)
                try {
                    const resp = await response.blob();
                    console.log("response", resp)
                    return resp;
                }
                catch (ex) {
                    return console.log(ex);
                }
            }
        })
        .catch((ex) => {
            console.log(ex);
        });
        // console.log("result: ", result)
    return result;
}
export default GetFetch;