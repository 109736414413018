import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Add } from '@mui/icons-material';
import DataGridCostEstimate from './DataGridCostEstimate';
import SearchButtonGroup from './SearchButtonGroup';
import { FormatRowData } from './DataGridProperties';
import { ROUTE_ADD_SECURITY_COST, ROUTE_FY_COST_ESTIMATE } from '../routes/Routes';
import { useAuth } from '../hooks/useAuth';
import { sortRegions } from "../util/_helpers";


const CostEstimates = () => {
    const title = ROUTE_FY_COST_ESTIMATE.title;
    const navigate = useNavigate();
    const {
        user: { Regions }
    } = useAuth();
    const { user } = useAuth();
    const [data, setData] = useState();
    const [securityCosts, setSecurityCosts] = useState();
    const [allowEditing, setAllowEditing] = useState(false);
    const [allowApprove, setAllowApprove] = useState(false);
    const [allowCreate, setAllowCreate] = useState(false);

    useEffect(() => {
        document.title = title;

        const permissions = user.role.ModulePermissions?.filter(module => { return (module.Page == "ROUTE_FY_COST_ESTIMATE") })
        setAllowEditing(permissions?.filter(module => { return (module.Update) }).length > 0)
        setAllowApprove(permissions?.filter(module => { return (module.Approve) }).length > 0)
        setAllowCreate(permissions?.filter(module => { return (module.Create) }).length > 0)
    }, []);

    useEffect(() => {
        console.log(securityCosts)
    },[securityCosts])

    return (
        <div>
            <Typography
                className="title-cost-estimates"
                component="div"
            >
                <h1  style={{ color: "#06152B" }}> {title} for Region {sortRegions(Regions)} </h1>
            </Typography>
            {allowCreate &&
                <Button
                    variant="contained"
                    sx={{ mx: 3, mt: 1, float: 'right' }}
                    elevation={0}
                    startIcon={<Add />}
                    id="add_securityc_cost_button_021"
                    onClick={() => {
                        navigate(ROUTE_ADD_SECURITY_COST.withSlash);
                    }}
                >
                    Add A Security Cost
                </Button>
            }
            <SearchButtonGroup setter={setData} secSetter={setSecurityCosts} url={'/v1/SummaryCostEstimate/'} />
            {
                (data && securityCosts) &&
                <Box sx={{
                    height: 600,
                    width: '97%',
                    margin: 'auto'
                }}>
                    <Paper
                        elevation={5}
                        sx={{ padding: '30px', marginTop: '7px' }}
                    >
                            <DataGridCostEstimate
                                setter={setData}
                                data={FormatRowData(data)}
                                allowEditing={allowEditing}
                                allowApprove={allowApprove}
                        />
                    </Paper>
                </Box>
            }
        </div>
    );
};

export default CostEstimates;