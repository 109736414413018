import { rmsApiUri, rmsDhsAuthSvcUri } from "./reactAppEnvVariables";

// Set from baseUrl using the react environment variables.
export function baseUrl() {
    return rmsApiUri;
};

// Set from baseUrl using the react environment variables.
export function dhsAuthSvcUrl() {
    return rmsDhsAuthSvcUri;
};