import { SelectBox } from "devextreme-react";
import Validator, { CustomRule, RequiredRule } from 'devextreme-react/validator';
import { Dimensions } from "../../Enums/dimensions";
import "./BaseSelectField.scss";

const { Heights: { inputHeight } } = Dimensions

/**
 * 
 * @param BaseInputSelectField 
 * This is the base of a BaseInputSelectField for RMS.
 * It uses a SelectBox as the root UI Component from DevExtreme, and is used 
 * strings of text to store options of values that can be selected, one at time.
 * 
 */

export const BaseSelectField = (
/**
 * @param { Array | Object | any | null } dataSource: Binds the UI component to a collection of data that contains String, Number, or Boolean values. Also accepts a DataSource (see DevExtreme documentation). Defaults to Null.  
 * @param { String | Function } displayExpr: Specifies the data field whose values should be, based on key-value pairs with valueExpr. Required, only if `Object` is the type for Data Source. Defaults to Undefined. Returns a String.
 * @param { String | Number | Function } height: Specifies the UI components height. Defaults to Undefined.
 * @param { Object } inputAttribute: Sets any global HTML attributes to the component. Optional. Defualts to {}.
 * @param { Boolean } isValid: Specifies or indicates if a field's value is valid (for required fields). Optional. Defaults to True.
 * @param { String } label: The visible label (text) for the button. Defaults to "".
 * @param { String } labelMode: Specifies the visible experience (mode) for how a label should persist when a user is filling the text field with a value. Accepts "static" (label is displayed above the input field), "floating" (label is used as a placeholder, but is moved to above the field once the field has focus) or "hidden" (the label is hidden). If autofill is enabled by the browser, avoid using "floating mode." Optional. Defaults to "static".
 * @param { String } message: Specifies the message that is shown if the rule is broken for a Validation Rule. Required, if Validator API is instantiated. Defaults to "Value is invalid." (literal value).
 * @param { Function} onEnterKey: A function that is executed when the Enter key has been pressed while the UI component is focused. Optional. Defaults to Null.
 * @param { Function } onInitialized: A function use in to save the instance of the UI component. The `component` property, when being accessed by this function, is used to perform data validate the DevExtreme editors against any defined validation rules within the `Validator` component of DevExtreme (read the API docs for Validator in DevExtreme to learn more). Optional. Defaults to null.
 * @param { Function } onOptionChanged: A function that is executed after the UI component property is changed. Optional. Defaults to null.
 * @param { Function } onValueChanged: A function that updates the state of the text field with a new value.
 * @param { Boolean } reevaluate: Indicates whether the rule should always be checked for the target value or only only when the target value changes. If this property is set to "false", the rule is checked only when the value in the field is changed. If a value is entered and validates the editor's value more than once, the validation callback function is executed only once. If this property is set to "true", the rule is checked every time the editor is validated. If a value is entered and validates the editor's value more tha once, the validation callback function is executed on each validation. Optional. Defaults to false.
 * @param { Function } validationCallback: A function that validates the target value in the field. Returns a boolean value - true if the value is valid; otherwise, false. Required when using the Custom Rule API. Defaults to null.
 * @param { Boolean } searchEnabled: Specifies whether a user is allowed to search for values within the field. Optional. Defaults to false.
 * @param { String } value: Sets the initial state for the the text field. Defaults to "".
 * @param { String | Function } valueExpr: Specifies which data field (from displayExpr) provides unique values to the UI component's value. Required, only if `Object` is the type for Data Source. Accepts an object as a function paramter. Defaults to 'this'. Returns a String, Number, or Boolean value.
 * @param { Boolean } readOnly: Sets component to read only when true.
 */
    {
        data,
        enableSearch,
        inputAttribute,
        label,
        labelMode,
        onEnterKeyDown,
        onInitialized,
        onOptionChanged,
        optionsLabel,
        optionUpdatedByUser,
        validationRuleCallback,
        value,
        valueExpr,
        isVisible,
        readOnly
    }
) => {
    return (
        <span className="inline__span--select-wrapper">
            <SelectBox
                dataSource={data}
                displayExpr={optionsLabel}
                height={inputHeight}
                isValid={value !== '' ? true : false}
                inputAttr={inputAttribute}
                label={label}
                labelMode={labelMode}
                onEnterKey={onEnterKeyDown}      
                onSelectionChanged={optionUpdatedByUser}
                searchEnabled={enableSearch}
                value={value}
                valueExpr={valueExpr}
                visible={isVisible ?? true}
                readOnly={readOnly}
            >
                <Validator
                    onInitialized={onInitialized}
                    onOptionChanged={onOptionChanged}
                >
                    <RequiredRule type="required" />
                    <CustomRule 
                        message="This field cannot be empty. Please provide a value."
                        reevaluate={true}
                        validationCallback={validationRuleCallback}
                    />
                </Validator>
            </SelectBox>
        </span>
    );
};