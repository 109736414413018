import DataGrid, { KeyboardNavigation, Export } from 'devextreme-react/data-grid';
import { Paper, TableContainer } from '@mui/material';
import currencyFormatter from '../components/CurrencyFormatter';
import CustomPreparedHeaderCell from '../components/CustomPreparedHeaderCell';
import ExportGrid from '../components/ExportGrid';
import { pageFormats } from "../components/GlobalDataGridConfigurations";
import { logExport, useAuth } from '../hooks/useAuth';

const TotalCostPerRegionAndGrandTotalTable = ({ pso, tcm, grandTotal, amort }) => {
    const { user } = useAuth();

  const parseStringArray = (value) => {
    const clean = value ? value : 0;
    const arrVal = clean;
    const total = arrVal;
    return total;
  };
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0, 
  });
  const dataSource = [
    {
      grandTotal: formatter.format(parseStringArray(grandTotal)),
      PSO: formatter.format(parseStringArray(pso)),
      TCM: formatter.format(parseStringArray(tcm)),
      AMORCAP: formatter.format(parseStringArray(amort))
    }
  ];

  const customHeaderStyle = () => {
    return (
      <span style={{ color: '#000000', fontWeight: '600' }}>
        Total PSO Charges
      </span>
    );
    };

    const handleExport = (e) => {
        logExport(user.email)
        ExportGrid(e, 'Grand_Total_For_FY_Cost_Estimate')
    }


  return (
    <TableContainer
      id="grand-total-table-container"
      component={Paper}
      elevation={0}
      sx={{
        maxWidth: '550px',
        marginBottom: '25px'
      }}
    >
      <p>Grand Totals</p>
            <div
                aria-live="polite"
                aria-relevant="text"
                aria-atomic="true"
                >
        <DataGrid
          dataSource={dataSource}
          onCellPrepared={CustomPreparedHeaderCell}
          onExporting={(e) => handleExport(e)}
        >
          <KeyboardNavigation enabled={true} />
          <Export 
            enabled={true} 
            formats={pageFormats}
            />
        </DataGrid>
      </div>
    </TableContainer>
  );
};

export default TotalCostPerRegionAndGrandTotalTable;
