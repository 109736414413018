import {
    useState,
    useMemo,
} from "react";
import DataGrid, {
    Column,
    Export,
    KeyboardNavigation,
    Paging,
    Pager,
    SearchPanel,
    Summary,
    GroupItem,
    TotalItem,
    GroupPanel
} from 'devextreme-react/data-grid';
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import CustomStore from 'devextreme/data/custom_store';
import ExportGrid from '../components/ExportGrid';

export default function DataGridSpecificCostEstimateSummary({ summaryCosts, fiscalYear }) {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const handleExport = (e) => {
        ExportGrid(e, `Specific_Cost_Estimate_Summary_${fiscalYear}`);
    }

    const summaryData = useMemo(() => new CustomStore({
        key: 'ID',
        load: async () => {
            if (summaryCosts !== null) {
                console.log('summary log: ', summaryCosts)
                return summaryCosts.SecurityCostDetails.map(securityDetails => {
                    const PsoCost = securityDetails.SecurityCostType === "PSO" ? securityDetails.Charges : null;
                    const TcmCost = securityDetails.SecurityCostType === "TCM" ? securityDetails.Charges : null;
                    const AmCapCost = securityDetails.SecurityCostType === "AMORCAP" ? securityDetails.Charges : null;

                    return {
                        FeeType: securityDetails.FeeType,
                        ID: securityDetails.SecurityCostId,
                        FiscalYear: securityDetails.FiscalYear,
                        Region: securityDetails.Region,
                        BuildingNumber: securityDetails.BuildingSequenceNumber,
                        PsoCost,
                        TcmCost,
                        AmCapCost,
                        TotalFyCost: PsoCost + TcmCost + AmCapCost
                    };                   
                });               
            }
            return [];
        }
    }), [summaryCosts, fiscalYear]);

    return (
        <>
            <DataGrid
                columnAutoWidth={true}
                dataSource={summaryData}
                onCellPrepared={null}
                showBorders={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                onExporting={(e) => handleExport(e)}
                style={{ color: "#06152B" }}
            >
                <KeyboardNavigation enabled={true} />
                <Paging defaultPageSize={10} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                />
                <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                />
                <GroupPanel visible={true} />
                <Column dataField="FeeType" caption="Fee Type" allowColumnDragging={false} groupIndex={2}/>
                <Column dataField="Region" caption="Region" allowColumnDragging={false} groupIndex={0} />
                <Column dataField="BuildingNumber" caption="Building Number" groupIndex={1}/>
                <Column dataField="BuildingName" caption="Building Name" />
                <Column dataField="CustomerName" caption="Customer Name" />
                <Column dataField="CustomerNumber" caption="Customer Number" allowColumnDragging={false} />
                <Column dataField="PsoCost" caption="PSO FY Cost Estimate" format="currency" allowColumnDragging={false} />
                <Column dataField="TcmCost" caption="TCM FY Cost Estimate" format="currency" allowColumnDragging={false} />
                <Column dataField="AmCapCost" caption="AM CAP FY Cost Estimate" format="currency" allowColumnDragging={false} />
                <Column dataField="TotalFyCost" caption="Total FY Cost Estimate" format="currency" allowColumnDragging={false} />

                <Summary>
                    <GroupItem
                        column="PsoCost"
                        summaryType="sum"
                        valueFormat="currency"
                        alignByColumn={true}
                        displayFormat="PSO FY Cost Estimate: {0}" />
                    <GroupItem
                        column="TcmCost"
                        summaryType="sum"
                        valueFormat="currency"
                        alignByColumn={true}
                        displayFormat="TCM FY Cost Estimate: {0}" />
                    <GroupItem
                        column="AmCapCost"
                        summaryType="sum"
                        valueFormat="currency"
                        alignByColumn={true}
                        displayFormat="AM CAP FY Cost Estimate" />
                    <GroupItem
                        column="TotalFyCost"
                        summaryType="sum"
                        valueFormat="currency"
                        alignByColumn={true}
                        displayFormat="Total FY Cost Estimate" />
                    <TotalItem
                        column="PsoCost"
                        summaryType="sum"
                        valueFormat="currency"
                        displayFormat="PSO Grand Total: {0}"
                        alignByColumn={true} />
                    <TotalItem
                        column="TcmCost"
                        summaryType="sum"
                        valueFormat="currency"
                        displayFormat="TCM Grand Total: {0}"
                        alignByColumn={true} />
                    <TotalItem
                        column="AmCapCost"
                        summaryType="sum"
                        valueFormat="currency"
                        displayFormat="AM CAP Grand Total: {0}"
                        alignByColumn={true} />
                    <TotalItem
                        column="TotalFyCost"
                        summaryType="sum"
                        valueFormat="currency"
                        displayFormat="Grand Total: {0}"
                        alignByColumn={true} />
                </Summary>
                <Export
                    enabled={true}
                    formats={pageFormats}
                />
            </DataGrid>
        </>
    );
}