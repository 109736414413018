import { useState } from 'react';
import Button from '@mui/material/Button';
import {
    ButtonGroup,
    FormGroup,
    Typography
} from '@mui/material';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';

import PutPostPatchFetch from '../../hooks/PutPostPatchFetch.js';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { LoadingIndicatorPageLength } from '../../components/Loading';

import ClosingAlert from '../../components/ClosingAlert';
import RegionMultiSelectDevEx from '../../Reports/TotalBuildingCostReportComponents/RegionMultiSelectDevEx.js';
import ApprovalMultiSelectDevEx from './ApprovalMultiSelectDevEx.js';


const dateFieldSx = {
    mt: 1,
    mr: 1,
    width: 200
};

const SearchButtonGroupAdjustmentReport = ({ setter }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedApprovals, setSelectedApprovals] = useState([])

    const [alertConfig, setAlertConfig] = useState({
        isVisible: false,
        type: 'info',
        message: '',
    })
    const showAlert = (message, type) => {
        setAlertConfig({
            isVisible: true,
            type: type,
            message: message,
        });
    };

    const hideAlert = () => {
        setAlertConfig({
            isVisible: false,
            type: alertConfig.type,
            message: "",
        });
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };
    const handleSearch = async () => {
        //if (!startDate ||  !endDate) {
        //    showAlert(invalidDate, "error");
        //}

        /*        else {*/
        hideAlert()
        setter(null);
        setLoadingData(true);
        const startDateFormat = startDate?.format('YYYY-MM-DD');
        const endDateFormat = endDate?.format('YYYY-MM-DD');

        try {

            let searchParameters = { StartDate: startDateFormat, EndDate: endDateFormat, Regions: selectedRegions, ApprovalStatus: selectedApprovals };
            console.log(searchParameters)
            const result = await PutPostPatchFetch('/v1/Adjustments/ByRegionList', 'POST', searchParameters);

            if (result.Success) {
                setter(result.Message);
                hideAlert();

            } else if (result.errors) {
                let errorMessage = "something went wrong."
                if (result.errors.startDate || result.errors.endDate)
                    errorMessage = "invalid date(s)";
                showAlert(errorMessage, "error");
                setter(null);
                console.error(result.errors);
            }

        } catch (error) {
            showAlert("something went wrong. Please try again", "error");
            setter(null);
            console.error(error);
        }
        setLoadingData(false);
        //}
    };

    return (
        <FormGroup sx={{ width: '97%', margin: 'auto' }}>
            <Typography
                variant="body1"
                component="em"
                style={{ color: "#06152B" }}
            >
                Search by Date Range, Building Number, and Occupancy Agreement
            </Typography>
            <ButtonGroup
                disableElevation
                variant="contained"
                sx={{ my: 1 }}
            >
                <RegionMultiSelectDevEx
                    value={selectedRegions}
                    valueSetter={setSelectedRegions}
                />
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <ApprovalMultiSelectDevEx
                    value={selectedApprovals}
                    valueSetter={setSelectedApprovals}
                />
            </ButtonGroup>
            <ButtonGroup>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                        sx={dateFieldSx}
                        id="start-date"
                        label="Bill Period Start Date"
                        format="MM-YYYY"
                        value={startDate}
                        onChange={(newValue) => { setStartDate(newValue) }}
                        inputProps={{
                            onKeyDown: handleKeyDown
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                    />
                    <DateField
                        id="end-date"
                        sx={dateFieldSx}
                        label="Bill Period End Date"
                        format="MM-YYYY"
                        value={endDate}
                        onChange={(newValue) => { setEndDate(newValue) }}
                        inputProps={{
                            onKeyDown: handleKeyDown
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                    />
                </LocalizationProvider>
                <span>&nbsp;</span>
                <Button
                    id="submit-button"
                    variant="contained"
                    disabled={!selectedRegions || selectedRegions.length < 1}

                    onClick={handleSearch}
                    startIcon={<QueryStatsRoundedIcon />}
                >
                    Find Changes
                </Button>
            </ButtonGroup>
            {loadingData &&
                <LoadingIndicatorPageLength message={"searching for records..."} />
            }
            <ClosingAlert
                severity={alertConfig.type}
                message={alertConfig.message}
                visible={alertConfig.isVisible}
                hideAlert={hideAlert}
            />
            {/* <CircularProgress color="secondary" /> */}
        </FormGroup>
    );
}

export default SearchButtonGroupAdjustmentReport;