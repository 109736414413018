import React,
{
    Suspense,
    useEffect,
    useState,
    useRef,
} from "react";
import {
    Await,
    useAsyncError,
    useLoaderData
} from "react-router-dom";
import { ROUTE_ROLES } from "../routes/Routes";
import {
    Box,
    Typography,
} from "@mui/material";
import DataGrid, {
    KeyboardNavigation,
    MasterDetail,
    Paging,
    Pager,
    SearchPanel,
    Export,
    Column
} from 'devextreme-react/data-grid';
import CustomPreparedHeaderCell from "../components/CustomPreparedHeaderCell";
import { LoadingIndicatorPageLength } from "../components/Loading";
import GetFetch from "../hooks/GetFetch";
import ExportGrid from '../components/ExportGrid';
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import { logExport, useAuth } from "../hooks/useAuth";
import ClosingAlert from "../components/ClosingAlert";
import { RowExpander } from "../components/RowExpander/RowExpander";

export const RoleLoader = () => {
    return GetFetch('/v1/SystemRoles/GetRoles');
}

const ErrorElement = () => {
    const [open, setOpen] = useState(true);
    const error = useAsyncError();
    console.log("RolesPage Error:", error);
    return (
        <ClosingAlert
            severity="error"
            message="Sorry, we having trouble completing your request, please try again later..."
            visible={open}
            hideAlert={() => { setOpen(false) }}
        />
    );
};

const displayRegions = (regions) => {
    let regionIds = [];
    regions.forEach(element => {
        regionIds.push(element.RegionId);
    });
    return regionIds;
}

const LoadedPage = ({ dataIn }) => {
    const { user } = useAuth();
    const [data, setData] = useState(dataIn)
    const dataFetchedRef = useRef(true);
    //const [selectedRole, setSelectedRole] = useState()
    //const [updateModal, setUpdateModal] = useState(false)
    const [loading, setLoading] = useState(false)

    //useEffect(() => {
    //    if (dataFetchedRef.current) return;
    //    dataFetchedRef.current = true;
    //    handleSettingData();
    //}, [dataFetchedRef.current]);


    //const handleSettingData = async () => {
    //    setLoading(true)
    //    const request = await GetFetch('/v1/SystemRoles/GetRoles');
    //    const { Message, Success, Errors } = request;

    //    if (Success) {
    //        setData(Message);
    //    };

    //    if (Errors.length > 0) {
    //        setErrors(Errors);
    //        console.error(Errors);
    //    };
    //    setLoading(false)
    //};

    const dataSource = data.map(
        (
            {
                RoleId,
                Name,
                Description,
                IsAdmin,
                IsInactive,
                ModulePermissions,
                SecurityTypePermissions,
                CreatedDate,
                CreatedBy,
                UpdatedBy,
                UpdatedDate,
                Regions,
            }
        ) => {

            let pagePermissions = ModulePermissions.map(({
                Create,
                Page,
                Read,
                Update
            }) => {
                return ("\n" + Page.replace("ROUTE_", "").toLowerCase() + ":" + (Read ? "Read," : "") + (Create ? "Create," : "") + (Update ? "Update" : ""))
            })

            let typePermissions = SecurityTypePermissions.map(({
                Create,
                TypeName,
                Read,
                Update
            }) => {
                return ("\n" + TypeName + ":" + (Read ? "Read," : "") + (Create ? "Create," : "") + (Update ? "Update" : ""))
            })
            return {
                RoleId: RoleId,
                Name: Name ?? 'N/A',
                Description: Description ?? 'N/A',
                IsAdmin: IsAdmin ?? 'N/A',
                IsInactive: IsInactive ?? 'N/A',
                PagePermissions: pagePermissions ?? 'N/A',
                TypePermissions: typePermissions ?? 'N/A',
                createdDate: CreatedDate ?? 'N/A',
                createdBy: CreatedBy ?? 'N/A',
                updatedBy: UpdatedBy ?? 'N/A',
                updatedDate: UpdatedDate ?? 'N/A',
                regions: Regions.length > 0 ? displayRegions(Regions) : 'N/A',
                ModulePermissions: ModulePermissions,
                SecurityTypePermissions: SecurityTypePermissions
            }
        }
    );

    //const handleEditRow = (value) => {
    //    let rowToUpdate = data.filter(row => row.RoleId === value.RoleId)
    //    setSelectedRole(rowToUpdate)
    //    setUpdateModal(true)
    //    //console.log(rowToUpdate)
    //    //dataFetchedRef.current = false;
    //    //console.log(dataFetchedRef)
    //}

    //useEffect(() => {
    //    if (!updateModal) {
    //        handleSettingData()
    //    }
    //}, [updateModal])

    const handleExport = (e) => {
        logExport(user.email)
        ExportGrid(e, "Roles")
    }

    return (
        <>
            {/*loading && 
                <LoadingIndicatorPageLength message={"Loading Roles, Please wait..."} />*/}

            <Box sx={{ mt: 1 }}>
                {/* <AddRoleModal /> */}
                <br />
                {/*<AddRoleModalV2 roles={data} update={() => { handleSettingData() }} />*/}
                {/*<UpdateRoleModalV2 roles={data} selectedRole={selectedRole} modalOpen={updateModal} setModalOpen={(value) => { setUpdateModal(value) }} />*/}
                <DataGrid
                    dataSource={dataSource}
                    keyExpr="RoleId"
                    onCellPrepared={CustomPreparedHeaderCell}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    onExporting={e => handleExport(e)}
                >
                    <KeyboardNavigation
                        enabled={true}
                    />
                    <SearchPanel
                        visible={true}
                        highlightCaseSensitive={true}
                    />
                    <Paging defaultPageSize={10} />
                    <Pager
                        allowedPageSizes={allowedPageSizes}
                        showPageSizeSelector={true}
                    />
                    <Column
                        type='detailExpand'
                        cellRender={(data) => RowExpander(data,"Name")}
                    />
                    <Column dataField="Name" caption="Title" />
                    <Column dataField="Description" caption="Description">
                    </Column>
                    <Column dataField="IsAdmin" caption="Is Admin" width={100} />
                    <Column dataField="IsInactive" caption="Is Inactive" width={100} />
                    {/*                        <Column type="buttons"><Button name="Edit" text="Edit" label="edit" onClick={(e) => { handleEditRow(e.row.data) }} /></Column>*/}
                    <Export
                        enabled={true}
                        formats={pageFormats}
                    />
                    <MasterDetail
                        enabled={true}
                        component={PermissionsDetail}
                    />
                </DataGrid>
            </Box>

        </>
    );
};

const PermissionsDetail = (source) => {
    return (
        <React.Fragment>
            <PagePermissionsDetail pagePermissions={source.data.data.ModulePermissions} />
            <br />
            <TypePermissionsDetail typePermissions={source.data.data.SecurityTypePermissions} />
        </React.Fragment>
    )
}

const PagePermissionsDetail = (source) => {
    return (
        <div>
            <DataGrid
                dataSource={source.pagePermissions}
                keyExpr="SystemModuleId"
                onCellPrepared={CustomPreparedHeaderCell}
                rowAlternationEnabled={true}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
            >
                <Column dataField="Page" />
                <Column dataField="Read" />
                <Column dataField="Create" />
                <Column dataField="Update" />
                <Column dataField="Deactivate" />
                <Column dataField="Approve" />
            </DataGrid>
        </div>
    )
}

const TypePermissionsDetail = (source) => {
    return (
        <div>
            <DataGrid
                dataSource={source.typePermissions}
                keyExpr="TypeId"
                onCellPrepared={CustomPreparedHeaderCell}
                rowAlternationEnabled={true}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
            >
                <Column dataField="TypeName" caption="Security Type" />
                <Column dataField="Read" />
                <Column dataField="Create" />
                <Column dataField="Update" />
            </DataGrid>
        </div>
    )
}

const RolesPage = () => {
    const { rolesPromise } = useLoaderData();
    const pageTitle = ROUTE_ROLES.title;
    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);
    return (
        <>
            <Typography component="div">
                <h1 style={{ color: "#06152B" }}>{pageTitle}</h1>
            </Typography>
            <Suspense fallback={
                <LoadingIndicatorPageLength message={"Loading Roles, Please wait..."} />
            }>
                <Await
                    resolve={rolesPromise}
                    errorElement={<ErrorElement />}
                    children={({ Message }) => (
                        <LoadedPage dataIn={Message} />
                    )}
                />
            </Suspense>
        </>
    );
};

export default RolesPage;