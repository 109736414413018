import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import SessionContext from '../App/SessionContext';
import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { LoadingIndicatorCircle } from '../components/Loading';
import ClosingAlert from '../components/ClosingAlert';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConcurrentSessionsModal({ open, setOpen }) {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const { logout, activeSessionBegin, activeSessionEnd } = useAuth();

    const handleContinue = async () => {
        setLoading(true);
        // End the other session and clear the SessionContext
        await activeSessionEnd(SessionContext.get("ExistingSessionToken"));
        SessionContext.remove("ExistingSessionToken");

        // Begin the new session
        let email = SessionContext.get("UserId");
        let activeSessionResult = await activeSessionBegin(email);
        if (!activeSessionResult.Success) {
            logout('error', `Failed to begin an active session. Error Message: "${activeSessionResult.Errors[0].Code}" User successfully logged out.`, false);
            return;
        }
        // Setup the Session Token in the SessionContext
        SessionContext.set("SessionToken", activeSessionResult.Message);
        setLoading(false);

        handleClose();
    }

    function handleAbandon() {
        logout('info', 'Login not completed since user decided to abandon due to another active session for user.', false);
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Box
                    textAlign='center'
                    sx={{ p: 3 }}
                >
                    <Typography variant="h6" align="center" component="div" mt={2}>
                        You can only have one active login (session) at a time within RMS
                    </Typography>
                    <Paper sx={{ p: 2, mt: 2 }}>
                        <Typography variant="p" align="left" component="div" mt={2}>
                            You currently have an active login (session) within RMS. You can only have one active session at a time.
                            You can continue loging into this session and the other open session will be ended. Alternatively, you can
                            end this attempt to login here and go back to the other open session.
                        </Typography>
                    </Paper>
                    {loading && <LoadingIndicatorCircle message={"We're working on it..."}/>}
                    {!loading &&
                        <>
                            <Button
                                color="primary"
                                variant="contained"
                                disableElevation
                                size="small"
                                onClick={handleContinue}
                                align="center"
                                sx={{ m: 1, mt: 3 }}
                            >
                                Continue Here
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                disableElevation
                                size="small"
                                onClick={handleAbandon}
                                align="center"
                                sx={{ m: 1, mt: 3 }}
                            >
                                Abandon this Login
                            </Button>
                        </>
                    }
                    <ClosingAlert
                        severity="error"
                        message={errorMessage}
                        visible={error && errorMessage != ""}
                        hideAlert={() => { setError(false) }}
                    />
                </Box>
            </Dialog>
        </div>
    );
}