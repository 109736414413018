import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { hoverColor } from './NavStyle';

const NavListItem = ({ indent, title, destination, icon, allowed=true, itemButtonId}) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    if (allowed) {
        return (
            <ListItem
                disablePadding
                onClick={() => { navigate(destination); }}
            >
                <ListItemButton
                    sx={{ pl: indent ? 4 : '' }}
                    color='primary'
                    onClick={({ key }) => { if (key === "Enter") navigate(destination)}}
                    onMouseEnter={() => { setShow(true); }}
                    onMouseLeave={() => { setShow(false); }}
                    style={{
                        color: show ? 'white' : '',
                        background: show ? hoverColor : '',
                        borderRadius: show ? '5px' : '',
                        pl: indent ? 4 : ''
                    }}
                    id={(itemButtonId)}
                >
                    <ListItemIcon
                        style={{
                            color: show ? 'white' : ''
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={title} />
                </ListItemButton>
            </ListItem>
        );
    }
    return (<></>)
};
export default NavListItem;