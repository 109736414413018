import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Export,
    Column,
    FilterRow,
    MasterDetail
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { pageFormats, allowedPageSizes } from '../../components/GlobalDataGridConfigurations';
import CustomPreparedHeaderCell from '../../components/CustomPreparedHeaderCell';
import InfoIconComponent, { InfoIconDevExtremeComponent } from '../../components/CustomInfoIcon';
import { FOUOheader, FOUOfooter } from '../../components/FOUO';
import { useAuth } from '../../hooks/useAuth';
import ExportGrid from '../../components/ExportGrid';
import dayjs from 'dayjs';

const Contracts = ( ({data})=>{
    const { user } = useAuth();

    const dataSource = new CustomStore({
        key: 'AuditTrailNumber',
        load: async () => {
            const mappedData = data.map((element) => {
               
                return {
                    AuditTrailNumber: element.AuditTrailNumber ?? "N/A",
                    ChangeType: element.ChangeType ?? "N/A",
                    Number: element.InitialValue.Number ?? "",
                    AwardDate: element.InitialValue.AwardDate ?? "",
                    StartDate: element.InitialValue.StartDate ?? "",
                    EndDate: element.InitialValue.EndDate ?? "",
                    TaskOrders: element.InitialValue.TaskOrders ?? [],
                    RepresentativeOfficer: element.InitialValue.RepresentativeOfficer ?? "",
                    DateCreated: element.ChangeType.toLowerCase() === "insert" ? element.user : "N/A",
                    CreatedBy: element.ChangeType.toLowerCase() === "insert" ? element.user : "N/A",
                    UpdatedBy: element.ChangeType.toLowerCase() === "update" ? element.user : "N/A"
                } });
                return mappedData;
            }
        });                   
        return (
            <div>
                <FOUOheader
                dateString={dayjs.utc().format('MM/DD/YYYY h:mma') + " " + "UTC"}                
                heading={"Audit - Task Order Changes"}
                userEmail={user.email}
                />
                <DataGrid
                    columnAutoWidth={true}
                    dataSource={dataSource}
                  //  onCellPrepared={CustomPreparedHeaderCell}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                  //  onExporting={e => ExportGrid(e, "Audit_Report")}
                >
                    <Column dataField="AuditTrailNumber" caption="Audit Trail Number" visible={false} />
                    <Column dataField="ChangeType" caption="Change Type" />
                    <Column dataField="Number" caption="Contract Number" />
                    <Column dataField="AwardType" caption="Award Type" />
                    <Column dataField="AwardDate" caption="Contract Award Date" />
                    <Column dataField="StartDate" caption="Contract Start Date" />
                    <Column dataField="EndDate" caption="Contract End Date" />
                    <Column dataField="CreatedBy" caption="Created By" />
                    <Column dataField="UpdatedBy" caption="Changed By" />
                    <Column dataField='RepresentativeOfficer.OfficerFirstName' caption ='Officer First Name'/>
                    <Column dataField='RepresentativeOfficer.OfficerLastName' caption ='Officer Last Name'/>                   
                    
                    <Column dataField='RepresentativeOfficer.OfficerEmail' caption ='Officer Email'/>
                    <Column dataField='RepresentativeOfficer.OfficerPhone' caption ='Officer Phone'/>
                    <MasterDetail
                            enabled={true}
                            render={DetailSection}
                        />

                </DataGrid>
                <br></br>
             
                <FOUOfooter />
            </div>
               )
              })
              const DetailSection = ({ data }) => {
                return (
                    <div>
                            <DataGrid
                                dataSource={data.TaskOrders}
                                showBorders={true}
                                columnAutoWidth={true}
                                key="Number"
                            >
                               <Column dataField="Number" caption="T.O. Number"  />
                               <Column dataField="AwardDate" caption="Award Date" />
                               <Column dataField="StartDate" caption="Start Date"  />
                               <Column dataField="EndDate" caption="End Date"  />                               
                               <Column dataField='FinancialCode.FinancialCode' caption="Acc. Code" />
                               <Column dataField='FinancialCode.CageCode' caption="Cage Code" />
                               <Column dataField='FinancialCode.AccSequenceNumber' caption="Acc. Seq. Number" />
                               <Column dataField='FinancialCode.AccObligatedAmount' caption="Acc. Oblig. Amount" />
                               <Column dataField='FinancialCode.TreasuryAccSymbol' caption="Treasury Acc. Symbol" />               
                               <Column dataField="VendorName" caption="Vendor Name" />
                               <Column dataField="LineNumber" caption="Line Number"  />
                               <Column dataField='ProductCodeId' caption="Product Code Id" />
                               <Column dataField='ProductDescriptionId' caption='Product Desc.' />
                               <Column dataField="RegionId" caption="Region Id" />
                            </DataGrid>
                    </div>
                )
            }
export default Contracts;