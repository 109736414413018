import React, { useEffect, useState } from 'react';
import CustomersTable from './CustomersTable';
import { Typography } from '@mui/material';
import { ROUTE_CUSTOMERS } from '../routes/Routes';
import { useAuth } from '../hooks/useAuth';
import './css/Customers.scss';

const Customers = () => {
  const pageTitle = "Customer Account Data";
  const [forceReload, setForceReload] = useState(false);
  const { user } = useAuth();
  const [allowEditing, setAllowEditing] = useState(false);
  const [allowCreate, setAllowCreate] = useState(false);

  useEffect(() => {
    const title = ROUTE_CUSTOMERS.title;
    document.title = title;
    const permissions = user.role.ModulePermissions?.filter(module => { return (module.Page === ROUTE_CUSTOMERS.module) })
    //Commented out for now. Edit a Customer is an upcoming story
    setAllowEditing(permissions?.filter(module => { return (module.Update) }).length > 0)
    setAllowCreate(permissions?.filter(module => { return (module.Create) }).length > 0)
  }, [])

  return (
    <div>
      <Typography component="div">
        <h1>{pageTitle}</h1>
      </Typography>
      <CustomersTable forceReload={forceReload} stopForceReload={() => { setForceReload(false) }} allowEditing={allowEditing} allowCreate={allowCreate} />
      <br />
    </div>
  );
}

export default Customers;
