import React, { useState, useEffect, useRef } from 'react';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import { ClickAwayListener } from '@mui/base';
import currencyFormatter from '../components/CurrencyFormatter';

export function CustomToolbar() {
    const [activeButton, setActiveButton] = useState(null);

    const handleClickOutside = () => {
      setActiveButton(null);
    };

    const handleClickButton = (buttonName) => {
      setActiveButton(buttonName);
    };

    return (
        <GridToolbarContainer
            aria-live="polite"
            aria-atomic="true"
            aria-relevant="text"
            >
            <ClickAwayListener onClickAway={handleClickOutside}>
                <span
                    aria-live="polite"
                    aria-atomic="true"
                    >
                    <GridToolbarColumnsButton 
                        aria-label="Menu for Columns" 
                        aria-live="polite"
                        onClick={() => handleClickButton('columns')}
                        aria-expanded={activeButton === 'columns'}
                        id="grid-toolbar-columns-button"
                        />
                    <GridToolbarFilterButton 
                        componentsProps={{ 
                          button: {
                            'aria-label': 'Menu for Filters',
                            'aria-live': 'polite',
                            'id': 'grid-toolbar-filter-button',
                            'aria-expanded': activeButton === 'filter',
                            onClick: () => handleClickButton('filter')
                          }
                        }}
                        />
                    <GridToolbarDensitySelector 
                        aria-label="Menu for Density" 
                        aria-live="polite"
                        id="grid-toolbar-density-button"
                        aria-expanded={activeButton === 'density'}
                        onClick={() => handleClickButton('density')}
                        />
                    <GridToolbarExport 
                        aria-label="Menu for Export"
                        aria-live="polite"
                        id="grid-toolbar-export-button"
                        aria-expanded={activeButton === 'export'}
                        onClick={() => handleClickButton('export')}
                        />
                </span>
            </ClickAwayListener>
        </GridToolbarContainer>
    );
};

/**
 * 
 * @param {TotalCostEstByFiscalYear} data - Takes entire object, and does data.SecurityCostDetails to access array
 * @returns 
 */
export function FormatRowData(data) {
    console.log(data)
    if(data && data.SecurityCostDetails)
    return data.SecurityCostDetails.map(
        row => {
            return {
                id: row.SecurityCostId
                , region: row.Region
                , buildingSequenceNo: row.BuildingSequenceNo
                , securityCostType: row.SecurityCostType
                , charges: currencyFormatter.format(row.Charges)
                , fiscalYear: row.FiscalYearDisplay
                , billDate: row.BillDate
                , month: row.Month
                , approvalStatus: row.ApprovalStatus
            }
        })
        else return data
}
export const tableStyleObj = {
    // m: 2,
    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
        py: 1,
    },
    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
        py: '15px',
    },
    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
        py: '22px',
    },
    height: '450px',
    marginBottom: '7px'
};
/**
 * Lists what columns to hide in the Data Grid
 */
export const columnVisibilityMode = {
    id: false
    , fiscalYear: false
};

/**
 * Data Grid Columns for Cost Estimate
 */
export const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: '40',
      hidden: true,
      renderHeader: (params) => (
        <div
          className="MuiDataGrid-columnHeaderTitle"
          aria-label="I.D. column header"
          data-mui-internal-clone-element="true"
          tabIndex={0}
        >
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: 'region',
      headerName: 'Region',
      width: '150',
      renderHeader: (params) => (
        <div
          className="MuiDataGrid-columnHeaderTitle"
          aria-label="Region column header"
          role = "columnheader"
          data-mui-internal-clone-element="true"
          tabIndex={0}
        >
          {params.colDef.headerName}
        </div>
      ),
    },
    {
      field: 'securityCostType',
      headerName: 'Cost Type',
      width: '150',
      renderHeader: (params) => (
        <div
          className="MuiDataGrid-columnHeaderTitle"
          aria-label="Cost Type column header"
          role = "columnheader"
          data-mui-internal-clone-element="true"
          tabIndex={0}
        >
          {params.colDef.headerName}
        </div>
      ),
    },
    {
      field: 'buildingSequenceNo',
      headerName: 'Building Number',
      width: '150',
      renderHeader: (params) => (
        <div
          className="MuiDataGrid-columnHeaderTitle"
          aria-label="Building Number column header"
          role = "columnheader"
          data-mui-internal-clone-element="true"
          tabIndex={0}
        >
          {params.colDef.headerName}
        </div>
      ),
    },
    {
      field: 'charges',
      headerName: 'Charges',
      width: 130,
      type: 'number',
      editable: true,
      //valueFormatter: ({ value }) => (currencyFormatter.format(value)),
      cellClassName: 'font-tabular-nums',
      renderHeader: (params) => (
        <div
          className="MuiDataGrid-columnHeaderTitle"
          aria-label="Charges column header"
          role = "columnheader"
          data-mui-internal-clone-element="true"
          tabIndex={0}
        >
          {params.colDef.headerName}
        </div>
      ),
    },
    {
      field: 'fiscalYear',
      headerName: 'Fiscal Year',
      width: '150',
      type: 'string',
      renderHeader: (params) => (
        <div
          className="MuiDataGrid-columnHeaderTitle"
          aria-label="Fiscal Year column header"
          role = "columnheader"
          data-mui-internal-clone-element="true"
          tabIndex={0}
        >
          {params.colDef.headerName}
        </div>
      ),
    },
    {
      field: 'billDate',
      headerName: 'Bill Date',
      width: '150',
      type: 'number',
      renderHeader: (params) => (
        <div
          className="MuiDataGrid-columnHeaderTitle"
          aria-label="Bill Date column header"
          role = "columnheader"
          data-mui-internal-clone-element="true"
          tabIndex={0}
        >
          {params.colDef.headerName}
        </div>
      ),
    },
    {
      field: 'month',
      headerName: 'Month',
      width: '150',
      type: 'string',
      renderHeader: (params) => (
        <div
          className="MuiDataGrid-columnHeaderTitle"
          aria-label="Month column header"
          role = "columnheader"
          data-mui-internal-clone-element="true"
          tabIndex={0}
        >
          {params.colDef.headerName}
        </div>
      ),
    },
    {
      field: 'approvalStatus',
      headerName: 'Approval Status',
      width: '150',
      type: 'string',
      renderHeader: (params) => (
        <div
          className="MuiDataGrid-columnHeaderTitle"
          aria-label="Approval Status column header"
          role = "columnheader"
          data-mui-internal-clone-element="true"
          tabIndex={0}
        >
          {params.colDef.headerName}
        </div>
      ),
    },
  ];
  