import { DropDownBox, List, TextBox } from "devextreme-react";
import { useEffect, useState, useRef } from "react";
import Validator, { CustomRule, RequiredRule } from 'devextreme-react/validator';

const ReasonSingleSelectDevEx = ({ value, setter }) => {
    const [selectedReason, setSelectedreason] = useState()
    const [customReason, setCustomReason] = useState("")
    const dropDownBoxRef = useRef(null);
    const [selectedKey, setSelectedItemKey] = useState();

    const reasons = [
        "Change in PSO requirements",
        "Increase/Decrease from original Cost Estimate",
        "PSO Wage Increase/Decrease",
        "Late activation/deactivation of an OA",
        "Change in TCM estimates/IDS Repairs",
        "Early termination or square footage modification of GSA lease",
        "Building added or removed from GSA inventory",
        "Other"
    ]

    useEffect(() => {
        if (reasons.includes(value)) {
            setSelectedreason(value)
            setSelectedItemKey(value)
        }
        else if (value) {
            setSelectedreason("Other");
            setSelectedItemKey("Other");
            setCustomReason(value)
        }
    },[value])

    useEffect(() => {
        if (selectedReason && selectedReason === "Other") {
            setter(customReason)
        }
        else { setter(selectedReason) }
    }, [selectedReason, customReason])


    const title = "Reason *";

    const inputProps = {
        "aria-required": "true"
    };

    return (
        <>
            <div className="inline__div--wrapper">
                <DropDownBox
                    label={title}
                    labelMode="floating"
                    height={45}
                    width={400 }
                    value={selectedReason}
                    dataSource={reasons}
                    ref={dropDownBoxRef}
                    onValueChanged={(e) => {
                        //console.log('dropdown func', e);
                        setSelectedreason(e.value);
                    }}
                    isValid={value ? true : false}
                >
                    <List
                        selectedItemKeys={[selectedKey]}
                        selectionMode="single"
                        showSelectionControls={true}
                        ref={dropDownBoxRef}
                        onSelectionChanged={(e) => {
                            setSelectedreason(e.addedItems[0]);
                            setSelectedItemKey(e.addedItems[0]);
                            dropDownBoxRef.current.instance.close();
                        }}
                        dataSource={reasons}
                        pageLoadMode="scrollBottom"
                    />
                </DropDownBox>
                <span>&nbsp;</span>
                {(selectedReason === "Other") &&

                    <TextBox
                        label={title}
                        labelMode='floating'
                        value={customReason}
                        height={56}
                        maxLength={2000}
                        width={500}
                        valueChangeEvent='input'
                        ignoreEmptyValue={false}
                        inputProps={inputProps}
                        id="reason_textBox_001"
                        onValueChange={(value) => {
                            setCustomReason(value)
                        }}
                    >
                        <Validator
                            onInitialized={(e) => e.component.validate()}
                            onOptionChanged={(e) => e.component.validate()}
                        >
                            <RequiredRule type='required' />
                        </Validator>
                    </TextBox>
                }
            </div>
        </>
    )
}
export default ReasonSingleSelectDevEx;