import { BaseTextField } from '../../../components/BaseTextField/BaseTextField';
import './ItemFormComponent.scss';

export const ItemFormComponent = ({ data }) => {
    const { 
        AwardDate,
        EndDate,
        StartDate,
        TaskOrderCount,
        TaskOrderId
    } = data;

    return (
        <>
            <BaseTextField 
                label={"Task Order ID"}
                readOnly={true}
                value={TaskOrderId ?? "N/A"}
            />   
            <BaseTextField 
                label={"Award Date"}
                readOnly={true}
                value={AwardDate ?? "N/A"}
            />   
            <BaseTextField 
                label={"Start Date"}
                readOnly={true}
                value={StartDate ?? "N/A"}
            />  
            <BaseTextField 
                label={"End Date"}
                readOnly={true}
                value={EndDate ?? "N/A"}
            />
            <BaseTextField 
                label={"Total Task Orders"}
                readOnly={true}
                value={TaskOrderCount ?? "N/A"}
            />
        </>
    );
};