import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_REGION_AND_BUILDING_DATA, ROUTE_EDIT_BUILDING } from '../routes/Routes';
import {
    Box,
    LinearProgress,
} from '@mui/material';
import GetFetch from '../hooks/GetFetch';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Selection,
    Column,
    Export
} from 'devextreme-react/data-grid';
import CustomPreparedHeaderCell from '../components/CustomPreparedHeaderCell';
import BuildingsAdd from './BuildingsAdd';
import { logExport, useAuth } from '../hooks/useAuth';
import ExportGrid from '../components/ExportGrid';
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import ClosingAlert from '../components/ClosingAlert';
import { BaseButton } from '../components/BaseButton/BaseButton';
import { Content } from '../Enums/contents';
import './css/Buildings.scss';


const {
    Buttons: {
        editBuildingButtonLabel, 
        newBuildingButtonLabel,
        MUI: { 
            Variant: { 
                outlined 
            }
        }
    } 
} = Content;

const BuildingsTable = (props) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [addBuilding, setAddBuilding] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState();
    const [updateBld, setUpdateBld] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [editRowButton, setEditRowButton] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState();
    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const [customEditButtonLabel, setCustomEditButtonLabel] = useState(undefined);
    const [focusedRowIndex, setFocusedRowIndex] = useState(-1);
    const [loading, setLoading] = useState(false);
    const title = ROUTE_REGION_AND_BUILDING_DATA.title;

    useEffect(() => {
        document.title = title;
    }, []);

    useEffect(() => {
        fetchData();
    }, [props]);

    const buildingObject = {
        "SequenceNumber": "",
        "Name": "",
        "Status": "",
        "Facility": "",
        "RegionNumber": 0,
        "Location": {
            "Id": 0,
            "Address1": "",
            "Address2": "",
            "CityName": "",
            "State": "",
            "ZipCode": 0
        },
        "RentUsageFactor": 0,
        "AdjustedRentSqFt": 0,
        "AdjustedUsageSqFt": 0,
        "AnsiRentSqFt": 0,
        "AnsiUsableSqFt": 0,
        "JointUseSqFt": 0,
        "CommunityJointUseSqFt": 0,
        "LeaseJointUseSqFt": 0,
        "CongressionalRep": ""
    };

    const fetchBuildings = () => {
        return GetFetch('/v1/Building')
    };

    const fetchData = async () => {
        setLoading(true);
        /**
         * @todo
         * REPLACE SESSION OBJECTS WITH USER DATA FROM CALLS
         */
        const { Errors, Message, Success } = await fetchBuildings();
        setLoading(false);

        if (Success === true) {
            setData(Message);
            setError(false);
            setErrorMessage(null);
        }
        else {
            setError(true);
            setErrorMessage(Errors[0].Message);
            console.error(Errors);
        }
    };

    const dataSource = new CustomStore({
        key: 'BuildingNumber',
        update: (key, value) => { console.log(key); console.log(value) },
        load: async () => {
            const data = await fetchBuildings();
            const { Message } = data;
            const checkedData = Message.map(
                (
                    {
                        SequenceNumber,
                        BuildingId,
                        Name,
                        Facility,
                        Status,
                        RegionNumber,
                        Location: {
                            Id,
                            Address1,
                            Address2,
                            CityName,
                            State,
                            ZipCode
                        },
                        RentUsageFactor,
                        AdjustedRentSqFt,
                        AdjustedUsageSqFt,
                        AnsiRentSqFt,
                        AnsiUsableSqFt,
                        JointUseSqFt,
                        CommunityJointUseSqFt,
                        LeaseJointUseSqFt,
                        CongressionalRep,
                        Version
                    }
                ) => {
                    return {
                        BuildingNumber: SequenceNumber,
                        BuildingId: BuildingId,
                        name: Name,
                        status: Status,
                        facility: Facility,
                        region: RegionNumber,
                        locationId: Id,
                        address: `${Address1},${Address2 !== '' ? ' ' + Address2 + ',' : ''} ${CityName}, ${State} ${ZipCode}`,
                        rentUsageFactor: RentUsageFactor,
                        adjustedRent: AdjustedRentSqFt,
                        adjustedUsage: AdjustedUsageSqFt,
                        ansiRent: AnsiRentSqFt,
                        ansiUsable: AnsiUsableSqFt,
                        jointUse: JointUseSqFt,
                        communityJointUse: CommunityJointUseSqFt,
                        leaseJointUse: LeaseJointUseSqFt,
                        congressionalRep: CongressionalRep,
                        version: Version
                    };
                }
            );

            return checkedData;
        }
    });

    useEffect(() => {
        if (selectedRowData) setEditRowButton(true);
    }, [selectedRowData]);

    const handleAddBuilding = () => {
        setAddBuilding(true);
    };

    const handleEditButtonClick = () => {
        navigate(ROUTE_EDIT_BUILDING.withSlash, {
            state: { selectedRowData }
        });
    };

    const handleEnterKeyPress = (e) => {
        const key = e.event?.originalEvent?.key;

        if (key === "Enter") {
            setCustomEditButtonLabel(selectedRowKey?.BuildingNumber);
            setEditRowButton(true);
            setSelectedRowData(selectedRowKey);
        };
    };

    const handleFocusedRowChange = (e) => {
        if (e.row.isRecordSelected === false) setFocusedRowIndex(e.rowIndex);

        const { data } = e.row;
        setSelectedRowKey(data);
    };
   const pdfFont = ()=>
   {    return 6;   }
   const wrapPdfCell = ()=>{
    return true;   }

    const handleExport = (e) => {
        logExport(user.email);
        ExportGrid(e, "Buildings_Data", wrapPdfCell(), pdfFont())
    }

    /**
     * @todo Share this global function with UpdateUser.js
     */
    const handleRowClick = () => {
        setCustomEditButtonLabel(selectedRowKey?.BuildingNumber);
        setEditRowButton(true);
        setSelectedRowData(selectedRowKey);
    };

    /**
     * @todo Share this variable with UsersPageComponents.js
     */
    const isRecordSelected = !editRowButton ? true : false;
    const editButtonLabel = customEditButtonLabel ? `Edit Building Number: ${customEditButtonLabel}` : editBuildingButtonLabel;

    return (
        <div>
            <ClosingAlert
                severity="error"
                message={errorMessage}
                visible={error}
                hideAlert={() => { setError(false) }}
            />
            {
                loading &&
                <LinearProgress />
            }
            {
                !loading && data && !(addBuilding || updateBld) &&
                <div>
                    <Box sx={{ height: 45, width: '97%', margin: 'auto auto 0 auto' }}>
                        <BaseButton 
                            label={newBuildingButtonLabel}
                            onClick={handleAddBuilding}
                            variant={outlined}
                        />
                        <BaseButton
                            ariaDisabled={isRecordSelected}
                            disabled={isRecordSelected}
                            label={editButtonLabel}
                            onClick={handleEditButtonClick}
                            variant={outlined}
                        />
                    </Box>
                    <Box sx={{ height: 700, width: '97%', margin: 'auto' }}>
                        <span
                            aria-live="polite"
                            aria-atomic="true"
                        >
                            <DataGrid
                                columnAutoWidth={true}
                                dataSource={dataSource}
                                focusedRowEnabled={true}
                                keyboardNavigation={{
                                    enabled: true,
                                    enterKeyAction: 'none',
                                    enterKeyDirection: 'none'
                                }}
                                onCellPrepared={CustomPreparedHeaderCell}
                                onExporting={e => handleExport(e)}
                                onFocusedRowChanged={handleFocusedRowChange}
                                onKeyDown={handleEnterKeyPress}
                                onRowClick={handleRowClick}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                            >
                                <KeyboardNavigation enabled={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} />
                                <Selection mode="single" />
                                <Paging defaultPageSize={10} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={allowedPageSizes}
                                />
                                <Column dataField="BuildingId" visible={false} />
                                <Column dataField="locationId" visible={false} />
                                <Column dataField="version" visible={false} />
                                <Column dataField="region" caption="Region" />
                                <Column dataField="BuildingNumber" caption="Building Number" />
                                <Column dataField="status" caption="Status" />
                                <Column dataField="name" caption="Name" />
                                <Column dataField="facility" caption="Facility" />
                                <Column dataField="address" caption="Address" />
                                <Column dataField="rentUsageFactor" caption="Rent Usage Factor" />
                                <Column dataField="adjustedRent" caption="Adjusted Rent" />
                                <Column dataField="adjustedUsage" caption="Adjusted Usage" />
                                <Column dataField="ansiRent" caption="ANSI Rent" format="fixedPoint" />
                                <Column dataField="ansiUsable" caption="ANSI Usable" />
                                <Column dataField="jointUse" caption="Joint Use" />
                                <Column dataField="communityJointUse" caption="Community Joint Use" />
                                <Column dataField="leaseJointUse" caption="Lease Joint Use" />
                                <Column dataField="congressionalRep" caption="Congressional Rep" />
                                <Export
                                    enabled={true}
                                    formats={pageFormats}
                                />
                            </DataGrid>

                        </span>
                    </Box>
                </div>
            }
            {
                addBuilding &&
                <BuildingsAdd
                    build={buildingObject}
                    Close={() => {
                        setAddBuilding(false);
                        fetchData();
                    }}
                    Update={() => { fetchData(); }}
                />
            }
        </div >
    );
};


export default BuildingsTable;