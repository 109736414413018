import { DropDownBox, List } from "devextreme-react";
import Validator, { RequiredRule } from 'devextreme-react/validator';
import React, { useEffect, useState, useRef } from "react";
import GetFetch from "../../hooks/GetFetch";
import { LoadingIndicatorCircle } from "../../components/Loading";
import ClosingAlert from "../../components/ClosingAlert";

const CostTypeDropdown = ({ selection, setSelection}) => {
    const [data, setData] = useState(null);
    const [selected, setSelected] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [resultCostType, setResultCostType] = useState("");
    const dropDownBoxRef = useRef(null);
    useEffect(() => {
        fetchData();
    }, [])
    useEffect(() => {
        selectedUpdated();
    }, [selected]);
 const label0 = {
     marginRight: 20 ,
     marginTop: 20 ,
     marginLeft: 5
 }
    const setter = (e) => {
        let prevValue = selected ;
        let added = e.addedItems;
        let removed = e.removedItems ;
      
        let addedVal = data.find(({ SecurityType }) => SecurityType == added)?.TypeId ;
        let removedVal = data.find(({ SecurityType }) => SecurityType == removed)?.TypeId ;

        if(addedVal )
        {
                 prevValue.push(addedVal);
                setSelected(prevValue);
                setSelection(selected);
                selectedUpdated();
        }
        else if (prevValue.length > 0 && removedVal)
       {
                let index = prevValue.indexOf(removedVal);
                if(index > -1)
                {
                prevValue.splice(index, 1);
                setSelected(prevValue);
                setSelection(selected);
                selectedUpdated();
                }
       }
         }

    const fetchData = async () => {
        setLoading(true);

        const data = await GetFetch('/v1/CostType');
        const { Success, Message, Errors } = data;

        if (Success) {
            let results = Message.filter(value => value.SecurityType !== "AMORTC");
            setData(results);
            setError(false);
        }
        else {
            setError(true);
            setErrorMessage(Errors[0].Message);
        }

        setLoading(false);
    }
 const selectedUpdated = ()=>
 {
    let res =  [];
    selected.forEach(item => {
      var i =  data.find(({ TypeId }) => TypeId == item)?.SecurityType ;
      if(i)
      res.push(i);
    })
    var re = "Selected Cost Type(s): " + res.join(", ") ;
    return setResultCostType(re);
 }
    return (
        <React.Fragment>
            {(data && !error && !loading)
                &&
                <>       
                    
                    <DropDownBox
                        label="Cost Type"
                        labelMode="floating"
                        height={56}
                        width={188}
                        value={selected}
                        dataSource={data.map(({ SecurityType }) => SecurityType)}
                        ref={dropDownBoxRef}
                        selectionMode = "multiple"
                      
                    >
                      
                        <List
                            selectionMode="multiple"
                            showSelectionControls={true}
                            ref={dropDownBoxRef}
                            onSelectionChanged={(e) => {
                                setter(e)                                
                            
                            }}
                            dataSource={data.map(({ SecurityType }) => SecurityType)}
                            pageLoadMode="scrollBottom"
                        />
                      
                    </DropDownBox>
                  { selected.length > 0  && <div style={label0}>{resultCostType}</div> } 
                </>
                  
            }
            {loading && <LoadingIndicatorCircle message="loading cost types, please wait ..." />}
            <ClosingAlert
                severity="error"
                message={errorMessage}
                visible={error}
                hideAlert={() => { setError(false) }}
            />
        </React.Fragment>
    )
}
export default CostTypeDropdown;