import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#005288'
        },
        secondary: {
            main: '#A8AAAE'
        },
        themeGrey: {
            main: '#4B465C'
        }
    }
});