import {
  Suspense,
  useEffect,
} from "react";
import {
  Await,
  useAsyncError,
  useLoaderData,
} from "react-router-dom";
import { ROUTE_ACTIVE_SESSIONS } from "../routes/Routes";
import {
  Alert,
  Box,
  Typography,
} from "@mui/material";
import { LoadingIndicatorPageLength } from "../components/Loading";
import DataGrid, {
  KeyboardNavigation,
  Paging,
  Pager,
  SearchPanel,
  Export,
  FilterRow
} from 'devextreme-react/data-grid';
import CustomPreparedHeaderCell from "../components/CustomPreparedHeaderCell";
import GetFetch from "../hooks/GetFetch";
import ExportGrid from '../components/ExportGrid';
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import { logExport } from "../hooks/useAuth";

const ErrorElement = () => {
  const error = useAsyncError();
  console.log("ActiveSessionsPage Error:", error);
  return (
    <Alert severity="error">
      Sorry, we having trouble completing your request, please try again later...
    </Alert>
  )
}

const handleExport = (e) => {
    const user = JSON.parse(localStorage.getItem('user'));
    logExport(user.email)
    ExportGrid(e, "Active_Sessions")
}

const LoadedPage = ({ data }) => (
  <Box sx={{ mt: 1 }}>
    <DataGrid
      dataSource={data}
      onCellPrepared={CustomPreparedHeaderCell}
      rowAlternationEnabled={true}
      showBorders={true}
      showColumnLines={true}
      showRowLines={true}
      onExporting={e => handleExport(e)}
    >
      {/* <FilterRow visible={true} /> */}
      <KeyboardNavigation enabled={true} />
      <SearchPanel visible={true} highlightCaseSensitive={true} />
      <Paging defaultPageSize={10} />
      <Pager allowedPageSizes={allowedPageSizes} showPageSizeSelector={true} />
      <Export
        enabled={true}
        formats={pageFormats}
      />
    </DataGrid>
  </Box>
);


export const ActiveSessionsLoader = () => {
  const _user = JSON.parse(localStorage.getItem('user'));
  return GetFetch('/v1/SystemUser/GetActiveSessions');
}

const ActiveSessionsPage = () => {
  const { activeSessionsPromise } = useLoaderData();
  const pageTitle = ROUTE_ACTIVE_SESSIONS.title;

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <>
      <Typography component="div">
        <h1 style={{ color: "#06152B" }}>{pageTitle}</h1>
      </Typography>
      <Suspense fallback={<LoadingIndicatorPageLength message={"Loading Active Sessions, Please wait..."} />}>
        <Await
          resolve={activeSessionsPromise}
          errorElement={<ErrorElement />}
          children={(result) => (
            <LoadedPage data={result.Message} />
          )}
        />
      </Suspense>
    </>
  );
};

export default ActiveSessionsPage;
