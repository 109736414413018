import { Box, Button, DataGrid } from "devextreme-react";
import { Item } from "devextreme-react/box";
import { ROUTE_CONTRACTS_PAGE, ROUTE_CREATE_A_NEW_CONTRACT_RECORD } from "../routes/Routes";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import GetFetch from "../hooks/GetFetch";
import { useState } from "react";
import { LoadingIndicatorCircle } from "../components/Loading";
import { Column, KeyboardNavigation, MasterDetail, Pager, Paging, SearchPanel } from "devextreme-react/data-grid";
import CustomPreparedHeaderCell from "../components/CustomPreparedHeaderCell";
import { useAuth } from "../hooks/useAuth";
import { RowExpander } from "../components/RowExpander/RowExpander";

const ContractPage = () => {
    const title = ROUTE_CONTRACTS_PAGE.title;
    const {
        user: { Regions } 
    } = useAuth();
    const { user } = useAuth();
    const [allowEditing, setAllowEditing] = useState(false);
    const [allowCreate, setAllowCreate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [contracts, setContracts] = useState();
    const [repOfficials, setRepOfficials]  = useState([]);
    const navigate = useNavigate();
    const endpoint = '/v1/Contract/GetContracts';
    const URL_contractOfficialRep = "/v1/Contract/GetContractPersonnel";
    const unauthorizedCreateRoles = ['missionsupportapprover','basicuser',
                                    'regionaltcmuser', 'hqrevenueuser',
                                    'accountadministrator', 'fycostuser' ]
    useEffect(() => {
        document.title = title;
        const permissions = user.role.ModulePermissions?.filter(module => { return (module.Page == "ROUTE_CONTRACTS_PAGE") })
        setAllowEditing(permissions?.filter(module => { return ( module.Update) }).length > 0)
        setAllowCreate(permissions?.filter(module => { return ( module.Create) }).length > 0 && !unauthorizedCreateRoles.includes(user.role.Name.toLowerCase()))
        fetchContracts();
        fetchRepOfficials();
    }, []);

   const fetchRepOfficials = async () => {
    const data = await GetFetch(URL_contractOfficialRep);
    const { Success, Message, Errors } = data;
    setError(Success === false);
    if (Success) {
        setRepOfficials(Message);
        // console.log(Message);
    }
   }
    const fetchContracts = async () => {
        setLoading(true);
        const data = await GetFetch(endpoint);
        const { Success, Message, Errors } = data;
        setError(Success === false);
        if (Success) {
            setContracts(Message);
            // console.log(Message);
        }
        setLoading(false);
    };
  const contractRep = (cellInfo)=>{
    
             if(repOfficials && repOfficials.length > 0 )
             {
                let element = repOfficials.filter(x=>x.Id ===  cellInfo.value);
                if(element.length === 1)
                { return element[0].OfficerFirstName + ' ' + element[0].OfficerLastName ;}
             }
                
             return cellInfo.value ;
  }
    return (
        <>
            <h1>{ROUTE_CONTRACTS_PAGE.title}</h1>

            {allowCreate &&
                <Button
                stylingMode="contained"
                text="Add a New Contract"
                width={220}
                onClick={() => navigate(ROUTE_CREATE_A_NEW_CONTRACT_RECORD.withSlash)}
                id="add_new_contract_button_029"
            />
            }
            {error && <p>Something went wrong.</p>}
            {loading && !contracts && <LoadingIndicatorCircle message="Loading contracts" />}
            {contracts &&
                <>
                    <DataGrid
                        columnAutoWidth={true}
                        dataSource={contracts}
                        onCellPrepared={CustomPreparedHeaderCell}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        key="ContractId"
                    >
                        <KeyboardNavigation enabled={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                        />
                        <Column
                            type='detailExpand'
                            cellRender={(data) => RowExpander(data,"Number")}
                        />
                        <Column dataField="Number" caption="Contract Number" width={250}/>
                        <Column dataField="StartDate" caption="Period of Performance Start Date" width={250}/>
                        <Column dataField="EndDate" caption="Period of Performance End Date" width={250}/>
                        <Column dataField="AwardDate" caption="Award Date" width={120} />
                        <Column dataField="TaskOrders.length" caption="Task Orders" width={110}/>
                        <Column dataField="ContractRepId" caption="Contracting Official" width={120} 
                                customizeText={contractRep}/>
                        <MasterDetail
                            enabled={true}
                            render={DetailSection}
                        />
                    </DataGrid>
                </>
            }
        </>
    )
}

const DetailSection = ({ data }) => {
    return (
        <div>
                <DataGrid
                    dataSource={data.TaskOrders}
                    showBorders={true}
                    columnAutoWidth={true}
                    key="TaskOrderId"
                >
                    <Column dataField="Number" caption="Task Order Number" />
                    <Column dataField="StartDate" caption="Start Date" />
                    <Column dataField="EndDate" caption="End Date" />
                    <Column dataField="AwardDate" caption="Award Date" />
                    <Column dataField="VendorName" caption="Vendor Name" />
                    <Column dataField="LineNumber" caption="Line Number" />
                    <Column dataField="ProductCodeId" caption="Product Code ID" />
                    <Column dataField="ProductDescriptionId" caption="Product Description ID" />
                    <Column dataField="RegionId" caption="Region" />
                </DataGrid>
        </div>
    )
}
export default ContractPage;