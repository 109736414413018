import React, { useState, useEffect } from 'react';
import {
    Box
} from "devextreme-react";
import { Item as BoxItem } from "devextreme-react/box";
import DataGrid, {
    KeyboardNavigation,
    SearchPanel,
    Column
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch.js';
import { BaseButton } from "../components/BaseButton/BaseButton";
import { Content } from '../Enums/contents';
import BuildingMultiSelectDevEx from '../Reports/TotalBuildingCostReportComponents/BuildingMultiselectDevEx';
import RegionMultiSelectDevEx from '../Reports/TotalBuildingCostReportComponents/RegionMultiSelectDevEx';
import OccupancyAgreementAdd from './OccupancyAgreementAdd.js';
import "./CustomersUpdate.scss"
import "./../styles/utilities.scss";
import { LoadingIndicatorCircle } from '../components/Loading.js';


const {
    Buttons: {
        selectBuildingsLabel,
        MUI: {
            Variant: {
                outlined
            }
        }
    }
} = Content;

const GetRegionsFromListOfBuildings = (listOfO) => {
    if (listOfO !== undefined) {
        if (listOfO !== null) {
            if (listOfO.length > 0) {
                let regions = [];
                listOfO.forEach(o => {
                    regions.push(o.RegionNumber);
                });
                let regionsWithoutDuplicates = [... new Set(regions)];
                return regionsWithoutDuplicates;
            }
        }
    }
    return null;
}
const CustomerBuildingSelect = ({ value, valueSetter, buildingIds, setOaList, user }) => {
    // console.log("value", value);
    const [_selectedRegions, set_SelectedRegions] = useState(GetRegionsFromListOfBuildings(value) ?? [])
    const [_selectedBuildingSequenceNumbers, set_SelectedBuildingSequenceNumbers] = useState(buildingIds ?? [])
    const [_buildingsInSelectedBuildingsTable, set_BuildingsInSelectedBuildingsTable] = useState(value ?? [])
    const [loading, setLoading] = useState(false);

    /**
     * @todo: Export this function for global sharing
    */


    useEffect(() => {
        valueSetter(_buildingsInSelectedBuildingsTable)
    }, [_buildingsInSelectedBuildingsTable])



    const handleBuildingSelect = async () => {
        setLoading(true);
        /** if we already have buildings selected, we need to make sure we don't add duplicates. */
        let submissionSeqList = [];
        if (_buildingsInSelectedBuildingsTable.length > 0) {
            let sequenceNumbersFromBuildingTable = _buildingsInSelectedBuildingsTable.map(building => building.SequenceNumber)
            submissionSeqList = [...sequenceNumbersFromBuildingTable]
            _selectedBuildingSequenceNumbers.forEach(selectedNumber => {
                // console.log("selectedNumber", selectedNumber)
                // console.log("sequenceNumbersFromBuildingTable", sequenceNumbersFromBuildingTable)

                if (sequenceNumbersFromBuildingTable.find(e => e === selectedNumber) === undefined) {
                    /**
                     * _selectedBuildingSequenceNumbers is an array, 
                     * use find function to see if value doesn't exist, 
                     * means we can add to list if doesn't exist.
                     */
                    submissionSeqList.push(selectedNumber);
                }
            });
        }
        else {
            submissionSeqList = [..._selectedBuildingSequenceNumbers]
        }
        console.log("selected", submissionSeqList)
        if (submissionSeqList.length > 0) {
            const request = await PutPostPatchFetch('/v1/Building/ListOfBuildingsByNumber', 'PUT', submissionSeqList);
            const { Success, Errors, Message } = request;

            if (Success) {
                set_BuildingsInSelectedBuildingsTable(Message)
            }
            else {
                console.log(Errors)
            }
        }
        setLoading(false);
    }

    const dataSource = new CustomStore({
        key: 'BuildingId',

        update: (key, value) => { console.log(key); console.log(value) },
        load: async () => {
            const data = value;
            let checkedData = []
            if (data) {
                checkedData = data.map(
                    (
                        {
                            BuildingId,
                            RegionNumber,
                            SequenceNumber,
                            Name,
                            Status,
                            Location: {
                                Id,
                                Address1,
                                Address2,
                                CityName,
                                State,
                                ZipCode
                            }
                        }
                    ) => {
                        return {
                            BuildingId: BuildingId,
                            RegionNumber: RegionNumber,
                            SequenceNumber: SequenceNumber,
                            Name: Name,
                            Status: Status,
                            AddressId: Id,
                            address: `${Address1}, ${Address2 !== null && Address2 !== '' ? Address2 + ',' : ''} ${CityName}, ${State} ${ZipCode}`
                        };
                    }
                );
            }
            return checkedData;
        }
    });

    return (
        <>
            <div style={{
                marginTop: "5px"
                , marginBottom: "5px"
            }}
            >
                <Box
                    direction="row"
                    width="25%"
                    align="left"
                    crossAlign="left">
                    <BoxItem className="inline__div--wrapper" ratio={2}>
                        <RegionMultiSelectDevEx
                            value={_selectedRegions}
                            valueSetter={set_SelectedRegions}
                        />
                    </BoxItem>
                    <BoxItem className="inline__div--wrapper" ratio={1}>
                        <BuildingMultiSelectDevEx
                            value={_selectedBuildingSequenceNumbers}
                            selectedBuildings={value}
                            valueSetter={set_SelectedBuildingSequenceNumbers}
                            selectedRgns={_selectedRegions}
                            dependentOnRegion={true}
                            allAllowed={false}
                        />
                    </BoxItem>

                </Box>
            </div>

            <div
                style={{
                    marginTop: "5px"
                    , marginBottom: "5px"
                }}
            >
                <Box
                    direction="row"
                    width="50%"
                    align="left"
                    crossAlign="left"
                >
                    <BoxItem className="inline__div--wrapper" ratio={1}>
                        {loading ?
                            <LoadingIndicatorCircle message="Assigning buildings, please wait..." />
                            :
                            <BaseButton
                                ariaDisabled={_selectedBuildingSequenceNumbers.length < 1}
                                disabled={_selectedBuildingSequenceNumbers.length < 1}
                                label={selectBuildingsLabel}
                                onClick={handleBuildingSelect}
                                variant={outlined}
                            />}
                    </BoxItem>
                </Box>
            </div>

            <Box style={{
                background: 'white',
                padding: '2em 1em 3em 1em',
                borderRadius: '1em'
            }}>
                <BoxItem
                    className="inline__div--wrapper"
                    ratio={1}
                >
                    {value &&
                        <>
                            <div
                                className="datagrid-title-selected-buildings"
                                style={{
                                    margin: "2em 0 1em 0"
                                }}
                            >
                                <strong>Selected Buildings:</strong>
                            </div>
                            <DataGrid
                                columnAutoWidth={true}
                                dataSource={dataSource}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                            >
                                <KeyboardNavigation enabled={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} />
                                <Column dataField="BuildingId" visible={false} />
                                <Column dataField="AddressId" visible={false} />
                                <Column dataField="RegionNumber" caption="Region" />
                                <Column dataField="SequenceNumber" caption="Building Number" />
                                <Column dataField="Name" caption="Name" />
                                <Column dataField="address" caption="Address" />
                                <Column dataField="Status" caption="Status" />
                            </DataGrid>
                        </>
                    }
                </BoxItem>
            </Box>
            <OccupancyAgreementAdd
                user={user}
                buildingOptions={_buildingsInSelectedBuildingsTable}
                setOas={(e) => { setOaList(e) }}
            />
        </>
    );
}

export default CustomerBuildingSelect;
