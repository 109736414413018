 import { TextBox } from "devextreme-react";
import Validator, { RequiredRule, StringLengthRule, CustomRule } from 'devextreme-react/validator';
import { Dimensions } from "../../Enums/dimensions";
import "./BaseTextField.scss";

const { Heights: { inputHeight } } = Dimensions

/**
 * 
 * @module BaseTextField 
 * This is the base of a BaseTextField for RMS.
 * It uses a textfield with type of "text" as the root UI Component,
 * and can be used strings of text.
 * 
 */

export const BaseTextField = (
    /**
     * @param { String } disabled: Disables the form field from user input when set to `true`. Optional. Defaults to false.
     * @param { String | Number | Function} height: Specifies the UI components height. Optional. Defaults to Undefined. 
     * @param { Boolean } hoverStateEnabled: (optional) Specifies whether the UI component changes it's state when a user hovers the cursor over the element. Optional. Defaults to {}.
     * @param { Boolean } isValueValid: Specifies or indicates if a field's value is valid (for required fields). Optional. Defaults to true.
     * @param { Boolean } isRequired: Specifies or indicates if a field's value is required. Optional. Defaults to true.
     * @param { Object } inputAttr: Sets any global HTML attributes to the component. Optional. Defualts to {}.
     * @param { String } label: The visible label (text) for the button. An asterisk (*) is manditory each label by placing one space after the end. Required. Defaults to "" (empty string).
     * @param { String } labelMode: Specifies the visible experience (mode) for how a label should persist when a user is filling the text field with a value. Accepts "static" (label is displayed above the input field), "floating" (label is used as a placeholder, but is moved to above the field once the field has focus) or "hidden" (the label is hidden). If autofill is enabled by the browser, avoid using "floating mode." Optional. Defaults to "static".
     * @param { String } mask: Specifies the custom format of the the string entered by the user (e.g.: 0-(000)-000-0000 for a phone number). A mask can contain the following operators: 0 (a digit), 9 (a digit or space), # (a digit, space, "+" or "-" sign), L, (a literal), C, (any character except space), c (any character), A (an alphanumeric), a (an alphanumeric or space), and \\ (double backslash for escaping). Optional. Defaults to "" (empty string).
     * @param { String } maskChar: Specifies a mask placeholder. A single character is recommended and will cause odd behavior if none or blank are specified when a mask is used. Optional Defaults to '_' (underscore).
     * @param { String } maskInvalidMessage: Displays a message when the entered text does not match the pattern set to `mask` parameter. Required only if `mask` property is being instantiated. Defaults to 'Value is Valid' (literal value).
     * @param { String | Number } maxLength: Specifies the maximum number of characters you can enter into the field. Optional. Defaults to null.
     * @param { String | Number } minLength: Specifies the maximum number of characters you can enter into the field. Optional. Defaults to 1 if required.
     * @param { Function } onEnterKey: Accepts a function that is executed when the Enter key has been pressed while this component is focused. Optional. Defaults to null.
     * @param { Function } onValueChanged: Accepts a function that updates the state of the text field with a new value. 
     * @param { Boolean } readOnly: Specifies whether the field is read-only (editable). Optional. Defaults to true.
     * @param { Boolean } visible: Specifies whether this UI component is visible. Optional. Defaults to true.
     * @param { String } value: Sets the initial state for the the text field. Required for set defalut value to a field. Defaults to "" (empty string).
     * @param { String } name: The value to be assigned to the name attribute of the underlying HTML element.
     */
    {
        disabled,
        hoverStateEnabled,
        inputAttribute,
        isValueValid,
        customValidationMessage,
        isRequired = true,
        isVisible,
        label,
        labelMode,
        mask,
        maskCharacter,
        maskInvalidMessage,
        maxLength,
        minLength = 1,
        onEnterKeyDown,
        readOnly,
        value,
        valueUpdatedByUser,
        name
    }
) => {
    const customValidate = () => {
        return isValueValid;
    }
    if (name === undefined || name === null) {
        if (label !== null || label !== undefined) {
            name = label?.replace(/\s/g, '');
        }
    }
    return (
        <span className="inline__span--textbox-wrapper">
            <TextBox
                disabled={disabled}
                height={inputHeight}
                hoverStateEnabled={hoverStateEnabled}
                isValid={isValueValid ?? true}
                inputAttr={inputAttribute}
                label={label}
                labelMode={labelMode}
                mask={mask}
                maskChar={maskCharacter}
                maskInvalidMessage={maskInvalidMessage}
                maxLength={maxLength}
                onEnterKey={onEnterKeyDown}
                onValueChanged={valueUpdatedByUser}
                readOnly={readOnly}
                visible={isVisible ?? true}
                value={value}
                name={name}
            >
                <Validator>
                    {isRequired && <RequiredRule
                        message="Field cannot be empty. Please supply a value."
                        type="required"
                    />}
                    {isRequired && <StringLengthRule
                        min={minLength}
                    />}
                    {isRequired && isValueValid === false &&
                        <CustomRule
                            validationCallback={customValidate}
                            reevaluate={true}
                            message={customValidationMessage ?? "Field cannot be empty. Please supply a value."}
                        />}
                </Validator>
            </TextBox>
        </span>
    );
};