import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import SessionContext from '../App/SessionContext';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch.js';
import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { LoadingIndicatorCircle } from '../components/Loading';
import ClosingAlert from '../components/ClosingAlert';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SecurityBannerModal({ open, setOpen }) {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const { logout } = useAuth();

    const handleSubmit = async () => {
        setLoading(true);
        let userAcceptance = {
            "UserAcceptedTerms": true,
            "UtcTimezoneOffsetInMinutes": (new Date()).getTimezoneOffset(),
            "UserAccount": SessionContext.get("UserId")
        };

        const userAccepted = await PutPostPatchFetch('/v1/SystemUser', 'POST', userAcceptance);
        if (userAccepted.Success === true) {
            SessionContext.set("SecurityAccepted", true);
            setError(false);
            setErrorMessage("");
            handleClose();
        }
        else {
            setError(true);
            setErrorMessage(userAccepted.Message);
            console.log("SecurityBanner.js - Error - ", userAccepted.Errors);
        }
        setLoading(false);
    }

    function handleLogout() {
        logout('warning', 'Login not completed since user did not accept the security banner.', false);
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Box
                    textAlign='center'
                    sx={{ p: 3,  color: "#06152B" }}
                >
                    <Typography variant="h6" align="center" component="div" mt={2}>
                        You must accept the U.S Government System terms to sign into this website
                    </Typography>
                    <Paper sx={{ p: 2, mt: 2,  color: "#06152B" }}>
                        <Typography variant="p" align="left" component="div" mt={2}>
                            You are accessing a U.S. Government information system, which includes (1) this computer,
                            (2) this computer network, (3) all computers connected to this network and (4) all devices
                            and storage media attached to this network or to a computer on this network. This information
                            system is provided for U.S. Government-authorized use only.
                            <br />
                            Unauthorized or improper use or access of this system may result in disciplinary action,
                            as well as civil and criminal penalties. By using this information system, you understand
                            and consent to the following:
                            <li>
                                You have no reasonable expectation of privacy when you use this information system;
                                this includes any communications or data transiting, stored on, originated from or directed
                                to this information system. At any time, and for any lawful government purpose, the
                                government may monitor, intercept, search and seize any communication or data transiting,
                                stored on, originated from or directed to or from this information system.
                            </li>
                            <li>
                                The government may disclose or use any communications or data transiting, stored on,
                                originated from or directed to or from this information system for any lawful government
                                purpose.
                            </li>
                            <li>
                                You are NOT authorized to process classified information on this information system.
                            </li>
                        </Typography>
                    </Paper>
                    {loading && <LoadingIndicatorCircle message={"We're working on it..."}/>}
                    {!loading &&
                        <>
                            <Button
                                color="primary"
                                variant="contained"
                                disableElevation
                                size="small"
                                onClick={handleSubmit}
                                align="center"
                                sx={{ m: 1, mt: 3 }}
                                id="accept_button_005"
                            >
                                Accept
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                disableElevation
                                size="small"
                                onClick={handleLogout}
                                align="center"
                                sx={{ m: 1, mt: 3 }}
                                id="decline_button_006"
                            >
                                Decline
                            </Button>
                        </>
                    }
                    <ClosingAlert
                        severity="error"
                        message={errorMessage}
                        visible={error && errorMessage != ""}
                        hideAlert={() => { setError(false) }}
                    />
                </Box>
            </Dialog>
        </div>
    );
}