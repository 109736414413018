import { DropDownBox, List } from "devextreme-react";
import { useEffect, useState, useRef } from "react";
import { Content } from '../../Enums/contents';
import Button from '@mui/material/Button';
import {
    ButtonGroup
} from '@mui/material';

const {
    Buttons: {
        MUI: {
            Variant: {
                outlined
            }
        }
    }
} = Content;

const ApprovalSingleSelectDevEx = ({ approvalOptions, approvalAllowed, selectedAdjustment, customLabel, handleSubmit }) => {
    const dropDownBoxRef = useRef(null);
    const [selectedKey, setSelectedItemKey] = useState();
    const [selectedApproval, setSelectedApproval] = useState()

    useEffect(() => {
        setSelectedApproval(selectedApproval)
    }, [selectedApproval, customLabel])

    const title = "Approval Status";
    const submitButtonLabel = customLabel ? `Change Adjustment Approval for OA: ${customLabel}` : "Change Adjustment Approval";

    return (
        <>
            {approvalAllowed &&
                <div className="inline__div--wrapper">
                    <ButtonGroup
                    >
                        <DropDownBox
                            label={title}
                            labelMode="floating"
                            height={37}
                            width={200}
                            value={selectedApproval}
                            dataSource={approvalOptions}
                            ref={dropDownBoxRef}
                            onValueChanged={(e) => {
                                //console.log('dropdown func', e);
                                setSelectedApproval(e.value);
                            }}
                            aria-disabled={!approvalAllowed || !selectedApproval}
                            disabled={!approvalAllowed || !selectedAdjustment}
                        >
                            <List
                                selectedItemKeys={[selectedKey]}
                                selectionMode="single"
                                showSelectionControls={true}
                                ref={dropDownBoxRef}
                                onSelectionChanged={(e) => {
                                    setSelectedApproval(e.addedItems[0]);
                                    setSelectedItemKey(e.addedItems[0]);
                                    dropDownBoxRef.current.instance.close();
                                }}
                                dataSource={approvalOptions}
                                pageLoadMode="scrollBottom"
                            />
                        </DropDownBox>
                        <br />
                        <span>&nbsp;</span>
                        <Button
                            sx={{ mt: 1, ml: 1, pl: 1 }}
                            id="submit-approval-button"
                            variant={outlined}
                            aria-disabled={!approvalAllowed || !selectedApproval}
                            disabled={!approvalAllowed || !selectedApproval}

                            onClick={() => { handleSubmit(selectedApproval) }}
                        >
                            {submitButtonLabel}
                        </Button>
                        <span>&nbsp;</span>
                    </ButtonGroup>
                </div>
            }
        </>
    )
}
export default ApprovalSingleSelectDevEx;