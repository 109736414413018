import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { ROUTE_ADJUSTMENTS_REPORT, ROUTE_BILLING_ADJUSTMENTS } from '../routes/Routes';
import { useAuth } from '../hooks/useAuth';
import AdjustmentsReportTable from './AdjustmentsReportTable';

const AdjustmentsReport = () => {
    const pageTitle = ROUTE_ADJUSTMENTS_REPORT.title;
    const { user } = useAuth();

  
  return (
    <div>
      <Typography component="div">
        <h1>{pageTitle}</h1>
          </Typography>
          <AdjustmentsReportTable />
    </div>
  );
}

export default AdjustmentsReport;
