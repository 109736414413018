import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import {
    Alert,
    ButtonGroup,
    CircularProgress,
    FormControl,
    FormGroup,
    InputLabel, 
    Typography,
    Select,
    MenuItem,
    TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import { useAuth } from '../hooks/useAuth';
import GetFetch from '../hooks/GetFetch';
import { FormatRowData } from './DataGridProperties';
import ClosingAlert from '../components/ClosingAlert';

const SearchButtonGroup = ({ setter, secSetter, url }) => {
    const [loading, setLoading] = useState(false);
    const [fiscalYearsList, setFiscalYearsList] = useState(null);
    const [regionsList, setRegionsList] = useState(null);
    const [isFYOpen, setIsFYOpen] = useState();
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const { user } = useAuth();

    useEffect(() => {
        fetchFiscalYears();
        fetchRegions();
    }, []);

    /**
     * @description this useEffect resets months to empty array if fiscal year is changed.
     */
    useEffect(() => {
        if (selectedFiscalYear === null) {
            setSelectedRegions([]);
        }
    }, [selectedFiscalYear]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleSettingData = (data) => {
        setter(data);
    };

    (IsNaturalYear, fiscalYearValue) => {
        if (IsNaturalYear) {
            return fiscalYearValue;
        }
        else {
            return fiscalYearValue - 1;
        }
    };

    const fetchRegions = async () => {
        const res = await GetFetch('/v1/Region');
        const { Message, Success, Errors } = res;

        if (Success) {
            const sortedRegions = Message.sort((a, b) => a.RegionNumber - b.RegionNumber);
            console.log(sortedRegions)
            setRegionsList(sortedRegions);
        };

        if (Errors.length > 0) {
            console.log(Errors)
            setErrorMessage(res.Errors[0].Message);
            console.log("What went wrong:", Errors);
        };
    };


    const fetchFiscalYears = async () => {
        setLoading(true);
        const fyList = await GetFetch('/v1/BillPeriod');
        if (fyList.Success === true) {
            console.log(fyList)
            setFiscalYearsList(fyList.Message);
            setError(false);
            setErrorMessage(null);
        }
        else {
            setError(true);
            setErrorMessage(fyList.Errors[0].Message);
            setFiscalYearsList(null);
            console.log("What went wrong:", fyList.Errors);
        }
        setLoading(false);
    };

    const fetchData = async () => {
        setLoading(true);
        const customerCostObject = await GetFetch(
            url +
            selectedFiscalYear +
            '/' +
            selectedRegions.join(',')
        );
        if (customerCostObject.Success === true) {
            handleSettingData(customerCostObject.Message);
            secSetter(FormatRowData(customerCostObject.Message));
            setError(false);
            setErrorMessage(null);
        }
        else {
            setError(true);
            setErrorMessage(customerCostObject.Errors[0].Message);
            handleSettingData(null);
            console.log("What went wrong:", customerCostObject.Errors);
        }
        setLoading(false);
    };      
    const handle_setSelectValue_Regions = (event, newValue) => {
        if (newValue) {
            const value = newValue.map((option) => option.RegionNumber);

            if (value.includes("Select All")) {
                setSelectAllChecked(!selectAllChecked);
            } else {
                setSelectAllChecked(false);
            }

            if (value.includes("Select All")) {
                setSelectedRegions(selectedRegions.length === regionsList.length ?
                    [] :
                    regionsList.map(({ RegionNumber }) => RegionNumber)
                );
            } else {
                setSelectedRegions(value);
            }

            if (
                selectedRegions.includes("Select All") &&
                selectedRegions.length !== regionsList.length
            ) {
                setSelectedRegions(selectedRegions.filter((value) => value !== "Select All"));
            }
        }
    };
    const handle_setSelectValue_FiscalYear = (event, newValue) => {
        if (newValue) {
            if (newValue.FiscalYear === 'None') {
                setSelectedFiscalYear(null);
            } else {
                setSelectedFiscalYear(newValue.FiscalYear);
                setIsFYOpen(false);
            }
        }
    };

    const handleCostEstSearch = () => {
        handleSettingData(null);
        setError(false);
        setErrorMessage(null);

        if (
            selectedRegions === undefined ||
            selectedRegions === null ||
            selectedRegions.length === 0
        ) { return; }
        fetchData();
    };

    const handleToggleFY = () => {
        setIsFYOpen(!isFYOpen);
    };

    return (
        <FormGroup >
            <Typography
                variant="body1"
                component="em"
                style={{ color: "#06152B" }}
            >
                Please select a fiscal year, and your desired regions to get a cost estimate
            </Typography>
            <ButtonGroup
                disableElevation
                variant="contained"
                sx={{ marginBottom: '3px' }}
            >
                {
                    fiscalYearsList &&
                    <FormControl
                        aria-expanded={isFYOpen}
                        aria-haspopup="listbox"
                        aria-owns='dropdown-options'
                        variant="filled" 
                        style={{ marginRight: '10px', minWidth: '120px'}}>
                        <InputLabel id="fiscal-year-label"></InputLabel>
                        <Autocomplete
                            id="fiscal-year-menu"
                            options={fiscalYearsList}
                            getOptionLabel={(option) => option.FiscalYear.toString()}
                            value={fiscalYearsList.find((option) => option.FiscalYear === selectedFiscalYear) || null}
                            onChange={(event, newValue) => handle_setSelectValue_FiscalYear(event, newValue)}
                            renderInput={(params) => (
                                <TextField {...params} label="Fiscal Year" variant="filled" />
                            )}
                        />
                    </FormControl>
                }
                {
                    selectedFiscalYear &&
                    <FormControl variant="filled" >
                        <div style={{ marginRight: '10px', minWidth: '120px'}}>
                            <InputLabel id="regions-label"></InputLabel>
                            <Autocomplete
                        id="regions-select-button"
                        multiple
                        options={[{ RegionNumber: 'Select All' }, ...regionsList]}
                        getOptionLabel={(option) => option.RegionNumber === 'Select All' ? option.RegionNumber.toString() : `Region ${option.RegionNumber}`}
                        value={regionsList.filter((option) => selectedRegions.includes(option.RegionNumber))}
                        onChange={(event, newValue) => handle_setSelectValue_Regions(event, newValue)}
                        renderInput={(params) => (
                            <TextField {...params} label="Regions" variant="filled" />
                        )}
                    />
                        </div>
                    </FormControl>
                }
                {
                    (selectedFiscalYear && selectedRegions.length > 0) &&
                    <Button
                        id="submit-button"
                        variant="contained"
                        onClick={handleCostEstSearch}
                        startIcon={<QueryStatsRoundedIcon />}
                        aria-label="Search Costs"
                        aria-labelledby="search-costs-label"
                    >
                        Search Costs
                    </Button>
                }
            </ButtonGroup>
            <ClosingAlert
                severity="error"
                message={errorMessage}
                visible={error && !loading}
                hideAlert={() => { setError(false) }}
            />
            {
                (loading) &&
                <CircularProgress color="secondary" />
            }
        </FormGroup>
    );
};

export default SearchButtonGroup;
