import React from 'react';
 
     export const MultiSelectCheckBoxListInstructions = ()=>{
         return (
             <>
             <ul aria-label="Accordion Control List Items for Tagbox Keyboard Navigation">
                        <li>
                        To open the drop-down list:
                        <code> Alt + ↓</code>
                        </li>
                        <li>
                        To move focus from search box to list items
                        <code> Press tab key</code>
                        </li>
                        <li>
                        To close the drop-down list and remove focus from the UI component:
                        <code> Press Tab Key twice</code> (escape)
                        </li>
                        <li>
                        Move focus to the previous or next item, when the dropdown list is open:
                        <code>  Alt + ↑ </code> / 
                        <code>  Alt + ↓ </code>
                        </li>
                        <li>
                        Select/Unselect the current item:
                        <code> Alt + Enter </code> or 
                        <code> Alt + Space </code>
                        </li>
                        <li> If user is searching by typing, it anounces as editing. </li>
                        <li> User can use space or comma if chose to search, then press Enter </li>
                        <li>While in the search box, left and right arrows can be used to read what is searched.</li>
                        <li>Any change in the search box to be applied, press Enter</li>
                        <li>
                        To exit the search text box enter tab key then can use the down and up arrows to go in the list.
                        </li>
                        <li>To exit and continue to the next component, press tab twice</li>
                        <li>To get back to the search text box from the list, press shift + tab keys</li>
                        <li>To collapse the search box and list of items, press shift + tab keys</li>
                   {/* <li>
                       Move focus to the first/last currently rendered item:
                       <code> Page Up </code> / 
                       <code> Page Down </code>
                   </li> 
                 
                   <li>
                       Unselect the last selected item:
                       <code> Backspace </code>
                   </li>
                   <li>
                       Focus on the previous/following tag:
                       <code> ← </code> / 
                       <code> → </code>
                   </li>
                   <li>
                       Removes focused tag:
                       <code> Backspace </code> / 
                       <code> Delete </code>
	                   </li>*/}
               </ul>
             </>
         )
     }

export const DataGridNavigationInstructions = () => {    
    return (
        <ul aria-label="Accordion Control List Items for Tagbox Keyboard Navigation">
            <li>To begin keyboard navigation (element by element), from the top of the page:
                <code> Tab </code> 
            </li>
            <li>
                When the "search" field has focus, press <code> Tab </code> again to enter into the DataGrid, beginning on the first table header.
            </li>
            <li>
                To enter into "grid navigation mode", press <code> Ctrl + ↓ </code> to enter focus into the DataGrid.
            </li>
            <li>
                To navigate row by row within use <code> ↑ </code> to go up and <code> ↓ </code> to go down, one row per keystroke.
            </li>
            <li>
                To set focus a row of data within a datagrid, press <code> Enter </code>. Once <code> Enter </code> is pressed, the account name of the aformentioned row will be printed to the label of the "Edit Row" button, and the same button will be enabled (the edit row button is disabled by default when no row has focus).
            </li>
            <li>
                To exit "grid navigation mode", continue pressing <code> Tab </code> until the element in visible focus is outside of the DataGrid and applied to a pagenation element.
                </li>
                <li>
                A pagenation element can be selected when focus is applied to it by pressing <code> Enter </code>
            </li>
        </ul>
    );
};