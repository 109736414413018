import { BaseSelectField } from "../components/BaseSelectField/BaseSelectField";
import IsNullOrEmpty from "../components/IsNullOrEmpty";
import { Fragment, useEffect, useState } from "react";
import GetFetch from '../hooks/GetFetch';

const FiscalYearSelectDropdown = (
    {
        enableSearch,
        inputAttribute,
        label,
        onEnterKeyDown,
        optionUpdatedByUser,
        value,
        isVisible
    }
) => {
    const [fiscalYearsList, setFiscalYearsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchFiscalYears = async () => {
        setLoading(true);
        const result = await GetFetch('/v1/BillPeriod');
        const { Success, Message, Errors } = result;
        if (Success) {
            setFiscalYearsList(Message);
            console.log(Message);
        }
        else {
            setErrorMessage(Errors[0].Message);
            console.log("What went wrong:", Errors);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchFiscalYears();
    }, []);


    const fiscalYears = fiscalYearsList.map(fy => fy.FiscalYear);

    return (
        <Fragment>
            <BaseSelectField
                data={fiscalYears}
                enableSearch={enableSearch}
                inputAttribute={inputAttribute}
                label={label}
                onEnterKeyDown={onEnterKeyDown}
                onOptionChanged={(e) => e.component.validate()}
                optionUpdatedByUser={optionUpdatedByUser}
                validationRuleCallback={() => !IsNullOrEmpty(value)}
                value={value}
                isVisible={isVisible}
            />
        </Fragment>
    );
};

export default FiscalYearSelectDropdown;