import { TagBox } from "devextreme-react";
import Validator, { CustomRule, RequiredRule } from 'devextreme-react/validator';
import IsNullOrEmpty from "../IsNullOrEmpty";
import "./BaseMultiSelect.scss";

/**
 * 
 * @module BaseInputSelectField 
 * This is the base of a BaseMultiSelect for RMS.
 * It uses a TagBox as the root UI Component from DevExtreme, and is used 
 * strings of text to store options of values that can be selected, one at time.
 * 
 */

export const BaseMultiSelect = (
/** 
 * @param {Array | Object | String | any | null} data: Binds the UI component to a collection of data that contains String, Number, or Boolean values. Also accepts a DataSource (see DevExtreme documentation). Defaults to Null. 
 * @param {Object} inputAttr: Sets any global HTML attributes to the component. Optional. Defualts to {}.
 * @param {String} label: The visible label (text) for the button. Defaults to "".
 * @param {String} labelMode: Specifies the visible experience (mode) for how a label should persist when a user is filling the text field with a value. Accepts "static" (label is displayed above the input field), "floating" (label is used as a placeholder, but is moved to above the field once the field has focus) or "hidden" (the label is hidden). If autofill is enabled by the browser, avoid using "floating mode." Optional. Defaults to "static".
 * @param {Function} onEnterKey: A function that is executed when the Enter key has been pressed while the UI component is focused. Optional. Defaults to Null
 * @param {Function} onValueChanged: Accepts a function that updates the state of the text field with a new value.
 * @param {String} value: Sets the initial state for the the text field. Defaults to "".
 */
    {
        data,
        inputAttr,
        label,
        labelMode,
        onEnterKeyDown,
        onSelectionChanged,
        value,
        displayExpr

    }
) => {
    let fiveOhEightAria = {}
    if (IsNullOrEmpty(inputAttr)) {
        fiveOhEightAria = {"aria-label": label}
    }
    fiveOhEightAria = inputAttr;
    return (
        <span className="inline__span--multiselect-wrapper">
            <TagBox
                dataSource={data}
                inputAttr={fiveOhEightAria}
                label={label}
                labelMode={labelMode}
                onEnterKey={onEnterKeyDown}
                onValueChanged={onSelectionChanged}
                value={value}
                displayExpr={displayExpr}
            >
                <Validator>
                    <RequiredRule type="required" />
                    <CustomRule 
                        message="At least one region must be selected. Please select a region(s)."
                        reevaluate={true}
                        validationCallback={() => !IsNullOrEmpty(value)}
                    />
                </Validator>
            </TagBox>
        </span>
    );
};