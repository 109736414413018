import {
    Alert,
    FormControl,
    InputLabel,
    OutlinedInput,
    MenuItem,
    Typography,
    FormHelperText,
    Autocomplete,
    TextField
} from "@mui/material";

import React, { useState, useEffect } from "react";
import GetFetch from "../../hooks/GetFetch";
import { useAuth } from "../../hooks/useAuth";
import { LoadingIndicatorCircle } from "../../components/Loading";

const selectStylesTwo = {
    m: 1,
    width: '13rem',
    margin: '1rem'
};
const inputLabelStyles = { margin: "1rem 0 0 1rem" };

const RoleDropdown = ({ value, setter }) => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const { user } = useAuth();

    const roleName = "Role *";
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        // here we will reset all values to default so that we only display one thing at a time
        // i.e. loading, data, or an error
        setError(false);
        setRoles(null);
        setLoading(true);
        const result = await GetFetch('/v1/SystemRoles/GetRoles');
        const { Success, Message, Errors } = result;
        if (Success) {
            setError(false);
            setRoles(Message);
        }
        else {
            setError(true);
            if (Errors[0].Message === "No records found at the moment") {
                setErrorMessage("Something went wrong. Please contact your administrator.");
            }
            else {
                setErrorMessage(Errors[0].Message);
            }
        }
        setLoading(false);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const setSelectedRoleId = (event, newValue) => {
        if (newValue) {
            setter(newValue.RoleId);
        } else {
            setter(null);
        }
    };

    return (
        <React.Fragment>
            {loading && <LoadingIndicatorCircle message="Loading available roles..." />}
            {roles && (
                <FormControl sx={{ mt: 1, mb: 1 }} error={!value}>
                    <InputLabel
                        style={inputLabelStyles}
                        id="roleName"
                        htmlFor="role-id"
                    >
                        
                    </InputLabel>
                    <Autocomplete
                        value={roles.find((r) => r.RoleId === value) || null}
                        onChange={setSelectedRoleId}
                        options={roles}
                        getOptionLabel={(option) => option.Name}
                        style={selectStylesTwo}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={roleName}
                                variant="outlined"
                                onKeyDown={handleKeyDown}
                                error={!value}
                                InputProps={{
                                    ...params.InputProps,
                                    inputProps: {
                                    ...params.inputProps,
                                    'aria-required': 'true',
                                    },
                                }}
                            />
                        )}
                    />
                    {!value && <FormHelperText>Required *</FormHelperText>}
                </FormControl>
            )}
            {error && (
                <Alert severity="error" sx={{ height: '50px', mt: 4 }}>
                    {errorMessage}
                </Alert>
            )}
        </React.Fragment>
    );
};

export default RoleDropdown;
