const SessionContext = (function () {
  const SESSION_OBJECT_NAME = "SESSION_CONTEXT";
  let SessionWriter = null;

  let get = function (key) {
    return SessionWriter.get(key);
  };

  let set = function (key, value) {
    SessionWriter.set(key, value);
  };

  let remove = function (key) {
    SessionWriter.remove(key);
  };

  let setStoreType = function () {
    SessionWriter = getSessionWriter();
  };

  let getSessionWriter = function () {
    return SessionStorageWriter;
  }


  let SessionStorageWriter = {
    set: function (key, value) {
      setItem(sessionStorage, key, value);
    },
    get: function (key) {
      return getItem(sessionStorage, key);
    },
    remove: function (key) {
      removeItem(sessionStorage, key);
    }
  }
  // what is this?? I can't find what memorywriter is...
  // SessionWriter = MemoryWriter;

  let setItem = function (storageObject, key, value) {
    const item = getStorageItem(storageObject);
    item[key] = value;
    setStorageItem(storageObject, item);
  }

  let getItem = function (storageObject, key) {
    const item = getStorageItem(storageObject);
    return item[key];
  }

  let removeItem = function (storageObject, key) {
    const item = getStorageItem(storageObject);
    delete item[key];
    setStorageItem(storageObject, item);
  }

  let getStorageItem = function (storageObject) {
    const item = storageObject.getItem(SESSION_OBJECT_NAME);
    return item ? JSON.parse(item) : {};
  }

  let setStorageItem = function (storageObject, item) {
    storageObject.setItem(SESSION_OBJECT_NAME, JSON.stringify(item));
  }

  let getUpdatedTime = function (numDays) {
    let now = new Date();
    now.setTime(now.getTime() + (numDays * 24 * 60 * 60 * 1000));
    return now.toUTCString();
  }

  return {
    setStoreType: setStoreType,
    remove: remove,
    get: get,
    set: set
  }

})();

export default SessionContext;