import { DataGrid } from "devextreme-react";
import { ROUTE_CONTRACT_COST_ESTIMATES } from "../routes/Routes";
import { useEffect } from "react";
import GetFetch from "../hooks/GetFetch";
import { useState } from "react";
import { LoadingIndicatorCircle } from "../components/Loading";
import { Column, FilterRow, Format, KeyboardNavigation, Pager, Paging, SearchPanel } from "devextreme-react/data-grid";
import CustomPreparedHeaderCell from "../components/CustomPreparedHeaderCell";

const ContractCostEstimates = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [contractEstimates, setContractEstimates] = useState();
    const endpoint = '/v1/Post/GetPosts';

    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        setLoading(true);
        const data = await GetFetch(endpoint);
        console.log(data)
        const { Success, Message, Errors } = data;
        setError(Success === false);
        if (Success) {
            setContractEstimates(Message);
        }
        setLoading(false);
    };

    return (
        <div>
            <h1>{ROUTE_CONTRACT_COST_ESTIMATES.title}</h1>
             {error && <p>Something went wrong.</p>}
            {loading && !contractEstimates && <LoadingIndicatorCircle message="Loading estimates..." />}
            {contractEstimates &&
                <div>
                    <DataGrid
                        columnAutoWidth={true}
                        dataSource={contractEstimates}
                        onCellPrepared={CustomPreparedHeaderCell}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        key="PostId">
                        <KeyboardNavigation enabled={true} />
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} />
                        <Column dataField="CLIN" caption="CLIN" width={100}/>
                        <Column dataField="Building.SequenceNumber" caption="Building Number" width={150}/>
                        <Column dataField="Building.RegionNumber" caption="Region" width={70} />
                        <Column dataField="PostLocation.Name" caption="Location" width={100}/>
                        <Column dataField="FeeType.FeeTypeName" caption="Fee Type" width={100}/>
                        <Column dataField="Screener.Name" caption="Screener" width={100}/>
                        {/* <Column dataField="VendorName" caption="Vendor" width={150}/> */}
                        <Column dataField="PrimaryPostType.Name" caption="Primary Post Type" width={250}/>
                        <Column dataField="SecondaryPostType.Name" caption="Secondary Post Type" width={250}/>
                        <Column dataField="SecurityLevel.Name" caption="Security Level" width={100}/>
                        <Column dataField="HourlyRate" caption="Hourly Rate" width={100}>
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column dataField="StartDate" dataType="date" caption="Start Date" width={100}/>
                        <Column dataField="EndDate" dataType="date" caption="End Date" width={100} />
                        <Column dataField="AdditionalServiceHours" caption="Additional Service Hours" width={100}>
                            <Format type="fixedPoint" precision={2}/>
                        </Column>
                    </DataGrid>
                </div>
            }
        </div>
    )
}
export default ContractCostEstimates;