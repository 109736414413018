import { useState, useEffect } from 'react';
import {
    Box,
    TableContainer
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Column,
    Export,
    Lookup
} from 'devextreme-react/data-grid';
import CustomPreparedHeaderCell from '../components/CustomPreparedHeaderCell';
import { logExport, useAuth } from '../hooks/useAuth';
import ExportGrid from '../components/ExportGrid';
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import './css/Adjustments.scss';
import SearchButtonGroupAdjustmentReport from './components/SearchButtonGroupAdjustmentReport';
import { FOUOfooter, FOUOheader } from '../components/FOUO';
import currencyFormatter from '../components/CurrencyFormatter';
import ClosingAlert from '../components/ClosingAlert';

const AdjustmentsReportTable = (props) => {
    const [data, setData] = useState();
    const [formattedData, setFormattedData] = useState();
    const {
        user
    } = useAuth();
    const approvalOptions = [{ id: 1, status: 'Submitted' }, { id: 2, status: 'Approved' }, { id: 3, status: 'Denied' }]

    useEffect(() => {
        if (data) {
            setFormattedData(
                data.map((adj) => {
                    let oa = adj.OccupancyAgreement
                    return {
                        AdjustmentId: adj.Id,
                        AdjustedAmount: currencyFormatter.format(adj.AdjustedAmount),
                        PSOAmount: currencyFormatter.format(adj.PSOAmount),
                        TCMAmount: currencyFormatter.format(adj.TCMAmount),
                        OANumber: oa.OANumber,
                        OccupancyAgreementId: oa.OccupancyAgreementId,
                        CustomerALC: oa.CustomerALC,
                        CustomerABCode: oa.CustomerABCode,
                        CustomerId: oa.CustomerAccountId,
                        BuildingNumber: oa.BuildingNumber,
                        BuildingId: oa.BuildingId,
                        BuildingRegion: oa.BuildingRegion,
                        Reason: adj.Reason,
                        CreatedBy: adj.CreatedBy,
                        CreatedDate: adj.CreatedDate,
                        UpdatedBy: adj.UpdatedBy,
                        UpdatedDate: adj.UpdatedDate,
                        EffectiveDate: formattedDate(adj.BillPeriodStart),
                        ExpirationDate: formattedDate(adj.BillPeriodEnd),
                        FeeTypeId: adj.FeeTypeId,
                        FeeTypeName: adj.FeeType.FeeTypeName,
                        IsInactive: adj.IsInactive,
                        ApprovalStatus: adj.ApprovalStatus ?? "Unknown",
                        BuildingRent: oa.BuildingAnsiRent,
                        TotalRentSqFt: oa.TotalRentSqFt
                    }
                })
            )
        }
        else setFormattedData([])
    }, [data])

    const dataIsValid = () => {
        if (data === undefined) {
            return false;
        }
        if (data === null) {
            return false;
        }

        return true;
    }

    const formattedDate = (dateString) => {
        let date = new Date(dateString);
        return (`${date.getMonth() + 2}-${date.getFullYear()}`)
    }

    const pdfFont = () => { return 6; }
    const wrapPdfCell = () => {
        return true;
    }
    const handleExport = (e) => {
        logExport(user.email)
        ExportGrid(e, "Adjustments", wrapPdfCell(), pdfFont())
    }

    return (
        <div>
            <SearchButtonGroupAdjustmentReport setter={setData} />

            {dataIsValid() && formattedData &&
                <>
                    <Box sx={{
                        height: 600
                        , width: '97%'
                        , margin: 'auto'
                    }}
                    >
                        <Paper
                            elevation={5}
                            sx={{ padding: '30px', marginTop: '7px' }}
                        >
                            <FOUOheader
                                dateString={new Date().toString()}
                                heading={`${new Date().toDateString()} Adjustment`}
                                userEmail={user.email}
                            />
                            <TableContainer
                                id="adjustments=report-table-container"
                                component={Paper}
                                elevation={0}
                            >
                                <DataGrid
                                    columnAutoWidth={true}
                                    dataSource={formattedData}
                                    onCellPrepared={CustomPreparedHeaderCell}
                                    showBorders={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                    onExporting={e => handleExport(e)}
                                >

                                    <Column dataField="ApprovalStatus" caption="Approval Status">
                                        <Lookup dataSource={approvalOptions} displayExpr="status" valueExpr="status" />
                                    </Column>
                                    <Column dataField="AdjustmentId" visible={false} />
                                    <Column dataField="OccupancyAgreementId" visible={false} />
                                    <Column dataField="OANumber" caption="Occupancy Agreement" />
                                    <Column dataField="BuildingRegion" caption="Region" />
                                    <Column dataField="CustomerABCode" caption="Agency Bureau Code" />
                                    <Column dataField="CustomerALC" caption="ALC" />
                                    <Column dataField="BuildingNumber" caption="Building Number" />
                                    <Column dataField="FeeTypeName" caption=" Fee Type" />
                                    <Column dataField="PSOAmount" caption="PSO Amount" />
                                    <Column dataField="TCMAmount" caption="TCM Amount" />
                                    <Column dataField="AdjustedAmount" caption="Total Adjusted Amount" />
                                    <Column dataField="Reason" caption="Reason" />
                                    <Column dataField="EffectiveDate" caption="Bill Period Start" />
                                    <Column dataField="ExpirationDate" caption="Bill Period End" />
                                    <Column dataField="IsInactive" caption="Deactivated" dataType="boolean" visible={false} />
                                    <KeyboardNavigation enabled={true} />
                                    <SearchPanel
                                        highlightCaseSensitive={true}
                                        visible={true}
                                    />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={allowedPageSizes}
                                    />

                                    <Export
                                        enabled={true}
                                        formats={pageFormats}
                                    />
                                </DataGrid>
                            </TableContainer>
                            <FOUOfooter />
                        </Paper>
                    </Box>
                </>
            }

        </div>
    )
}


export default AdjustmentsReportTable;