import * as React from "react";
import {
    Alert,
    FormControl,
    InputLabel,
    FormHelperText,
    Autocomplete,
    TextField
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import GetFetch from "../../hooks/GetFetch";
import { LoadingIndicatorCircle } from "../../components/Loading";
import { useAuth } from "../../hooks/useAuth";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const selectStylesTwo = {
    m: 1,
    width: '11rem',
    // height: '4rem',
    margin: '1rem'
};

const inputLabelStyles = { margin: "1rem 0 0 1rem" };
export default function RegionSelect({ value, setter }) {
    const [loading, setLoading] = useState(false);
    const [userStatusid, setUserStatusId] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [error, setError] = useState(false);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {

        setError(false);
        setUserStatusId([]);
        setLoading(true);
        const result = await GetFetch('/v1/SystemUser/GetUserStatuses');
        const { Success, Message, Errors } = result;
        if (Success) {
            setError(false);
            setUserStatusId(Message);

        }
        else {
            setError(true);
            if (Errors[0].Message) {
                setErrorMessage("Something went wrong. " + Errors[0].Message + " Please contact your administrator.");
            }
            else {
                setErrorMessage(Errors[0].Message);
            }
        }
        setLoading(false);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const onStatusChange = (event, newValue) => {
        if (newValue) {
            setter(newValue.StatusId);
        } else {
            setter(0);
        }
    };
    return (
        <div>

            {(loading && !userStatusid) && <LoadingIndicatorCircle message="Loading User Statuses, please wait ..." />}
            {userStatusid &&

                <FormControl sx={{ mt: 1, mb: 1 }} error={value == 0}>
                    <InputLabel
                        id="userStatusID"
                        style={inputLabelStyles}
                        htmlFor="user-status-id"
                    >
                    </InputLabel>

                    <Autocomplete
                        value={userStatusid.find((u) => u.StatusId === value) || null}
                        options={userStatusid}
                        getOptionLabel={(option) => option.Name}
                        style={selectStylesTwo}
                        onChange={onStatusChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="User Status"
                                variant="outlined"
                                onKeyDown={handleKeyDown}
                                error={value == 0}
                                required={true}
                                InputProps={{
                                    ...params.InputProps,
                                    inputProps: {
                                        ...params.inputProps,
                                        'aria-required': 'true',
                                    },
                                }}
                            />
                        )}
                    />
                    {value == 0 && <FormHelperText>Required *</FormHelperText>}
                </FormControl>
            }
            {error &&

                <Alert severity="error" sx={{ height: '50px', mt: 4 }}>
                    {errorMessage}
                </Alert>
            }
        </div>
    );
}
