import React, { useState } from "react";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import LoginCard from '../util/CustomCard';
import RmsPool from "../Login/RmsPool";
import ClosingAlert from "../components/ClosingAlert";

const SignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [alert, setAlert] = useState(false);
    const [isSignUp, setSignUp] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();
        RmsPool.signUp(email, password, [], null, (err, date) => {
            if (err) {
                setErrorMessage(err.message);
                setAlert(true);
                setSignUp(false);
                console.error(err);

            }
            else {
                setSignUp(true);
                setAlert(false);
            }


        })


    };
    return (

        <div>
            <form onSubmit={onSubmit}>
                <LoginCard>
                    <Typography variant="h5" component="div" mt={2}>
                        Sign Up
                    </Typography>
                    <Input
                        id="set-email"
                        type="text"
                        placeholder="Email"
                        value={email}
                        variant="outlined"
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <Input
                        id="set-password"
                        type="password"
                        placeholder="Password"
                        variant="outlined"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        size="small"
                        onClick={onSubmit}
                        disabled={!email || !password}
                    >
                        Sign Up
                    </Button>
                    <Stack spacing={2}>
                        <ClosingAlert
                            severity="error"
                            message={errorMessage}
                            visible={alert}
                            hideAlert={() => { setAlert(false) }}
                        />
                        <ClosingAlert
                            severity="error"
                            message="Success"
                            visible={isSignUp}
                            hideAlert={() => { setSignUp(false) }}
                        />
                    </Stack>
                </LoginCard>
            </form>
        </div>
    );
};
export default SignUp;