
 import "./css/SkipToMainContentStyle.scss";

const SkipToMainContent = () => {
   
    const skipToContent = (event) => {
        const contentElement = document.getElementById('main-div');
        if (contentElement) {
          contentElement.tabIndex = -1;
          contentElement.focus();
          event.preventDefault();
        }
      };
    
      return (
      <div className = "skipnav">  <a  href="#main-div" onClick={skipToContent}>
          Skip to Main Content
        </a>
        </div>
      );
      };

export default SkipToMainContent;