 /**
  * @file
  * This module only contains helper functions that can be shared across RMS.
  */
 
 /**
  * @function
  * @name sortRegions
  * @param integers[]
  * @description function accepts an array numbers. The function will convert 
  * each number to a string though mapping, sort each number, and join a comma
  * to each number (except the last index) for readablity
  * @returns An array of sorted regions
  */
 
 export function sortRegions(regionStrings) {
    const strings = regionStrings.map(number => String(number));
    const comparator = (a, b) => a - b;
    const regions = strings.slice().sort(comparator).join(', ');

    return regions;
};


 /**
  * @function
  * @name generateUID
  * @param array[]
  * @description function accepts an array. The function will operate over the
  * array by appending a new property (_uid) with a value of an integer that
  * begins at 0 (zero) and increments with each loop until the length of the
  * array is has been reached.
  * @returns The array - mutated to contain appended key values.
  */

export const generateUID = (array) => {
    let currentUIdValue = 0;

    array.forEach(label => {
        label._uid = currentUIdValue++;
    });

    return array;
};