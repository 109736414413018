import React from "react";

export const FOUOheader = ({
  dateString,
  fiscalYears,
  fiscalYearsBoolean,
  heading,
  userEmail,
}) => {
  return (
    <>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <h5>
          {heading} Report{" "}
          {fiscalYearsBoolean && (
            <>
              for fiscal year(s) {fiscalYears}
            </>
          )}
        </h5>
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "-2.5rem",
        }}
      >
        <p>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 400,
            }}
          >
            Generated by {userEmail} on {dateString}
          </span>
        </p>
      </div>
    </>
  );
};

export const FOUOfooter = () => {
  return (
    <>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          marginTop: "-2.0rem",
          width: "100%",
        }}
      >
        <h5>
          <span style={{ fontSize: "40px" }}>For Official Use Only</span>
        </h5>
      </div>
    </>
  );
};