import {  useState } from 'react';
import Button from '@mui/material/Button';
import { 
    ButtonGroup,
    FormGroup,
    Typography
} from '@mui/material';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';

import PutPostPatchFetch from '../../hooks/PutPostPatchFetch.js';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { LoadingIndicatorPageLength } from '../../components/Loading';
import CostTypeDropdown from './CostTypeDropdown.js';

import ClosingAlert from '../../components/ClosingAlert';
import BuildingMultiselectDevEx from '../TotalBuildingCostReportComponents/BuildingMultiselectDevEx.js' ;
import RegionMultiSelectDevEx from '../TotalBuildingCostReportComponents/RegionMultiSelectDevEx.js';


const dateFieldSx = {
    mr: 1,
    width: 100
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const SearchButtonGroupCostEstimates = ({ setter }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedBldgNumber, setSelectedBldgNumber] = useState([]);
    const [selectedCostType, setSelectedCostType] = useState([]);
       const [loadingData, setLoadingData] = useState(false);
    
    const [alertConfig, setAlertConfig] = useState({
        isVisible: false,
        type: 'info',
        message: '',
    })
   
    const [selectedRegions, setSelectedRegions ] = useState([]);
    const showAlert = (message, type) => {
        setAlertConfig({
            isVisible: true,
            type: type,
            message: message,
        });
    };

    const hideAlert = () => {
        setAlertConfig({
            isVisible: false,
            type: alertConfig.type,
            message: "",
        });
    };

    const invalidDate = "Please ensure that the date range you entered follows the correct syntax. The date range should be in the format of 'MM/YYYY - MM/YYYY.' For example, '01/2023 - 12/2023' represents a valid date range for the fiscal year. Double-check your input and try again. If you continue to experience issues, please contact our support team for assistance.";
 
    const handle_setSelectValue_BldgSequenceNo = (value) => {
        setSelectedBldgNumber(value);
    };
 
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const parametersObject = (s, e, c, b, r) => {
        var jsonObj = {   
            SubParameters: { StartDate: s.startDateFormat, EndDate: e.endDateFormat, CostType: c.selectedCostType, RegionNumbers: r},
            BuildingDomain: b.length === 0 ? null: b.replace(/,$/, "").split(",").map(function (b) {
                return { Building: b };
            })
           
        }       
        return jsonObj;
    }
    const handleSearch = async () => {
        if (!startDate || !endDate) {
            showAlert(invalidDate, "error");
        }
       
        else {
            hideAlert()
            setter(null);
            setLoadingData(true);

            const startDateFormat = new Date(startDate.format('MM/DD/YYYY'));
            const endDateFormat = new Date(endDate.format('MM/DD/YYYY'));
            const buildingNumber =  selectedBldgNumber ;         

            try {
                
                let searchParameters = parametersObject({startDateFormat}, {endDateFormat}, {selectedCostType},
                                       buildingNumber.toString(), selectedRegions);
                const result = await PutPostPatchFetch('/v1/CostEstimateChangesReportByFiscalYearReport/CostChargesEstimate', 'POST', searchParameters);
              
                if (result.Success) {                   
                        setter(result.Message);
                        hideAlert();
                   
                } else if(result.errors){
                    let errorMessage = "something went wrong."
                 if(result.errors.startDate || result.errors.endDate)
                   errorMessage = "invalid date(s)";
                    showAlert(errorMessage, "error");                   
                    setter(null);
                    console.error(result.errors);
                }

            } catch (error) {
                showAlert("something went wrong. Please try again", "error");
                setter(null);
                console.error(error);
            }
            setLoadingData(false);
        }
    };

    return (
        <FormGroup sx={{ width: '97%', margin: 'auto' }}>
            <Typography
                variant="body1"
                component="em"
                style={{ color: "#06152B" }}
            >
                Search by Date Range, Building Number, and Cost Type
            </Typography>
            <ButtonGroup
                disableElevation
                variant="contained"
                sx={{ my: 1 }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                        sx={dateFieldSx}
                        id="start-date"
                        label="Start Date"
                        format="MM-YYYY"
                        value={startDate}
                        onChange={(newValue) => { setStartDate(newValue) }}
                        inputProps={{
                            onKeyDown: handleKeyDown,
                            "aria-required": "true"
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                        error={!startDate}
                        helperText={"Required *"}
                    />
                    <DateField
                        id="end-date"
                        sx={dateFieldSx}
                        label="End Date"
                        format="MM-YYYY"
                        value={endDate}
                        onChange={(newValue) => { setEndDate(newValue) }}
                        inputProps={{
                            onKeyDown: handleKeyDown,
                            "aria-required": "true"
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                        error={!endDate}
                        helperText={"Required *"}
                    />
                </LocalizationProvider>
                <span>&nbsp;</span>
                 <RegionMultiSelectDevEx
                 value ={selectedRegions} 
                 valueSetter = {setSelectedRegions}/>

                <span>&nbsp;</span>
                <BuildingMultiselectDevEx
                                value={selectedBldgNumber}                              
                                valueSetter={handle_setSelectValue_BldgSequenceNo}
                                selectedRgns={selectedRegions}
                                dependentOnRegion = {true}
                                allAllowed = {false}
                                req = {false}
                                             
                            />
                             
               
                <CostTypeDropdown
                    selection={selectedCostType}
                    setSelection={setSelectedCostType}
                    handleKeyDown={handleKeyDown}
                />

                <Button
                    id="submit-button"
                    variant="contained"
                    disabled={!startDate || !endDate || (selectedRegions && selectedRegions.length < 1 )}
                
                    onClick={handleSearch}
                    startIcon={<QueryStatsRoundedIcon />}
                >
                    Find Changes
                </Button>
            </ButtonGroup>
            {loadingData &&
                <LoadingIndicatorPageLength message={"searching for records..."} />
            }
            <ClosingAlert
                severity={alertConfig.type}
                message={alertConfig.message}
                visible={alertConfig.isVisible}
                hideAlert={hideAlert}
            />
            {/* <CircularProgress color="secondary" /> */}
        </FormGroup>
    );
}

export default SearchButtonGroupCostEstimates;