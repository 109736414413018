import { Button } from 'devextreme-react/button'
import './RowExpander.scss';

/**
 * @module RowExpander
 * This is the RowExpander for MasterDetail in RMS.
 * It contains a custom expander for the details section that includes a unique id for each master row.
 */

export const RowExpander = (props, key) => {
      /**
     * @param { Object } props: Specifies the data from the datagrid. Required. Defaults to undefined.
     * @param { String } key: Specifies the unique value to store in each row's expander. Required. Defaults to "" (empty string).
     */

      const icon = props.value ? "spindown" : "spinright";
      const label = props.data[key];
      const text = props.value ? "Collapse " + label : "Expand " + label;
      const inputAttribute = text;
      const ariaExpanded = props.value ? 'true' : 'false';

      const detailClick = () => {
        if (props.value) {
          props.component.collapseRow(props.key);
        } else {
          props.component.expandRow(props.key);
        }
      };
      return (
          <Button 
          onClick={detailClick}
          icon={icon} 
          text={text}
          stylingMode='text'
          inputAttribute={inputAttribute}
          elementAttr={{
            'aria-expanded': ariaExpanded,
          }}
           />
      );
    };