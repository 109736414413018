import {
    useState
    , useEffect
} from 'react';
import {
    Typography
    , Paper
    , Box
    , LinearProgress
} from '@mui/material';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { ROUTE_WORKFLOW_TASKS } from '../routes/Routes';
import ClosingAlert from '../components/ClosingAlert';
import Tasks from './Tasks';
import GetFetch from '../hooks/GetFetch';

const WorkflowTaskList = () => {
    const [data, setData] = useState();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        retrieveData();
        document.title = ROUTE_WORKFLOW_TASKS.title;
    }, []);

    const retrieveData = async () => {
        setLoading(true)
        const result = await GetFetch(
            `/v1/CRMAWorkflow/CRMAWorkflow/GetWorkflowStepByRoleAndRegion`
        );
        // console.log("result: ", result)
        const { Success, Errors, Message } = result;

        if (Success) {
            console.log(Message)
            setData(Message);
            setError(false);
            setErrorMessage(null);
            setLoading(false)
        }
        else {
            setError(true);
            setErrorMessage(Errors[0].Message);
            setData(null);
            console.log("What went wrong:", result.Errors);
            setLoading(false)
        }
    }

    return (
        <div>
            <Typography
                sx={{ width: '98%', margin: 'auto' }}
                className="title-task-list"
                component="div"
            >
                <h1 style={{ color: "#06152B" }}>Task List</h1>
            </Typography>
            <br />
            {
                loading &&
                <LinearProgress />
            }
            {
                !loading && data && data.length == 0 &&
                <Box sx={{
                    height: 600
                    , width: '97%'
                    , margin: 'auto'
                }}
                >
                    <Typography
                        sx={{ width: '98%', margin: 'auto' }}
                        className="task-list-empty"
                        component="div"
                    >
                        <h3 style={{ color: "#06152B" }}>There are currently no tasks that need attention.</h3>
                    </Typography>
                </Box>
            }
            {data && data.length > 0 &&
                <>
                    <Box sx={{
                        height: 600
                        , width: '97%'
                        , margin: 'auto'
                    }}
                    >
                        <Tasks data={data} />
                    </Box>
                    <ClosingAlert
                        severity="error"
                        message={errorMessage}
                        visible={error}
                        hideAlert={() => { setError(false) }}
                    />
                </>
            }
        </div>
    )
}

export default WorkflowTaskList;
