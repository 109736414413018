import {
    useState
    , useEffect
} from 'react';
import {
    Typography
    , Paper
    , Box
} from '@mui/material';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { ROUTE_AUDIT_REPORT } from '../../routes/Routes';
import SearchButtonGroupAudit from './SearchButtonGroupAudit';
import UserChanges from './UserChanges';
import BuildingChanges from './BuildingChanges ';
import UserSession from './UserSessions';
import Contracts from './Contract';
import AdjustmentChanges from './AdjustmentChanges';
import CustomerChanges from './CustomerChanges';
const AuditReport = () => {
    const [data, setData] = useState();
    useEffect(() => {
        document.title = ROUTE_AUDIT_REPORT.title;
    }, []);
    return (
        <div>
            <Typography
                sx={{ width: '98%', margin: 'auto' }}
                className="title-cost-estimates"
                component="div"
            >
                <h1 style={{ color: "#06152B" }}>Review Changes</h1>
            </Typography>
            <SearchButtonGroupAudit setter={setData} />
            <br />
            {data &&
                <>
                    <Box sx={{
                        height: 600
                        , width: '97%'
                        , margin: 'auto'
                    }}
                    >
                        <Paper
                            elevation={5}
                            sx={{ padding: '30px', marginTop: '7px' }}
                        >
                            <TabPanel id="tabPanel">
                                <Item title="User Changes" id="user-changes-tab" aria-label="user-changes-tab">
                                    <UserChanges data={data.UserChanges} />
                                </Item>
                                <Item title="Building Changes" id="building-changes-tab" aria-label="building-changes-tab">
                                    <BuildingChanges data={data.BuildingChanges} />
                                </Item>
                                <Item title="User Session Changes" id="session-changes-tab" aria-label="session-changes-tab">
                                    <UserSession data={data.Sessions} />
                                </Item>
                                <Item title="Contract Changes" id="contract-changes-tab" aria-label="contract-changes-tab">
                                    <Contracts data={data.Contracts} />
                                </Item>
                                <Item title="Adjustment Changes" id="adjustment-changes-tab" aria-label="adjustment-changes-tab">
                                    <AdjustmentChanges data={data.Adjustments} />
                                </Item>
                                <Item title="Customer Changes" id="customer-changes-tab" aria-label="customer-changes-tab">
                                    <CustomerChanges data={data.CustomerAccounts} />
                                </Item>
                            </TabPanel>
                        </Paper>
                    </Box>
                </>
            }
        </div>
    )
}

export default AuditReport;
