import {
    DataGrid
    , Box
    , DateBox
    , NumberBox
    , TextBox
    , Button as DevExButton
    , SelectBox
} from "devextreme-react";
import { Item as BoxItem } from "devextreme-react/box";
import {
    Column
    , Editing
    , Item as DataGridItem
    , Lookup
    , RequiredRule
    , Toolbar
} from "devextreme-react/data-grid";
import {Validator, CustomRule, RequiredRule as ValidatorReqRule } from "devextreme-react/validator";
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';
import notify from 'devextreme/ui/notify';
import {useEffect, useState } from "react";
import { ROUTE_CONTRACTS_PAGE, ROUTE_CREATE_A_NEW_CONTRACT_RECORD } from "../routes/Routes";
import { useNavigate } from "react-router";
import GetFetch from "../hooks/GetFetch";
import ItemProductDropdowns from "./ItemProductDropdowns";
import IsNullOrEmpty from "../components/IsNullOrEmpty";
import PutPostPatchFetch from "../hooks/PutPostPatchFetch";
import CustomPreparedHeaderCell from "../components/CustomPreparedHeaderCell";
import { useAuth } from '../hooks/useAuth';
const URL_feetypes = '/v1/FeeType/GetFeeTypes';
const URL_postTypes = '/v1/PostType';
const URL_postLocations = '/v1/PostLocation';
const URL_postStatuses = '/v1/PostStatus/GetPostStatus';
const URL_screeners = '/v1/Screener';
const URL_securityLevels = '/v1/SecurityLevel';
const URL_products = '/v1/ProductCode/GetProductCodes';
const URL_contractOfficialRep = "/v1/Contract/GetContractPersonnel";

const AddAContractPage = () => {
    // posts   
    const {user} = useAuth();
    const [posts, setPosts] = useState([]);
    const [feeTypes, setFeeTypes] = useState([]);
    const [postTypes, setPostTypes] = useState([]);
    const [postLocations, setPostLocations] = useState([]);
    const [postStatuses, setPostStatuses] = useState([]);
    const [screeners, setScreeners] = useState([]);
    const [securityLevels, setSecurityLevels] = useState([]);
    const [buildings, setBuildings] = useState([]);
    //end exhibit items
    const [contractNumber, setContractNumber] = useState();
    const [periodOfPerformanceStartDate, setPeriodOfPerformanceStartDate] = useState();
    const [periodOfPerformanceEndDate, setPeriodOfPerformanceEndDate] = useState();
    const [awardDateContract, setAwardDateContract] = useState();
    const [contRepOfficer, setContractRepOfficer] = useState([]);
    const [selectedContractRepOfficer, setSelectedContractRepOfficer] = useState([]);
    const [ContractRepId, setContractRepId] = useState();
    /**OptionPeriodData is our Contract Items, is filled based on numberOfOptionPeriods' value. */
    const [taskOrderData, setTaskOrderData] = useState(
        {
            TaskOrderId: 0,
            id: 1,
            Number: null,
            StartDate: null,
            EndDate: null,
            VendorName: null,
            LineNumber: null,
            ProductCodeId: null,
            ProductDescriptionId: null,
            RegionId: null,
            AwardDate: null,
            PostExhibit: []
        }
    );
    const [financialCodeData, setFinancialCodeData] = useState(
        {
            FinancialCodeId: 0,
            TaskOrderId: 0,
            AccountCode: null,
            AccSequenceNumber: null,
            AccObligatedAmount: null,
            TreasuryAccSymbol: null,
            CageCode: null
        }
    );
  
    const [productInfomation, setProductionInformation] = useState([]);

    /** signal page to close */
    const [successAndClose, setSuccessAndClose] = useState(false);
    const [isSubmittable, setIsSubmittable] = useState(false);
    const navigate = useNavigate();
    const minDate = new Date(1900, 0, 1);
    let URL_buildings = '/v1/Building/Region/';
   
    if (successAndClose) {
        setTimeout(() => {
            navigate(ROUTE_CONTRACTS_PAGE.withSlash);
        }, 1000);
    }
    useEffect(() => {
        fetchAndSet(URL_contractOfficialRep, setContractRepOfficer);
        fetchAndSet(URL_products, setProductionInformation);
        fetchAndSet(URL_feetypes, setFeeTypes);
        fetchAndSet(URL_postTypes, setPostTypes);
        fetchAndSet(URL_postLocations, setPostLocations);
        fetchAndSet(URL_postStatuses, setPostStatuses);
        fetchAndSet(URL_screeners, setScreeners);
        fetchAndSet(URL_securityLevels, setSecurityLevels);
    }, []);
    useEffect(() => {
        if (taskOrderData.RegionId !== null) {
            fetchAndSet(URL_buildings + taskOrderData.RegionId, setBuildings);
        }
    }, [taskOrderData]);
    useEffect(() => {
        const result = canSubmit();
        // console.log(result);
        setIsSubmittable(result);
    }, [contractNumber, periodOfPerformanceEndDate, periodOfPerformanceStartDate, awardDateContract, ContractRepId,
        taskOrderData.AwardDate, taskOrderData.Number, taskOrderData.StartDate, taskOrderData.EndDate, taskOrderData.ProductCodeId,
         taskOrderData.RegionId, financialCodeData.AccObligatedAmount, financialCodeData.AccSequenceNumber,
        financialCodeData.AccountCode, financialCodeData.CageCode, financialCodeData.TreasuryAccSymbol
    
     ])
     const filteredProductInfomation = ()=>{
       var res = productInfomation.filter(p=>p.ProductDescriptions.length > 0);
       return res;
     }
     const updatePostExhibitEffect = () => {
        const result = canSubmit();
        setIsSubmittable(result);
    }
    const fetchAndSet = async (URL, setter) => {
        const { Errors, Message, Success } = await GetFetch(URL);
        if (Success === true) {
            // console.log(Message);
            setter(Message);
        }
        else {
            console.error(Errors[0].Message);
        }
    }
    const showPicker = (e) =>
    {
        e.component.open(); 
    }

    const IsValidTaskOrder = (object) => {
        return object.Number && object.StartDate && object.EndDate && object.ProductCodeId && object.RegionId;
    }
    const IsValidFinancialCode = (object) =>{
        return financialCodeData.AccObligatedAmount  && financialCodeData.AccSequenceNumber &&
        financialCodeData.AccountCode && financialCodeData.CageCode && financialCodeData.TreasuryAccSymbol ;
    }
    const IsValidContractNumber = (value) => {
        if (IsNullOrEmpty(value)) {
            return false;
        }
        if (value.length !== 17) {
            return false;
        }
        return true;
    }
    const canSubmit = () => {
        return IsValidContractNumber(contractNumber) && periodOfPerformanceStartDate !== undefined && ContractRepId != undefined &&
                  periodOfPerformanceEndDate !== undefined && periodOfPerformanceStartDate < periodOfPerformanceEndDate 
                  && awardDateContract!== undefined && taskOrderData.Number && 
                  taskOrderData.StartDate && taskOrderData.EndDate && taskOrderData.ProductCodeId  && validateTODates() &&            
                  taskOrderData.RegionId && taskOrderData.AwardDate && 
                  financialCodeData.AccObligatedAmount  && financialCodeData.AccSequenceNumber &&
                  financialCodeData.AccountCode && financialCodeData.CageCode && financialCodeData.TreasuryAccSymbol                  
             }
    const showMessage = (message, success) => {
        notify(
            {
                message: message,
                width: 500,
                position: {
                    at: "bottom",
                    my: "bottom",
                    of: "#container"
                }
            },
            success ? "success" : "error",
            7500
        );
    }
  
    function convert(str) {
        const date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }
    const handleSubmit = async () => {
        // console.log(convert(periodOfPerformanceStartDate))
        // console.log(periodOfPerformanceEndDate)

        if (taskOrderData === null || financialCodeData === null) {
            return;
        }

        // TODO: Must add award date when endpoint is updated (task order item)
        if (!IsValidTaskOrder(taskOrderData)) {
            let taskOrderValidationErrorMsg = `Please fill all required fields for 'Base Period'`;
            showMessage(taskOrderValidationErrorMsg, false);
            return;
        }
        if(!IsValidFinancialCode){
            let accErrMsg = "Please fill all required fields for Contract Account";
            showMessage(accErrMsg, false);
            return;
        }
        let postExhibit = [];
        if(posts.length > 0)
       {
         posts.forEach(postObj => {          
            const post = {
                "CLIN": postObj.CLIN,
                "PostLocationId": postObj.PostLocation,
                "FeeTypeId": postObj.FeeType,
                "ScreenerId": postObj.Screener,
                "BuildingId": postObj.Building,
                "PostStatusId": postObj.PostStatus,
                "PrimaryPostTypeId": postObj.PrimaryPostType,
                "SecondaryPostTypeId": postObj.SecondaryPostType,
                "SecurityLevelId": postObj.SecurityLevel,
                "HourlyRate": postObj.HourlyRate,
                "StartDate": convert(postObj.StartDate),
                "EndDate": convert(postObj.EndDate),
                "AdditionalServiceHours": postObj.AdditionalServiceHours
            }
            postExhibit.push(post);       
        });
       }
       const contractAccount = {
        AccountCode: financialCodeData.AccountCode,
        AccSequenceNumber: financialCodeData.AccSequenceNumber,
        AccObligatedAmount: financialCodeData.AccObligatedAmount,
        TreasuryAccSymbol: financialCodeData.TreasuryAccSymbol,
        CageCode: financialCodeData.CageCode
      }
        const TaskOrder = {
            Number: taskOrderData.Number,
            StartDate: convert(taskOrderData.StartDate),
            EndDate: convert(taskOrderData.EndDate),
            AwardDate: convert(taskOrderData.AwardDate),
            VendorName: taskOrderData.VendorName,
            LineNumber: taskOrderData.LineNumber,
            ProductCodeId: taskOrderData.ProductCodeId,
            ProductDescriptionId: taskOrderData.ProductDescriptionId ,
            RegionId: taskOrderData.RegionId,
            FinancialAccount: contractAccount,
            PostExhibit: postExhibit
        };
        
        // TODO: Must add award date for top-level contract, overarching award date (not for each task order.)
        const postData = {
            Number: contractNumber,
            StartDate: convert(periodOfPerformanceStartDate),
            EndDate: convert(periodOfPerformanceEndDate),
            AwardDate: convert(awardDateContract),
            ContractRepId : ContractRepId ,
            RepresentativeOfficer : selectedContractRepOfficer[0] ,
            TaskOrders: [TaskOrder] ,
            CreatedBy : user.email           
        }
        console.log("posting this object", postData);
        const result = await PutPostPatchFetch('/v1/Contract/AddContract', 'POST', postData);

        const { Success, Errors } = result;
        if (Success) {
            showMessage("Success! You've added a new contract record.", true);
            setSuccessAndClose(true);
        }
        else {
            showMessage("failure inserting record.", true);
        }

    } 
   const numberCheck = (input)=>{
      let val = input !== null && input !== "" && !isNaN(input) ;
      return val;
        
   }
    const onSavng = (e)=>{
        if( e.changes && e.changes.length > 0)
        {
            var dt = e.changes[e.changes.length - 1];
            if(dt.type === 'insert' && dt.data.StartDate && dt.data.EndDate && dt.data.EndDate < dt.data.StartDate)
            {
                e.cancel = true;
            }
          
        }
                     
       
    }
    const setRepOfficer = ()=>{
        let selected = ContractRepId > 0 ? contRepOfficer.map(x=>x.Id === ContractRepId): null ;
        return setSelectedContractRepOfficer(selected);
        }
   const setSelectedRepOfficer = (e)=>{
   let selected = e.selectedItem  ? contRepOfficer.filter(x=>x.Id === e.selectedItem.Id): null ;
   return setSelectedContractRepOfficer(selected);
   }
   const setSelectedOnValueChange = (e)=>{
    setContractRepId(e);
    let selected =  contRepOfficer.filter(x=>x.Id === e) ;
    if(selected)
    {
     setSelectedContractRepOfficer(selected);
    }
   }
   const validateTOEndDate = (e)=>{
   var toEndDt = new Date(e.value);
   var st = new Date(taskOrderData.StartDate);
   var award = new Date(taskOrderData.AwardDate)
   var valid = toEndDt > st && toEndDt > award ;
   return valid ;
   }
   const validateTOStartDate = (e)=>{
    var toStartDt = new Date(e.value) ;       
    var valid =  toStartDt >= periodOfPerformanceStartDate && toStartDt < periodOfPerformanceEndDate;
    return valid ;
  }
 const validateTOAwardDate = (e)=>{
    var toAwardDate = new Date(e.value);
    var st = new Date(taskOrderData.StartDate);
    var end = new Date(taskOrderData.EndDate)
    var valid = toAwardDate <= st && toAwardDate < end ;
    return valid ;
 }

    const validateTODates = ()=>{        
        var endDt = taskOrderData.EndDate ;
        var startDt = taskOrderData.StartDate ;
        
        var strEndDt = "";
        var strStDt = "";
        var poStartdt = "";
        var poEnddt = "";
        var toAwardDt = "";
        if(periodOfPerformanceStartDate)
        poStartdt = convert(periodOfPerformanceStartDate);
        if(periodOfPerformanceEndDate)
        poEnddt = convert(periodOfPerformanceEndDate);
        if(taskOrderData.AwardDate)
        toAwardDt = convert(taskOrderData.AwardDate);
        if(endDt && endDt != null)
           strEndDt = convert(endDt);
        if(startDt && startDt != null)
          strStDt = convert(taskOrderData.StartDate);
        if(strStDt != "" && strEndDt != "" && poStartdt != "" && poEnddt != "" && toAwardDt)
        {
        var to1 = new Date(strStDt);
        var to2 = new Date(strEndDt);
        var toAwd = new Date(toAwardDt);
        var podt1 = new Date(poStartdt);
        var podt2 = new Date(poEnddt);
 
        var valid =  to1.getTime() < to2.getTime() && 
                    toAwd.getTime() <= to1.getTime() &&
                    toAwd.getTime() < to2.getTime() &&
                    (to1.getTime() >= podt1.getTime() && 
                    to1.getTime()<= podt2.getTime()) ;
    
        return valid;
      
    }
    return false;
}
  const validateContractAwardDate = (e)=>{
    return e.value <= periodOfPerformanceStartDate && e.value < periodOfPerformanceEndDate ;
  }
    const validPPStartDate = (e)=>{
                
    return periodOfPerformanceEndDate != null && e.value < periodOfPerformanceEndDate && e.value >= awardDateContract ;
    }
    const validPPEndDate = (e)=>{
    return periodOfPerformanceStartDate != null && periodOfPerformanceStartDate < e.value && e.value > awardDateContract  ;
    }
   
    const renderPostExhibit = () => {
        if (buildings.length <= 0) {
            return (<>Please specify a region to be able to create a Post Exhibit.</>);
        }       
        return (
            <>               
                <strong>Post Exhibit</strong>
                <DataGrid
                    dataSource={posts}
                    keyExpr="PostId"
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    allowColumnReordering={true}
                    onCellPrepared={CustomPreparedHeaderCell}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    noDataText="Please Add Posts to Post Exhibit"
                    onRowUpdated={() => setPosts(this)}
                    onSaving={onSavng}
                >
                    <Column dataType="string" dataField="CLIN">
                        <RequiredRule />
                    </Column>
                    <Column dataType="number" dataField="HourlyRate">
                        <RequiredRule />
                    </Column>
                    <Column dataType="date" dataField="StartDate" >
                        <RequiredRule />
                       
                    </Column>
                    <Column dataType="date" dataField="EndDate">
                        <RequiredRule />
                    </Column>
                    <Column dataType="number" dataField="AdditionalServiceHours">
                        <RequiredRule />
                    </Column>
                    <Column dataType="string" dataField="Building">
                        <RequiredRule />
                        <Lookup
                            dataSource={buildings}
                            displayExpr="SequenceNumber"
                            valueExpr="BuildingId"
                        />
                    </Column>
                    <Column dataType="string" dataField="FeeType">
                        <RequiredRule />
                        <Lookup
                            dataSource={feeTypes}
                            displayExpr="FeeTypeName"
                            valueExpr="FeeTypeId"
                        />
                    </Column>
                    <Column dataType="string" dataField="PostLocation">
                        <RequiredRule />
                        <Lookup
                            dataSource={postLocations}
                            displayExpr="Name"
                            valueExpr="PostLocationId"
                        />
                    </Column>
                    <Column dataType="string" dataField="PostStatus">
                         <RequiredRule /> 
                        <Lookup
                            dataSource={postStatuses}
                            displayExpr="Name"
                            valueExpr="PostStatusId"
                        />
                    </Column>
                    <Column dataType="string" dataField="PrimaryPostType">
                        <RequiredRule />
                        <Lookup
                            dataSource={postTypes}
                            displayExpr="Name"
                            valueExpr="PostTypeId"
                        />
                    </Column>
                    <Column dataType="string" dataField="SecondaryPostType">
                        <RequiredRule />
                        <Lookup
                            dataSource={postTypes}
                            displayExpr="Name"
                            valueExpr="PostTypeId"
                        />
                    </Column>
                    <Column dataType="string" dataField="Screener">
                        <RequiredRule />
                        <Lookup
                            dataSource={screeners}
                            displayExpr="Name"
                            valueExpr="ScreenerId"
                        />
                    </Column>
                    <Column dataType="string" dataField="SecurityLevel">
                        <RequiredRule />
                        <Lookup
                            dataSource={securityLevels}
                            displayExpr="Name"
                            valueExpr="SecurityLevelId"
                        />
                    </Column>
                    <Editing
                        mode = "popup"
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding = {true}
                        onChangesChange = {updatePostExhibitEffect}
                    />
                    <Toolbar>
                        <DataGridItem name="groupPanel" />
                      
                        <DataGridItem name="addRowButton" showText="always" />
                        <DataGridItem name="exportButton" />
                        <DataGridItem name="columnChooserButton" />
                        <DataGridItem name="searchPanel" />
                    </Toolbar>
                </DataGrid>
            </>
        );
    }
    const renderBaseTaskOrder = () => {
        // must have contractNumber - we check in the rendering
        return (
            <div key={taskOrderData.id}>
                <hr />
                <div style={{marginBottom:'10px'}} ><strong>TASK ORDER: BASE</strong></div>
                <Box
                    direction="row"
                    height={100}
                    width="75%"
                >
                    <BoxItem ratio={2}>
                        <TextBox
                            label='Task Order Number *'
                            labelMode='static'
                            value={taskOrderData.Number}
                            maxLength={17}
                            height={56}
                            width={400}
                            id="task_order_number_034"
                            valueChangeEvent='input'
                            onValueChange={(value) => {
                                const b = value.replace(/[^a-z0-9]/gi, '');
                                const inputVal = b.toLocaleUpperCase();
                                const objectCopy = JSON.parse(JSON.stringify(taskOrderData));
                                objectCopy.Number = inputVal;
                                setTaskOrderData(objectCopy);
                            }}
                        >
                             <Validator
                                 onInitialized={(e) => e.component.validate()}
                                 onOptionChanged={(e) => e.component.validate()}
                             >
                                <ValidatorReqRule type="required" />
                            </Validator>
                        </TextBox>
                    </BoxItem>
                    <BoxItem ratio={1}>
                        <DateBox
                            label='T.O. Award Date *'
                            labelMode="static"
                            type="date"
                            height={56}
                            width={200}
                            min={minDate}
                            value={taskOrderData.AwardDate}
                            valueChangeEvent='change'
                            id="to_contract_award_date_039"
                            onFocusIn={e => showPicker(e)}
                            onValueChange={(value) => {
                                let objectCopy = JSON.parse(JSON.stringify(taskOrderData));
                                objectCopy.AwardDate = value;
                                setTaskOrderData(objectCopy);
                            }}
                        >
                              <Validator
                                 onInitialized={(e) => e.component.validate()}
                                 onOptionChanged={(e) => e.component.validate()}
                             >
                                <ValidatorReqRule type="required" />

                                <CustomRule
                                    message="T.O. Award date is required and should come before Start & end dates."
                                    validationCallback={validateTOAwardDate}
                                    reevaluate={true}
                                />
                            </Validator>
                        </DateBox>
                    </BoxItem>
              <BoxItem ratio={1}>
                        <DateBox
                            label='T.O. Start Date *'
                            labelMode='static'
                            type="date"
                            height={56}
                            width={200}
                            min={minDate}
                            value={taskOrderData.StartDate}
                            id="to_start_date_035"
                            valueChangeEvent='change'
                            onFocusIn={e => showPicker(e)}
                            onValueChange={(value) => {                                
                                let objectCopy = JSON.parse(JSON.stringify(taskOrderData));
                                objectCopy.StartDate = value;
                                setTaskOrderData(objectCopy);
                            }}
                        >
                              <Validator
                                 onInitialized={(e) => e.component.validate()}
                                 onOptionChanged={(e) => e.component.validate()}
                             >
                                <ValidatorReqRule type="required" />
                                <CustomRule
                                     message="Start date is required and should come between Period Of Performance dates."
                                     validationCallback={validateTOStartDate}
                                     reevaluate={true}
                                 /> 
                            </Validator>
                        </DateBox>
                    </BoxItem>
                    <BoxItem ratio={1}>
                        <DateBox
                            label='T.O. End Date *'
                            labelMode="static"
                            type="date"
                            height={56}
                            width={200}
                            min={minDate}
                            value={taskOrderData.EndDate}
                            id="to_end_date_036"
                            valueChangeEvent='change'
                            onFocusIn={e => showPicker(e)}
                            onValueChange={(value) => {
                                let objectCopy = JSON.parse(JSON.stringify(taskOrderData));
                                objectCopy.EndDate = value;
                                setTaskOrderData(objectCopy);
                            }}
                        >
                             <Validator
                               onInitialized={(e) => e.component.validate()}
                              onOptionChanged={(e) => e.component.validate()}
                             >
                                <ValidatorReqRule type="required" />
                                <CustomRule
                                     message="End date is required and should come after start date."
                                     validationCallback={validateTOEndDate}
                                     reevaluate={true}
                                 /> 
                            </Validator>
                        </DateBox>
                    </BoxItem>
              </Box>
                <Box
                    direction="row"
                    height={100}
                    width="75%">
                        <BoxItem ratio={1}>
                <NumberBox
                    label='Region *'
                    labelMode='static'
                    value={taskOrderData.RegionId}
                    min={1}
                    max={11}
                    height={56}
                    width={150}
                    showSpinButtons={true}
                    valueChangeEvent='input'
                    id="contract_region_038"
                    onValueChange={(value) => {
                        let objectCopy = JSON.parse(JSON.stringify(taskOrderData));
                        objectCopy.RegionId = value;
                        setTaskOrderData(objectCopy);
                    }}
                >
                     <Validator
                         onInitialized={(e) => e.component.validate()}
                         onOptionChanged={(e) => e.component.validate()}
                     >
                        <ValidatorReqRule type="required" />
                    </Validator>
                </NumberBox>
            </BoxItem>
                    <BoxItem ratio={1}>
                        <TextBox
                            label='Vendor Name'
                            labelMode='static'
                            value={taskOrderData.VendorName}
                            height={56}
                            width={250}
                            id="vendor_name_036"
                            valueChangeEvent='input'                            
                            onValueChange={(value) => {
                                let objectCopy = JSON.parse(JSON.stringify(taskOrderData));
                                objectCopy.VendorName = value;
                                setTaskOrderData(objectCopy);
                            }}
                        >
                        </TextBox>
                    </BoxItem>
                    <BoxItem ratio={1}>
                        <TextBox
                            label='Contract Line-Item Number'
                            labelMode='static'
                            value={taskOrderData.LineNumber}
                            height={56}
                            width={250}
                            id="contract_line_item_number_037"
                            onValueChange={(value) => {
                                let objectCopy = JSON.parse(JSON.stringify(taskOrderData));
                                objectCopy.LineNumber = value;
                                setTaskOrderData(objectCopy);
                            }}
                        >
                        </TextBox>
                    </BoxItem>
                    <BoxItem ratio={1}>
                        <ItemProductDropdowns value={taskOrderData} setter={setTaskOrderData} index={taskOrderData.id - 1} productData={productInfomation} />
                    </BoxItem>
                </Box>
            </div>
        );
    }
    const renderFinancialCode = ()=>{
        return (
            <div key={financialCodeData.FinancialCodeId}>
                <div style={{marginBottom:'10px'}} ><strong>Financial Codes</strong></div>
                <Box
                    direction="row"
                    height={100}
                    width="75%"
                >
                    <BoxItem ratio={1}>
                    <TextBox
                            label='Sequence Code *'
                            labelMode='static'
                            value={financialCodeData.AccSequenceNumber}
                            height={56}
                            width={200}
                            id="sequence_number_01"
                            valueChangeEvent='input'                            
                            onValueChange={(value) => {
                                let objectCopy = JSON.parse(JSON.stringify(financialCodeData));
                                objectCopy.AccSequenceNumber = value;
                                setFinancialCodeData(objectCopy);
                            }}
                        >                         
                           
                            <Validator
                                 onInitialized={(e) => e.component.validate()}
                                 onOptionChanged={(e) => e.component.validate()}
                             >
                            <ValidatorReqRule type="required" />
                            </Validator>
                        </TextBox>
                        </BoxItem>
                        <BoxItem ratio={1}>
                        <TextBox
                            label='Account Code *'
                            labelMode='static'
                            value={financialCodeData.AccountCode}
                            height={56}
                            width={200}
                            id="account_code_01"
                            valueChangeEvent='input'                            
                            onValueChange={(value) => {
                                let objectCopy = JSON.parse(JSON.stringify(financialCodeData));
                                objectCopy.AccountCode = value;
                                setFinancialCodeData(objectCopy);
                            }}
                        >
                              <Validator
                                 onInitialized={(e) => e.component.validate()}
                                 onOptionChanged={(e) => e.component.validate()}
                             >
                                <ValidatorReqRule type="required" />
                            </Validator>
                        </TextBox>
                        </BoxItem>
                        <BoxItem ratio={1}>
                        <TextBox
                            label='Obligated Amount *'
                            labelMode='static'
                            value={financialCodeData.AccObligatedAmount}
                            height={56}
                            width={200}
                            id="account_amount_01"
                            valueChangeEvent='input'                            
                            onValueChange={(value) => {
                                let objectCopy = JSON.parse(JSON.stringify(financialCodeData));
                                objectCopy.AccObligatedAmount = value;
                                setFinancialCodeData(objectCopy);
                            }}
                        >
                              <Validator
                                 onInitialized={(e) => e.component.validate()}
                                 onOptionChanged={(e) => e.component.validate()}
                             >
                                {/* <ValidatorReqRule type="required" /> */}
                                <CustomRule
                                    message='Amount should be a number'
                                    validationCallback={()=> numberCheck(financialCodeData.AccObligatedAmount)}
                                    reevaluate={true}
                                />
                            </Validator>
                        </TextBox>
                        </BoxItem> <BoxItem ratio={1}>
                        <TextBox
                            label='Cage Code *'
                            labelMode='static'
                            value={financialCodeData.CageCode}
                            height={56}
                            width={200}
                            id="cage_code_01"
                            valueChangeEvent='input'                            
                            onValueChange={(value) => {
                                let objectCopy = JSON.parse(JSON.stringify(financialCodeData));
                                objectCopy.CageCode = value;
                                setFinancialCodeData(objectCopy);
                            }}
                        >
                              <Validator
                                 onInitialized={(e) => e.component.validate()}
                                 onOptionChanged={(e) => e.component.validate()}
                             >
                                <ValidatorReqRule type="required" />
                            </Validator>
                        </TextBox>
                        </BoxItem>
                        <BoxItem ratio={1}>
                        <TextBox
                            label='Treasury Symbol *'
                            labelMode='static'
                            value={financialCodeData.TreasuryAccSymbol}
                            height={56}
                            width={200}
                            id="cage_code_01"
                            valueChangeEvent='input'                            
                            onValueChange={(value) => {
                                let objectCopy = JSON.parse(JSON.stringify(financialCodeData));
                                objectCopy.TreasuryAccSymbol = value;
                                setFinancialCodeData(objectCopy);
                            }}
                        >                         
                           
                            <Validator
                                 onInitialized={(e) => e.component.validate()}
                                 onOptionChanged={(e) => e.component.validate()}
                             >
                            <ValidatorReqRule type="required" />
                            </Validator>
                        </TextBox>
                        </BoxItem>
                 </Box>
             </div>                    
            )
    }
    const rendecContractRepresentative =(type)=>{
        return (
            <div key={ContractRepId}>
                <Box
                    direction="row"
                    height={100}
                    width="75%">
                    <BoxItem shrink={1} ratio={2}>
                         <SelectBox
                             label= 'Contracting Officer *'
                             labelMode='static'
                             value={ContractRepId}
                             min={1}
                             max={11}
                             height={56}
                             width={350}
                           valueExpr= "Id"
                           displayExpr="OfficerFullName"
                           dataSource={contRepOfficer}   
                           onInitialized={setRepOfficer}   
                           onSelectionChanged={setSelectedRepOfficer}             
                           onValueChange={(value) => {
                                setSelectedOnValueChange(value);
                                }}>
                            <Validator
                                  onInitialized={(e) => e.component.validate()}
                                  onOptionChanged={(e) => e.component.validate()}>
                                <ValidatorReqRule type="required" />
                            </Validator>
                         </SelectBox>
                     </BoxItem>
                     <BoxItem shrink={1} ratio={2}>
                         <SelectBox
                             label= 'Contracting Officer Representative *'
                             labelMode='static'
                             value={ContractRepId}
                             min={1}
                             max={11}
                             height={56}
                             width={350}
                           valueExpr= "Id"
                           displayExpr="OfficerFullName"
                           dataSource={contRepOfficer}   
                           onInitialized={setRepOfficer}   
                           onSelectionChanged={setSelectedRepOfficer}             
                           onValueChange={(value) => {
                                setSelectedOnValueChange(value);
                                }}>
                            <Validator
                                  onInitialized={(e) => e.component.validate()}
                                  onOptionChanged={(e) => e.component.validate()}>
                                <ValidatorReqRule type="required" />
                            </Validator>
                         </SelectBox>
                     </BoxItem>
                     <BoxItem shrink={1} ratio={2}>
                         <SelectBox
                             label= 'Alternate Contracting Officer Representative *'
                             labelMode='static'
                             value={ContractRepId}
                             min={1}
                             max={11}
                             height={56}
                             width={350}
                           valueExpr= "Id"
                           displayExpr="OfficerFullName"
                           dataSource={contRepOfficer}   
                           onInitialized={setRepOfficer}   
                           onSelectionChanged={setSelectedRepOfficer}             
                           onValueChange={(value) => {
                                setSelectedOnValueChange(value);
                                }}>
                            <Validator
                                  onInitialized={(e) => e.component.validate()}
                                  onOptionChanged={(e) => e.component.validate()}>
                                <ValidatorReqRule type="required" />
                            </Validator>
                         </SelectBox>
                     </BoxItem>
             </Box>
             
             <div>
            { selectedContractRepOfficer && selectedContractRepOfficer.length > 0 &&            
            <DataGrid
                    dataSource={selectedContractRepOfficer}
                    showBorders={true}
                    columnAutoWidth={true}                    
                    key="Id"
                >
                    <Column dataField="OfficerFirstName" caption="Officer First Name" />
                    <Column dataField="OfficerLastName" caption="Officer Last Name" />
                    <Column dataField="OfficerEmail" caption="Officer Email" />
                    <Column dataField="OfficerPhone" caption="Officer Phone" />                  
                    </DataGrid>               
            
            }
            </div>
            </div>
        )
    }
    return (
        <div id="container">
            <h1>{ROUTE_CREATE_A_NEW_CONTRACT_RECORD.title}</h1>
            <h3>Contract Information</h3>

            <Box
                className='contract-section'
                direction="row"
                height={100}
                width="75%">
                <BoxItem ratio={2}>
                    <TextBox
                        label='Contract Number *'
                        labelMode='static'
                        value={contractNumber}
                        height={56}
                        width={400}
                        maxLength={17}
                        valueChangeEvent='input'
                        id="contract_number_field_030"
                        onValueChange={(value) => {
                            const b = value.replace(/[^a-z0-9]/gi, '');
                            const inputVal = b.toLocaleUpperCase();
                            setContractNumber(inputVal);
                        }}>
                          <Validator
                                 onInitialized={(e) => e.component.validate()}
                                 onOptionChanged={(e) => e.component.validate()}
                             >
                            <ValidatorReqRule type='required' />
                            <CustomRule
                                message="Contract Number are 17 characters long."
                                validationCallback={() => IsValidContractNumber(contractNumber)}
                            />
                        </Validator>
                    </TextBox>
                </BoxItem>
                <BoxItem ratio={1}>
                    <DateBox
                        label='Award Date *'
                        labelMode='static'
                        type="date"
                        height={56}
                        width={200}
                        min={minDate}
                        value={awardDateContract}
                        id="award_date_field_033"
                        valueChangeEvent='change'
                        onFocusIn={e => showPicker(e)}
                        onValueChange={(value) => {
                            setAwardDateContract(value);
                        }}
                    >
                          <Validator
                                 onInitialized={(e) => e.component.validate()}
                                 onOptionChanged={(e) => e.component.validate()}
                          >
                            <ValidatorReqRule type="required" />
                                                      <CustomRule
                                message="Contract Award Date is required and should come before Start and End dates."
                                validationCallback={validateContractAwardDate}
                                reevaluate={true}
                            />
                        </Validator>

                    </DateBox>
                </BoxItem>
                {/* http://localhost:3000/Create-A-New-Contract-Record */}
                <BoxItem ratio={1}>
                    {/* <p>Period Of Performance Start Date *</p> */}
                    <DateBox
                        label='P.O.P. Start Date *'
                        labelMode="static"
                        type="date"
                        height={56}
                        width={200}
                        min={minDate}
                        value={periodOfPerformanceStartDate}
                        valueChangeEvent='change'
                        id="start_date_field_031"
                        onFocusIn={e => showPicker(e)}
                        onValueChange={(value) => {
                         setPeriodOfPerformanceStartDate(value);
                        }}
                    >
                         <Validator
                                 onInitialized={(e) => e.component.validate()}
                                 onOptionChanged={(e) => e.component.validate()}
                             >
                            <ValidatorReqRule type='required' />
                            <CustomRule
                                message="P.O.P. Start Date is required and should come before End date."
                                validationCallback={validPPStartDate}
                                reevaluate={true}
                            />
                        </Validator>
                    </DateBox>
                </BoxItem>
                <BoxItem ratio={1}>
                    {/* <p>Period Of Performance End Date *</p> */}
                    <DateBox
                        label='P.O.P. End Date *'
                        labelMode="static"
                        type="date"
                        height={56}
                        width={200}
                        min={minDate}
                        value={periodOfPerformanceEndDate}
                        valueChangeEvent='change'
                        id="end_date_field_032"
                        onFocusIn={e => showPicker(e)}
                        onValueChange={(value) => {
                            setPeriodOfPerformanceEndDate(value);
                        }}
                    >
                        <Validator
                                 onInitialized={(e) => e.component.validate()}
                                 onOptionChanged={(e) => e.component.validate()}
                        >
                            <ValidatorReqRule type='required' />
                            <CustomRule
                                message="P.O.P. End Date is required and should come after Start date."
                                validationCallback={validPPEndDate}
                                reevaluate={true}
                            />
                        </Validator>
                    </DateBox>
                </BoxItem>

            </Box>
            {rendecContractRepresentative()}
            {renderBaseTaskOrder()}
            {renderFinancialCode()}
            {isSubmittable ?
                <>
                    <DevExButton
                        onClick={handleSubmit}
                    >
                        Create
                    </DevExButton>
                    <DevExButton

                        onClick={() => {
                            navigate(ROUTE_CONTRACTS_PAGE.withSlash)
                        }}
                    >
                        Cancel
                    </DevExButton>
                </>
                :
                <>
                    <em>
                        Please enter in all required fields to submit. Required fields have an asterisk (*) next to their label.
                    </em>
                    <br />
                    <DevExButton
                        style={{ position: 'absolute', right: '50px', }}
                        width={110}
                        onClick={() => {
                            navigate(ROUTE_CONTRACTS_PAGE.withSlash)
                        }}
                    >
                        Go Back
                    </DevExButton>
                </>
            }
            <br /><br /><br />
            {renderPostExhibit()}
            
        </div>
    )
}
export default AddAContractPage;