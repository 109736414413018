import { useState, useEffect} from 'react';
import { Typography } from '@mui/material';
import CustomStore from "devextreme/data/custom_store";
import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Export,
    Column,
    FilterRow
} from 'devextreme-react/data-grid';
import { ROUTE_USER_REPORT } from '../../routes/Routes';
import SearchButtonGroupUser from './SearchButtonGroupUser';
import ExportGrid from '../../components/ExportGrid';
import { allowedPageSizes, pageFormats } from '../../components/GlobalDataGridConfigurations';
import { logExport, useAuth } from '../../hooks/useAuth';
import { FOUOfooter, FOUOheader } from '../../components/FOUO';
import CustomPreparedHeaderCell from '../../components/CustomPreparedHeaderCell';
import { sortRegions } from '../../util/_helpers';

const UserReport = () => {
    const [data, setData] = useState();
    const { user } = useAuth();
    const title = ROUTE_USER_REPORT.title

    useEffect(() => {
        document.title = title;
    }, []);

    const dataSource = new CustomStore({
        key: 'EDIPI',
        load: async () => {
            const { Message } = data;
            const checkedData = Message.map(
                (
                    {
                        EDIPI,
                        UserAccountName,
                        EmailAddress,
                        ContractStartDate,
                        ContractEndDate,
                        ContractorCompany,
                        CreatedBy,
                        CreatedDate,
                        EmployeeType,
                        JobTitle,
                        Regions,
                        SeparationDate,
                        UpdatedBy,
                        UpdatedDate,
                        UserStatus,
                        Role: { Name }
                    }
                ) => {
                    return {
                        EDIPI: EDIPI ?? 'N/A',
                        userAccountName: UserAccountName ?? 'N/A',
                        emailAddress: EmailAddress || 'N/A',
                        contractStartDate: ContractStartDate ?? 'N/A',
                        contractEndDate: ContractEndDate ?? 'N/A',
                        contractorCompany: ContractorCompany || 'N/A',
                        createdBy: CreatedBy ?? 'N/A',
                        createdDate: CreatedDate ?? 'N/A',
                        employeeType: EmployeeType || 'N/A',
                        jobTitle: JobTitle || 'N/A',
                        regions: sortRegions(Regions) ?? 'N/A',
                        separationDate: SeparationDate ?? 'N/A',
                        updatedBy: UpdatedBy ?? 'N/A',
                        updatedDate: UpdatedDate ?? 'N/A',
                        userStatus: UserStatus ?? 'N/A',
                        roleName: Name ?? 'N/A',
                    };
                }
            );

            return checkedData;
        },
    });
    const pdfFont = ()=>
    {    return 8;   }
    const wrapPdfCell = ()=>{
     return true;   }
    const handleExport = (e) => {
        logExport(user.email)
        ExportGrid(e, "User_Report_generated_at", wrapPdfCell() , pdfFont())
    }
    
    return (
        <div>
            <Typography component="div">
                <h1 style={{ color: "#06152B" }}>{title}</h1>
            </Typography>
            <SearchButtonGroupUser setter={setData} />
            <br />
            { 
                data &&
                <>
                    <FOUOheader 
                        dateString={new Date().toString()}
                        fiscalYearsBoolean={false}
                        heading={"User"}
                        userEmail={user.email}
                    />
                        <DataGrid
                            columnAutoWidth={true}
                            dataSource={dataSource}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            rowAlternationEnabled={true}
                            onCellPrepared={CustomPreparedHeaderCell}
                            onExporting={e => handleExport(e) } 
                        >
                            {/* <FilterRow visible={true}/> */}
                            <KeyboardNavigation enabled={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} />
                            <Paging defaultPageSize={10} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={allowedPageSizes}
                            />
                            <Column dataField="regions" caption="Regions" />
                            <Column dataField="userAccountName" caption="User Account Name" />
                            <Column dataField="emailAddress" caption="Email Address" />
                            <Column dataField="contractStartDate" caption="Contract Start Date" />
                            <Column dataField="contractEndDate" caption="Contract End Date" />
                            <Column dataField="contractorCompany" caption="Contractor Company" />
                            <Column dataField="createdBy" caption="Created By" />
                            <Column dataField="createdDate" caption="Created Date" />
                            <Column dataField="employeeType" caption="Employee Type" />
                            <Column dataField="jobTitle" caption="Job Title" />
                            <Column dataField="separationDate" caption="Separation Date" />
                            <Column dataField="updatedBy" caption="Updated By" />
                            <Column dataField="updatedDate" caption="Updated Date" />
                            <Column dataField="userStatus" caption="User Status" />
                            <Column dataField="roleName" caption="Role Name" />
                            <Export 
                                enabled={true}
                                formats={pageFormats}
                            />
                        </DataGrid>
                    <FOUOfooter />
                </>
            }
        </div >
    );
};

export default UserReport;