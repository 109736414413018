import {
    useEffect,
    useState,
    useMemo,
    useCallback
} from "react";
import { ROUTE_SPECIFIC_COST_ESTIMATE_SUMMARY } from "../routes/Routes";
import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';
import { Button } from 'devextreme-react/button';
import './SpecificCostEstimate.scss'
import { KeyboardNavigation } from 'devextreme-react/data-grid';
import FiscalYearSelectDropdown from './FiscalYearSelectDropdown';
import IsNullOrEmpty from '../components/IsNullOrEmpty';
import DataGridSpecificCostEstimateSummary from "./DataGridSpecificCostEstimateSummary";
import GetFetch from '../hooks/GetFetch';

export default function SpecificCostEstimateSummary() {
    const pageTitle = ROUTE_SPECIFIC_COST_ESTIMATE_SUMMARY.title;
    const [selectedFiscalYear, setSelectedFiscalYear] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [summaryCostsTabData, setSummaryCostsTabData] = useState({
        0: null, // Current Year == Fiscal Year
        1: null, // Budget Year == Fiscal Year +1 
        2: null, // Budget Year+1 == Fiscal Year +2
        3: null, // Budget Year+2 == Fiscal Year +3
        4: null  // Budget Year+3 == Fiscal Year +4
    });
    const [isTabDataReset, setIsDataReset] = useState(false);

    const fetchSummaryCostsByFiscalYear = useCallback(async (tabIndex) => {
        if (!selectedFiscalYear || summaryCostsTabData[tabIndex]) return;
        setLoading(true);
        const fiscalYear = selectedFiscalYear + tabIndex;
        const summaries = await GetFetch(`/v1/SummaryCostEstimate/${fiscalYear}/1,2,3,4,5,6,7,8,9,10,11`);  //passing in all regions
        const { Success, Message, Errors } = summaries;
        if (Success) {
            setSummaryCostsTabData(prevData => ({
                ...prevData,
                [tabIndex]: Message
            }));
        }
        else {
            setError(true);
            if (Errors[0].Message === "No records found at the moment") {
                console.log(Errors[0].Message);
            }
        }
        setLoading(false);
    }, [selectedFiscalYear, summaryCostsTabData]);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setSelectedTabIndex(0);
            setSummaryCostsTabData({ 0: null, 1: null, 2: null, 3: null, 4: null });
            setIsDataReset(true);
        }
    }

    const handleSubmit = async () => {
        setSelectedTabIndex(0);
        setSummaryCostsTabData({ 0: null, 1: null, 2: null, 3: null, 4: null });
        setIsDataReset(true);
    }

    useEffect(()=> {
        if (isTabDataReset) {
            fetchSummaryCostsByFiscalYear(0);
            setIsDataReset(false);
        }
    }, [isTabDataReset]);

    const onTabSelectionChanged = useCallback((e) => {
        const newTabIndex = e.component.option('selectedIndex');
        setSelectedTabIndex(newTabIndex);
        fetchSummaryCostsByFiscalYear(newTabIndex);
    }, [fetchSummaryCostsByFiscalYear]);

    return (
        <div>
            <h1 style={{ color: "#06152B" }}>{pageTitle}</h1>
            <em style={{ color: "#06152B" }}>
                Please select a current year
            </em>
            <div className="inline__div--wrapper"
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: '100%'
                }}
            >
                <FiscalYearSelectDropdown
                    inputAttribute={{ "aria-label": "Current Year *" }}
                    enableSearch={true}
                    label={"Current Year"}
                    onEnterKeyDown={handleKeyDown}
                    value={selectedFiscalYear}
                    optionUpdatedByUser={({ selectedItem }) => setSelectedFiscalYear(selectedItem)}
                    isVisible={true}
                />
                <Button
                    id="cy-submit-button"
                    text="Search"
                    type="default"
                    stylingMode="contained"
                    icon="search"
                    onClick={handleSubmit}
                    disabled={IsNullOrEmpty(selectedFiscalYear)}
                    style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                    }}                    
                />

            </div>
            <div className='inline_div--tabPanel-wrapper'>
                <TabPanel id="tabPanel" onSelectionChanged={onTabSelectionChanged} selectedIndex={selectedTabIndex}>
                    <KeyboardNavigation enabled={true} />
                    <TabPanelItem title={`Current Year (${selectedFiscalYear})`} id="cy-cost-est" aria-label="cy-cost-est">
                        <DataGridSpecificCostEstimateSummary summaryCosts={summaryCostsTabData[0]} fiscalYear={selectedFiscalYear} />
                    </TabPanelItem>
                    <TabPanelItem title={`Budget Year (${selectedFiscalYear + 1})`} id="budget-year-cost-est" aria-label="budget-year-cost-est">
                        <DataGridSpecificCostEstimateSummary summaryCosts={summaryCostsTabData[1]} fiscalYear={selectedFiscalYear + 1} />
                    </TabPanelItem>
                    <TabPanelItem title={`Budget Year+1 (${selectedFiscalYear + 2})`} id="budget-year-cost-est+1" aria-label="budget-year-cost-est+1">
                        <DataGridSpecificCostEstimateSummary summaryCosts={summaryCostsTabData[2]} fiscalYear={selectedFiscalYear + 2} />
                    </TabPanelItem>
                    <TabPanelItem title={`Budget Year+3 (${selectedFiscalYear + 3})`} id="budget-year-cost-est+2" aria-label="budget-year-cost-est+2">
                        <DataGridSpecificCostEstimateSummary summaryCosts={summaryCostsTabData[3]} fiscalYear={selectedFiscalYear + 3} />
                    </TabPanelItem>
                    <TabPanelItem title={`Budget Year+3 (${selectedFiscalYear + 4})`} id="budget-year-cost-est+3" aria-label="budget-year-cost-est+3">
                        <DataGridSpecificCostEstimateSummary summaryCosts={summaryCostsTabData[4]} fiscalYear={selectedFiscalYear + 4} />
                    </TabPanelItem>
                </TabPanel>
            </div>
        </div>
    );
}