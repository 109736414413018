import { useState, useEffect } from "react";
import SessionContext from "../App/SessionContext";


export const useAutoTimeout = (startTime) => {
    const [timer, setTimer] = useState(startTime);
    useEffect(() => {
        const myInterval = setInterval(() => {
            if (SessionContext.get("logoutTime") && (new Date()).getTime() > SessionContext.get("logoutTime")) {
                setTimer(0);
            }
            else if (timer > 0) {
                setTimer(timer - 1);
            }
        }, 1000);
        const resetTimeout = () => {
            SessionContext.set("logoutTime", (new Date()).getTime() + (1000 * (startTime)))

            setTimer(startTime);
        };
        const events = [
            "load",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];
        for (let i in events) {
            window.addEventListener(events[i], resetTimeout);
        }
        return () => {
            clearInterval(myInterval);
            for (let i in events) {
                window.removeEventListener(events[i], resetTimeout);
            }
        };
    });
    return timer
}
export default useAutoTimeout;