import { useState, useEffect } from 'react';
import {
    Box,
    TableContainer
} from '@mui/material';
import Paper from '@mui/material/Paper';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Selection,
    Column
} from 'devextreme-react/data-grid';
import CustomPreparedHeaderCell from '../components/CustomPreparedHeaderCell';
import { allowedPageSizes } from "../components/GlobalDataGridConfigurations";
import './css/Adjustments.scss';
import { ROUTE_BILLING_ADJUSTMENTS } from '../routes/Routes';
import currencyFormatter from '../components/CurrencyFormatter';
import BillingAdjustmentsTableModifiers from './BillingAdjustmentsTableModifiers';

const BillingAdjustmentsTable = ({ allowApprove, allowEditing, allowDeactivate, data, setter}) => {
    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const [selectedRow, setSelectedRow] = useState(false);


    const title = ROUTE_BILLING_ADJUSTMENTS.title;
    useEffect(() => {
        document.title = title;
    }, []);

    const dataSource = new CustomStore({
        key: 'AdjustmentId',
        update: (key, value) => { console.log(key); console.log(value) },
        load: async () => {
            let checkedData = []
            if (data)
                checkedData = data.map(
                    (
                        {
                            Id,
                            AdjustedAmount,
                            PSOAmount,
                            TCMAmount,
                            OccupancyAgreement: {
                                OANumber,
                                OccupancyAgreementId,
                                CustomerALC,
                                CustomerABCode,
                                CustomerAccountId,
                                BuildingNumber,
                                BuildingId,
                                BuildingRegion,
                                BuildingAnsiRent,
                                TotalRentSqFt
                            },
                            Reason,
                            CreatedBy,
                            CreatedDate,
                            UpdatedBy,
                            UpdatedDate,
                            BillPeriodStart,
                            BillPeriodEnd,
                            FeeTypeId,
                            FeeType: {
                                FeeTypeName
                            },
                            IsInactive,
                            ApprovalStatus
                        }
                    ) => {
                        return {
                            AdjustmentId: Id,
                            AdjustedAmount: currencyFormatter.format(AdjustedAmount),
                            PSOAmount: currencyFormatter.format(PSOAmount),
                            TCMAmount: currencyFormatter.format(TCMAmount),
                            OANumber: OANumber,
                            OccupancyAgreementId: OccupancyAgreementId,
                            CustomerALC: CustomerALC,
                            CustomerABCode: CustomerABCode,
                            CustomerId: CustomerAccountId,
                            BuildingNumber: BuildingNumber,
                            BuildingId: BuildingId,
                            BuildingRegion: BuildingRegion,
                            Reason: Reason,
                            CreatedBy: CreatedBy,
                            CreatedDate: CreatedDate,
                            UpdatedBy: UpdatedBy,
                            UpdatedDate: UpdatedDate,
                            EffectiveDate: formattedDate(BillPeriodStart),
                            ExpirationDate: formattedDate(BillPeriodEnd),
                            FeeTypeId: FeeTypeId,
                            FeeTypeName: FeeTypeName,
                            IsInactive: IsInactive,
                            ApprovalStatus: ApprovalStatus ?? "Unknown",
                            BuildingRent: BuildingAnsiRent,
                            TotalRentSqFt: TotalRentSqFt,
                            InitIsInactive: IsInactive,
                            InitApprovalStatus: ApprovalStatus ?? "Unknown",
                            unformattedStartDate: BillPeriodStart,
                            unformattedEndDate: BillPeriodEnd
                        };
                    }
                );

            return checkedData;
        }
    });


    const formattedDate = (dateString) => {
        let date = new Date(dateString);
        return (`${date.getMonth() + 2}-${date.getFullYear()}`)
    }

    const onContentReady = async (e) => {
        e.component.getCombinedFilter();
    }

    const handleFocusedRowChange = (e) => {
        const { data } = e.row;
        setSelectedRowKey(data);
    };

    const handleEnterKeyPress = (e) => {
        const key = e.event?.originalEvent?.key;

        if (key === "Enter") {
            handleRowClick()
        };
    };

    const handleRowClick = () => {
        setSelectedRow(true);
    };

    const approvalChanged = (value) => {
        var modData = data.map(adj => {
            if (adj.Id == selectedRowKey.AdjustmentId) adj.ApprovalStatus = value
            return adj
        })
        setter(modData)
    }

    const activationChanged = (value) => {
        var modData = data.map(adj => {
            if (adj.Id == selectedRowKey.AdjustmentId) {
                adj.IsInactive = (value == "Deactivate");
                adj.ApprovalStatus = "Submitted";
            }
            return adj
        })
        setter(modData)
    }

    return (
        <div>
            {data &&
                <>
                    <Box sx={{
                        height: 600
                        , width: '97%'
                        , margin: 'auto'
                    }}
                    >
                        <Paper
                            elevation={5}
                            sx={{ padding: '30px', marginTop: '7px' }}
                        >
                            <span>&nbsp;</span>
                            <BillingAdjustmentsTableModifiers
                                selectedRowKey={selectedRowKey}
                                roleAllowApprove={allowApprove}
                                roleAllowEdit={allowEditing}
                                roleAllowDeactivate={allowDeactivate}
                                rowSelected={selectedRow}
                                onApprovalChange={approvalChanged}
                                onActivationChange={activationChanged}
                            />
                            <TableContainer
                                id="audit-table-container"
                                component={Paper}
                                elevation={0}
                            >
                                <DataGrid
                                    columnAutoWidth={true}
                                    dataSource={dataSource}
                                    onCellPrepared={CustomPreparedHeaderCell}
                                    showBorders={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                    onContentReady={(onContentReady)}
                                    focusedRowEnabled={true}
                                    keyboardNavigation={{
                                        enabled: true,
                                        enterKeyAction: 'none',
                                        enterKeyDirection: 'none'
                                    }}
                                    onFocusedRowChanged={handleFocusedRowChange}
                                    onKeyDown={handleEnterKeyPress}
                                    onRowClick={handleRowClick}
                                >
                                    <Selection mode="single" />
                                    <Column dataField="IsInactive" caption="Deactivated" dataType="boolean" />
                                    <Column dataField="ApprovalStatus" caption="Approval Status" />
                                    <Column dataField="AdjustmentId" visible={false} />
                                    <Column dataField="OccupancyAgreementId" visible={false} />
                                    <Column dataField="BuildingRegion" caption="Region" />
                                    <Column dataField="OANumber" caption="Occupancy Agreement" />
                                    <Column dataField="CustomerABCode" caption="Agency Bureau Code" />
                                    <Column dataField="CustomerALC" caption="ALC" />
                                    <Column dataField="BuildingNumber" caption="Building Number" />
                                    <Column dataField="FeeTypeName" caption=" Fee Type" />
                                    <Column dataField="PSOAmount" caption="PSOAmount" />
                                    <Column dataField="TCMAmount" caption="TCM Amount" />
                                    <Column dataField="AdjustedAmount" caption="Adjusted Amount" />
                                    <Column dataField="Reason" caption="Reason" />
                                    <Column dataField="EffectiveDate" caption="Bill Period Start" />
                                    <Column dataField="ExpirationDate" caption="Bill Period End" />
                                    <Column dataField="unformattedStartDate" visible={false} />
                                    <Column dataField="unformattedEndDate" visible={false} />
                                    <KeyboardNavigation enabled={true} />
                                    <SearchPanel
                                        highlightCaseSensitive={true}
                                        visible={true}
                                    />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={allowedPageSizes}
                                    />
                                </DataGrid>
                            </TableContainer>
                        </Paper>
                    </Box>
                </>
            }
        </div>
    )
}


export default BillingAdjustmentsTable;