import { BaseSelectField } from "../../components/BaseSelectField/BaseSelectField";
import FiscalYearList  from "../../hooks/FiscalYear";
import IsNullOrEmpty from "../../components/IsNullOrEmpty";
import { Fragment } from "react";

const FiscalYearDropdown = (
    { 
        enableSearch,
        inputAttribute,
        label,
        onEnterKeyDown,
        optionUpdatedByUser,
        value,
        isVisible
    }
) => {
    const generateFiscalYears = () => {
        const years =  FiscalYearList() ;
        return years;
    };
 
    const fiscalYears = generateFiscalYears();
      
    return (
        <Fragment>
            <BaseSelectField 
                data={fiscalYears}
                enableSearch={enableSearch}
                inputAttribute={inputAttribute}
                label={label}
                onEnterKeyDown={onEnterKeyDown}
                onOptionChanged={(e) => e.component.validate()}
                optionUpdatedByUser={optionUpdatedByUser}
                validationRuleCallback={() => !IsNullOrEmpty(value)}
                value={value}
                isVisible={isVisible}
            />
        </Fragment>
    );
};

 export default FiscalYearDropdown;