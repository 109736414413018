import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const selectStylesTwo = {
  m: 1,
  width: "13rem",
  margin: "1rem",
};
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const filterOptionsList = [
  "Start Date",
  "End Date",
  "Usernames",
  "Last Login Date",
  "Roles",
  "Regions",
  "User Statuses",
  "Job Title",
  "Deactivation Date",
  "Reactivation Date",
  "Creation Date",
  "Termination Date",
  "Suspension Date",
];

const inputLabelStyles = { margin: "1rem 0 0 1rem" };

const SearchBtnGroupSelect = ({ value, setter }) => {
  const dropdownTitle = "Report filter options";

  return (
    <>
      {filterOptionsList && (
        <FormControl sx={{ mt: 0, mb: 1 }}>
          <InputLabel
            style={inputLabelStyles}
            id="select-multiple-checkbox-label"
            htmlFor="region-id"
          >
          </InputLabel>
            <Autocomplete
                    multiple
                    id="filterOpId"
                    style={selectStylesTwo}
                    options={filterOptionsList}
                    value={value}
                    onChange={(event, newValue) => {
                        setter(newValue);
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                        <TextField
                        aria-labelledby="select-multiple-checkbox-label"
                        aria-describedby="select-multiple-checkbox-description"
                        {...params}
                        variant="outlined"
                        label={dropdownTitle}
                        InputProps={{
                            ...params.InputProps,
                            inputProps: {
                            ...params.inputProps,
                            autoComplete: 'new-password',
                            'aria-labelledby': 'select-multiple-checkbox-label',
                            'aria-describedby': 'select-multiple-checkbox-description',
                            },
                        }}
                        
                        />
                    )}
                    >
                    {filterOptionsList.map((element) => (
                        <MenuItem key={element} value={element}>
                        <Checkbox checked={value?.indexOf(element) > -1} />
                        {element}
                        </MenuItem>
                    ))}
              </Autocomplete>
          <FormHelperText id="select-multiple-checkbox-description">
            {dropdownTitle}
          </FormHelperText>
        </FormControl>
      )}
    </>
  );
};

export default SearchBtnGroupSelect;
