import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { rmsVersion, rmsDeployment, rmsShowReactEnvVariables, rmsAuthMethod, RMSAUTHMETHOD_COGNITO } from "../util/reactAppEnvVariables";
import { ROUTE_LOGIN_COGNITO, ROUTE_LOGIN_OKTA } from "./Routes";
import dhsImage from '../assets/FPS_Logo_20_0925-500.jpg';
import ClosingAlert from "../components/ClosingAlert";

const RMS = () => {
    const [alertConfig, setAlertConfig] = useState({
        isVisible: false,
        type: 'info',
        message: '',
    })
    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "65%",
        border: "2px solid #808080",
        borderRadius: "20px",
        margin: "auto",
    };

    useEffect(() => {
        let currentUrl = window.location.search.substring(1)
        let queries = currentUrl.split('&')
        var alertSev; var message;
        var i;

        if (queries.length > 1) {
            for (i = 0; i < queries.length; i++) {
                let getParameter = queries[i].split('=');
                if (getParameter[0] === 'alertseverity' && getParameter[1] !== undefined) { alertSev = getParameter[1]; }
                else if (getParameter[0] === 'alertmessage' && getParameter[1] !== undefined) {

                    message = decodeURIComponent(getParameter[1]);
                }
            }
            showAlert(message, alertSev)
        }

    }
        , [])

    const showAlert = (message, type) => {
        setAlertConfig({
            isVisible: true,
            type: type,
            message: message
        });
    }
    const hideAlert = () => {
        setAlertConfig({
            isVisible: false,
            type: alertConfig.type
        });
    }


    const loginPage = (rmsAuthMethod === RMSAUTHMETHOD_COGNITO) ? ROUTE_LOGIN_COGNITO : ROUTE_LOGIN_OKTA;

    return (
        <div style={{marginTop:"30px"}}>
            <div id="error-page" style={containerStyle}>
                {rmsShowReactEnvVariables()}
                <img
                    src={dhsImage}
                    alt="logo"
                    style={{
                        maxWidth: '250px'
                        , minWidth: '50px'
                        , float: 'left'
                    }}
                />
                <h1 style={{ marginBottom: "20px", fontSize: "36px", textAlign: "center", color: "#06152B" }}>FPS Revenue Management System (RMS)</h1>
                <p style={{ fontSize: "18px", lineHeight: "1.6", textAlign: "center", color: "#06152B" }}>
                    You are attempting to access RMS but do not have the proper permissions to view/use this application.
                </p>
                <Link id="lnk_rms_login_001" to={loginPage.withSlash} style={{ textDecoration: "none", color: "#005288", fontSize: "20px", textAlign: "center" }}>
                    <i style={{ display: "block", marginTop: "20px", fontSize: "18px", textAlign: "center" }}>
                        If you would like to try and log in, please click here
                    </i>
                </Link>
                <h6 style={{ marginTop: "30px", fontSize: "16px", color: "#06152B" }}>RMS version: {rmsVersion}-{rmsDeployment}</h6>

            </div>
            <ClosingAlert
                severity={alertConfig.type} message={alertConfig.message} visible={alertConfig.isVisible} hideAlert={() => {hideAlert()}} />
        </div>
    );
};

export default RMS;
