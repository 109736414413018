const CustomPreparedHeaderCell = (
    {
        rowType, 
        cellElement: { style }
    }
) => {
    const black = '#000000';
    const medium = '600'

    if (rowType === "header") {
        style.cssText = `color: ${black}; font-weight: ${medium}`;
    }
};

export default CustomPreparedHeaderCell;