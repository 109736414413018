import { 
    useEffect,
    useRef, 
    useState 
} from 'react';
import { useNavigate } from 'react-router-dom';
import GetFetch from '../../hooks/GetFetch';
import { Endpoints } from '../../Enums/endpoints';
import { 
    ROUTE_WELCOME, 
    ROUTE_VIEW_SINGLE_PSO_CONTRACT,
    ROUTE_PSO_CONTRACT_SUMMARY_PAGE
} from '../../routes/Routes';
import US_StatesSelectField from '../../BuildingsPage/components/US_StatesSelectField';
import SingleRegionSelectField from '../../CustomerAccounts/components/SingleRegionSelectField/SingleRegionSelectField';
import BldgNumberDropDownDevEx from '../../Reports/CostEstimateReport/BldgNumberDropdownDevEx';
import { BaseButton } from '../../components/BaseButton/BaseButton';
import DataGrid, {
    Column,
    KeyboardNavigation,
    Paging,
    Pager,
    SearchPanel
} from 'devextreme-react/data-grid';
import CustomPreparedHeaderCell from '../../components/CustomPreparedHeaderCell';
import { BaseSelectField } from '../../components/BaseSelectField/BaseSelectField';
import usePageTitle from '../../hooks/usePageTitle';
import './PSO_ContractCostSummaryPage.scss';

const PSO_ContractCostSummaryPage = () => {
    const [summaries, setSummaries] = useState();
    const [focusedRowIndex, setFocusedRowIndex] = useState(-1);
    const [selectedRowData, setSelectedRowData] = useState();

    const [_buildingNumber, set_BuildingNumber] = useState();
    const [_region, set_Region] = useState();
    const [_state, set_State] = useState();
    const [_customerName, set_CustomerName] = useState();

    const [customers, setCustomers] = useState();
    
    const [viewRecordButton, setViewRecordButton] = useState(false);

    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const [viewSummaryContractNumberButtonLabel, setViewSummaryContractNumberButtonLabel] = useState(undefined);
    const [focusedRowKey, setFocusedRowKey] = useState(null)
    
    const title = ROUTE_PSO_CONTRACT_SUMMARY_PAGE.title;
    
    const navigate = useNavigate();
    const navigateToWelcomeRoute = () => navigate(ROUTE_WELCOME.withSlash);
    
    const { GET_CONTRACT_SUMMARIES, GET_CUSTOMERS } = Endpoints;
    const viewSingleTaskOrderLabel = "View Summary";
    
    const dataGridRef = useRef(null);
    
    usePageTitle(title);
    useEffect(() => { getCustomers() }, []);

    const getCustomers = async () => {
        try {
            const { Errors, Message, Success } = await GetFetch(GET_CUSTOMERS);

            if (Success) {
                const data = Message.map(({ OccupancyAgreements }) => {
                    return OccupancyAgreements;
                });

                const customerNames = data
                    .flatMap(object => object)
                    .map(property => property.CustomerName)
                    .filter(string => string.length > 0);

                setCustomers(customerNames);
            };

            if (Errors > 0) console.error(Errors);
        } catch (error) {
            console.error(error)
        ;} 
    };

    const onFocusedRowChanged = (
            {
                row,
                rowIndex
            }
        ) => {
        if (row.isSelected === false) setFocusedRowIndex(rowIndex);

        const { data } = row;

        setViewSummaryContractNumberButtonLabel(data.number)
        setSelectedRowData(data)
        setSelectedRowKey(data);
        setViewRecordButton(true);
        setFocusedRowKey(e.component.option('focusedRowKey'));
    };

    const handleRowClick = (e) => {
        setSelectedRowData(e.row.data)
    };

    const handleViewButtonClick = () => {
        navigate(ROUTE_VIEW_SINGLE_PSO_CONTRACT.withSlash, {
            state: { selectedRowData }
        });
    };

    const handleSubmit = async () => {
        try { 
            const searchParameters = [
                ... _region ? [`Region=${_region}`] : [],
                ... _state ? [`State=${_state}`] : [],
                ... _buildingNumber ? [`BuildingNumber=${_buildingNumber}`] : [],
                ... _customerName ? [`CustomerName=${_customerName}`] : []
              ];

            const SEARCHPARAMS = `${ searchParameters.length > 0 ? `?${searchParameters.join('&')}` : '' }`

            const { Errors, Message, Success } = await GetFetch(
                `${GET_CONTRACT_SUMMARIES + SEARCHPARAMS}`
            );

            const data = Message?.map(
                (
                    {
                        AwardDate,
                        ContractId,
                        TaskOrders,
                        EndDate,
                        Number,
                        StartDate
                    }
                ) => {
                    return {
                        awardDate: AwardDate ?? 'N/A',
                        contractId: ContractId ?? 'N/A',
                        endDate: EndDate ?? 'N/A',
                        number: Number ?? 'N/A',
                        startDate: StartDate ?? 'N/A',
                        taskOrders: TaskOrders
                    };
                }
            );
            
            if (Success) { setSummaries(data) }
            if (Errors.length !== 0) console.error(Errors);
        } catch (ex) {
            console.error(`The request for retriving a PSO contract summary is invalid: ${ex}`);
        }
    };

    const editButtonLabel = 
        viewSummaryContractNumberButtonLabel ? 
        `${viewSingleTaskOrderLabel}: ${viewSummaryContractNumberButtonLabel}` : viewSingleTaskOrderLabel;

    const isRecordSelected = !viewRecordButton ? true : false;
    const allowedPageSizes = [ 10, 20, 30 ];

    return (
        <div ref={dataGridRef}>
            <h1>{ title }</h1>
            <div className="block__div--wrapper">
                <SingleRegionSelectField
                    enableValidation={true}
                    label={"Region"}
                    optionUpdatedByUser={({selectedItem}) => set_Region(selectedItem)}
                    value={_region}
                />
                <US_StatesSelectField
                    enableSearch={true}
                    enableValidation={true}
                    optionUpdatedByUser={({selectedItem}) => set_State(selectedItem)}
                    value={_state}
                />
                <BldgNumberDropDownDevEx
                    value={_buildingNumber}
                    setter={set_BuildingNumber}
                />
                <BaseSelectField 
                    data={customers}
                    label={"Customer Name"}
                    optionUpdatedByUser={({ selectedItem }) => set_CustomerName(selectedItem)}
                    value={_customerName}
                />     
            </div>
            <div className="inline__div--button-wrapper">
                <BaseButton 
                    label={'cancel'}
                    onClick={navigateToWelcomeRoute}
                    variant={'outlined'}
                />
                <BaseButton 
                    label={'submit'}
                    onClick={handleSubmit}
                    variant={'outlined'}
                />
                <BaseButton 
                    ariaDisabled={isRecordSelected}
                    disabled={isRecordSelected}
                    label={editButtonLabel}
                    onClick={handleViewButtonClick}
                    variant={'outlined'}
                />
            </div>
            {
                summaries &&
                <div className="block__div--wrapper">
                    <DataGrid
                        columnAutoWidth={true}
                        ref={dataGridRef}
                        dataSource={summaries}
                        focusedRowKey={focusedRowKey}
                        focusedRowEnabled={true}
                        keyExpr={"contractId"}
                        keyboardNavigation={{
                            enabled: true,
                            enterKeyAction: 'none',
                            enterKeyDirection: 'none'
                        }}
                        onCellPrepared={CustomPreparedHeaderCell}
                        onFocusedRowChanged={onFocusedRowChanged}
                        onRowClick={handleRowClick}
                        rowAlternationEnabled={true}
                        scrolling={{ mode: 'standard' }}
                        width={"92vw"}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                    >
                        <KeyboardNavigation
                            enabled={true}
                        />
                        <Paging defaultPageSize={10} />
                        <Pager
                            allowedPageSizes={allowedPageSizes}
                            showPageSizeSelector={true}
                        />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Column 
                            caption="Id"
                            dataField="contractId"
                            visible={false}
                        />
                        <Column 
                            caption="Contract Number"
                            dataField="number"
                        />
                        <Column 
                            caption="Contract Start Date"
                            dataField="startDate"
                        />
                        <Column 
                            caption="Contract End Date"
                            dataField="endDate"
                        />
                        <Column 
                            caption="Award Date"
                            dataField="awardDate"
                        />
                    </DataGrid>
                </div>
            }
        </div>
    );
};

export default PSO_ContractCostSummaryPage;