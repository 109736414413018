import { useState } from "react";
import "./CustomAccordion.scss";

export const CustomAccordion = ({itemList, description}) => {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    
    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen)
    };

    return (
        <div className="inline__elm--accordion">
            <button 
                aria-controls="accordion-control"
                aria-expanded={isAccordionOpen ? true : false}
                className="button__accordion--toggle" 
                onClick={toggleAccordion}
            >
                <span className="modifier__elem--button-text">
                    Keyboard Navigation Instructions
                </span>
                <span className="modifier__elem--accordion-indicator">
                    { isAccordionOpen ? "-" : "+" }
                </span>
            </button>
            {
                isAccordionOpen &&
                <div 
                    aria-atomic="true"
                    aria-hidden="false"
                    aria-live="polite"
                    id="accordion-control"    
                >
                     {description}  <span>{itemList}</span>
                </div>
            }
        </div>
    );
};
