import React from "react";
import { usStates } from "../util/StateAbbreviations";
import IsNullOrEmpty from "../../components/IsNullOrEmpty";
import { BaseSelectField } from "../../components/BaseSelectField/BaseSelectField";

const US_StatesSelectField = (
    {        
        enableSearch,
        isVisible, 
        optionUpdatedByUser,
        value
    }
) => {
    const data = [
        ...usStates
    ];

    const abbreviatedStates = data.map((values) => values.value);

    const formLabel = "State (Abbrev.) *";
    return (
        <>
   
            <BaseSelectField 
                data={abbreviatedStates}
                isVisible={isVisible}
                label={formLabel}
                enableSearch={enableSearch}
                onInitialized={(e) => e.component.validate()}
                onOptionChanged={(e) => e.component.validate()}
                optionUpdatedByUser={optionUpdatedByUser}
                validationRuleCallback={() => !IsNullOrEmpty(value)}
                value={value}
            />

        </>
    );
};

export default US_StatesSelectField;