import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const InfoIconComponent = ({ ariaLabel, item }) => {
    return (
        <>
            <Tooltip
                title={`Altered ${item}`} 
            >
                <IconButton
                    aria-label={`Altered ${ariaLabel}`}
                    color="primary"
                    edge="start"
                    size="small"
                    >
                    <InfoIcon 
                        color="info"
                        /> 
                </IconButton>
            </Tooltip>
        </>
    );
};

export function InfoIconDevExtremeComponent(cellData) {
    return (
        <>
            {JSON.stringify(cellData)}
        </>
    );
};

export default InfoIconComponent;