import { DropDownBox, List, TextBox } from "devextreme-react";
import { useEffect, useState, useRef } from "react";
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import { useAuth } from '../../hooks/useAuth';
import { LoadingIndicatorCircle } from "../../components/Loading";
import GetFetch from "../../hooks/GetFetch";
import ClosingAlert from "../../components/ClosingAlert";
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';

const OASingleSelectDevEx = ({ value, setter, selectedBuilding, isRequired }) => {
    const [oaList, setOAList] = useState([]);
    const [allOAList, setAllOAList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [selectedOA, setSelectedOA] = useState(value?.OANumber)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const dropDownBoxRef = useRef(null);
    const { user } = useAuth();
    const [selectedKey, setSelectedItemKey] = useState(value?.OANumber);

    useEffect(() => {
        if (selectedBuilding) fetchOccupancyAgreements();
        //setSelectedOA(null)
    }, [selectedBuilding])

    useEffect(() => {
        if (allOAList) {
            if (allOAList?.map(oa => oa.OANumber).includes(selectedOA)) {
                setter(allOAList.filter(oa => oa.OANumber === selectedOA)[0])
            }
            else {
                setter(null)
            }
        }
    }, [selectedOA, allOAList])


    const title = isRequired == true ? "OccupancyAgreement *" : "OccupancyAgreement";
    const fetchOccupancyAgreements = async () => {
        setLoading(true);

        const oaList = await GetFetch('/v1/OccupancyAgreement/GetByBuilding?buildingNumber=' + selectedBuilding)

        if (oaList.Success === true) {
            if (oaList.Message.length > 0 && oaList.Message.filter(oa => oa.OANumber).length > 0) {
                //Filter out blank and duplicate OA Numbers
                let allSeqNumbers = oaList.Message
                    .filter((oa, index, current_value) => (oa.OANumber && current_value.findIndex(t => (t.OANumber === oa.OANumber)) === index));
                if (allSeqNumbers.map(oa => oa.OANumber).includes(value?.OANumber)) {
                    setSelectedOA(value?.OANumber);
                    setSelectedItemKey(value?.OANumber);
                }
                else {
                    setSelectedOA(null);
                    setSelectedItemKey(null);
                }
                setOAList(allSeqNumbers.map((oa) => oa.OANumber));
                setAllOAList(allSeqNumbers);
            }
            else {
                setError(true)
                setErrorMessage("No Occupancy Agreements found for this building")
                setOAList([])
                setAllOAList([])
            }
            setLoading(false)
        }
        else {
            setError(true)
            setErrorMessage(oaList.Errors[0].Message)
            setLoading(false)
        }
    }

    const onSearchValueChanged = (e) => {
        let searchedVal = e.value;
        setSearchValue(searchedVal);
        if (typeof searchedVal === "string" && searchedVal) {
            let filteredList = allOAList
                .map((oa) => oa.OANumber)
                .filter((oa) => {
                    return oa.toUpperCase()
                        .includes(searchedVal.toUpperCase())
                });
            if (filteredList && filteredList.length > 0)
                setOAList(filteredList)
            else if (filteredList && filteredList.length === 0) {
                setter('');
                setSelectedItemKey();
                setOAList(filteredList);
            }
        }
        else if (typeof searchedVal === "string" && searchedVal === '') {
            setter('');
            setSelectedItemKey();
            setOAList(allOAList.map((oa) => oa.OANumber));
        }

    };

    return (
        <>
            {(loading) && (
                <LoadingIndicatorCircle message="Loading Occupancy Agreement Numbers, please wait ..." />
            )}
            {!loading && oaList &&
                <div className="inline__div--wrapper">
                    <DropDownBox
                        label={title}
                        labelMode="floating"
                        height={45}
                        value={selectedOA}
                        dataSource={oaList}
                        ref={dropDownBoxRef}
                        onValueChanged={(e) => {
                            setSelectedOA(e.value);
                        }}
                        isValid={!isRequired || (selectedOA != null && selectedOA != '')}
                    >
                        <TextBox
                            value={searchValue ?? ""}
                            onValueChanged={onSearchValueChanged}
                            placeholder="Search..."
                            showClearButton={true}
                        />
                        <List
                            selectedItemKeys={[selectedKey]}
                            selectionMode="single"
                            showSelectionControls={true}
                            ref={dropDownBoxRef}
                            onSelectionChanged={(e) => {
                                console.log(e.addedItems[0])
                                setSelectedOA(e.addedItems[0]);
                                setSelectedItemKey(e.addedItems[0]);
                                dropDownBoxRef.current.instance.close();
                            }}
                            dataSource={oaList}
                            pageLoadMode="scrollBottom"
                        />
                    </DropDownBox>
                    <ClosingAlert
                        severity="error"
                        message={errorMessage}
                        visible={error}
                        hideAlert={() => { setError(false) }}
                    />
                </div>
            }
        </>
    )
}
export default OASingleSelectDevEx;