import { useRouteError } from "react-router-dom";
import CustomCard from "../util/CustomCard";
import Typography from '@mui/material/Typography';
import { rmsVersion, rmsDeployment } from "../util/reactAppEnvVariables";

const ErrorPage = () => {
    const error = useRouteError();
    // console.error(error);
    return (
        <div id="error-page"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
            }}>
            <CustomCard>
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error.statusText || error.message}</i>
                </p>
                <Typography
                    component='div'
                >
                    <h6 style={{ color: "#06152B" }}>RMS version: {rmsVersion}-{rmsDeployment}</h6>
                </Typography>
            </CustomCard>
        </div>
    );
}
export default ErrorPage;