import { useAuth } from '../hooks/useAuth';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {
    useNavigate,
} from 'react-router';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SessionContext from '../App/SessionContext';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch';
import { RMSAUTHSAML_URL } from '../util/reactAppEnvVariables.js';
import { useLocalStorage } from "../hooks/useLocalStorage";

import React, {
    useState,
    useEffect,
} from 'react';
import axios from 'axios';
import { dhsAuthSvcUrl } from '../util/Endpoint';
import ClosingAlert from '../components/ClosingAlert';

const LoginOkta = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const { login, logout } = useAuth();
    const [unverifiedUser, setUnverifiedUser] = useLocalStorage("unverifiedUser", null);
    const navigate = useNavigate();
    useEffect(() => {
        axios({
            method: 'GET',
            url: dhsAuthSvcUrl() + RMSAUTHSAML_URL,
            withCredentials: true
        })
            .then(response => {
                if (response.data.user.nameID) {
                    console.log("response.data.user.nameID was NOT NULL .. setting email and Session Context.");
                    setEmail(response.data.user.nameID);
                    SessionContext.set("UserId", response.data.user.nameID);
                    const userUnverifiedData = {
                        "IsAuthorized": false,
                        "email": response.data.user.nameID,
                      }  
                      setUnverifiedUser(userUnverifiedData);
                }
                else {
                    console.log("response.data.user.nameID was not found.. RedirectToDhsAUth() Called.");
                    setUnverifiedUser(null);
                    RedirectToOktaAuth();
                }
            })
            .catch(error => {
                console.log("error.. RedirectToDhsAUth() Called." + error);
                RedirectToOktaAuth();
            })
    }, []);
    const handleSubmit = async () => {
        let userAcceptance = {
            "UserAcceptedTerms": true,
            "UtcTimezoneOffsetInMinutes": (new Date()).getTimezoneOffset(),
            "UserAccount": SessionContext.get("UserId")
        };

        const userAccepted = await PutPostPatchFetch('/v1/SystemUser', 'POST', userAcceptance);
        if (userAccepted.Success === true) {
            SessionContext.set("SecurityAccepted", true);
            try {
                await login({ email: email })
                setError(false);
                setErrorMessage("");
            }
            catch (ex) {
                console.log("login exception:", ex)
                setUnverifiedUser(null);
                logout('error', `Failed to login. Error Message: "${ex}" User successfully logged out.`), false;
            }

        }
        else {
            setError(true);
            setErrorMessage(userAccepted.Message);
            //logout
            console.log("SecurityBanner.js - Error - ", userAccepted.Errors);
        }
    }
    const RedirectToOktaAuth = () => {
        window.location.replace(dhsAuthSvcUrl() + '/login');
    }
    function handleLogout() {
        logout('warning', 'Login not completed since user did not accept the security banner.', false);
    }
    return (
        <>
            {email ?
                <Box
                    textAlign='center'
                    sx={{ p: 3 }}
                >
                    <Typography variant="h6" align="center" component="div" mt={2}>
                        You must accept the U.S Government System terms to sign into this website
                    </Typography>
                    <Paper sx={{ p: 2, mt: 2 }}>
                        <Typography variant="p" align="left" component="div" mt={2}>
                            You are accessing a U.S. Government information system, which includes (1) this computer,
                            (2) this computer network, (3) all computers connected to this network and (4) all devices
                            and storage media attached to this network or to a computer on this network. This information
                            system is provided for U.S. Government-authorized use only.
                            <br />
                            Unauthorized or improper use or access of this system may result in disciplinary action,
                            as well as civil and criminal penalties. By using this information system, you understand
                            and consent to the following:
                            <li>
                                You have no reasonable expectation of privacy when you use this information system;
                                this includes any communications or data transiting, stored on, originated from or directed
                                to this information system. At any time, and for any lawful government purpose, the
                                government may monitor, intercept, search and seize any communication or data transiting,
                                stored on, originated from or directed to or from this information system.
                            </li>
                            <li>
                                The government may disclose or use any communications or data transiting, stored on,
                                originated from or directed to or from this information system for any lawful government
                                purpose.
                            </li>
                            <li>
                                You are NOT authorized to process classified information on this information system.
                            </li>
                        </Typography>
                    </Paper>
                    <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        size="small"
                        onClick={handleSubmit}
                        align="center"
                        sx={{ m: 1, mt: 3 }}
                    >
                        Accept
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        size="small"
                        onClick={handleLogout}
                        align="center"
                        sx={{ m: 1, mt: 3 }}
                    >
                        Decline
                    </Button>
                    <ClosingAlert
                        severity="error"
                        message={errorMessage}
                        visible={error && errorMessage != ""}
                        hideAlert={() => { setError(false) }}
                    />
                </Box>
                : <p>Please wait ...</p>
            }
        </>
    );
}
export default LoginOkta;
