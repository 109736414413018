import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useRef, useState } from "react";
import { useEffect } from "react";
import GetFetch from "../../hooks/GetFetch";
import { LoadingIndicatorCircle } from "../../components/Loading";
import { DropDownBox, List, TextBox } from "devextreme-react";
import Validator, { RequiredRule } from 'devextreme-react/validator';


export default function FiscalYearMultiSelectDevEx({ value, valueSetter }) {
    const [loading, setLoading] = useState(false);
    const [listOfFiscalYears, setListOfFiscalYears] = useState([]);
    const [allFiscalYears, setAllFiscalYears] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    useEffect(() => {
        fetchFiscalYears();
    }, []);
    const dropDownBoxRef = useRef(null);
    const selectionMode = "multiple";
    const fetchFiscalYears = async () => {
        setLoading(true);
        const result = await GetFetch('/v1/BillPeriod');
        const { Success, Message, Errors } = result;
        if (Success) {
            var displayFiscalYears = [{ FiscalYear: "All" }].concat(Message);
            setListOfFiscalYears(displayFiscalYears);
            setAllFiscalYears(Message);
        }
        else {
            console.log("What went wrong:", Errors);
        }
        setLoading(false);
    };
    const onSearchValueChanged = (e) => {
        let searchedVal = e.value;
        var list = listOfFiscalYears.map(x => x.FiscalYear);
        let newVal = [];
        let val = [];
        let intList = [];
        let valArray = typeof searchedVal === "string" && searchedVal !== '' ? searchedVal.split(/[\s+,]/) : null;
        if (typeof searchedVal === "string" && searchedVal === '') {
            setSelectedKeys([])
            valueSetter([])
            return;
        }

        if (valArray && valArray.length > 0) {
            valArray.forEach(element => {
                var int = parseInt(element);

                if (list.indexOf(int) >= 0 && !intList.includes(int)) {
                    intList.push(int)
                }
                if (element.toUpperCase() === "ALL") {
                    intList = list
                }
            });
            val = listOfFiscalYears.filter(x => intList.includes(x.FiscalYear));
            if (val.length > 0) {
                let v = val.map((yrs) => yrs.FiscalYear);
                newVal.push(v);
            }
        }
        if (newVal.length > 0) {
            setSelectedKeys(val);
            valueSetter(newVal);

        }

    };
    const onSelectedItemKeysChange = ({ name, value }) => {

        if (name === 'selectedItemKeys') {
            if (selectionMode !== 'none' || selectedKeys.length !== 0) {

                let newValue = value.map((yrs) => yrs.FiscalYear);
                if (newValue.includes("All")) {
                    const allYears = allFiscalYears.map((year) => year.FiscalYear);
                    valueSetter(allYears);
                    setSelectedKeys(listOfFiscalYears);
                }
                else {
                    let newList = value.map((yrs) => yrs.FiscalYear);
                    let selectedList = selectedKeys.map((item) => item.FiscalYear);
                    if (selectedList.includes("All") && !newList.includes("All")) {
                        valueSetter([]);
                        setSelectedKeys([]);
                    }
                    else {
                        setSelectedKeys(value);
                        valueSetter(newValue);
                        selectedKeys;
                    }
                }

            }
        }
    };

    const contentRender = (e) => {
        return (
            <div>
                <TextBox
                    value={value}
                    onValueChanged={onSearchValueChanged}
                    placeholder="Search..."
                />
                <List
                    selectedItemKeys={selectedKeys}
                    showSelectionControls={true}
                    selectionMode="multiple"
                    ref={dropDownBoxRef}
                    displayExpr="FiscalYear"
                    onOptionChanged={onSelectedItemKeysChange}
                    dataSource={listOfFiscalYears}
                >
                </List>
            </div>
        );
    };
    return (
        <div>
            {(loading && !listOfFiscalYears) && (
                <LoadingIndicatorCircle message="Loading Fiscal Years, please wait ..." />
            )}
            {
                listOfFiscalYears &&
                (
                    <FormControl sx={{ width: 150 }}>
                        <InputLabel id="fy-autocomplete-label"></InputLabel>
                        <div id="myDropDownBox" role="combobox" aria-haspopup="listbox" aria-expanded="false" aria-owns="listOfFiscalYears" aria-required="true">
                            <DropDownBox
                                label="Fiscal Year(s)*"
                                labelMode="floating"
                                height={56}
                                width={170}
                                value={value}
                                valueExpr="FiscalYear"
                                displayExpr="FiscalYear"
                                dataSource={listOfFiscalYears}
                                ref={dropDownBoxRef}
                                tabIndex={0}
                                contentRender={contentRender}
                            >
                                <Validator
                                    onInitialized={(e) => e.component.validate()}
                                    onOptionChanged={(e) => e.component.validate()}
                                >
                                    <RequiredRule type='required' />
                                </Validator>

                            </DropDownBox>
                        </div>
                    </FormControl>
                )}
        </div>
    );
}
