import {
    useEffect,
    useState
} from "react";
import DataGrid, {
    Column,
    Button,
    Export,
    Editing,
    KeyboardNavigation,
    Paging,
    Pager,
    PatternRule,
    Popup,
    RequiredRule,
    Selection,
    SearchPanel
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import {
    Typography
} from "@mui/material";
import TotalCostPerRegionAndGrandTotalTable from "./TotalCostPerRegionAndGrandTotalTable";
import CustomPreparedHeaderCell from "../components/CustomPreparedHeaderCell";
import PutPostPatchFetch from "../hooks/PutPostPatchFetch";
import { logExport, useAuth } from "../hooks/useAuth";
import ExportGrid from '../components/ExportGrid';
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import roundedFormatter from '../components/RoundedFormatter';
import ClosingAlert from "../components/ClosingAlert";
import FiscalYearList from '../hooks/FiscalYear';
import FYCostsTableModifiers from "./FYCostsTableModifiers";
export default function DataGridCostEstimate({ setter, data, allowEditing, allowApprove }) {
    const [loading, setLoading] = useState(false);
    const [promiseArguments, setPromiseArguments] = useState(null);
    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const [selectedRow, setSelectedRow] = useState(false);
    const [snackbar, setSnackbar] = useState(null);
    const [pso, setPso] = useState();
    const [tcm, setTcm] = useState();
    const [amort, setAmort] = useState();
    const [grandTotal, setGrandTotal] = useState();
    const { user } = useAuth();

    useEffect(() => {
        let psov = 0;
        let tcmv = 0;
        let amort = 0
        data.forEach(element => {
            if (element.securityCostType === 'PSO') {
                psov += getAmount(element.charges);
            }
            if (element.securityCostType === 'TCM') {
                tcmv += getAmount(element.charges);
            }
            if (element.securityCostType === 'AMORCAP') {
                amort += getAmount(element.charges);
            }
        });
        setPso(psov);
        setTcm(tcmv);
        setAmort(amort);
    }, []);

    useEffect(() => {
        setGrandTotal(pso + tcm + amort);
    }, [pso, tcm, amort]);

    const dataSource = new CustomStore({
        key: 'id',
        update: (key, value) => { console.log(key); console.log(value) },
        load: async () => {
            let checkedData = []
            if (data)
                checkedData = data.map(
                    (
                        {
                            id,
                            approvalStatus,
                            billDate,
                            buildingSequenceNo,
                            charges,
                            fiscalYear,
                            month,
                            region,
                            securityCostType
                        }
                    ) => {
                        return {
                            id: id,
                            approvalStatus: approvalStatus,
                            billDate: billDate,
                            buildingSequenceNo: buildingSequenceNo,
                            charges: charges,
                            fiscalYear: fiscalYear,
                            month: month,
                            region: region,
                            securityCostType: securityCostType
                        };
                    }
                );

            return checkedData;
        }
    });

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleSecurityCostSetter = (newRow, oldRow, securityType) => {
        let localPso = pso;
        let localTcm = tcm;
        let localAmort = amort;

        if (securityType.securityCostType === 'PSO') {
            localPso -= getAmount(oldRow, '');
            localPso += getAmount(newRow);
            setPso(localPso);
        };
        if (securityType.securityCostType === 'TCM') {
            localTcm -= getAmount(oldRow, '');
            localTcm += getAmount(newRow);
            setTcm(localTcm);
        };
        if (securityType.securityCostType === 'AMORCAP') {
            localAmort -= getAmount(oldRow, '');
            localAmort += getAmount(newRow);
            setAmort(localAmort);
        };
    };

    const onSaving = async (e) => {
        handleYes(e);
    }
    
    const onContentReady = async (e) => {
        e.component.getCombinedFilter();
    ;}

    const handleYes = async (e) => {
        try {
            const securitiesCostObject = [{}];
            const securitiesSetterCostObject = [{}];
            for (let i = 0; i < e.changes.length; i++) {
                let newRowId = e.changes[i].key;
                let charges = getAmount(e.changes[i].data.charges);
                let newRow = e.changes[i].data.charges;
                let oldRow = e.changes[i].key.charges;
                let securityType = e.changes[i].key;
                securitiesCostObject[i] = { "SecurityCostId": newRowId, "Charges": charges, "UserAccount": user.email, "ApprovalStatus": "Submitted" }
                securitiesSetterCostObject[i] = { "OldRow": oldRow, "NewRow": newRow, "Charges": charges, "UserAccount": user.email, "SecurityType": securityType }

            };
            setLoading(true);
            await PutPostPatchFetch('/v1/SecurityCostBatch', 'PUT', securitiesCostObject);
            handleChanges(e.changes)
            setSnackbar({
                children: "Charges successfully saved",
                severity: "success"
            });

            for (let i = 0; i < securitiesSetterCostObject.length; i++) {
                let newRowSetter = securitiesSetterCostObject[i].NewRow;
                let oldRowSetter = securitiesSetterCostObject[i].OldRow;
                let securityTypeSetter = securitiesSetterCostObject[i].SecurityType;

                handleSecurityCostSetter(newRowSetter, oldRowSetter, securityTypeSetter);

            }
            setLoading(false);
            setPromiseArguments(null);
        }
        catch (error) {
            setSnackbar(
                {
                    children: "Charges couldn't be updated, please try again later.",
                    severity: "error"
                }
            );
            for (let i = 0; i < securitiesSetterCostObject.length; i++) {
                let oldRowReject = securitiesSetterCostObject[i].OldRow;
                reject(oldRowReject);

            }
            setPromiseArguments(null);
            setLoading(false);
        }
    };
    const allowEditForCurrentFY = (data) => {
        var res = false
        if (data && data.length > 0 && typeof data[0].fiscalYear === "number"
        && selectedRowKey && selectedRowKey.approvalStatus !== "Approved")
        {
            var fy = FiscalYearList();
            res = fy.includes(data[0].fiscalYear);
        }
        return res;
    
    }
    const getAmount = (charges) => {
        return parseFloat(charges.replace(/[$,]/g, ""));
    };

    const handleFocusedRowChange = (e) => {
        const { data } = e.row;
        console.log(data)
        setSelectedRowKey(data);
    };

    const handleEnterKeyPress = (e) => {
        const key = e.event?.originalEvent?.key;

        if (key === "Enter") {
            handleRowClick()
        };
    };

    const handleRowClick = () => {
        console.log("selected")
        setSelectedRow(true);
    };

    const approvalChanged = (value) => {
        var modData = data.map(adj => {
            if (adj.id == selectedRowKey.id) {
                adj.approvalStatus = value
            }
            return adj
        })
        setter(modData)
    }

    const handleChanges = (values) => {
        try {
            var modData = data.map(adj => {
                if (values) {
                    var mod = values.filter(val => val.key === adj.id)
                    if (mod?.length > 0 && mod[0].key === adj.id) {
                        adj.charges = mod[0].data.charges
                        adj.approvalStatus = "Submitted"
                    }
                }
                return adj
            })
            setter(modData)
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleExport = (e) => {
        logExport(user.email)
        ExportGrid(e, "FY_Cost_Estimate_Data")
    }
    //const buttonRender = (data) => {
    //    return <div>Approve</div>;
    //}

    return (<>
        <TotalCostPerRegionAndGrandTotalTable
            pso={pso}
            tcm={tcm}
            grandTotal={grandTotal}
            amort={amort}
        />
        <FYCostsTableModifiers
            selectedRowKey={selectedRowKey}
            roleAllowApprove={allowApprove}
            roleAllowEdit={allowEditing}
            rowSelected={selectedRow}
            onApprovalChange={approvalChanged}
        />
        <Typography
            variant="body2"
            component="p"
        >
            Related Security Costs
        </Typography>
        <span
            aria-live="polite"
            aria-relevant="text"
            aria-atomic="true"
        >
            <DataGrid
                columnAutoWidth={true}
                dataSource={dataSource}
                onCellPrepared={CustomPreparedHeaderCell}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                onExporting={e => handleExport(e)}
                onSaving={onSaving}
                onContentReady={(onContentReady)}
                focusedRowEnabled={true}
                keyboardNavigation={{
                    enabled: true,
                    enterKeyAction: 'none',
                    enterKeyDirection: 'none'
                }}
                onFocusedRowChanged={handleFocusedRowChange}
                onKeyDown={handleEnterKeyPress}
                onRowClick={handleRowClick}
            >
                <Selection mode="single" />
                <KeyboardNavigation enabled={true} />
                <Paging defaultPageSize={10} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                />
                <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                />
                <Column dataField="region" caption="Region" allowEditing={false} />
                <Column dataField="approvalStatus" caption="Approval Status" allowEditing={false} />
                <Column
                    dataField="buildingSequenceNo"
                    caption="Building Number"
                    allowEditing={false}
                />
                <Editing
                    allowUpdating={allowEditing}
                    mode="batch"
                />
                <Column dataField="securityCostType" caption="Security Cost Type" allowEditing={false} />
                <Column dataField="charges" caption="Charges" dataType="number" alignment="left" allowEditing ={allowEditForCurrentFY(data)} cellRender={data => {
                    const charges = data.data.charges;
                    const cleanedCharges = charges.replace(/[$,]/g, '');
                    const numericValue = parseFloat(cleanedCharges);
                    if (Number.isInteger(numericValue)) {
                        return <span>{roundedFormatter.format(numericValue)}</span>;
                    }
                    return <span>No Decimal Point</span>;
                }}>
                    <RequiredRule />
                    <PatternRule
                        message={'Your amount should be a whole dollar number!'}
                        pattern={/^[0-9]+$/} // Use regex to match whole numbers only
                    />
                </Column>
                <Column dataField="fiscalYear" caption="Fiscal Year" allowEditing={false} />
                <Export
                    enabled={true}
                    formats={pageFormats}
                />
                <Popup title="Charges" showTitle={true}
                    width={500}
                    height={500} />
            </DataGrid>
        </span>
                <ClosingAlert visible={!!snackbar} severity={snackbar?.severity} message={snackbar?.children} hideAlert={handleCloseSnackbar} />
    </>
    );
}
